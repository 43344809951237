<div class="share-card-div">

    <div class="heading-4 share-card-title">Share Options</div>


     <share-buttons
     [include]="['whatsapp','facebook','twitter','telegram','messenger','linkedin','pinterest','sms','email','copy']"
     [show]="10"
     [showText]="true"
     [url]="url?url:''" (opened)="opened($event)" (windowClosed)="closed($event)" (popupClosed)="popupClosed($event)"></share-buttons>
</div>
