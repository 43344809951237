import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cart_items:any;
  myproducts!:any[];
  product_id:any[]=[];

  token=  this.commonService.getLocalStorage('token');

  constructor(public commonService:CommonService, public router:Router, public ngbModal:NgbModal) { }

  ngOnInit(): void {
    //this.commonService.deleteLocalStorage('newcart');
    this.getCartItems();
  }

  getCartItems(){
      this.cart_items= this.commonService.getLocalStorage('newcart');
      if(this.cart_items){
         this.myproducts= this.cart_items['items'][0]['products'];
         this.product_id= this.cart_items['id'];
      }
  }

  increment(i: number){
      this.myproducts[i]['quantity']++;
      this.saveCart();
  }

  decrement(i: number){
    if(this.myproducts[i]['quantity']>1){
    this.myproducts[i]['quantity']--;
    this.saveCart();
    }
  }

  saveCart(){
    if(this.cart_items && this.myproducts.length>0 && this.product_id.length>0){
      let cart= {
        id: this.product_id,
        items: [{
                   creator_id: this.cart_items['items'][0]['creator_id'],
                   products: [...this.myproducts]
                }]
      }
      this.commonService.setLocalStorage('newcart',cart);
    }else{
      this.commonService.deleteLocalStorage('newcart');
    }
  }

  removeCartItem(i:number, id: number){
     let index= this.product_id.indexOf(id);
     if(index!==-1){
      this.product_id.splice(i,1);
     }
      this.myproducts.splice(i,1);
      this.saveCart();
  }

  login_successfull(){
    this.token=  this.commonService.getLocalStorage('token');
  }

  goToCheckout(data:any){
    this.token=  this.commonService.getLocalStorage('token');
    if(!this.token){
      this.ngbModal.open(data,{size:'md'});
    }else{
    this.router.navigate(['/user/checkout']);
    }
  }

  goToList(){
     this.router.navigate(['/shop/home']);
  }

  ngOnDestroy(){
      if(this.cart_items && this.myproducts.length>0 && this.product_id.length>0){
      let cart= {
        id: this.product_id,
        items: [{
                   creator_id: this.cart_items['items'][0]['creator_id'],
                   products: [...this.myproducts]
                }]
      }
      this.commonService.setLocalStorage('newcart',cart);
    }else{
      this.commonService.deleteLocalStorage('newcart');
    }
  }

}
