<!--<div class="AllPostTag">
    <div style="display: flex;margin: 10px;">
        <img (click)="back_to_news_home()" class="reaction-list-back-icon" src="./../../../../assets/icons/back-button.svg">
        <h5 style="margin-top: auto; margin-bottom: auto;">Showing result for <span style="font-weight: 800;">{{tag.toUpperCase()}}</span></h5>
    </div>
    <app-posts-list [posts]="post" [type]="'feed_with_ads'"></app-posts-list>
</div>-->

<div class="new-all-posts-layout">
    <div class="new-all-posts-layout-left">
        <div style="display: flex;margin: 10px 0px 20px;">
            <img (click)="back_to_news_home()" class="reaction-list-back-icon" src="./../../../../assets/icons/back-button.svg">
            <h5 style="margin-top: auto; margin-bottom: auto;">Showing result for <span style="font-weight: 800;">{{tag.toUpperCase()}}</span></h5>
        </div>
        <app-posts-list *ngIf="!is_content_loading" [posts]="post" [type]="'feed_with_ads'"></app-posts-list>
        <div class="sk-all-posts-outer-div" style="min-height: 100vh;min-width: 100%;position: relative;display: block;" *ngIf="is_content_loading"> 
    
            <div class="sk-post mb-em-2" style="background-color: white" *ngFor="let i of skeleton_posts">
               
              <div class="d-flex align-center mb-em-1">
                <div class="sk-circle mr-px-10"></div>
                <div class="sk-rect-div">
                  <div class="sk-sm-rect mb-px-10" style="max-width: 50%;min-height: 20px;"></div>
                  <div class="sk-sm-rect" style="max-width: 40%;"></div>
                </div>
               </div>
          
               <div class="sk-all-post-image mb-em-1"></div>
               <div class="sk-rect-div">
                <div class="sk-sm-rect mb-px-10" style="min-height: 20px;"></div>
                <div class="sk-sm-rect" style="min-height: 60px;"></div>
              </div>
              </div>
          
          
          </div>
    </div>



<div class="new-all-posts-layout-right">
    <div class="new-post-detail-layout-adv">
    <app-topslide-page [billboard]="billboard" [topslides]="topslides_list"></app-topslide-page> 

        <!--Featured Posts div--> 
        <div class="featured-stories-div">

           <div class="divider-line-3"></div>

           <app-featured-stories [featured_stories]="featured_posts"></app-featured-stories> 
        </div> 
        <!--End Featured Posts div-->
     </div> 
<!--End of Advertisement and people you may know section-->
<!--new post layout right end-->
</div>

</div>