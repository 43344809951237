import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyCartComponent } from '../common-utility/my-cart/my-cart.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { MyCheckoutComponent } from './my-checkout/my-checkout.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';

const routes: Routes = [
/*
  {path:'cart', component: MyCartComponent},
  {path:'checkout', component: MyCheckoutComponent},
  {path:'orders',component: AllOrdersComponent},
  {path:'coming-soon',component: ComingSoonComponent},
  {path:'order-detail/:id',component: OrderDetailComponent}*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonUtilityRoutingModule { }
