import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({ 
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {
  }

  goToHome(){
    this.router.navigate(['/home'])
  }
}
