import { Injectable } from '@angular/core';
import { Observable , BehaviorSubject } from 'rxjs';
import { config } from './../../core/config/config';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(public http:HttpClient) {
   }

   fetchFollowersList(){
    return  this.http.get(`${config.endpoint}3km/followers/list`);
   }

   fetchJansevakContestResults(){
    return this.http.get(`${config.endpoint}3km/contest/results`);
   }

   fetchJansevakContestCategoryResults(payload:any){
    return this.http.post(`${config.endpoint}3km/contest/detail`, payload);
   }
}
