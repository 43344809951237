<div class="help-s-div">
    <div class="help-font-3 help-title-1">Contact Us</div>
    <div class="help-font-1 help-title-2">For any queries please contact</div>
    <div>
        <a href="tel: 7020627265" class="help-font-2"><i class="fa fa-phone" style="margin-right: 5px;"></i> 7020627265</a>
    </div>

    <div>
        <a href="mailto:info@5km.city" class="help-font-2"><i class="fa fa-envelope" style="margin-right: 5px;"></i> info@5km.city</a>
    </div>
</div>

