import { Component, HostListener, OnInit, ViewChild, Input, ViewEncapsulation, OnDestroy, ChangeDetectorRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { NewsService } from 'src/app/core/services/news/news.service';
import { OwlOptions} from 'ngx-owl-carousel-o';
import { map } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import * as urlSlug from 'url-slug';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-topslide-page',
  templateUrl: './topslide-page.component.html',
  styleUrls: ['./topslide-page.component.scss'],
})

export class TopslidePageComponent implements OnInit {

  amz_img='https://m.media-amazon.com/images/I/61W7kEbHCHL._SX425_.jpg';

  customOptionsAdv: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    autoplay: true,
    autoplaySpeed:2000,
    autoplayTimeout: 5000,
   // dots: true,
    pullDrag: true,
    center: true,
    lazyLoad: true,
    dots: false,
    slideBy:1,
    navSpeed: 700,
    navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    items: 1.4,
  //  center: true,
    nav: false
  }

  cur_url='';
  cur_video_type='video';

  @Input() topslides:any;
  @Input() billboard:any;

  sample="https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/0/1b2520c0-e3d5-11ec-9e3a-29fd52e50ecd.png"

  constructor(public router:Router,public modal:NgbModal) { }

  ngOnInit(): void {
     if(window.innerWidth<600){
      this.customOptionsAdv={...this.customOptionsAdv, items: 1.2 , center: true}
    } 
  }

  goToUrl(b:any,content:any){
     if(b['imageswcta'][0]['post_id']){
           this.router.navigate(['/post-detail/5km-post-detail/'+b['imageswcta'][0]['post_id']]);
     }else if(b['imageswcta'][0]['video']){
           this.cur_url= b['imageswcta'][0]['video'];
           this.cur_video_type= b['imageswcta'][0]['type']=='vimeo'?'vimeo':'normal';
           this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'});
     }
  }

}
