<div class="cart-outer-div checkout-outer-div">

    <!--Select address div-->
    <div class="checkout-select-address-div" *ngIf="type=='address'">
    
        <div class="back-btn-main-div category-back-title mb-px-20" style="position: sticky;z-index: 10;justify-content:flex-start;top:0px;">
            <div class="cursor" (click)="goToCart()"><img class="back-icon cursor" src="./../../../../assets/icons/back-button.svg"></div>
            <div class="shop-category-title">{{sub_type=='addaddress'?'Add Address':'Select Address'}}</div>
        </div>
        
        <div class="mobile-addressdiv">
           <div *ngIf="sub_type=='addaddress'">
                <app-add-address (success)="newAddressAdded($event)"></app-add-address>
           </div>

           <div *ngIf="sub_type=='selectaddress'">
            <div class="checkout-font-2 poppins-medium blue text-center mb-px-20 cursor" (click)="sub_type='addaddress'">
                <i class="fa fa-plus blue"></i> Add new address
             </div> 

            <app-address-list (success)="addressSelected($event)" [selected_address]="selected_address"></app-address-list>
        </div>
      </div>
    </div>
    <!--Select address div-->

    <div class="checkout-div" *ngIf="type=='payment'" style="padding-bottom:40px;">

         <div class="checkout-items-div" style="position:relative;">

            <div class="back-btn-main-div category-back-title mb-px-20" style="position: sticky;z-index: 10;justify-content:flex-start;top:0px;">
                <div class="cursor" (click)="type='address'"><img class="back-icon cursor" src="./../../../../assets/icons/back-button.svg"></div>
                <div class="shop-category-title">Checkout</div>
            </div>

            <ng-container *ngIf="myproducts && myproducts.length>0">
                <div class="cart-products-div" style="padding: 10px;">

               <!--   <div class="checkout-address-div">
                        <div *ngIf="selected_address">
                         <div class="poppins-medium checkout-font-2">Deliver To:</div>
                         <div class="poppins-medium checkout-font-2">{{selected_address?.firstname}} {{selected_address?.lastname}}</div>
                         <div class="poppins-medium checkout-font-3">{{selected_address?.flat_no}},{{selected_address.landmark}}</div>
                         <div class="poppins-medium checkout-font-3">{{selected_address?.area}},{{selected_address.city}}-{{selected_address?.pincode}}</div>                        </div>

                        <div>
                            <button class="poppins-light checkout-address-btn btn-white" (click)="openAddressModal(addressData)">{{selected_address?'Change Address':'Select Address'}}</button>
                        </div>
                    </div> -->  

                    <div class="poppins-medium checkout-font-2 mb-px-20 mobile-only">Cart Items</div>
            
                    <div *ngFor="let product of myproducts; index as i;">
            
                        <div class="d-flex space-between align-center mb-px-20 cart-product-card-outer-div checkout-product-card-outer-div">
                            <div class="cart-product-card checkout-product-card">
                                <div style="position: relative;">
                                    <img class="news-post-red-close-icon cart-product-discard-icon mobile-only" (click)="removeCartItem(i, product.catalog_id)" src="./../../../../assets/icons/red-close-icon.svg">
                                    <img [src]="product['image']" class="checkout-image"></div>
                                <div class="d-flex align-center">
                                   <div>
                                    <div class="poppins-medium mb-1 text-eclipse checkout-font-3">{{product?.name}}</div>
            
                                    <div class="d-flex column-gap-20 align-center mb-2">
                                        <div class="blue checkout-font-3">
                                            <i class="fa fa-rupee blue"></i>{{product?.selected_price}}
                                        </div>
                                        <div class="font-grey checkout-font-3 line-through" *ngIf="product.price>product.strike_price">
                                            <i class="fa fa-rupee font-grey line-through"></i>{{product?.strike_price}}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                              <!--Count increment/decrement-->
                            <div>
                              <div class="count-controller">
                                <div (click)="decrement(i)" class="cursor"><img src="./../../../../assets/shop/minus-XS-light.svg"></div>
                                <div class="checkout-font-3">{{product?.quantity}}</div>
                                <div (click)="increment(i)" class="cursor"><img src="./../../../../assets/shop/plus-XS-light.svg"></div>
                           </div>
                        </div>
                           <!--End count increment/decrement-->
            
                            <div class="no-mobile ml-2">
                                <img (click)="removeCartItem(i, product.catalog_id)" class="news-post-red-close-icon" src="./../../../../assets/icons/red-close-icon.svg">
                            </div>
                        </div>
                    </div>
                </div>
            
                </ng-container>
         </div>


         <div class="checkout-summary-outer-div">

            <div class="checkout-summary-div">
                 <div class="poppins-medium checkout-font-2 mb-px-20">Order Details</div>

                 <div *ngFor="let product of myproducts">
                      <div class="cart-summary-product-card d-flex space-between align-center mb-px-20" style="gap:15px">
                          <div class="d-flex align-center" style="gap:10px">
                              <img [src]="product.image" class="checkout-image">
                             <div>
                              <div class="poppins-medium checkout-font-3 text-eclipse">{{product?.name}}</div>
                              <div class="lato-medium checkout-font-3 font-grey">Quantity: {{product.quantity}}</div>
                            </div>
                          </div>

                          <div class="blue checkout-font-3">
                            <i class="fa fa-rupee blue"><span class="poppins-medium blue">{{product?.selected_price * product.quantity}}</span></i>
                        </div>

                      </div>
                 </div>

                 <div class="divider-line"></div>
                <!--price div-->
                 <div>
                   <div class="d-flex space-between mb-px-20">
                      <div class="checkout-font-3 font-grey poppins-medium">Shipping Charges</div>
                      <div class="black checkout-font-3" *ngIf="shipping_charge>=0 && is_from_backend">
                        <i class="fa fa-rupee black"></i>{{shipping_charge}}
                    </div>
                    </div>

                    <div class="d-flex space-between mb-px-20">
                        <div class="checkout-font-3 font-grey poppins-medium">Cart Total</div>
                        <div class="black checkout-font-3 poppins-medium">
                          <i class="fa fa-rupee black"></i>{{getTotal()+shipping_charge}}
                      </div>
                      </div>


                 </div>
                <!--price div end-->


                <div class="d-flex justify-flex-end desktop-only">
                    <button class="btn-blue-1 poppins-light checkout-pay-btn desktop-only" [disabled]="shipping_charge==0 && !is_from_backend" (click)="pay()">PAY NOW</button>
                </div>

                 <div>

                 </div>
            </div>
        </div>

          <div class="category-cart-icon mobile-only">
            <!--<app-cart-minimum [count]="input_count"></app-cart-minimum>-->
            <button class="btn-blue-1 poppins-medium checkout-font-2 mobile-only" style="padding: 10px 20px;border-radius: 0px;margin-top: 0px;" (click)="pay()">Pay Now</button>
          </div>

    </div>

     <!--success div-->
  <ng-template #successdata let-modal>
    <app-success [id]="project_id"></app-success>
  </ng-template>
     <!--End success div-->

      <!--success div-->
  <ng-template #addressData let-modal>
    <app-address-list (close)="closeModal()" (success)="addressChange($event)"></app-address-list>
  </ng-template>
     <!--End success div-->

     <div class="category-cart-icon" *ngIf="type=='address' && sub_type=='selectaddress'">
        <!--<app-cart-minimum [count]="input_count"></app-cart-minimum>-->
        <button class="btn-blue-1 poppins-medium checkout-font-2 checkout-cust-btn" (click)="checkAddress()">Continue</button>
      </div>

</div>

