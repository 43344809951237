import { Component, OnInit,Directive} from '@angular/core';
import {ReportService} from './.././../../services/report/report.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss']
})

export class FollowersComponent implements OnInit {

  followers_list=[];

  constructor(public service:ReportService) { }

  ngOnInit(): void {
    this.fetchFollowersList();
  }

  fetchFollowersList(){
     this.service.fetchFollowersList().subscribe((data:any)=>{
       this.followers_list=data['data']['result']['followers'];
     })
  }

}
