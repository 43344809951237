<div class="new-top-bar">

    <!--Notifcaiton dialog-->
    <div class="new-notification-dialog cursor" id="notdialog" (click)="openNotification()">
        <div class="new-notification-dialog-left">
            <img class="notification-img" [src]="not_image">
        </div>
        <div class="new-notification-dialog-right">
            <h4 class="notification-dialog-text-1" style="margin-bottom: 3px;">{{not_title}}</h4>
            <p style="text-align: left;" class="notification-dialog-text-2">{{not_body}}</p>
        </div>
    </div>
    <!--Notifcation dialog end-->

    <div class="new-top-bar-top-div">
        <!--Top bar left-->
        <div class="new-top-bar-left">
            <img class="app-3km-logo cursor" [routerLink]="['/home']" src="./../assets/5km-logo/5km Logo on Top left corner 80 x72 px.webp">
            <div class="new-top-bar-location-div">
                <img src="./../assets/new-logo/location-logo.svg" class="app-location-img cursor">
                <div class="new-top-bar-location-right-div">
                    <div class="app-new-topbar-font-1">Hello, your location</div>
                    <div class="app-new-topbar-font-2 cursor new-c-text-eclipse" (click)="openMapModal(mapData)">
                        {{current_location}}</div>
                </div>
            </div>
        </div>
        <!--Top bar left end-->

        <div class="new-top-bar-right">
            <img class="new-top-bar-image-1 cursor" (click)="openSearchModal(searchPage)"
                src="./../assets/new-logo/search_black.svg">
            <!--    <img class="new-top-bar-image-1 cursor" [routerLink]="['/user/cart']" src="./../assets/new-logo/shopping_cart_black.svg">
       <img class="new-top-bar-image-1 cursor" (click)="requestPermission()" src="./../assets/new-logo/notifications_black.svg"> -->
            <div class="d-flex align-center justify-center" (click)="goToLogin()" *ngIf="!user">
                <img class="new-top-bar-image-1 cursor" style="margin-right: 5px;"
                    src="./../assets/new-logo/account_circle_black.svg">
                <div class="new-top-bar-font-3 new-top-bar-color-1 cursor">Log In</div>
                <img class="new-top-bar-image-3 cursor" loading="lazy" src="./../assets/new-logo/expand_more_black.svg">
            </div>
            <div class="d-flex align-center justify-center" *ngIf="user" (click)="openUserProfileModal(profileData)">
                <img class="new-top-bar-image-1 new-user-avatar cursor" style="margin-right: 5px;"
                    [src]="user_info?.avatar">
                <div class="new-top-bar-font-3 new-top-bar-color-1 cursor new-desk-only">{{user_info?.firstname}}
                    {{user_info?.lastname}}</div>
                <img class="new-top-bar-image-3 cursor" loading="lazy" src="./../assets/new-logo/expand_more_black.svg">
            </div>
        </div>
    </div>

    <div class="new-top-bar-line"></div>

    <!--new top bar bottom div-->
    <div class="new-top-bar-bottom-div">
        <div class="new-desktop-side-nav" id="newdesktopnav">
            <img class="new-top-bar-image-4 new-desktop-side-nav-close-btn cursor"
                src="./../assets/new-logo/menu-close.svg" (click)="closeMenu()">

            <button class="btn no-btn new-create-new-post-btn new-top-bar-font-1"
                (click)="openPostOptions('postoptions',loginData)"><img src="./../assets/new-logo/add-circle.svg"
                    style="margin-right: 5px;" class="new-top-bar-image-4" loading="lazy"> Create New Post</button>

            <div class="new-desktop-side-nav-menu">
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        (click)="goToAuthorProfile(loginData);closeMenu()"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-my-post.svg" loading="lazy">My Post</a></div>
                <ng-container *ngIf="user_pages.length>0">
                    <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                            (click)="show_pages=!show_pages"><img class="new-top-bar-image-4 new-mr-12"
                                src="./../assets/new-logo/menu-my-pages.svg" loading="lazy">My Pages<svg width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1685_4775)">
                                    <path
                                        d="M15.8805 9.28859L12.0005 13.1686L8.12047 9.28859C7.73047 8.89859 7.10047 8.89859 6.71047 9.28859C6.32047 9.67859 6.32047 10.3086 6.71047 10.6986L11.3005 15.2886C11.6905 15.6786 12.3205 15.6786 12.7105 15.2886L17.3005 10.6986C17.6905 10.3086 17.6905 9.67859 17.3005 9.28859C16.9105 8.90859 16.2705 8.89859 15.8805 9.28859Z"
                                        fill="#7C7C7C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1685_4775">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></a></div>

                    <ul *ngIf="show_pages">
                        <li class="new-top-bar-font-5 new-top-bar-color-3">
                            <div class="cursor" (click)="selectUser();closeMenu()"><img class="new-top-bar-image-4 new-mr-12 menu-profile-icon"
                                [src]="user_info?.avatar">{{user_info?.firstname}} {{user_info?.lastname}}</div>
                            <img class="new-top-bar-image-4" loading="lazy" *ngIf="!current_selected_page['page_id']" src="./../assets/new-logo/menu-checked-circle.svg">
                        </li>
                        <li class="new-top-bar-font-5 new-top-bar-color-3" *ngFor="let page of user_pages">
                            <div class="cursor" (click)="selectPage(page);closeMenu()"><img
                                    class="new-top-bar-image-4 new-mr-12 menu-profile-icon"
                                    [src]="page?.display_picture">{{page?.title}}</div>
                            <img class="new-top-bar-image-4" loading="lazy" *ngIf="((current_selected_page['page_id']==page['page_id']) || (current_selected_page['proxy_user_id']==page['proxy_user_id']) )" src="./../assets/new-logo/menu-checked-circle.svg">
                        </li>
                    </ul>

                </ng-container>
                <div class="cursor new-desktop-side-nav-menu-item" *ngIf="user_pages.length<5"><a
                        class="new-top-bar-font-5 new-top-bar-color-3"
                        (click)="createNewPage(loginData,newPageContent);closeMenu()"><img
                            class="new-top-bar-image-4 new-mr-12" src="./../assets/new-logo/menu-add-page.svg">Create
                        Page</a></div>
                <div class="new-top-bar-line-2"></div>
                <!-- <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/food/home']" (click)="closeMenu()"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-order-food.svg" loading="lazy">Order Food</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/user/cart']" (click)="closeMenu()"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-shopping-cart.svg" loading="lazy">Shopping Cart</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/shop/orders']" (click)="closeMenu()"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-my-orders.svg" loading="lazy">My Orders</a></div>
                <div class="new-top-bar-line-2"></div> -->
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/contact']" (click)="closeMenu()"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-help-and-support.svg" loading="lazy">Help & Support</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        href="https://bulbandkey.com/privacy-policy" (click)="closeMenu()"><img
                            class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-privacy-policy.svg" loading="lazy">Privacy Policy</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/terms-and-conditions']" (click)="closeMenu()"><img
                            class="new-top-bar-image-4 new-mr-12" loading="lazy" src="./../assets/new-logo/menu-contact.svg">Terms &
                        Conditions</a></div>
                <div class="new-top-bar-line-2"></div>
                <div class="cursor new-desktop-side-nav-menu-item" *ngIf="user" style="padding-bottom: 85px;"><a (click)="logout();closeMenu();"
                        class="new-top-bar-font-5 new-top-bar-color-3"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-logout.svg" loading="lazy">Logout</a></div>
            </div>

            <!-- <div class="new-home-download-banner">
                <div class="new-home-download-banner-logo-wrapper">
                    <img class="new-home-download-banner-logo" src="./../assets/new-logo/new-3km-logo.svg">
                </div>
                <div class="post-title-tex text-white text-center new-desktop-only">Download the app now! <br> get
                    unlimited breaking news & entertainment content <br> its free</div>
                <div class="post-title-tex text-white text-center new-mobile-only">Download the app now! <br> and get
                    unlimited breaking news and <br>entertainment content its free</div>
                <img class="new-home-download-banner-google-play-img cursor" loading="lazy" (click)="goToAppStore()"
                    src="./../../../../assets/new-logo/google-play-image.svg">
            </div> -->

        </div>
        <img id="toggleicon" class="new-top-bar-image-3 cursor toggle-menu-icon new-desk-only"
            src="./../assets/new-logo/menu_black.svg" (click)="openMenu()" loading="lazy">
        <img id="toggleicon" class="new-top-bar-image-3 cursor toggle-menu-icon new-mobile-only"
            src="./../assets/new-logo/menu_black.svg" (click)="openMobileMenu()" loading="lazy">


        <div class="new-top-bar-top-menu">
          <!--<div class="new-top-bar-font-3 new-top-bar-color-2 cursor c-inline"
                [ngClass]="cur_menu=='home'?'new-active-menu':'new-inactive-menu'" (click)="changeMenu('home')"
                [routerLink]="['/home']">Home</div>
            <div class="new-top-bar-font-3 new-top-bar-color-2 cursor c-inline"
                [ngClass]="cur_menu=='feed'?'new-active-menu':'new-inactive-menu'" (click)="changeMenu('feed')"
                [routerLink]="['feed/home']">Feed</div>
            <div class="new-top-bar-font-3 new-top-bar-color-2 cursor c-inline"
                [ngClass]="cur_menu=='food'?'new-active-menu':'new-inactive-menu'" (click)="changeMenu('food')"
                [routerLink]="['food/home']">Food</div>
            <div class="new-top-bar-font-3 new-top-bar-color-2 cursor c-inline"
                [ngClass]="cur_menu=='shop'?'new-active-menu':'new-inactive-menu'" (click)="changeMenu('shop')"
                [routerLink]="['shop/home']">Shop</div>
                               <ng-container >
            </ng-container>
        -->
               
                <div class="new-top-bar-font-3 new-top-bar-color-2 cursor" *ngFor="let tabs of dynamic_tabs"
                [ngClass]="cur_menu==tabs['name_lc']?'new-active-menu':'new-inactive-menu'" (click)="changeMenu(tabs['name_lc'])"
                [routerLink]="[tabs['link']]" [queryParams]="{id: tabs['category_id'], tags: tagsstringify(tabs['tags'])}" style="display: inline-block;"><span>{{tabs['name']}}</span></div>
                
        </div>
    </div>
    <!--new top bar bottom div end-->
</div>


<div class="new-content-area" id="maincontent" style="position: relative;">
  
    <div class="desktop-common-menu-wrapper">
        <div class="desktop-common-menu" id="desktopcommonmenu">
            <button class="btn no-btn new-create-new-post-btn new-top-bar-font-1" style="margin-top: 20px;width:90%;"
            (click)="openPostOptions('postoptions',loginData)"><img src="./../assets/new-logo/add-circle.svg"
                    style="margin-right: 5px;" class="new-top-bar-image-4" loading="lazy"> Create New Post</button>

            <div class="new-desktop-side-nav-menu">
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        (click)="goToAuthorProfile(loginData)"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-my-post.svg" loading="lazy">My Post</a></div>
                            <ng-container *ngIf="user_pages.length>0">
                                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                                        (click)="show_pages=!show_pages"><img class="new-top-bar-image-4 new-mr-12"
                                            src="./../assets/new-logo/menu-my-pages.svg">My Pages <svg width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip1_1685_4775)">
                                                <path
                                                    d="M15.8805 9.28859L12.0005 13.1686L8.12047 9.28859C7.73047 8.89859 7.10047 8.89859 6.71047 9.28859C6.32047 9.67859 6.32047 10.3086 6.71047 10.6986L11.3005 15.2886C11.6905 15.6786 12.3205 15.6786 12.7105 15.2886L17.3005 10.6986C17.6905 10.3086 17.6905 9.67859 17.3005 9.28859C16.9105 8.90859 16.2705 8.89859 15.8805 9.28859Z"
                                                    fill="#7C7C7C"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1685_4775">
                                                    <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg></a></div>
                                <ul *ngIf="show_pages">
                                    <li class="new-top-bar-font-5 new-top-bar-color-3">
                                        <div class="cursor" (click)="selectUser();closeMenu()"><img class="new-top-bar-image-4 new-mr-12 menu-profile-icon"
                                            [src]="user_info?.avatar">{{user_info?.firstname}} {{user_info?.lastname}}</div>
                                        <img class="new-top-bar-image-4" loading="lazy" *ngIf="!current_selected_page['page_id']" src="./../assets/new-logo/menu-checked-circle.svg">
                                    </li>
                                    <li class="new-top-bar-font-5 new-top-bar-color-3" *ngFor="let page of user_pages">
                                        <div class="cursor" (click)="selectPage(page);closeMenu()"><img class="new-top-bar-image-4 new-mr-12 menu-profile-icon"
                                                [src]="page?.display_picture">{{page?.title}}</div>
                                        <img class="new-top-bar-image-4" loading="lazy" *ngIf="((current_selected_page['page_id']==page['page_id']) || (current_selected_page['proxy_user_id'] == page['proxy_user_id']))" src="./../assets/new-logo/menu-checked-circle.svg">
                                    </li>
                                </ul>
                            </ng-container>
                <div class="cursor new-desktop-side-nav-menu-item" *ngIf="user_pages.length<5"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        (click)="createNewPage(loginData,newPageContent);closeMenu()"><img
                            class="new-top-bar-image-4 new-mr-12" loading="lazy" src="./../assets/new-logo/menu-add-page.svg">Create
                        Page</a></div>
                <div class="new-top-bar-line-2"></div>
                <!-- <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/food/home']"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-order-food.svg" loading="lazy">Order Food</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/user/cart']"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-shopping-cart.svg" loading="lazy">Shopping Cart</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/shop/orders']"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-my-orders.svg" loading="lazy">My Orders</a></div>
                <div class="new-top-bar-line-2"></div> -->
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/contact']"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-help-and-support.svg" loading="lazy">Help & Support</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        href="https://bulbandkey.com/privacy-policy"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-privacy-policy.svg" loading="lazy">Privacy Policy</a></div>
                <div class="cursor new-desktop-side-nav-menu-item"><a class="new-top-bar-font-5 new-top-bar-color-3"
                        [routerLink]="['/terms-and-conditions']"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-contact.svg" loading="lazy">Terms & Conditions</a></div>
                <div class="new-top-bar-line-2"></div>
                <div class="cursor new-desktop-side-nav-menu-item" style="padding-bottom: 85px;" *ngIf="user"><a (click)="logout()"
                        class="new-top-bar-font-5 new-top-bar-color-3"><img class="new-top-bar-image-4 new-mr-12"
                            src="./../assets/new-logo/menu-logout.svg" loading="lazy">Logout</a></div>
            </div>
           
        </div>
    </div>
    <div class="new-content-router" id="contentarea">
        <router-outlet></router-outlet>
    </div>
</div>
<!--
    <router-outlet></router-outlet>
 -->
<!--
<div class="content-area-div" id="contentarea">
    <div class="content-flex">
      
        <div class="content-div">
          <router-outlet></router-outlet>
        </div> 
    </div>
 </div> 
 -->
<ng-template #newPostContent let-modal>
    <app-new-post (close)="closeModal()"></app-new-post>
</ng-template>

<ng-template #newPageContent let-modal>
    <app-create-page (close)="closeCreatePageModal($event)" (success)="pageCreated($event)"></app-create-page>
</ng-template>

<!--Login modal start-->
<ng-template #loginData let-modal>
    <div class="login-modal">
        <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="closeModal()"><i
                class="fa fa-close"></i></div>
        <app-login (login_successfull)="modal.close();loginSuccessfull();" [is_modal]="true"></app-login>
    </div>
</ng-template>
<!--End login modal-->

<!--Search page-->
<ng-template #searchPage let-modal>
    <app-common-search [type]="'news'"></app-common-search>
</ng-template>
<!--Search page end-->

<!--Search page-->
<ng-template #jobpost>
    <app-job-post (close)="closeModal()"></app-job-post>
</ng-template>
<!--Search page end-->

<!--Search page-->
<ng-template #eventpost>
    <app-event-post (close)="closeModal()"></app-event-post>
</ng-template>
<!--Search page end-->

<!--Search page-->
<ng-template #marriagepost>
    <app-marriage-post (close)="closeModal()"></app-marriage-post>
</ng-template>
<!--Search page end-->

<!--Search page-->
<ng-template #buysellpost>
    <app-buy-sell (close)="closeModal()"></app-buy-sell>
</ng-template>
<!--Search page end-->

<!--Search page-->
<div id="postoptions">
    <div class="post-options-content">
        <div class="post-options-title">
            <h2 class="post-options-content-text-1" style="margin-bottom: 0px;">Create</h2><img
                (click)="closePostOptions()" src="./../assets/icons/close_black_24dp.svg"
                class="post-options-icon cursor">
        </div>
        <ng-container>
            <div class="post-options-content-text-2 post-options" (click)="createPostType('News',newPostContent)">News/
                Post<img class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
            <div class="post-options-content-text-2 post-options" (click)="createPostType('Job',jobpost)">Job<img
                    class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
            <div class="post-options-content-text-2 post-options" (click)="createPostType('Event',eventpost)">Event<img
                    class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
            <div class="post-options-content-text-2 post-options" (click)="createPostType('Marriage',marriagepost)">Marriage AD<img class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
            <div class="post-options-content-text-2 post-options" (click)="createPostType('buysell',buysellpost)">Buy and Sell<img class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
        </ng-container>
    </div>
</div>
<!--Search page end-->

<!--Map Component End-->
<ng-template #mapData let-modal>
    <app-map (location)="selected_location($event)" (cancel)="closeModal()"></app-map>
</ng-template>
<!--Map Component-->

<!--Profile data Content-->
<ng-template #profileData let-modal>
    <div class="flex-3 poppins-18-normal modal-close-custom"><button class="profile-change-pwd-btn modal-close-cust-btn"
            (click)="modal.close()"><i class="fa fa-close"></i></button></div>
    <app-profile-info></app-profile-info>
</ng-template>
<!--Profile data Content end-->

<!--Notifciation permission div-->

<div class="not-permission-div" id="notperm">
    <div class="not-permission-inner-div">
        <div>
            <h5 class="text-center not-text-1">Push Notifications</h5>
            <p class="not-text-2">Get top news alerts from 5km</p>
            <button class="btn no-btn not-enable-button not-text-3" (click)="confirmPermission()">Enable</button>
            <button class="btn no-btn not-later-button not-text-3" (click)="maybeLater()">Not now</button>
        </div>
    </div>
</div>
<!--Notifciation permission div end-->