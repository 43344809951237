import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';
import { PollsService } from 'src/app/core/services/polls/polls.service';

declare var confetti:any;

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {

  @Input() public poll: any;
  public loading: boolean = false
  @Input() poll_id:any;
  public token:any=this.commonService.getLocalStorage('token');
  @Output() login= new EventEmitter();

  selected_answer='';
  poll_index=0;
  amz_img='https://m.media-amazon.com/images/I/61W7kEbHCHL._SX425_.jpg';


  constructor(private route: ActivatedRoute,
    private router: Router,
    private pollsService: PollsService,
    public ngbModal:NgbModal,
    public commonService:CommonService,
     public cdr:ChangeDetectorRef) { 
     }

  // Get Poll/Quiz ID from the URL params.
  ngOnInit(): void {
   //  this.getPollsDetails(this.poll_id);
     this.poll = Object.assign({}, this.poll);
     this.cdr.detectChanges();
  }

  // Get Poll Details for populating Meta Data and Quiz Items.
  getPollsDetails(id: number) {
    this.loading = true
    let req_obj = {
      token: this.token,
      poll_id: id, 
      action: 'display'
    }
    this.pollsService.getPollDetails(req_obj).subscribe((data: any) => {
      this.loading = false
      if (data['status'] == "success") {
        let quiz_details = data['Result']['quiz'];
        this.poll = Object.assign({}, quiz_details) // Deep cloning of Quiz Object to avoid any copy conflicts
/*
        this.title.setTitle(quiz_details.question);

        if (quiz_details.context && quiz_details.context.description) {
          this.meta.addTag({ property: 'og:description', content: quiz_details.context.description });
          this.meta.addTag({ property: 'description', content: quiz_details.context.description });
        } else {
          this.meta.addTag({ property: 'og:description', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
          this.meta.addTag({ property: 'description', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
        }

        if (quiz_details.context && quiz_details.context.title) {
          this.meta.addTag({ property: 'og:title', content: quiz_details.context.title });
          this.meta.addTag({ property: 'title', content: quiz_details.context.title });
        } else {
          this.meta.addTag({ property: 'og:title', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
          this.meta.addTag({ property: 'title', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
        }

        if (quiz_details.context && quiz_details.context.image) {
          let image = quiz_details.context.image.replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/', 'https://cdn.bulbandkey.com/fit-in/400x400/')
          this.meta.addTag({ property: 'og:image', content: image });
        } else {
          if (quiz_details.image) {
            let image = quiz_details.image.replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/', 'https://cdn.bulbandkey.com/fit-in/400x400/')
            this.meta.addTag({ property: 'og:image', content: image });
          }
        }
        this.meta.addTag({ property: 'og:type', content: 'website' });
      //  this.meta.addTag({ property: 'og:url', content: `https://3km.in/poll/${quiz_details.id}` });
      this.meta.addTag({ property: 'og:url', content: `https://stage.3km.in/polls/${quiz_details.id}` });*/
      }
    }, (err) => {
      this.loading = false
    })
  }

  pollAnswerSelected(poll_obj: any, option: { text: any; }, i: number, data?:any) {
    this.selected_answer= option.text;
    this.poll_index= i;
    let user = localStorage.getItem('token');
    //user = '621555986df6b62ee62161c4'

    if (user) {
      if (!this.poll.is_answered) {
        let total_votes = 0
        for (let j = 0; j < this.poll.options.length; j++) {
          if (option.text == this.poll.options[j].text) {
            this.poll.options[j].count += 1
          }
          total_votes += this.poll.options[j].count
        }
        //
        let total_percentage = 0;
        let last_marked_index = -1

        for (let j = 0; j < this.poll.options.length; j++) {
          this.poll.options[j].percent = (this.poll.options[j].count / total_votes) * 100

          if (j < this.poll.options.length - 1) {
            this.poll.options[j].percent = Number((this.poll.options[j].percent).toFixed(2))
          } else if ((j == this.poll.options.length - 1) && (j > 1)) {
          /*  this.poll.options[j].percent = Number((100 - total_percentage).toFixed(2))
            if ((this.poll.options[j].percent == 0) && (total_percentage < 100) && (last_marked_index > -1)) {
              this.poll.options[last_marked_index].percent += Number((100 - total_percentage).toFixed(2))
              this.poll.options[last_marked_index].d_percent = this.poll.options[last_marked_index].percent + '%'
            }*/
            this.poll.options[j].percent = Number((this.poll.options[j].percent).toFixed(2))

          } else {
            this.poll.options[j].percent = Number((this.poll.options[j].percent).toFixed(2))
          }
          total_percentage += this.poll.options[j].percent
          if (total_percentage > 100) {
            this.poll.options[j].percent -= Number((total_percentage - 100).toFixed(2));
            total_percentage = 100
          }
          if (this.poll.options[j].percent) {
            last_marked_index = j
          }
          this.poll.options[j].d_percent = this.poll.options[j].percent + '%'
        }
        this.poll.is_answered = true
        document.getElementById('poll' + this.poll.id + '_' + i)!.style.backgroundColor = "rgb(167 174 180 / 42%)";
        this.submit_poll_answer(this.poll.id, option.text, data);
      }

    } else {
     //  this.router.navigate(['/account/login'],{queryParams:{return_url:`polls/${this.poll_id}`}});
     this.ngbModal.open(data,{size:'md'});
    }
  }


  quizAnswerSelected(quiz_obj: any,option: { text: any; }, i: number, data?:any){
    this.selected_answer= option.text;
    this.poll_index= i;
    let user = localStorage.getItem('token')

   // user = '621555986df6b62ee62161c4'
    if(user){
      
      if(!this.poll.is_answered){
        this.poll.is_answered = true
        if(option.text == this.poll.answer){
          
          document.getElementById('poll'+this.poll.quiz_id+'_'+i)!.classList.add("post_quiz_block_answers_option_correct");
          document.getElementById('poll_overlay')!.classList.add("tada");
          
       //   confetti({particleCount: 160,spread: 100,origin: { y: 0.8 }});
          setTimeout(() => {
            document.getElementById('poll_overlay')!.classList.remove("tada");
          }, 3000);
        } else {
          for (let j = 0; j < this.poll.options.length; j++) {
            if(this.poll.options[j].text == this.poll.answer){
              
              document.getElementById('poll'+this.poll.quiz_id+'_'+j)!.classList.add("post_quiz_block_answers_option_correct");
            }
          }
          document.getElementById('poll_overlay')!.classList.add("shake");
          setTimeout(() => {
            document.getElementById('poll_overlay')!.classList.remove("shake");
          }, 3000);
          document.getElementById('poll'+this.poll.quiz_id+'_'+i)!.classList.add("post_quiz_block_answers_option_wrong");
          
        }
        this.submit_answer(this.poll.quiz_id, option.text,data);
      }
  } else {
    this.ngbModal.open(data,{size:'md'});
  }
  }

  async submit_poll_answer(quiz_id: any, answer: any,data?:any) {
    let token = localStorage.getItem('token')
    if (token) {
      token = JSON.parse(token)
    } else {
     // token = ''
     this.ngbModal.open(data,{size:'md'});
    }
   // token = '621555986df6b62ee62161c4'

    let req_obj = {
      quiz_id: quiz_id,
      selected_option: answer,
      token: token
    }
    this.poll['selected_option'] = answer
    this.pollsService.submitPollAnswer(req_obj).subscribe((data: any) => {
    })
  }

  async submit_answer(quiz_id: any, answer: any, data?:any){
    console.log("Calling submit quiz answer");
    let token = localStorage.getItem('token')
    if (token) {
      token = JSON.parse(token);

      let req_obj = {
        quiz_id: quiz_id,
        selected_option: answer,
        token: token
      }
  
      this.poll['selected_option'] = answer
      this.pollsService.submitQuizAnswer(req_obj).subscribe((data: any) => {
        
      })

    } else 
    {
      token = ''
    //  this.router.navigate(['/account/login'],{queryParams:{return_url:`polls/${this.poll_id}`}});
       this.ngbModal.open(data,{size:'md'});
    }
   // token = '621555986df6b62ee62161c4'
  }


  loginSuccessfull(){
       this.login.emit();
       if(this.poll['type']=='poll'){
          this.pollAnswerSelected(this.poll, {text:this.selected_answer},this.poll_index);
       }else if(this.poll['type']=='quiz'){
        this.quizAnswerSelected(this.poll, {text:this.selected_answer},this.poll_index);
      }
  }  

}
