import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { PollsService } from 'src/app/core/services/polls/polls.service';

@Component({
  selector: 'app-poll-detail',
  templateUrl: './poll-detail.component.html',
  styleUrls: ['./poll-detail.component.scss']
})
export class PollDetailComponent implements OnInit {

  poll:any;
  poll_id!:number;
  loading= false;
  public token:any=this.commonService.getLocalStorage('token');

  constructor(public pollsService:PollsService,public commonService:CommonService, public _route:ActivatedRoute, public router:Router,
              private title: Title,
               private meta: Meta) { }

  ngOnInit(): void {
    this._route.params.subscribe((data)=>{
      if(data['id']){
          let id= data['id'];
    
          if(id){
            this.poll_id=id;
            this.getPollsDetails(this.poll_id);
          }
      }
    })
  }

  back_to_news_home(){
    this.router.navigate(['/home'])
}

  getPollsDetails(id: number) {
    this.loading = true
    let req_obj = {
      token: this.token,
      poll_id: id, 
      action: 'display'
    }
    this.pollsService.getPollDetails(req_obj).subscribe((data: any) => {
      this.loading = false
      if (data['status'] == "success") {
        let quiz_details = data['Result']['quiz'];
        this.poll = data['Result']['quiz'];

        this.title.setTitle(quiz_details.question);

        if (quiz_details.context && quiz_details.context.description) {
          this.meta.addTag({ property: 'og:description', content: quiz_details.context.description });
          this.meta.addTag({ property: 'description', content: quiz_details.context.description });
        } else {
          this.meta.addTag({ property: 'og:description', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
          this.meta.addTag({ property: 'description', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
        }

        if (quiz_details.context && quiz_details.context.title) {
          this.meta.addTag({ property: 'og:title', content: quiz_details.context.title });
          this.meta.addTag({ property: 'title', content: quiz_details.context.title });
        } else {
          this.meta.addTag({ property: 'og:title', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
          this.meta.addTag({ property: 'title', content: quiz_details.question.replace(/(<([^>]+)>)/ig, '').substring(0, 150) });
        }

        if (quiz_details.context && quiz_details.context.image) {
          let image = quiz_details.context.image.replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/', 'https://cdn.bulbandkey.com/fit-in/400x400/')
          this.meta.addTag({ property: 'og:image', content: image });
        } else {
          if (quiz_details.image) {
            let image = quiz_details.image.replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/', 'https://cdn.bulbandkey.com/fit-in/400x400/')
            this.meta.addTag({ property: 'og:image', content: image });
          }
        }
        this.meta.addTag({ property: 'og:type', content: 'website' });
      //  this.meta.addTag({ property: 'og:url', content: `https://3km.in/poll/${quiz_details.id}` });
      this.meta.addTag({ property: 'og:url', content: `https://5km.city/poll/${quiz_details.id}` });
      }
    }, (err) => {
      this.loading = false
    })
  }

    loginSuccessfull(){
      this.token=this.commonService.getLocalStorage('token');
      this.getPollsDetails(this.poll_id);
    }

}
