import { Component, OnInit, TemplateRef,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';
import { PaymentService } from 'src/app/core/services/payment/payment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  cart_items:any;
  myproducts!:any[];
  product_id:any[]=[];
  sub_type= 'selectaddress';

  selected_address:any;
  shipping_charge:number=0;
  distance!: number;
  user_info:any;

  project_id:any;

  type='address';
  is_from_backend= false;

  @ViewChild('successdata',{static: true}) successdata!:TemplateRef<any>;
 

  constructor(public commonService:CommonService, public router:Router,
              public toastr:ToastrService, 
              public modal:NgbModal, public paymentService:PaymentService) {
   }

   payment_project_id:any=0;

  ngOnInit(): void {
    let project_id= this.commonService.getLocalStorage('payment_project_id');
    if(project_id){
       this.payment_project_id= project_id;
    }
    this.modal.dismissAll();
    this.getUserData();
    this.getCartItems();
    this.paymentService.paymentsuccessBehaviourSubject.subscribe((data:any)=>{
        this.handlePayment(data);
      })
  }

  is_payment_success= false;

  handlePayment(data:any){
       if(data['status']=='success' && data['response']['project_id']!==this.payment_project_id){
         this.commonService.setLocalStorage('payment_project_id',data['response']['project_id']);
         this.toastr.clear();
         this.is_payment_success= true;
         this.commonService.deleteLocalStorage('newcart');
         this.modal.open(this.successdata,{size:'md',centered: true,backdrop:'static'});

    /*   setTimeout(()=>{
              this.closeModal();
              this.commonService.deleteLocalStorage('newcart');
              this.router.navigate(['/user/cart']);
            },5000) */
      }
  }

  paymentSuccess(){
    this.modal.open(this.successdata,{size:'md',centered: true});
  }

  getCartItems(){
      this.cart_items= this.commonService.getLocalStorage('newcart');
      if(this.cart_items){
         this.myproducts= this.cart_items['items'][0]['products'];
         this.product_id= this.cart_items['id'];
      }else{
        this.router.navigate(['/shop/category/shomumangoes']);
      }
  }

  increment(i: number){
      this.myproducts[i]['quantity']++;
      this.calculateShippingRates();
  }

  decrement(i: number){
    if(this.myproducts[i]['quantity']>1){
    this.myproducts[i]['quantity']--;
    this.calculateShippingRates();
    }
  }

  removeCartItem(i:number, id: number){
     let index= this.product_id.indexOf(id);
     if(index!==-1){
      this.product_id.splice(i,1);
     }
     this.myproducts.splice(i,1);
     this.checkCart();
  }

  checkCart(){
    if(this.myproducts.length<1){
      this.router.navigate(['/shop/category/shomumangoes']);
    }
  }

  addressSelected(event:any){
        this.selected_address= event;
  }

  getSelectedAddress(){
    let address=this.commonService.getLocalStorage('selected_address');
    if(address){
          this.selected_address= address;
    }
  }

  getUserData(){
    let user=this.commonService.getLocalStorage('user');
    if(user){
    this.user_info= user;
    }
}

getTotal(){
  if(this.myproducts.length>0){
  let total=0;
  for(let product of this.myproducts){
    total += product['quantity']*product['selected_price'];
  }
  return total;
 }
 return 0;
}

  calculateShippingRates(){


       if(this.selected_address){
       this.toastr.info('Calculating shipping charges',undefined,{positionClass:'toast-center-center',progressBar:true});
      let weight= 0;

      for(let product of this.myproducts){
        weight += product['quantity']* product['selected_weight'];
      }
      
       let payload={
           creator_id: this.cart_items['items'][0]['creator_id'],
           latitude: this.selected_address['latitude'],
           longitude: this.selected_address['longitude'],
           weight: weight,
           pincode: this.selected_address['pincode']
       }
       this.commonService.shipRates(payload).subscribe((data:any)=>{
           if(data['delivery_rate'] || data['delivery_rate']>=0){
           this.toastr.clear();
           this.shipping_charge= data['delivery_rate'];
           this.is_from_backend= true;
           this.distance= data['distance'];
           this.warning_msg='';
           }else{
              if(data['message'] && data['message']!==''){
                this.warning_msg= data['message'];
              }
              this.toastr.clear();
              this.toastr.warning(data['message']);
              this.is_from_backend= false;
              this.shipping_charge= 0;
              this.distance= 0;
           }
       })
      }
  }

  warning_msg='';

  goToCheckout(){
    this.router.navigate(['/user/checkout'])
  }

  goToCart(){
    if(this.sub_type=='addaddress'){
        this.sub_type='selectaddress';
    }else{
    this.router.navigate(['/shop/category/shomumangoes']);
    }
  }

  openModal(data:any){
       this.modal.open(data,{centered: true, size:'md'});
  }

  openAddressModal(data:any){
    this.modal.open(data,{size:'md', backdrop:'static'});
  }  

  addressChange(address:any){
      this.selected_address= address;
      this.calculateShippingRates();
      this.closeModal();
  }

  newAddressAdded(address:any){
       this.sub_type='selectaddress';
  }

  pay(){
       
       if(this.shipping_charge==0 && !this.is_from_backend)
       {
             this.toastr.warning(this.warning_msg);
             return;
       }
       this.toastr.show('Please wait...');
       let products=[];

       for(let product of this.myproducts){
              let obj:any={
                id: product['catalog_id'],
                quantity: product['quantity']
              }
              if(product['has_variations'] && product['variation_id']){
                  obj['variation_id']= product['variation_id'];
              }
              products.push(obj);
       }

      let payload={
            customer_firstname: this.user_info['firstname'],
            customer_lastname: this.user_info['lastname'],
            customer_phone: this.user_info['phone_no'],
            distance: `${this.distance}`,
            drop_location: this.selected_address,
            products: products,
            shipping_amount: this.shipping_charge
      }
      this.commonService.shopCheckout(payload).subscribe((data:any)=>{
        if(data['Result']['status']=='redirect'){
              this.project_id= data['Result']['project_id'];
              this.paymentService.payment(this.selected_address,data['Result']);
        }
      }) 

  }

  closeModal(){
      this.modal.dismissAll();
   }

  ngOnDestroy(){
      if(this.cart_items && this.myproducts && !this.is_payment_success){
      let cart= {
        id: this.product_id,
        items: [{
                   creator_id: this.cart_items['items'][0]['creator_id'],
                   products: [...this.myproducts]
                }]
      }
      this.commonService.setLocalStorage('newcart',cart);
    }
  }

  checkAddress(){
     if(this.selected_address){
         this.type='payment';
         this.calculateShippingRates();
     }else{
         this.toastr.warning('Please select address');
     }
  }

}
