  import { Component, OnInit, ChangeDetectorRef, ViewChild,Renderer2,
  ElementRef, AfterViewInit, ViewEncapsulation, HostListener, Inject, PLATFORM_ID, OnDestroy, Optional} from '@angular/core';
  import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
  import {Router, ActivatedRoute} from '@angular/router';
  import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import { CommonService } from 'src/app/core/services/common/common.service';
  import { NewsService } from 'src/app/core/services/news/news.service';
  import {config} from './../../../core/config/config';
  import * as urlSlug from 'url-slug';
  import { isPlatformBrowser, isPlatformServer } from '@angular/common';
  import { AuthorService } from 'src/app/core/services/author/author.service';
  import { FeedService } from 'src/app/core/services/feed/feed.service';
  import { Subscription } from 'rxjs';
  import {DOCUMENT} from '@angular/common';

  @Component({
    selector: 'app-single-post',
    templateUrl: './single-post.component.html',
    styleUrls: ['./single-post.component.scss']
  })
  export class SinglePostComponent implements OnInit,AfterViewInit,OnDestroy{
  
    all_posts=[];
    all_posts_ids=[];
  
    is_liked=false;
    is_commented=true;
    click_reactions=false;
  
    comments_list=[];
    related_posts:any=[];
  
    is_content_loading=false;
    skeleton_posts=[1,2,3,4,5,6,7,8,9,10];
  
    showNavigationArrows = false;
    showNavigationIndicators = true;
  
    post_id:any=0;
    lang:string='en';
  
    feed_available= false;
    
    cur_url:any;
    cur_video_type:any;
    token=  this.commonService.getLocalStorage('token');
    cur_index:any;
    pageLevelAds= true;
  
    cmttext='';
  
    display_posts:any=[];
  
    content_loading= false;
  
    feed_posts:any=[];
    subscription1:any;
    subscription2:any;
    isBrowser= false;
  
    paramSubscription:any;
    subscription5:Subscription;
  
  
    cur_user= this.commonService.getLocalStorage('user');
    param:any;
    featured_posts:any=[];
    billboard:any=[];
    topslides_list:any=[];

    youtubewidth:any;

    @ViewChild('ins', { read: ElementRef, static: true }) ins!: ElementRef;
  
  
    @HostListener('document:click', ['$event']) onDocumentClick(event:any) {
        if(this.cur_index || this.cur_index==0){
           this.display_posts[this.cur_index]['click_reactions']= false;
        }
        event.stopPropagation();
    }
  
    constructor(public router:Router,public modal:NgbModal,public _route:ActivatedRoute, public news_service:NewsService,
                public cdr:ChangeDetectorRef, public commonService:CommonService,
                public domsanitizer:DomSanitizer,public authorservice:AuthorService,
                public ngbModal:NgbModal,
                public feedService:FeedService,
                private _renderer2: Renderer2,
                @Inject(DOCUMENT) private _document: Document,
                @Inject(PLATFORM_ID) private platformId: Object,
                public meta:Meta, public title:Title) {
      // customize default values of carousels used by this component tree
      this.subscription5= this.commonService.user_data.subscribe((data)=>{
        this.token=  this.commonService.getLocalStorage('token');
      })
    }

    public YT: any;
    public player: any;
    public reframed: Boolean = false;
    
    ngOnInit(): void {
      if(!this.apiLoaded) {
             const tag = document.createElement('script');
             tag.src = 'https://www.youtube.com/iframe_api';
              document.body.appendChild(tag);
              this.apiLoaded = true;
         }

        console.log('Post detail component');
        this.customOptions = {
          loop: true,
          mouseDrag: true,
          lazyLoad: true,
          touchDrag: true,
          pullDrag: true,
          dots: false,
          slideBy:1,
          navSpeed: 700,
          navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
          items: 3,
          nav: false
        }

    this.paramSubscription = this._route.paramMap.subscribe((data) => {
      this.param = data.get('id')?.split('-');
      this.post_id = this.param ? this.param[0] : '';
      if (this.post_id) {
        this.fetchPostDetail();
        if (isPlatformBrowser(this.platformId)) {
          this.isBrowser = true;
          if (window.innerWidth > 900) {
            this.fetchLatestAdv();
          }
        }
      }
    });
  }

  private apiLoaded = false;
  customOptions: any;
  window_width: any = '';
  display_a = false;
  advertisement:any;

  refresh() {
    if (this.window_width !== window.innerWidth) {
      this.window_width = window.innerWidth;
      this.is_refreshed = false;
      this.customOptions = { ...this.customOptions, items: 2.5 };
      setTimeout(() => {
        this.is_refreshed = true;
      });
    }
  }

  is_refreshed = true;

  onYouTubeIframeAPIReady() {
    console.log('Youtube player is ready');
    this.player = new YT.Player('existing-iframe-example', {
      events: {
        onReady: this.onPlayerReady,
      },
    });
  }

  onPlayerReady(event: any) {
    //  document.getElementById('existing-iframe-example').style.borderColor = '#FF6D00';
  }
  
    moveToTop(){
      let top=document.getElementById('top');
      top?.scrollIntoView({behavior:'smooth'});
    }
  
    ngAfterViewInit(){
    
    }
  
    is_back_btn= false;
  
    checkBackBtn(){
        let back= sessionStorage.getItem('back_btn');
        if(back){
          this.is_back_btn= true;
        }
    }
    post:any;
    right_display="";
    left_display="";
    no_view= true;
    related= false;
    //view all of a category
    alttag='5km';
  
    reactions:any={
      like: './../../../../assets/lottie-icons/Like.svg',
      love: './../../../../assets/lottie-icons/Love.svg',
      care: './../../../../assets/lottie-icons/Care.svg',
      angry: './../../../../assets/lottie-icons/Angry.svg',
      sad: './../../../../assets/lottie-icons/Sad.svg',
      laugh: './../../../../assets/lottie-icons/Laugh.svg'
   }
  
    fetchPostDetail(){
      console.log('Calling post detail service');
      let ip='';
         let visitor='';
         let sharer=this.param[1]?this.param[1]:'';
      if(isPlatformBrowser(this.platformId)){
         this.no_view= false;
         this.related= true;
         ip= this.commonService.getLocalStorage('ip');
         visitor= this.commonService.getLocalStorage('visitorid');
      }
  
      this.display_a= false;
     
      
      this.subscription1= this.news_service.getServerlessPostDetail(this.post_id,this.token,this.lang,this.no_view,this.related,ip,visitor,sharer).subscribe((data:any)=>{
        if(data['StatusCode']==200){
        this.post= data['Result']['post'];
        if(isPlatformServer(this.platformId)){
            console.log(data);
        }
        if(isPlatformBrowser(this.platformId)){
        this.advertisement= data['Result']['advertizement']['adv_id']?data['Result']['advertizement']:'';
        setTimeout(()=>{
        let pb= document.getElementById('platformbanner');
        let pi= document.getElementById('platformbannerimage');
        if(pb){
            pb.style.visibility= 'visible';   
            pb.style.maxHeight= `${pi?.offsetWidth}px`   
        }
        if(pi){
           pi.style.visibility= 'visible';
           pi.style.height='auto';
           pi.style.maxHeight= `${pi?.offsetWidth}px`;
       }
       },3000);

        if(data['Result'] && data['Result']['related_posts']){
           this.related_posts= [...data['Result']['related_posts']];
        } 
        
         if(isPlatformBrowser(this.platformId)){
         setTimeout(()=>{
           let id=document.getElementById('videocontainer');
                   if(id){
                      this.youtubewidth=id.clientWidth;
                 }},2000);
         }
       }
       this.post['read_more']= false;
       this.post['click_reactions']= false;
       this.post['can_comment']= true;
    //   this.post['videos'].push({id:'0m6hIjCFIOA',src:'https://www.youtube.com/watch?v=0m6hIjCFIOA&origin=https://3km.in/&enablejsapi=1',thumbnail:''})
       this.post['comments_length']= 5;
       this.post['all_comments']=data['Result']['post']['comments'];
       if(this.post['views']>50){
        this.right_display= `${this.post.views} Views`
       }
       else if(this.post['all_comments'].length>0){
          this.right_display= `${this.post.all_comments.length} ${this.post.all_comments.length>1?'Comments':'Comment'}`
       }
        
        this.display_posts=[this.post];
        this.display_a= true;
         this.generateStructuredData(this.post);
         this.meta.addTag({ property:'og:site_name', content: '5km'});
         this.meta.addTag({ property:'og:type', content: 'website'});
         this.title.setTitle(this.post.headline);
         this.alttag= (this.post.context && this.post.context.alt_tag)?this.post.context.alt_tag:this.post.headline.replace( /(<([^>]+)>)/ig, '');
         if(this.post.story.replace( /(<([^>]+)>)/ig, '')){
           this.meta.addTag({ property:'og:description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
           this.meta.updateTag({name:'description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
         }
         if(this.post.thumbnail){
           this.meta.addTag({ property:'og:image', content: this.post.thumbnail});
           this.meta.updateTag({name:'image', content: this.post.thumbnail});
         }
         else if(this.post.images[0]){
           let image = this.post.images[0].replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/','https://cdn.bulbandkey.com/fit-in/400x400/')
           this.share_image= image;
           this.meta.updateTag({ property:'og:image', content: image});
           this.meta.updateTag({name:'image', content: image});
         } else if(this.post.videos[0]){
           this.meta.addTag({ property:'og:video', content: this.post.videos[0]['src']});
           this.meta.addTag({ property:'og:video:secure_url', content: this.post.videos[0]['src']});
           this.meta.updateTag({ property:'og:type', content: 'video.other'});
           this.meta.updateTag({ property:'og:video:type', content: 'video/mp4'});
           if(this.post.videos[0].thumbnail){
           this.meta.addTag({ property:'og:image', content: this.post.videos[0].thumbnail});
           this.meta.updateTag({ property:'image', content: this.post.videos[0].thumbnail});
           }
         }
         if(this.post.headline.replace( /(<([^>]+)>)/ig, '')){
           this.meta.addTag({ property:'og:title', content: this.post.headline.replace( /(<([^>]+)>)/ig, '')});
           this.meta.updateTag({name:'title',content:this.post.headline.replace( /(<([^>]+)>)/ig, '')})
         }
         
           let shareurl= this.urlSlugConverter(this.post['slug_headline']);
           this.meta.addTag({ property:'og:url', content: `${this.url}post-detail/${shareurl?shareurl:'5-km-post'}/${this.post.post_id}`});
        } 
   })
 }

  /*
    this.subscription1= this.news_service.getPostDetail(this.post_id,this.token,this.lang,this.no_view,this.related).subscribe((data:any)=>{
      if(data['status']=='success'){
      this.post= data['data']['result']['post'];
     if(data['data']['result'] && data['data']['result']['related_posts']){
         this.related_posts= [...data['data']['result']['related_posts']];
      } 
      this.post['read_more']= false;
      this.post['click_reactions']= false;
      this.post['can_comment']= true;
      this.post['comments_length']= 5;
      this.post['all_comments']=data['data']['result']['post']['comments'];
      if(this.post['views']>50){
       this.right_display= `${this.post.views} Views`
      }
      else if(this.post['all_comments'].length>0){
         this.right_display= `${this.post.all_comments.length} ${this.post.all_comments.length>1?'Comments':'Comment'}`
      }
      this.display_posts=[this.post];

       this.meta.addTag({ property:'og:site_name', content: '3km'});
       this.meta.addTag({ property:'og:type', content: 'website'});
       this.title.setTitle(this.post.headline);
       if(this.post.story.replace( /(<([^>]+)>)/ig, '')){
         this.meta.addTag({ property:'og:description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
         this.meta.addTag({ property:'description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
       }
       if(this.post.thumbnail){
         this.meta.addTag({ property:'og:image', content: this.post.thumbnail});
       }
       else if(this.post.images[0]){
         let image = this.post.images[0].replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/','https://cdn.bulbandkey.com/fit-in/400x400/')
         this.share_image= image;
         this.meta.addTag({ property:'og:image', content: image});
       } else if(this.post.videos[0]){
         this.meta.addTag({ property:'og:video', content: this.post.videos[0]['src']});
         this.meta.addTag({ property:'og:video:secure_url', content: this.post.videos[0]['src']});
         this.meta.updateTag({ property:'og:type', content: 'video.other'});
         this.meta.updateTag({ property:'og:video:type', content: 'video/mp4'});
         if(this.post.videos[0].thumbnail){
         this.meta.addTag({ property:'og:image', content: this.post.videos[0].thumbnail});
         }
       }
       if(this.post.headline.replace( /(<([^>]+)>)/ig, '')){
         this.meta.addTag({ property:'og:title', content: this.post.headline.replace( /(<([^>]+)>)/ig, '')});
       }
       
         let shareurl= this.urlSlugConverter(this.post['slug_headline']);
         this.meta.addTag({ property:'og:url', content: `${this.url}post-detail/${shareurl?shareurl:'3-km-post'}/${this.post.post_id}`});
         this.display_a= true;
      } 
 })
}*/
  
generateStructuredData(post:any){
  let images: any[]=[];
  let description:any='';

  if(post['images']){
    for(let i of post['images']){
      images.push(i);
    }
  }
  if(post['thumbnail']){
     images.push(post['thumbnail'])
  }
  if(post['story']){
      description= post['story'];
  }
  let tag= post['tags'].length>0?post.tags.toString():"";
  let url= `https://5km.city/author/profile/${post['author']['id']}-${post['author_type'][0]}`;
  let post_url= this.router.url;
  let structuredDataText=`{ 
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "url": "https://5km.city${post_url}",
  "headline": "${post['headline']}",
  "description": "${post['story_flat']}",
  "datePublished": "${post['post_created_date']}",
  "dateModified": "${post['updated_date']}",
  "keywords": "${tag}",
  "articleSection":"${post['tags'][0]?post['tags'][0]:"3km News"}",
  "articleBody": "${post.story_flat}",
  "image": ["${images[0]?images[0]:''}"],
  "Publisher":{
     "type":"Oraganization",
     "name": "5km",
     "url": "https://5km.city/",
     "logo":{
       "type":"ImageObject",
       "url":"https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/8c90ec00-cbf4-11ed-9932-d744dde6a983.png",
       "width":"80",
       "height":"80"
     }
  },
  "author": [{
      "@type": "Person",
      "name": "${post['author']['name']}",
      "image": "${post['author']['image']}",
      "url": "${url}"
      }]
   }`;
   
   console.log(structuredDataText);
   let script = this._renderer2.createElement('script');
   script.type = `application/ld+json`;
   script.text = structuredDataText;

   this._renderer2.appendChild(this._document.head, script);
}


    fetchPostComments=async ()=>{
      let payload={
         entity_id: this.post_id,
         module: 'news_post'
      }
     this.subscription2= this.news_service.getComments(payload).subscribe((data:any)=>{
         this.display_posts[0]['all_comments']= data['data']['result']['comments'].slice().reverse();
      })
    }
  
    goToAuthorProfile(author:any,t:any){
      let type=t[0];
      this.router.navigate([`/author/profile/${author['id']}-${type}`])
  }
  
  
    back_to_news_home(){
        this.router.navigate(['/home'])
    }
  
    toggle_like_button(type:string){
        if(type=='like'){
          this.is_liked=true;
          this.click_reactions=true;
        }else{
        this.is_liked=false;
         this.click_reactions=false;
        }
    }
  
    openModal(content:any){
      this.modal.open(content,{ scrollable: true,size:'lg'})
    }
  
    openLikesModal(content:any){
      this.modal.open(content,{ scrollable: true,size:'lg'})
    }
  
    openVideoModal(content:any, i:any, v:any){
      let obj= this.display_posts[i];
      this.cur_url= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? obj['videos'][v]['vimeo_url']:obj['videos'][v]['src'];
      this.cur_video_type= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? 'vimeo':'normal';
      this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'})
    }
  
   /* dom(){
        for(let i=0;i<this.display_posts.length;i++){
            let elem=document.getElementById(`${i}-pd`);
            if(elem && elem?.clientHeight<120){
                this.display_posts[i]['read_more']=false;
                elem.classList.remove('pd-story');
                elem.classList.add('pd-story-max');
            }
        }
        this.cdr.detectChanges();
    }*/

  likePost(data: any, id: any, i: any, event: any) {
    //  this.display_posts[i]['click_reactions']= false;
    this.token = this.commonService.getLocalStorage('token');

    if (!this.token) {
      this.ngbModal.open(data, { size: 'md' });
    } else {
      this.display_posts[i]['is_liked'] = true;
      this.display_posts[i]['likes']++;
      if (
        event &&
        this.display_posts[i]['list_emotions'].indexOf(event) == -1
      ) {
        this.display_posts[i]['list_emotions'].push(event);
      }
      let payload = {
        module: 'news_post',
        entity_id: id,
        emotion: event,
      };

      this.news_service.like(payload).subscribe((data: any) => {
        if (data['status'] == 'success') {
          this.display_posts[i]['is_liked'] = true;
          // this.display_posts[i]['likes']++;
        } else {
          this.display_posts[i]['list_emotions'].pop();
          this.display_posts[i]['is_liked'] = false;
          this.display_posts[i]['likes']--;
        }
      });
    }
  }

  push(): void {
    const p: Record<string, string | boolean> = {};
    if (this.pageLevelAds) {
      p['google_ad_client'] = 'ca-pub-2898290509296226';
    }

    if (window) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
          p
        );
      } catch {
        // pass
      }
    }
  }

  unlikePost(id: any, i: any) {
    this.display_posts[i]['is_liked'] = false;
    this.display_posts[i]['likes']--;
    let payload = {
      module: 'news_post',
      entity_id: id,
    };

    this.news_service.unlike(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.display_posts[i]['is_liked'] = false;
        this.display_posts[i]['list_emotions'].pop();
        //     this.display_posts[i]['likes']--;
      } else {
        this.display_posts[i]['is_liked'] = true;
        this.display_posts[i]['likes']++;
      }
    });
  }

  viewPostDetail(id: number, i: number, lang: string) {
    let post = this.related_posts[i];
    let url = this.urlSlugConverter(post['slug_headline']);
    if (!url || url == 'undefined') {
      //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
      //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
      url = '5-km-post-detail';
    }
    //  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);
    this.router.navigate(['/post-detail', url, `${post['post_id']}`]);
  }

  openReactions(i: any) {
    if (this.cur_index) {
      this.display_posts[this.cur_index]['click_reactions'] = false;
    }
    this.display_posts[i]['click_reactions'] = true;
    this.cur_index = i;
  }

  login_successfull() {
    this.token = this.commonService.getLocalStorage('token');
    this.cur_user = this.commonService.getLocalStorage('user');
  }

  commentOnPost(id: number, i: number, data: any) {
    this.token = this.commonService.getLocalStorage('token');

    if (!this.token) {
      this.ngbModal.open(data, { size: 'md' });
    } else {
      this.display_posts[i]['can_comment'] = true;
      let input = document.getElementById('cmtinputdiv');
      if (input) {
        input.focus();
        input.scrollIntoView({ behavior: 'smooth' });
      }
      this.loadComments(id, i);
    }
  }

  url = config.domain;
  share_post_url = '';
  share_image = '';

  urlSlugConverter(headline: string) {
    let url = urlSlug.convert(`${headline}`, { camelCase: false });
    return url;
  }

  cur_post_index: any;

  sharePost(id: number, i: number, data: any) {
    this.cur_post_index = i;
    let post = this.display_posts[i];
    let shareurl = this.urlSlugConverter(post['slug_headline']);
    /*  if(!shareurl || (shareurl.replace(/\-+/g,'')).match(/[0-9]+$/)){
           shareurl=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
        } */
    //   this.share_post_url= `${this.url}post-detail/${shareurl?shareurl:'3-km-post-detail'}/${id}-${this.lang}`;
    let user_id = this.commonService.getLocalStorage('user_id');
    let proxy_user_ids = this.commonService.getLocalStorage('proxy_user_ids');
    if (!proxy_user_ids) {
      proxy_user_ids = [];
    }

        if((user_id && user_id!==post['author']['id']) && !proxy_user_ids.includes(post['author']['id'])){
          this.share_post_url= `${this.url}post-detail/${shareurl?shareurl:'5-km-post'}/${id}-${user_id}`;
        }else{
          this.share_post_url= `${this.url}post-detail/${shareurl?shareurl:'5-km-post'}/${id}`;
        }
      //  this.share_post_url= `${this.url}post-detail/${shareurl?shareurl:'3-km-post'}/${id}`;
        this.ngbModal.open(data,{size:'md',centered:true});
    }
  
    subscription3:any;
    subscription4:any;
    
    loadComments(id:number,i:number){
      let payload={
        entity_id: id,
        module: 'news_post'
      }
      this.subscription3= this.news_service.getComments(payload).subscribe((data:any)=>{
        this.display_posts[i]['all_comments']= data['data']['result']['comments'];
        this.display_posts[i]['all_comments']= this.display_posts[i]['all_comments'].slice().reverse();
      })
  }
  
    postComment(id:number,i:number,comment:string,data:any){
  
      this.token=  this.commonService.getLocalStorage('token');
  
      if(!this.token){
        this.ngbModal.open(data,{size:'md'});
        return;
       }
  
       if(comment.trim()==''){
        return;
      }
      let payload={
        entity_id: id,
        module: 'news_post',
        comment: comment
      }
       let new_comment={
         avatar: this.cur_user.avatar,
         comment: comment
       }
       this.display_posts[i]['all_comments']=[new_comment,...this.display_posts[i]['all_comments']]
       this.display_posts[i]['comments_length']=5;
  
       this.news_service.postComment(payload).subscribe((data:any)=>{
          if(data['status']=='success'){
             this.display_posts[i]['all_comments']= data['data']['result']['comments'].slice().reverse();
          }
       })
    }
  
    secureUrl(url:any){
         let re_url=this.domsanitizer.bypassSecurityTrustResourceUrl(url);
         return re_url;
    }
  
    postShared(event:any){
         this.display_posts[this.cur_post_index]['shares']++;
    }
  
    closeModal(){
      this.ngbModal.dismissAll();
    }
  
    loadAllComments(a:any){
        let num=this.display_posts[a]['comments_length'];
        if(num>5){
          this.display_posts[a]['comments_length']= 5;
        }else{
          this.display_posts[a]['comments_length']= this.display_posts[a]['all_comments'].length;
        }
    }

  fetchLatestAdv() {
    let payload = {
      token: this.token,
      lang: this.lang,
    };

    this.subscription1 = this.news_service
      .fetchAdvAndFeaturedPosts(payload)
      .subscribe((data: any) => {
        if (data['status'] == 'success') {
          this.billboard = data['data']['result']['finalposts'][2]
            ? data['data']['result']['finalposts'][2]['hoardings']
            : [];
          this.topslides_list =
            data['data']['result']['finalposts'][0]['banners'];
          this.featured_posts[0] =
            data['data']['result']['finalposts'][1]['business'];
        }
      });
  }

  followAuthor(author: any, data: any, type: any, i: any) {
    let user = this.commonService.getLocalStorage('token');
    if (user) {
      this.display_posts[i]['author']['is_followed'] = true;
      let payload = {
        entity: type,
        type: type,
        entity_id: author['id'],
      };
      this.authorservice.followAuthor(payload).subscribe((data: any) => {
        if (data['status'] == 'success') {
          this.display_posts[i]['author']['is_followed'] = true;
        }
      });
    } else {
      this.modal.open(data, { size: 'md' });
    }
  }

  onScroll() {
    if (!this.feed_posts || this.feed_posts.length < 1) {
      this.content_loading = true;
      this.getFeedPosts();
    }
  }

  getFeedPosts() {
    console.log('Get feed posts');
    this.feed_available = false;
    this.feed_posts = [];
    let payload = {
      token: this.token,
      limit: 30,
    };

    this.subscription4 = this.feedService
      .fetchNewsFeed(payload)
      .subscribe((data: any) => {
        let arr: [] = data['data']['result']['posts'];
        let narr = arr.filter((f) => f['post_id'] !== parseInt(this.post_id));
        this.feed_posts = narr.slice(0, 10);
        this.feed_available = true;
        this.content_loading = false;
        console.log(this.feed_posts);
      });
  }

  getIcon(i: number) {
    let image = './../../../../assets/icons/new-like-icon.svg';

    if (this.post['list_emotions'][i])
      if (this.post['list_emotions'][i] == 'like') {
        image = './../../../../assets/icons/new-like-icon.svg';
      } else if (this.post['list_emotions'][i] == 'love') {
        image = './../../../../assets/icons/new-love-reaction-icon.svg';
      }

    return image;
  }

  last_played_id: any;

  playingVideo(id: any) {
    console.log('Playing video' + id);
    if (this.last_played_id && this.last_played_id !== id) {
      let video = document.getElementById(
        this.last_played_id
      ) as HTMLVideoElement;
      console.log(video);
      if (video) {
        video.pause();
        this.last_played_id = id;
      }
    } else {
      this.last_played_id = id;
    }
  }

  focusingSlide() {
    console.log('Focusing Slide');
  }

  slideChange() {
    console.log('Slide change');
    if (this.last_played_id) {
      let video = document.getElementById(
        this.last_played_id
      ) as HTMLVideoElement;
      if (video) {
        video.pause();
      }
    }
  }

  showInterest(data:any){
    this.ngbModal.open(data, {size:'lg'});
  }

  ngOnDestroy() {
    /*
      const iframe = this.ins.nativeElement.querySelector('iframe');
      if (iframe && iframe.contentWindow) {
        iframe.src = 'about:blank';
        iframe.remove();
      } */
    this.feed_posts = null;
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    this.subscription5.unsubscribe();
    this.isBrowser = false;
    this.title.setTitle('5KM');
  }

  navigateToTagPage(tagName: string) {
    this.router.navigate([`/tag-all-post/${tagName}`]);
  }
}
