import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AbstractControl, FormBuilder,FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';

export const matchPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const name = control.get('new_password');
  const alterEgo = control.get('confirm_password');
  return name && alterEgo && name.value !== alterEgo.value ? { passwordMatch: true } : null;
};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordForm!:FormGroup;
  @Output() password_changed=new EventEmitter();
  wrong_old_password:boolean= false;
  message= '';

  constructor(public formbuilder:FormBuilder, public authservice:AuthService) { }

  ngOnInit(): void {
     this.initPasswordForm();
     this.passwordForm.valueChanges.subscribe((data)=>{
     })
  }

  initPasswordForm(){
      this.passwordForm= this.formbuilder.group({
         old_password:['',[Validators.required]],
         new_password:['',[Validators.required,Validators.minLength(6)]],
         confirm_password:['',[Validators.required]]
      },{
        validators: matchPassword
      })
  }

  get form(){
     return this.passwordForm.controls;  
  }

  changePassword(){
     let msg= document.getElementById('msg');
     let payload={
        oldPassword: this.form['old_password'].value,
        password: this.form['new_password'].value
     }

     this.authservice.changePassword(payload).subscribe((data:any)=>{
         if(data['status']=='success'){          
            this.message= 'Password changed successfully!';
            if(msg){
              msg.style.display= 'block';
              msg.classList.remove('text-danger');
              msg.classList.add('text-success');
            }  
            setTimeout(()=>{
               this.password_changed.emit();
         },2000)
         }else{
          if(msg){
            this.message= 'You have entered wrong current password!';
            msg.style.display= 'block';
            msg.classList.add('text-danger');
            msg.classList.remove('text-success');

            setTimeout(()=>{
              if(msg){
                msg.style.display='none';
              }
        },5000)
          }
         }
     })
  }
}
