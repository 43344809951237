import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  constructor(public commonservice:CommonService) { }

  ngOnInit(): void {
     this.commonservice.hideMenu();
  }

  ngOnDestroy(): void {
    this.commonservice.showMenu();
  }


}
