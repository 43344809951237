import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit,EventEmitter,Output} from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss']
})
export class CreatePageComponent implements OnInit {

  @Output() close=new EventEmitter<any>();
  @Output() success= new EventEmitter<any>();

  page_name:string='';
  phase:number=1;

  invalid_page_name= false;

  page:any;

  title='Add page name';
  profile_pic:any;

  titles=['Add page name','Add page profile picture','Page Created']
  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
    this.title= this.titles[this.phase-1];
  }

  next(){
    if(this.phase==2){
       this.createpage();
    }else{
    this.phase++;
    this.title=this.titles[this.phase-1];
    }
  }

  back(){
    this.phase--;
    this.title=this.titles[this.phase-1];
    if(this.phase==0){
       this.closeModal();
    }
  }

  async filesSelected(event: any){
      for(let i=0;i<event.target.files.length; i++){
           this.upload(event.target.files[i]);
      }
 }

 upload(file:any){
  this.commonService.uploadImage(file).subscribe({
    next: (event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
      } else if (event instanceof HttpResponse) {
         this.profile_pic= event.body.photo.id;
      }
    },
    error: (err: any) => {
    }

  })
}

createpage(){
    let payload={
       title: this.page_name.trim(),
       display_picture: this.profile_pic,
       website:'',
       youtube_channel:''
    }

    this.commonService.createPage(payload).subscribe((data:any)=>{
       if(data['status']=='success'){
           this.phase++;
           this.title=this.titles[this.phase-1];
           this.page= data['data']['result']['page'];
       }
    })
}

inv_msg="Invalid page name";
typingPageName(){
    if(!this.page_name || this.page_name.trim()=='' || this.page_name.trim().length>45){
       this.invalid_page_name= true;
    }else{
       this.invalid_page_name= false;
    }
}

goToPage(){
   this.success.emit(this.page);
}

closeModal(){
     this.close.emit(this.phase);
}
}
