<div>
  <div class="new-post-detail-layout" infiniteScroll [infiniteScrollDistance]="1" (scrolled)="onScroll()">
    <div class="new-post-detail-layout-left">
      <div class="posts-list-div" style="max-width: 600px;position: relative;margin-left: auto;margin-right: auto;">

        <div class="np-g-a-div-2">
          <ng-adsense [adClient]="'ca-pub-2898290509296226'" [adSlot]="6297836226" [height]="120" [adFormat]="'fluid'"
            [className]="'np-n-border-2'" [fullWidthResponsive]="true">
          </ng-adsense>
        </div>

        <div *ngIf="advertisement" class="pd-platform-banner" id="platformbanner" (click)="showInterest(interested)">
            <img [src]="advertisement['images'][0]['image']" id="platformbannerimage" class="pd-platform-banner-image">
        </div>

        <div *ngFor="let post of display_posts;index as a">
          <!--New post card start-->
          <div class="new-post-detail-card single-post-div-card" *ngIf="post['status']!=='rejected'" style="max-width: 100%;position: relative;box-shadow: none;">
            <div class="new-post-card-name-div">

              <div class="new-post-card-image-div cursor"
                (click)="goToAuthorProfile(post['author'],post['author_type'])"
                [ngStyle]="{'background-image':'url('+post.author.image+')'}"></div>
              <div>
                
                <div
                  class="new-post-card-font-1 new-post-card-color-1 new-post-card-author-name new-text-eclipse-1 cursor"
                  (click)="goToAuthorProfile(post['author'],post['author_type'])">{{post['author']['name']}}</div>
                <div class="d-flex align-center new-post-card-timestamp">
                  <div class="new-post-card-font-2 new-post-card-color-1">{{post['display_date']}}</div>
                </div>
              </div>

              <div class="new-post-card-more-div">
                <img class="new-post-card-icon" src="./../../../../assets/icons/new-vert-more-black-icon.svg" alt="more-icon">
              </div>

            </div>

            <!--Image Div-->
            <div class="pd-image-div" style="max-width: 100%;position: relative"  #videocontainer id="videocontainer">
              <ngb-carousel #caro
                [showNavigationArrows]="((post['images'].length+ post['videos'].length)>0 && post.thumbnail) || ((post['images'].length+ post['videos'].length)>1 && !post.thumbnail)"
                [keyboard]="false" [interval]="0" (slide)="slideChange()"
                [showNavigationIndicators]="((post['images'].length+ post['videos'].length)>0 && post.thumbnail) || ((post['images'].length+ post['videos'].length)>1 && !post.thumbnail)"
                [pauseOnFocus]="false" [pauseOnHover]="false" style="max-width: 100%;position: relative;">
                <!-- <ngb-carousel #caro [showNavigationArrows]="true" [keyboard]="false" [interval]="0" [showNavigationIndicators]="true" [pauseOnFocus]="false" [pauseOnHover]="false" style="max-width: 100%;position: relative;"> -->
                <ng-template ngbSlide *ngIf="post['thumbnail']">
                  <div class="ap-img-wrapper">
                    <img [src]="post.thumbnail" [alt]="alttag"
                      style="width: 100%;position: relative;max-height: 80vh;object-fit: contain;min-height:30vh;"
                      error="this.display='none'">
                  </div>
                </ng-template>

                <ng-template ngbSlide *ngFor="let image of post['images']">
                  <div class="ap-img-wrapper">
                    <img [src]="image" [alt]="alttag"
                      style="width: 100%;position: relative;max-height: 80vh;object-fit: contain;min-height:30vh;"
                      error="this.display='none'">
                  </div>
                </ng-template>

                <ng-template ngbSlide *ngFor="let video of post['videos'];index as v">
                  <div class="ap-img-wrapper">
                  <ng-container *ngIf="video.src.indexOf('youtube')==-1 && !video.youtube_id">
                    <video width="100%" height="auto" style="max-height: 80vh;" preload="metadata"
                      [poster]="video?.thumbnail" webkit-playsinline="webkit-playsinline" controls
                      controlslist="nodownload" [id]="a+'-post-'+v" (play)="playingVideo(a+'-post-'+v)"
                      *ngIf="video?.player!=='vimeo'">
                      <source [src]="video.src">
                    </video>

                    <div *ngIf="video?.player=='vimeo'">
                      <app-post-video-player [url]="video.vimeo_url" [id]="a+1"></app-post-video-player>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="(video.src.indexOf('youtube')!==-1 || video.youtube_id) && youtubewidth">
                    <youtube-player [width]="youtubewidth" [height]="(youtubewidth*9)/16" [videoId]="video.youtube_id"></youtube-player>

                  <!--<iframe width="560" height="315" src="https://www.youtube.com/embed/EbcdDXEPukk" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>-->  
                  </ng-container>
                </div>
              </ng-template>

              </ngb-carousel>
            </div>
            <!--End Image Div-->


            <div class="new-post-card-title-div">
              <h1 class="new-post-card-font-4 new-post-card-color-1 new-headline-font">{{post['headline']}}</h1>
             <!-- <h1 style="display: none;">{{post['headline']}}</h1>-->

              <!--    <div class="pd-story pd-font-6" [innerHtml]="post['story']"></div> -->
              <div class="new-post-card-font-2 new-post-card-color-1 link-special new-description-font"
                [ngClass]="post['read_more']?'pd-story':'pd-story-max'" [innerHtml]="post['story']" [id]="a+'-pd'">
              </div>
              <div class="new-post-card-font-2 new-post-card-color-1 link-special pd-read-more"
                *ngIf="post['read_more']"><span class="text-blue text-underline cursor"
                  (click)="post['read_more']=!post['read_more']">Read More</span></div>

              <div class="new-post-card-font-1 new-post-card-color-1 new-post-tags-div new-description-font" *ngIf="post.location">Post
                Location: <span class="new-post-card-font-2 new-post-card-color-3">{{post['location']}}</span></div>
              <!-- <div class="new-post-card-font-1 new-post-card-color-1"
                *ngIf="post.tags.length>0 && post.tags.toString()!==''"
                style="max-width: 100%;white-space: wrap;word-break: break-all;">Tags: <span
                  class="new-post-card-font-2 new-post-card-color-3">{{post['tags']}}</span></div> -->
                  <h5 *ngIf="post.tags.length>0 && post.tags.toString()!==''" class="new-post-card-font-1 new-post-card-color-1">Tags: &nbsp;</h5>
                    <div *ngIf="post.tags.length>0 && post.tags.toString()!==''"   class="new-post-tags" style="overflow-x: auto;overflow-y: hidden;">
                      <ng-container  *ngFor="let tag of post.tags">
                        <div *ngIf="tag && tag!=='3km'" class="mr-2 tag cursor" (click)="navigateToTagPage(tag)">{{tag}}</div>
                      </ng-container>
                    </div>

            </div>


            <div class="new-post-card-views-div">

              <div class="d-flex align-center cursor" (click)="openLikesModal(likesContent)" style="gap: 3px;"
                *ngIf="post?.likes && post.likes>0">
                <div class="new-post-reaction-series-div" *ngIf="post?.list_emotions.length>0">
                  <div style="position: relative;" (click)="openLikesModal(likesContent)">
                    <img *ngFor="let em of post?.list_emotions;index as z" class="new-post-card-icon overlap-icon"
                      [ngClass]="z==0?'new-post-card-icon':'new-post-card-icon-3'" [src]="reactions[em]" [alt]="reactions[em]">
                  </div>
                </div>

                <div class="new-post-reaction-series-div" *ngIf="post?.list_emotions.length<1">
                  <div style="position: relative;" (click)="openLikesModal(likesContent)">
                    <img class="new-post-card-icon overlap-icon"
                      src="./../../../../assets/icons/new-like-reaction-icon.svg" alt="like-icon">
                    <img class="new-post-card-icon-3 overlap-icon"
                      src="./../../../../assets/icons/new-love-reaction-icon.svg" alt="love-icon" *ngIf="post?.likes>1">
                  </div>
                </div>


                <div class="new-post-card-font-2 new-post-card-color-1">{{post?.likes}}</div>
              </div>
              <div *ngIf="!post.likes || post.likes==0">{{left_display}}</div>
              <div class="new-post-card-font-2 new-post-card-color-1">{{right_display}}</div>
            </div>

            <div class="new-post-card-margin-2"></div>

            <div class="new-post-card-reaction-div">

              <div class="reactions-popup-div" *ngIf="post?.click_reactions">
                <app-like-reactions
                  (liked)="likePost(loginData,post?.post_id, a, $event);post.click_reactions=false;"></app-like-reactions>
              </div>

              <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor"
                (click)="!post?.is_liked?[openReactions(a),$event.stopPropagation()]:unlikePost(post?.post_id, a)">
                <img class="new-post-card-icon cursor" alt="like-icon" src="./../../../../assets/icons/new-like-unfilled.svg"
                  *ngIf="!post?.is_liked">
                <img class="new-post-card-icon cursor" alt="like-icon-filled" src="./../../../../assets/icons/new-like-icon.svg"
                  *ngIf="post.is_liked">Like
              </div>
              <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor"><img class="new-post-card-icon"
                  src="./../../../../assets/icons/new-comment-icon.svg" alt="comment-icon">Comment</div>
              <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor"
                (click)="sharePost(post?.post_id,a, shareData)"><img class="new-post-card-icon cursor"
                  src="./../../../../assets/icons/new-share-icon.svg" alt="share-icon">Share</div>
            </div>
            <div class="new-post-card-margin-2"></div>

            <div class="new-post-card-comment-input-div" *ngIf="post?.comments_length<=5">
              <div class="new-post-card-image-div" [ngStyle]="{'background-image':'url('+cur_user.avatar+')'}"></div>
              <div class="new-post-card-input-parent">
                <input class="new-post-card-input" #cmtinput [(ngModel)]="cmttext"
                  placeholder="Leave your comment here...">
                <div class="new-post-card-send-button-div cursor"
                  (click)="postComment(post.post_id,a,cmttext,loginData);cmttext=''">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.4 20.3995L20.85 12.9195C21.66 12.5695 21.66 11.4295 20.85 11.0795L3.4 3.59953C2.74 3.30953 2.01 3.79953 2.01 4.50953L2 9.11953C2 9.61953 2.37 10.0495 2.87 10.1095L17 11.9995L2.87 13.8795C2.37 13.9495 2 14.3795 2 14.8795L2.01 19.4895C2.01 20.1995 2.74 20.6895 3.4 20.3995Z"
                      [style.fill]="(cmttext && cmttext.trim()!=='')?'#3E7EFF':'#BBBBBB'" />
                  </svg>
                </div>
              </div>
            </div>


            <div class="new-post-card-comment-div">
              <div class="d-flex space-between">
                <div class="new-post-card-font-4 new-post-card-font-color-1 mobile-font-1"
                  *ngIf="post?.all_comments && post.all_comments.length>0">{{post?.all_comments.length}}
                  {{post?.all_comments.length>1?'Comments':'comment'}}</div>
                <div class="new-post-card-font-4 new-post-card-color-2 cursor" (click)="loadAllComments(a)"
                  *ngIf="post?.all_comments && post.all_comments.length>5">{{post?.comments_length<=5?'Load more
                    comments...':'Hide comments'}}</div>

                </div>
                <div *ngIf="post?.all_comments">
                  <div class="new-post-card-comment" *ngFor="let c of post?.all_comments;index as g;">
                    <ng-container *ngIf="g<post?.comments_length">
                      <div class="new-post-card-image-div" [ngStyle]="{'background-image':'url('+c.avatar+')'}"></div>
                      <div class="new-post-card-comment-text-div">
                        <div class="new-post-delete-comment-icon" *ngIf="c?.is_self">
                          <img class="new-post-card-icon-2 cursor" src="./../../../../assets/icons/new-delete-icon.svg">
                        </div>
                        <div class="icon-flex new-post-card-comment-name">
                          <div class="new-post-card-font-5 new-post-card-color-1 new-text-eclipse-1">{{c?.username}}
                          </div>
                          <div class="new-post-card-font-4 new-post-card-color-1 mobile-font-1 new-text-eclipse-2">
                            {{c?.time_lapsed}}</div>
                        </div>

                        <div class="new-post-card-font-2 new-post-card-color-1 mobile-font-1">{{c?.comment}}</div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="new-post-card-font-4 new-post-card-color-2 text-underline text-center cursor"
                    (click)="loadAllComments(a)" *ngIf="post?.all_comments.length>5">{{post?.comments_length<=5?'Load
                      more comments...':'Hide comments'}}</div>

                      <div class="new-post-card-comment-input-div" *ngIf="post?.comments_length>5">
                        <div class="new-post-card-image-div"
                          [ngStyle]="{'background-image':'url('+cur_user.avatar+')'}"></div>
                        <div class="new-post-card-input-parent cursor">
                          <input class="new-post-card-input" #cmtinput [(ngModel)]="cmttext">
                          <div class="new-post-card-send-button-div"
                            (click)="postComment(post.post_id,a,cmttext,loginData);cmttext=''">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M3.4 20.3995L20.85 12.9195C21.66 12.5695 21.66 11.4295 20.85 11.0795L3.4 3.59953C2.74 3.30953 2.01 3.79953 2.01 4.50953L2 9.11953C2 9.61953 2.37 10.0495 2.87 10.1095L17 11.9995L2.87 13.8795C2.37 13.9495 2 14.3795 2 14.8795L2.01 19.4895C2.01 20.1995 2.74 20.6895 3.4 20.3995Z"
                                [style.fill]="cmttext?'#3E7EFF':'#BBBBBB'" />
                            </svg>
                          </div>
                        </div>
                      </div>
                  </div>

                </div>
              </div>

              <!--Rejected post-->
              <div class="new-post-detail-card single-post-div-card" *ngIf="post['status']=='rejected'"
                style="box-shadow:none;">
                <div
                  style="min-height:400px;width:300px;margin: 24px auto;overflow: hidden;background-image: url(./../../../../assets/icons/rejected-post-anime.gif);background-position: center;background-size: contain;">
                </div>
                <div class="new-post-card-font-1 text-center" style="margin: 24px 0px 16px">Oops! this post is
                  unavailable</div>
                <button class="btn n-btn blue-btn" style="display:block;margin: 16px auto;"
                  [routerLink]="['/home']">Explore 5km</button>
              </div>
              <!--Rejected post-->



              <div class="np-g-a-div" *ngIf="isBrowser">
                <ng-adsense [adClient]="'ca-pub-2898290509296226'" [display]="'block'" [adSlot]="2330671059"
                  [adFormat]="'auto'" [className]="'np-n-border'" [fullWidthResponsive]="true"></ng-adsense>
              </div>

              <ng-container>

                <div *ngIf="related_posts && related_posts.length>0">

                  <div class="np-posts-list-title-2">
                    <div class="posts-list-font-1 post-card-top-name">Related posts</div>
                  </div>

                  <div class="posts-category-list-card new-posts-category-list-card">

                    <div class="news-posts-carousel-div margin-for-small" *ngIf="related_posts">

                      <div class="opacity-div">
                        <div id="nextbtn" class="next-arrow-div" (click)="owlCarSecond.next()">
                          <img class="next-arrow-icon cursor" src="./../../../../assets/icons/next-arrow-lg.png">
                        </div>
                      </div>

                      <owl-carousel-o [options]="customOptions" id="101" #owlCarSecond>
                        <ng-container *ngIf="related_posts && related_posts.length>0">
                          <ng-template carouselSlide [id]="post?.post_id" *ngFor="let post of related_posts;index as i">


                            <div class="post-wrapper-div" style="max-width: 100%;">
                              <div class="news-post-ind-card cursor" (click)="viewPostDetail(post['post_id'],i,'en')">
                                <div class="post-image-div">
                                  <div class="post-image-blur" *ngIf="post?.thumbnail"
                                    [ngStyle]="{'background-image': 'url('+post.thumbnail+')'}"></div>
                                  <div class="post-image-blur" *ngIf="post.images[0] && !post.thumbnail"
                                    [ngStyle]="{'background-image': 'url('+post.images[0]+')'}"></div>
                                  <div class="post-image-blur"
                                    *ngIf="!post.images[0] && !post.thumbnail && post['videos'][0] && post['videos'][0]['thumbnail']"
                                    [ngStyle]="{'background-image': 'url('+post.videos[0]['thumbnail']+')'}"></div>
                                  <div class="video-btn-div" *ngIf="post?.videos && post.videos.length>0">
                                    <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
                                  </div>
                                  <img class="post-image" *ngIf="post.thumbnail" [alt]="((post.context && post.context.alt_tag)?post.context.alt_tag:post?.submitted_headline)" [src]="post['thumbnail']"
                                    loading="lazy">
                                  <img class="post-image" *ngIf="post.images[0] && !post.thumbnail"
                                    [src]="post.images[0]" loading="lazy" [alt]="((post.context && post.context.alt_tag)?post.context.alt_tag:post?.submitted_headline)">
                                  <img class="post-image"
                                    *ngIf="!post.images[0] && !post.thumbnail && post['videos'][0] && post['videos'][0]['thumbnail']"
                                    [src]="post.videos[0]['thumbnail']" loading="lazy" [alt]="((post.context && post.context.alt_tag)?post.context.alt_tag:post?.submitted_headline)">
                                </div>

                                <div class="post-footer-div margin-top-10">
                                  <div class="post-author-img-wrapper">
                                    <img class="post-author-img margin-right-10" [src]="post?.author?.image" [alt]="post?.author?.name">

                                  </div>
                                  <div class="post-text-wrapper-div">
                                    <div *ngIf="post?.submitted_headline" class="post-title-text">
                                      {{post?.submitted_headline}}</div>
                                    <div *ngIf="!post?.submitted_headline" class="post-title-text">
                                      {{post?.submitted_story}}</div>
                                    <div class="post-timeline-text">
                                      <div class="c-text-eclipse">{{post?.author?.name}}</div>
                                      <div class="timeline-circle"></div>
                                      <div class="c-text-eclipse-2">{{post?.created_date}}</div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </ng-template>
                        </ng-container>

                      </owl-carousel-o>

                    </div>

                  </div>

                </div>
              </ng-container>

              <div class="np-join-group">
                <img class="np-join-group-img" style="margin-bottom: 5px;border-radius: 3px;" src="./../../../../assets/5km-logo/whatsapp green.webp" height="60"
                  width="60" alt="whatsapp-icon">
                <div>
                  <div class="new-post-card-font-1 new-post-card-color-1 text-center">Stay updated with latest <br>
                    <span style="color:#3D7EFF;"> news & trends </span></div>
                  <button class="btn no-btn blue-btn" style="display: block;margin: 4px auto;"><a
                      href="https://chat.whatsapp.com/H3bZj6VqPQeGpIIem217yP" target="_blank" class="text-white">Join
                      our whatsapp group</a></button>
                </div>
                <img class="np-join-group-background" src="./../../../../assets/5km-logo/5km logo favicon and whatsapp 80x 80.webp">
                <img class="np-join-group-background-2" src="./../../../../assets/5km-logo/5km logo favicon and whatsapp 80x 80.webp">
              </div>

              <div class="pd-platform-banner-2" *ngIf="advertisement" (click)="showInterest(interested)">
                <img [src]="advertisement['context']['large_banner'][0]['image']" class="pd-platform-banner-image-2">
              </div>

              <div class="np-g-a-div" *ngIf="isBrowser">
                <ng-adsense [adClient]="'ca-pub-2898290509296226'" [display]="'block'" [adSlot]="5004805976"
                  [layoutKey]="'-6r+di+5g-2m-8y'" [adFormat]="'fluid'" [className]="'np-n-border'"></ng-adsense>
              </div>
              <!--Likes Modal -->
              <ng-template #likesContent let-modal>
                <app-followers-list (close_modal)="closeModal()" [id]="post_id"></app-followers-list>
              </ng-template>
              <!--End likes list div-->

              <!--Login modal start-->
              <ng-template #loginData let-modal>
                <div class="login-modal">
                  <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i
                      class="fa fa-close"></i></div>
                  <app-login (login_successfull)="modal.close();login_successfull();" [is_modal]="true"></app-login>
                </div>
              </ng-template>
              <!--End login modal-->

              <!--Share Component-->
              <ng-template #shareData let-modal>
                <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i
                    class="fa fa-close"></i></div>
                <app-share [url]="share_post_url" (share_success)="postShared($event)"></app-share>
              </ng-template>
              <!--zEnd Share Component-->


              <!--Vimeo Player-->
              <ng-template #videoContent let-modal>
                <div class="d-flex flex-end" style="padding: 1em;justify-content: flex-end;">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <app-video-player [url]="cur_url" [type]="cur_video_type"></app-video-player>
                </div>
              </ng-template>

              <!--Feed start-->
              <ng-container>
                <div class="np-posts-list-title">
                  <div class="posts-list-font-1 post-card-top-name">Latest News</div>
                </div>


                <div class="np-feed-list-div" *ngIf="!content_loading">
                   <app-posts-list [posts]="feed_posts" [type]="'feed_with_ads'" [from]="'postdetail'"></app-posts-list>
                </div>

                <div class="sk-all-posts-outer-div author-profile-sk-posts" style="width: 100%;" *ngIf="content_loading">

                  <div class="sk-post mb-em-2" style="background-color: white" *ngFor="let i of skeleton_posts">
                    <div class="d-flex align-center mb-em-1">
                        <div class="sk-circle mr-px-10"></div>
                      <div class="sk-rect-div">
                        <div class="sk-sm-rect mb-px-10" style="max-width: 50%;min-height: 20px;"></div>
                        <div class="sk-sm-rect" style="max-width: 40%;"></div>
                      </div>
                    </div>

                    <div class="sk-all-post-image mb-em-1"></div>
                    <div class="sk-rect-div">
                      <div class="sk-sm-rect mb-px-10" style="min-height: 20px;"></div>
                      <div class="sk-sm-rect" style="min-height: 60px;"></div>
                    </div>
                  </div>


                </div>
              </ng-container>
              <!--Feed end-->

            </div>
          </div>


                 <!--Skeleton-->
            <div class="sk-all-posts-outer-div" style="min-height: 100vh;min-width: 100%;position: relative;display: block;" *ngIf="display_posts.length<1">

            <div class="sk-post mb-em-2" style="background-color: white" *ngFor="let i of skeleton_posts">

              <div class="d-flex align-center mb-em-1">
                <div class="sk-circle mr-px-10"></div>
                <div class="sk-rect-div">
                  <div class="sk-sm-rect mb-px-10" style="max-width: 50%;min-height: 20px;"></div>
                  <div class="sk-sm-rect" style="max-width: 40%;"></div>
                </div>
              </div>

              <div class="sk-all-post-image mb-em-1"></div>
              <div class="sk-rect-div">
                <div class="sk-sm-rect mb-px-10" style="min-height: 20px;"></div>
                <div class="sk-sm-rect" style="min-height: 60px;"></div>
              </div>
            </div>


          </div>

          <!--Skeleton end-->
        </div>

        <!--new post layout right-->
        <div class="new-post-detail-layout-right" style="width: 100%;">
          <!--Advertisement and people you may know section-->
          <div class="new-post-detail-layout-adv">
            <app-topslide-page [billboard]="billboard" [topslides]="topslides_list"></app-topslide-page>



            <!--Start People you may know div-->
            <!-- <div class="people-suggestion-div">
                 <app-ads-people-page></app-ads-people-page>
  
  
            </div>  -->


            <!--End People you may know-->


            <!--Featured Posts div-->
            <div class="featured-stories-div">

              <div class="divider-line-3"></div>

              <app-featured-stories [featured_stories]="featured_posts"></app-featured-stories>
            </div>
            <!--End Featured Posts div-->
          </div>

        </div>
        <!--End of Advertisement and people you may know section-->
        <!--new post layout right end-->
        <!--New post detail layout end-->

      </div>

      <ng-template #interested let-modal>
       <app-interested-form [advertisement]="advertisement" (close)="closeModal()"></app-interested-form>
      </ng-template>