import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as Hammer from 'hammerjs';
import html2canvas from 'html2canvas';

import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-post-thumbnail',
  templateUrl: './post-thumbnail.component.html',
  styleUrls: ['./post-thumbnail.component.scss'],
})
export class PostThumbnailComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput: any;

  isDragging: boolean = false;
  imageUrl = '';
  newImgSrc = '';
  startX = 0;
  startY = 0;
  currentScale = 1.0;
  x = 105.0;
  y = 105.0;
  heading = '';
  layoutBg = '';
  layoutTextColor = '';

  imageChangedEvent: any = '';
  croppedImage: any = '';
  buttonState = '';
  constructor() {}

  ngOnInit(): void {}
  @ViewChild('myDiv') myDiv: ElementRef | undefined;

  type = 'option'; // thumbnailForm
  title = '';
  private storedPosition: any = null;
  private storedScale: number = 1;

  data: string[] = [
    'political_thumbnail.webp',
    'social_thumbnail.webp',
    'crime_thumbnail.webp',
    'general_thumbnail.webp',
  ];
  dataColor: string[] = ['#610000', '#010101', '#FFA800', '#FFFFFF'];

  onInput(): void {
    this.heading = this.heading.trim();
  }

  convertToImage() {
    if (this.buttonState == '') {
      this.buttonState = 'saving';
      html2canvas(this.myDiv?.nativeElement)
        .then((canvas) => {
          this.newImgSrc = canvas.toDataURL('image/png');
          localStorage.setItem('thumbnailSrc', this.newImgSrc);
          this.dismissModal('save');
        })
        .then(() => {
          this.buttonState = '';
        });
    }
  }

  dismissModal(state: string) {
    if (this.type != 'option' && state != 'save') {
      this.type = 'option';
    } else {
      this.close.emit();
    }
  }

  openFileInput(type: string) {
    if (this.imageUrl == '' && type == 'div') {
      var ele = document.getElementById('fileInput') as HTMLVideoElement;
      ele.click();
    } else if (type == 'btn') {
      var ele = document.getElementById('fileInput') as HTMLVideoElement;
      ele.click();
    }
  }

  selectLayout(title: string, color: any) {
    this.type = 'thumbnailForm';
    this.layoutBg = `url('../../../../../assets/news-thumbnail/${
      title.split('.')[0]
    }.png')`;
    this.layoutTextColor = this.dataColor[color];
    this.title = title.split('.')[0].split('_').join(' ');
  }

  previewImage() {
    const file = this.fileInput.nativeElement.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      //this.imageUrl = `url(${reader.result as string})`;
      this.imageUrl = reader.result as string;
    };

    reader.readAsDataURL(file);
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    if (event.target.files.length > 0) {
      this.imageChangedEvent = event;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
    console.log(' image load  here');
  }
  cropperReady() {
    // cropper ready
    console.log(' image cropperReady here');
  }
  loadImageFailed() {
    // show message
    console.log(' image load cancle here');
  }
}
