<div class="cart-outer-div checkout-outer-div all-orders-div" style=" background-color: #F4F3F8;padding: 0px">
 

    <div class="od-order-detail-main-card" *ngIf="order">

      <!--Main card outer div-->
      <div class="od-order-detail-left-div">
        <div class="d-flex mb-px-20 align-center desktop-only" style="margin-top: 10px;">
          <div class="cursor" (click)="goToCart()"><img class="back-icon cursor" src="./../../../../assets/icons/back-button.svg"></div>
          <div class="shop-category-title">Past Orders</div>
      </div>

      <div class="od-image-div">
          <img class="od-image" src="./../../../../assets/food/order-detail-image.png">
      </div>


      <div class="food-checkout-font-4 text-center" [ngStyle]="{'color': order?.center_color}" style="margin: 20px 0px">{{order?.center_status}}</div>

         <!--Food checkout cart div-->
         <div class="food-checkout-cart-div" style="margin: 25px 0px;">

               <!--Cart items-->
               <div class="food-checkout-cart-list-div" *ngFor="let m of order?.cart">
               <div class="menu-title-info-div food-cart-item space-between">
                  <div class="food-checkout-font-4">{{m['name']}}</div>
                  <div class="food-checkout-font-5"><i class="fa fa-rupee"></i>{{m['price'] * m['quantity']}}</div>

               <!--Cart items end-->
              </div>
              </div>
              <div class="restaurant-menu-divider-line-2"></div>
      
              <!--Total subtoal div-->
              <div>
              <div class="food-checkout-font-3 d-flex space-between mb-px-10">
                  <div style="color: #D5D5D5">Sub Total</div>
                  <div><i class="fa fa-rupee">{{order?.total?.product}}</i></div>
              </div>
      
              <div class="food-checkout-font-3 d-flex space-between mb-px-10">
                  <div style="color: #D5D5D5">+Taxes</div>
                  <div><i class="fa fa-rupee"></i>{{getTax(order?.total?.tax)}}</div>
              </div>
      
              <div class="food-checkout-font-3 d-flex space-between mb-px-10">
                  <div style="color: #D5D5D5">Delivery Charges</div>
                  <div><i class="fa fa-rupee"></i>{{order?.total?.shipping}}</div>
              </div>

              <div class="food-checkout-font-3 d-flex space-between mb-px-10" *ngIf="order?.type=='Menu Order'">
                  <div style="color: #D5D5D5">Platform Charges</div>
                  <div><i class="fa fa-rupee"></i>{{order?.total?.fees}}</div>
              </div>

              <div class="food-checkout-font-2 d-flex space-between">
                  <div>Total</div>
                  <div><i class="fa fa-rupee"></i>{{getTotal(order?.total)}}</div>
              </div>
      
          </div>
              <!--Total subtoal div-->
      </div>
          <!--Food checkout cart div end-->
</div>
      <div>

      <div class="od-order-detail-right-div">
        <div class="checkout-font-3 poppins-medium mt-px-20 mb-px-10">Deliver To:</div>
        <div class="address-list-card">
          <div class="checkout-font-3 poppins-medium">{{address?.address_type | uppercase}}</div>
         <div class="checkout-font-3 poppins-light">{{address?.flat_no}},{{address?.landmark}}</div>
         <div class="checkout-font-3 poppins-light">{{address?.area}},{{address?.city}}-{{address?.pincode}}</div>
     
         <div style="margin-top: 20px;">
          <div class="checkout-font-3 poppins-medium">{{address?.firstname}} {{address?.lastname}}</div>
          <div class="checkout-font-3 poppins-light">{{address?.phone_no}}</div>
         </div>

        </div>

       
      </div>


      <div class="od-order-detail-right-div" style="margin:25px 0px;" *ngIf="firebase_order?.delivery_logs">
        <!--Delivery progress-->

         <div class="ps-delivery-progress-div">
          <ul class="ps-delivery-progress-list" style="width: 100%;position: relative;">
            <div class="ps-vertical-line"></div> 
            <ng-container  *ngFor="let status of firebase_order.delivery_logs">
            <li class="d-flex align-center">
               <div class="ps-delivery-progress-list-circle"></div>
               <div class="d-flex space-between" style="flex: auto">
                 <div class="checkout-font-3 poppins-medium">{{status?.title}}</div>
                 <div class="checkout-font-3 poppins-medium">{{status?.time}}</div>
               </div>
             </li>
           </ng-container>
          </ul>
          </div>
      </div>

      <div class="od-order-detail-right-div" style="margin:25px 0px;">
        
        <div class="d-flex space-between mb-px-10" style="flex: auto">
          <div class="checkout-font-3 poppins-medium">Order Id:</div>
          <div class="checkout-font-3 poppins-medium">{{order?.project_id}}</div>
        </div>
        <div class="d-flex space-between mb-px-10" style="flex: auto">
          <div class="checkout-font-3 poppins-medium">Transaction Id:</div>
          <div class="checkout-font-3 poppins-medium">{{order?.transaction_id}}</div>
        </div>
        <div class="d-flex space-between mb-px-10" style="flex: auto">
          <div class="checkout-font-3 poppins-medium">Paid Through:</div>
          <div class="checkout-font-3 poppins-medium">{{order?.payment_method}}</div>
        </div>

        <div class="d-flex justify-center" style="margin: 20px 0px">
          <button class="btn no-btn blue-btn" (click)="openSupportModal(support)">Support</button>
        </div>
      </div>
      <!--Main card outer div end-->
    </div>
  </div>

    <div class="od-order-detail-main-card" *ngIf="!order">

      <div class="od-sk-left">

      </div>

      <div class="od-sk-right">

        <div class="od-sk-right-div-1">

        </div>

        <div class="od-sk-right-div-1">

        </div>

        <div class="od-sk-right-div-1">

        </div>
        
      </div>
    </div>
    <!--
    <div class="checkout-summary-div detail-order-detail-div" style="box-shadow: none;" *ngIf="order">

      <div class="checkout-font-3 poppins-medium text-right mb-px-20">Order <span class="id-div">#{{id}}</span></div>

        <div class="checkout-font-3 poppins-medium mb-px-10">Order Items</div>

        <div *ngFor="let product of order.line_items">
             <div class="cart-summary-product-card d-flex space-between align-center mb-px-10" style="gap:15px">
                 <div class="d-flex align-center" style="gap:10px">
                     <img [src]="product.image" class="checkout-image">
                    <div>
                     <div class="poppins-medium checkout-font-3 text-eclipse">{{product?.name}}</div>
                     <div class="lato-medium checkout-font-3 font-grey">Quantity: {{product.quantity}}</div>
                   </div>
                 </div>

                 <div class="blue checkout-font-3">
                   <i class="fa fa-rupee blue"><span class="poppins-medium blue">{{product?.subtotal}}</span></i>
                </div>

             </div>
        </div>

        <div class="divider-line mt-px-10 mb-px-20"></div>
       <!--price div-->
       <!--
        <div>
          <div class="d-flex space-between mb-px-10">
             <div class="checkout-font-3 poppins-medium">Shipping Charges</div>
             <div class="black checkout-font-3">
               <i class="fa fa-rupee black"></i>{{shipping_charge}}
           </div>
           </div>

           <div class="d-flex space-between mb-px-10">
               <div class="checkout-font-3 poppins-medium mb-px-10">Total Order Price</div>
               <div class="black checkout-font-2 poppins-medium">
                 <i class="fa fa-rupee black"></i>{{getTotal()+shipping_charge}}
             </div>
             </div>


        </div>-->
       <!--price div end-->

       <!--
       <div class="divider-line mb-px-20" style="margin-top: 15px;"></div>

       <div class="checkout-font-3 poppins-medium" style="margin-bottom: 0px;">
          <div [ngStyle]="{color: order.center_color}"> <i class="fa fa-circle" style="size: 21px;" [ngStyle]="{color: order.center_color}"></i>
           {{order.center_status}}</div>

           <div class="checkout-font-3" style="margin-left: 20px;" *ngIf="order.center_status!=='Delivery not booked'">on {{order.center_time | date}}</div>
           
       </div>

       <div class="divider-line mb-px-20" style="margin-top: 15px;"></div>

       <div class="checkout-font-3 poppins-medium mt-px-20 mb-px-10">Deliver To:</div>
       <div class="address-list-card">
        <div class="checkout-font-3 poppins-medium">{{address?.firstname}} {{address?.lastname}}</div>
        <div class="checkout-font-3 poppins-light">{{address?.flat_no}},{{address?.landmark}}</div>
        <div class="checkout-font-3 poppins-light">{{address?.area}},{{address?.city}}-{{address?.pincode}}</div>
    </div>

    <div class="divider-line mb-px-20" style="margin-top: 15px;"></div>

    <div class="checkout-font-2 poppins-medium mt-px-20 mb-px-10">Support</div>
    <div class="address-list-card no-border">
        <div class="checkout-font-3 poppins-medium">For any queries</div>
        <div class="d-flex space-between align-center">
        <div class="checkout-font-3 poppins-medium blue">+91 7028794743</div>
        <button class="no-btn poppins-medium checkout-font-3" style="background-color:#3E7EFF;padding: 5px 10px;display: inline-block;border-radius: 10px;color: white"><a href="tel:7028794743" style="color: white;text-decoration: none;">Call Now</a></button>
    </div>
  </div>-->

  <ng-template #support let-modal>
    <img class="payment-success-close-img" src="./../../../../assets/icons/close_black_24dp.svg" (click)="closeModal()"> 
     <div style="padding: 20px 20px">
        <div class="checkout-font-3 poppins-medium">Call our customer support representative <br> on the  on the following numbers</div>
  
        <div style="margin-top: 25px;">
            <div class="checkout-font-3 poppins-medium" style="margin-top: 10px;">1. <a href="tel: 9359611138">9359611138</a></div>
            <div class="checkout-font-3 poppins-medium" style="margin-top: 10px;">2. <a href="tel: 9921925125">9921925125</a></div>
            <div class="checkout-font-3 poppins-medium" style="margin-top: 10px;">3. <a href="tel: 8055059296">8055059296</a></div>
  
        </div>
     </div>
  </ng-template>
</div>

