import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRemoveLeadingSpace]',
})
export class RemoveLeadingSpaceDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const newValue = event.target.value.trimStart();
    this.el.nativeElement.value = newValue;
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }
}
