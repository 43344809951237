<div class="m-0 p-0">
    <ng-container *ngIf="type=='option'">
        <div class="modal-title-div">
            <div class="modal-title-4 d-flex align-center"><img class="post-back-btn cursor" src="./../../../../assets/icons/back-button.svg" (click)="dismissModal('')">&nbsp; Select Thumbnail Type</div>
        </div>
        <div class="modal-content-div">
            <div class="thumbnail-option-img m-2" *ngFor="let img of data; let i = index;">
        
                <img  src="../../../../../assets/news-thumbnail/{{img}}" alt="3km post thumbnail" style="max-width: 100%; max-height: 100%;" (click)="selectLayout(img,i)">
            </div>
        </div>
        <div class="modal-footer">
        <!--  <button type="button" class="btn btn-secondary" (click)="dismissModal()">Close</button>-->
          <button type="button" class="btn btn-secondary" (click)="dismissModal('')">Close</button>
        </div>
    </ng-container>
    
    <ng-container *ngIf="type == 'thumbnailForm'">
        <div class="modal-title-div">
            <div class="modal-title-4  d-flex align-center" style="font-size: medium;"><img class="post-back-btn cursor" src="./../../../../assets/icons/back-button.svg" (click)="dismissModal('')">&nbsp; Edit {{title}}</div>
            <div>
               <!-- <img class="modal-close-img cursor" src="./../../../../assets/icons/close_black_24dp.svg">--></div> 
               <!-- [disabled]="!latitude || !longitude || !title || !location" -->
                <button class="btn no-btn post-submit-btn" [disabled]="!heading || !croppedImage || buttonState"  (click)="convertToImage()" ><span *ngIf="!buttonState">SAVE</span> <span *ngIf="buttonState">SAVING</span></button>
        </div>
        <div class="modal-content-div" id="thumbnail-div" >
            <div id="thumbnail-main-container" #myDiv >
                <div class="thumbnail-child" id="image-div" >
                    <div id="image-container" (click)="openFileInput('div')" >
                        <input type="file" id="fileInput" #fileInput (change)="fileChangeEvent($event)" (cancle)="fileChangeEvent($event)" hidden accept="image/*" />
                        <!-- <input type="file" hidden id="fileInput" #fileInput accept="image/*" (change)="previewImage()"> -->
                        <img *ngIf="!imageChangedEvent" src="../../../../../assets/news-thumbnail/add_photo_alternate_black.png" width="40" height="40" alt="3km">
                        <p *ngIf="!imageChangedEvent" style="color:white">your Image will be placed here</p>
                        <img id="previewImg"  [src]="croppedImage" *ngIf="croppedImage" alt="Image">
                    </div>
                </div>
               
                <div class="thumbnail-child" id="on3km-icon">
                    <img src="../../../../../assets/5km-logo/On_5km_logo_thumbnail 120 x 69.webp" alt="on 5km">
                </div>
                <div class="thumbnail-child p-2" id="text-div" [style.backgroundImage]="layoutBg">
                    <p class="thumbnail-heading" [style.color]="layoutTextColor">{{heading}}</p>
                </div>
            </div>
        </div>
        <div id="image-cropper"  *ngIf="imageChangedEvent">
            <image-cropper 
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="16 / 9"
            format="webp"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [imageQuality]=70
        ></image-cropper>
        </div>
       
        <div class="m-3">
            <button class="btn changeImageBtn mb-2" (click)="openFileInput('btn')"><img src="../../../../../assets/news-thumbnail/add_photo_alternate_blue.png" width="20" height="20" alt="3km"> &nbsp;<span *ngIf="!croppedImage">Add</span><span *ngIf="croppedImage">Change</span> image</button>
            <label class="np-font-1 np-font-color-1 text-body">Text</label>
            <div class="np-font-3 np-font-color-1">Adding a Headline will help your post to stand out</div>
            <div style="position: relative;" >
                <textarea class="np-custom-input bg-transparent" type="text" [(ngModel)]="heading" maxlength="60" id="" (input)="onInput()"></textarea>
                <div class="np-font-3 np-font-color-1 limit-text" *ngIf="title">{{heading.length}}/60</div>
            </div>    
        </div>
       
    </ng-container>
    
</div>