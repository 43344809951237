import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import * as urlSlug from 'url-slug';

@Component({
  selector: 'app-featured-stories',
  templateUrl: './featured-stories.component.html',
  styleUrls: ['./featured-stories.component.scss']
})
export class FeaturedStoriesComponent implements OnInit {

  @Input() featured_stories:any;
  lang= this.commonService.getLocalStorage('language');
 // lang='hi';

  constructor(public router: Router, public platform:Platform, public commonService:CommonService) { }

  ngOnInit(): void {
    if(!this.lang){
      this.lang='en';
    }
  }

  viewPostDetail(id:number,index:number,lang:string){
    let post=this.featured_stories[index];
    let url= this.urlSlugConverter(post['slug_headline']);

    if(!url){
      //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
       //  url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
        url= '5km-post-detail';
      }
      this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);
 }
 
   urlSlugConverter(headline:string){
    let url= urlSlug.convert(headline,
      {camelCase: false});
    return url;
 }

  open_app(pf:string){
    if(this.platform.ANDROID || pf=='android') {
      window.open('http://play.google.com/store/apps/details?id=com.bulbandkey.threekmseller&hl=en_IN&gl=US', "_blank")
    } else if(this.platform.IOS || pf=='ios') {
      window.open('https://apps.apple.com/in/app/3km/id1533435804', "_blank")
    }
  }
}
