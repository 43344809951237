import { Component, OnInit, NgZone, ViewChild, ElementRef, Output,EventEmitter} from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss']
})
export class JobPostComponent implements OnInit {
   
   @Output() close= new EventEmitter<any>();
   step=1;
   post_pic:any="https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/0c0c1540-ae18-11ed-8881-57892f01216b.png";
   
   role:any;
   location:any;
   description:any;
   salary:any='';
   worktype:any;
   jobtype:any;
   experience:any='Select';
   qualification:any='Select';
   companyname:any='';
   contactno:any;
   contactemail:any='';
   link:any='';

   inv_role=false;
   inv_location= false;
   inv_job= true;
   inv_work= true;
   inv_salary= false;
   inv_description= false;
   inv_qualification= true;
   inv_experience= true;
   inv_company_name= false;
   inv_contact_no= false;
   inv_email= false;

   latitude:any=18.530823;
   longitude:any=73.847466;
   postlocation:any='Pune, Maharashtra, India';

   constructor(public commonService:CommonService,private sanitizer:DomSanitizer){

   }

   ngOnInit(): void {
      this.getLocation();
   }

   closeModal(){
      this.close.emit();
   }

   async filesSelected(event: any){
    for(let i=0;i<event.target.files.length; i++){
         this.upload(event.target.files[i]);
    }
}

getLocation(){
   let val=this.commonService.getLocalStorage('post_location');
   if(val){
       this.latitude= val['lat'];
       this.longitude= val['lng'];
       this.postlocation= val['location'];
   }
 }


upload(file:any){
this.commonService.uploadImage(file).subscribe({
  next: (event: any) => {
    if (event.type === HttpEventType.UploadProgress) {
    } else if (event instanceof HttpResponse) {
       this.post_pic= event.body.photo.id;
    }
  },
  error: (err: any) => {
  }

})
}

typingRole(){
    let str:string= this.role;
    if(str.trim()==''){
        this.inv_role= true;
    }else{
       this.inv_role= false;
    }
}

selectWorkType(event:any){
   console.log(event);
}

selectExperience(event:any){
   console.log(this.experience);
}

selectQualification(event:any){
   console.log(event);
}

typingLocation(){
   let str:string= this.location;
   if(str.trim()==''){
       this.inv_location= true;
   }else{
      this.inv_location= false;
   }
}

typingSalary(){
   
}

formatdesc:any='';

typingDescription(){
   let str:string= this.description;
   if(str.trim()==''){
       this.inv_description= true;
   }else{
      this.inv_description= false;
   }
}

typingCompanyName(){
  /* let str:string= this.companyname;
   if(str.trim()==''){
       this.inv_company_name= true;
   }else{
      this.inv_company_name= false;
   }*/
}

typingContactNo(){
   let str:string= `${this.contactno}`;
   if(this.contactno && str.length>0 && str.length!==10){
       this.inv_contact_no= true;
   }else{
      this.inv_contact_no= false;
   }
}

typingEmail(){
   let str:string= this.contactemail;
   if(str.trim().length>0 && (str.indexOf('@')==-1 || str.indexOf('.')==-1)){
       this.inv_email= true;
   }else{
      this.inv_email= false;
   }
}

finaltitle:any;
finaldesc:any='';

prepareTitle(){
   this.finaltitle=`Urgently Hiring for ${this.role} (${this.jobtype})`;
}

prepareDesc(){
   if(this.description){
      this.finaldesc= `<p><strong>Description: </strong></p><p>${this.description.trim()}</p>`;
   }
   if(this.role){
      let role=`<p><strong>Role: </strong>${this.role}</p>`;

      this.finaldesc= `${this.finaldesc} ${role}`;
   }
   if(this.location){
      this.finaldesc= `${this.finaldesc} <p><strong>Location: </strong>${this.location.trim()}</p>`;
   }
   if(this.worktype){
      this.finaldesc=  `${this.finaldesc} <p><strong>Type of Work: </strong>${this.worktype}</p>`;
   }
   if(this.jobtype){
      this.finaldesc=  `${this.finaldesc} <p><strong>Job Type: </strong>${this.jobtype}</p>`;
   }
   if(this.salary.trim()){
      this.finaldesc=  `${this.finaldesc} <p><strong>Monthly Salary: </strong>${this.salary.trim()}</p>`;
   }
   if(this.experience){
      this.finaldesc=  `${this.finaldesc} <p><strong>Years of Experience: </strong>${this.experience}</p>`;
   }
   if(this.qualification){
      this.finaldesc=  `${this.finaldesc} <p><strong>Educational Qualification: </strong>${this.qualification}</p>`;
   }
   if(this.companyname.trim()){
      this.finaldesc=  `${this.finaldesc} <p><strong>Company Name: </strong>${this.companyname.trim()}</p>`;
   }
   if(this.contactno){
      this.finaldesc=  `${this.finaldesc} <p><strong>Phone Number: </strong><a href="tel:${this.contactno}">${this.contactno}</a></p>`;
   }
   if(this.contactemail.trim()){
      this.finaldesc=  `${this.finaldesc} <p><strong>Email: </strong><a href="mailto:${this.contactemail.trim()}">${this.contactemail.trim()}</a></p>`;
   }
   if(this.link.trim()){
      this.finaldesc=  `${this.finaldesc} <p><strong>Apply Now: </strong><a href="${this.link}" target="_blank">${this.link.trim()}</a></p>`;
   }
}

editDetails(){
   this.step=1;
}

createJobPost(){
    this.prepareTitle();
    this.prepareDesc();
    let page= this.commonService.getLocalStorage('current_page');
    let payload:any = {
      headline: this.finaltitle,
      story: this.finaldesc,
      images: [this.post_pic],
      videos: [],
      tags: ['Skills','Jobs','Hiring'],
      areas: [],
      latitude: this.latitude,
      longitude: this.longitude,
      location: this.postlocation,
      business: [],
      products: []
    }
    console.log(payload);
    if(page && page['page_id']){
      payload['page_id']= page['page_id'];
    }
    this.commonService.createPost(payload).subscribe((data:any)=>{
       if(data['status']=='success'){
          this.step=4;
          //  this.msg='Post Uploaded successfully';
       }
    })
}

 shareJobPost(){

 }

 goBack(){
     if(this.step>1){
       this.step--;
     }else{
        this.closeModal();
     }
   }
}