import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllPostsComponent } from './all-posts/all-posts.component';
import { NewsHomeComponent } from './news-home/news-home.component';
import { PostsPageComponent } from './posts-page/posts-page.component';
import { SinglePostComponent } from './single-post/single-post.component';
import { ShowReelComponent } from './show-reel/show-reel.component';
import { PollDetailComponent } from './poll-detail/poll-detail.component';
import { HelpAndSupportComponent } from './help-and-support/help-and-support.component';
import { PostDetailSsrComponent } from './post-detail-ssr/post-detail-ssr.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { NewsTagsComponent } from './news-tags/news-tags.component';

const routes: Routes = [
 /* { path: 'home', component: NewHomeComponent },
  { path: 'category/posts', component: AllPostsComponent },
  { path: 'post-detail/:title/:id', component: SinglePostComponent },
  { path: 'poll/:id', component: PollDetailComponent },
  { path: 'contact', component: HelpAndSupportComponent },
  { path: 'showreel', component: ShowReelComponent },
  { path: 'tag-all-post/:tag', component: NewsTagsComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
