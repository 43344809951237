import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map/map.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CreatePageComponent } from './create-page/create-page.component';
import { FormsModule } from '@angular/forms';
import { UserAnalyticsComponent } from './user-analytics/user-analytics.component';
import { JobPostComponent } from './job-post/job-post.component';
import { EventPostComponent } from './event-post/event-post.component';
import { InterestedFormComponent } from './interested-form/interested-form.component';
import { MarriagePostComponent } from './marriage-post/marriage-post.component';
import { RemoveLeadingSpaceDirective } from 'src/custom_directive/RemoveLeadingSpace';
import { BuySellComponent } from './buy-sell/buy-sell.component';

@NgModule({
  declarations: [
    SearchComponent,
    JobPostComponent,
    VideoPlayerComponent,
    MapComponent,
    TermsAndConditionsComponent,
    CreatePageComponent,
    UserAnalyticsComponent,
    EventPostComponent,
    InterestedFormComponent,
    MarriagePostComponent,
    RemoveLeadingSpaceDirective,
    BuySellComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule,
    /*.forRoot({
      apiKey: 'AIzaSyDFJN-QCh-tcHxufHpH3TU861N2ICVb9Gw',
      libraries:['places']
    })*/
  ],
  providers: [DatePipe],
  exports: [
    SearchComponent,
    VideoPlayerComponent,
    JobPostComponent,
    MapComponent,
    CreatePageComponent,
    UserAnalyticsComponent,
    EventPostComponent,
    InterestedFormComponent,
    MarriagePostComponent,
    RemoveLeadingSpaceDirective,
    BuySellComponent,
  ],
})
export class SharedModule {}
