import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-buy-sell',
  templateUrl: './buy-sell.component.html',
  styleUrls: ['./buy-sell.component.scss']
})
export class BuySellComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<any>();
  
  step = 1;
  inv_contact_no: boolean = false;
  contactno: any;
  description?: string;
  itemName?: string;
  itemPrice?: string;
  subscribeObject1: any;
  subscribeObject2: any;

  imageUrls: string[] =[]
  post_pic: string[] = [];
  finaldesc: string = ""
  latitude: any = 18.530823;
  longitude: any = 73.847466;
  postlocation: any = 'Pune, Maharashtra, India';


  constructor( public commonService: CommonService,) { }



  ngOnInit(): void {
    this.getLocation();
  }

ngOnDestroy(): void {
  this.subscribeObject1.unsubscribe()
  this.subscribeObject2.unsubscribe()
}


  getLocation() {
    let val = this.commonService.getLocalStorage('post_location');
    if (val) {
      this.latitude = val['lat'];
      this.longitude = val['lng'];
      this.postlocation = val['location'];
    }
  }

  closeModal() {
    this.close.emit();
  }
  goBack() {
    if (this.step == 3) {
      this.closeModal();
    } else if (this.step > 1) {
      this.step--;
    } else {
      this.closeModal();
    }
  }

  typingContactNo() {
    let str: string = `${this.contactno}`;
    if (this.contactno && str.length > 0 && str.length !== 10) {
      this.inv_contact_no = true;
    } else {
      this.inv_contact_no = false;
    }
  }


  handleFileInput(event: Event) {
    let files = (event.target as HTMLInputElement).files!;
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrls.push(event.target.result);
      };
      reader.readAsDataURL(files[i]);
      //this.filesToUpload.push(files[i]);
      this.upload(files[i]);
    }
  }

  deleteImg(i:number) {
    this.imageUrls.splice(i, 1)
   // this.filesToUpload.splice(i,1)
  }


  upload(file: any) {
    this.subscribeObject1=  this.commonService.uploadImage(file).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          this.post_pic.push(event.body.photo.id)
        }
      },
      error: (err: any) => {},
    });
  }


  preparDesc() {
    if (this.description) {
      this.finaldesc = `<p><strong>Description: </strong></p><p>${this.description.trim()}</p>`
    }
    if (this.itemPrice) {
      this.finaldesc = `${this.finaldesc} <p><strong>Price: </strong>${this.itemPrice} </p>`
    }
 
    if (this.contactno) {
      this.finaldesc = `${this.finaldesc} <p><strong>Phone Number: </strong><a href="tel:${this.contactno}"> ${this.contactno}</a> </p>`
      
    }
    // this.finaldesc = `${this.finaldesc} <br><img style="width:-webkit-fill-available; height:auto" alt="3km-buy sell Items" src="https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/e4b5a320-da19-11ed-b54d-67a06d8bebb2.png">`
    
  }


  createSalePost() {
    

    
    this.preparDesc();
    let page= this.commonService.getLocalStorage('current_page');
    let payload: any = {
      headline: `${this.itemName} for sale`,
      story: this.finaldesc,
      images: this.post_pic,
      videos: [],
      tags: ["sale","buy","offer"],
      areas: [],
      latitude: this.latitude,
      longitude: this.longitude,
      location: this.postlocation,
      business: [],
      products: [],
    };
    console.log(payload);
    if(page && page['page_id']){
      payload['page_id']= page['page_id'];
    }
    this.subscribeObject2 = this.commonService.createPost(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.step = 3;
        //  this.msg='Post Uploaded successfully';
      }
    });
  }



}
