import { Component, OnInit } from '@angular/core';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';

@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss']
})
export class DownloadLinkComponent implements OnInit {

  constructor(private platform: Platform) { }

  ngOnInit(): void {
    if(this.platform.ANDROID) {
      window.open('https://play.google.com/store/apps/details?id=com.bulbandkey.threekmseller&hl=en_IN&gl=US', "_blank")
    } else if(this.platform.IOS) {
      window.open('https://apps.apple.com/in/app/3km/id1533435804', "_blank")
    }
  }

  openLink(url: string) {
    window.open(url, "_blank")
  }

}
