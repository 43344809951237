<div class="cp-main-div">
    <div class="heading-4 verify-otp-title">Change Password</div>

    <div class="vo-divider"></div>

      <p class="show-message text-center" id="msg">{{message}}</p>
     <div class="cp-input-div" [formGroup]="passwordForm">
          
        <div class="cp-input">
            <label class="cp-input-text">Current Password</label>
            <input class="input-box cp-input-text-2" placeholder="Old password" formControlName="old_password">
            <div *ngIf="form['old_password'].dirty && form['old_password'].errors">
                <p class="text-danger" *ngIf="form['old_password'].errors['required']">Enter current password</p>
             </div>
        </div> 
        <div class="cp-input">
            <label class="cp-input-text">New Password</label>
            <input class="input-box cp-input-text-2" placeholder="New password" formControlName="new_password">
            <div *ngIf="form['new_password'].dirty && form['new_password'].errors">
               <p class="text-danger" *ngIf="form['new_password'].errors['minlength']">Invalid password length</p>
               <p class="text-danger" *ngIf="form['new_password'].errors['required']">Enter new password</p>
            </div>
        </div> 
        <div class="cp-input">
            <label class="cp-input-text">Confirm New Password</label>
            <input class="input-box cp-input-text-2" placeholder="Confirm new password" formControlName="confirm_password">
            <div class="text-danger" *ngIf="form['confirm_password'].dirty && passwordForm.errors?.['passwordMatch']">Password doesn't match</div>
            <div *ngIf="form['confirm_password'].errors && form['confirm_password'].dirty">
                <p class="text-danger" *ngIf="form['confirm_password'].errors['required']">Confirm password</p>
             </div>
        </div> 

     </div>

     <div class="cp-input-submit-btn">
        <button class="otp-btn cp-input-text-2 text-login-blue" [disabled]="passwordForm.invalid" (click)="changePassword()">Save New Password</button>
      </div>
</div>

