<div class="like-reactions-div">
    <div class="like-div cursor like-reactions-logo-rm" (click)="like('like')">
      <!--  <img src="./../../../../assets/icons/icons8-facebook-like-3.svg" class="like-reactions-logo" (click)="like('like')">-->
        <ng-lottie [options]="options1" height="26px" width="26px" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>

    <div class="heart-div cursor like-reactions-logo-rm-2" (click)="like('love')">
      <!-- <img src="./../../../../assets/icons/icons8-heart-2.svg" class="like-reactions-logo">--> 
      <ng-lottie [options]="options2" height="26px" width="26px" (animationCreated)="animationCreated($event)"></ng-lottie>

    </div> 
    <div class="trust-div cursor" (click)="like('care')">
       <!-- <img src="./../../../../assets/icons/icons8-trust.svg" class="like-reactions-logo">-->
       <ng-lottie [options]="options3" height="40px" width="40px" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>
    <div class="lol-div cursor" (click)="like('laugh')">
       <!-- <img src="./../../../../assets/icons/icons8-lol-3.svg" class="like-reactions-logo">-->
       <ng-lottie [options]="options4" height="40px" width="40px" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>
    <div class="sad-div cursor" (click)="like('sad')">
       <!-- <img src="./../../../../assets/icons/icons8-sad-2.svg" class="like-reactions-logo">-->
       <ng-lottie [options]="options5" height="40px" width="40px" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>

    <div class="sad-div cursor" (click)="like('angry')">
        <!-- <img src="./../../../../assets/icons/icons8-sad-2.svg" class="like-reactions-logo">-->
        <ng-lottie [options]="options6" height="40px" width="40px" (animationCreated)="animationCreated($event)"></ng-lottie>
     </div>
</div>