import { Component, OnInit, NgZone, ViewChild, ElementRef, Output,EventEmitter} from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  latitude:any;
  longitude:any;
  zoom=12;
  geoCoder:any;
  address:any;
  searchterm:any;

  @Output() location=new EventEmitter<any>();
  @Output() cancel=new EventEmitter();

  @ViewChild('postsearch',{static:false,read:ElementRef}) public searchElementRef:any;
  place:any='';

  constructor(public apiloader:MapsAPILoader, private ngZone:NgZone, public commonService:CommonService) { }

  ngOnInit(): void {
     this.getLocalLocation();
     this.apiloader.load().then(() => {
     this.setCurrentLocation();
     this.geoCoder = new google.maps.Geocoder;

     let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
     autocomplete.addListener("place_changed", () => {
       this.ngZone.run(() => {
      //    get the place result
         let place: google.maps.places.PlaceResult = autocomplete.getPlace();

       //   verify result
         if (place.geometry === undefined || place.geometry === null) {
           return;
         }

       //   set latitude, longitude and zoom
         this.latitude = place.geometry.location.lat();
         this.longitude = place.geometry.location.lng();
         this.zoom = 12;
         this.getAddress(this.latitude,this.longitude);
         this.userLocation({lat: this.latitude, lng: this.longitude});
       });
     });
    });
    
  }

  userLocation(payload:any){
    this.commonService.getUserLocation(payload).subscribe((data:any)=>{
         console.log(data);
          if(data['status']=='success'){
             this.place= data['Result']['location'];
              let location= {
                              location:data['Result']['location'],
                              lat: payload['lat'],
                              lng: payload['lng']
                             };
              this.commonService.setLocalStorage('location',location);
              this.commonService.locationUpdate();
          }
    })
   }

   getLocalLocation(){
    let location=this.commonService.getLocalStorage('location');
    if(location){
       this.latitude= location['lat'];
       this.longitude= location['lng'];
       this.place= location['location'];
    }
   }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      let location=this.commonService.getLocalStorage('location');
      if(!location){
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.userLocation({lat: this.latitude, lng: this.longitude});
        this.zoom = 15;
     //   this.getAddress(this.latitude,this.longitude);
      });
      }
    }
  }

 
  markerDragEnd($event:any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.userLocation({lat: this.latitude, lng: this.longitude});
  //  this.getAddress(this.latitude, this.longitude);
  }
  

  getAddress(latitude:any, longitude:any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
         // this.confirmLocation();
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    }
    )
  }

  confirmLocation(){

    let location= {
      location: this.place,
      lat: this.latitude,
      lng: this.longitude
     };
      this.commonService.setLocalStorage('location',location);
      this.commonService.locationUpdate();

      this.location.emit(this.place);
  }

  closeModal(){
    this.cancel.emit();
  }
}
