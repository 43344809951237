<ng-template #imageSelector>
    <div class="ev-img-div">
        <img class="ev-edit-icon" src="./../../../assets/profile/profile-info-edit.svg" alt=""
            (click)="fileInput.click()">
        <img style="width:70%; justify-content: center;" id="previewImg" [src]="post_pic" alt="Image">
        <input type="file" id="fileInput" #fileInput (change)="filesSelected($event)" (cancle)="fileChangeEvent($event)"
            hidden accept="image/*" />
    </div>
</ng-template>


<div class="event-post-main-div p-4">
    <div class="d-flex space-between align-center" *ngIf="step<4" style="margin-bottom: 30px;">
        <div class="d-flex align-center justify-center"><img style="margin-right: 10px;" class="job-post-icon-1 cursor"
                (click)="goBack()" src="./../../../assets/icons/back-button.svg">
            <h2 class="job-post-text-1" style="margin-bottom: 0px;">{{step<3?'Create event':'Review event post'}}</h2>
        </div>
        <p class="job-post-text-2 job-post-color-1 desktop-only">step {{step}}/3</p>
    </div>
    <div class="job-post-close-icon" *ngIf="step==4" (click)="closeModal()">
        <img class="cursor job-post-close-icon-2" src="./../../../../assets/icons/close_black_24dp.svg">
    </div>
    <ng-container *ngIf="step==1">
        <div class="">

            <ng-container *ngTemplateOutlet="imageSelector"></ng-container>

            <p class="ev-post-text1 mt-2">Event type</p>
            <div class="event-type-radio">
                <div class="mr-2"><input style="margin-right: 8px;" type="radio" name="eventType" value="Physical"
                        id="Physical" [(ngModel)]="eventType"> <label for="Physical"> Physical </label></div>
                <div class="mr-2"><input style="margin-right: 8px;" type="radio" name="eventType" value="Online"
                        id="Online" [(ngModel)]="eventType"> <label for="Online"> Online </label></div>
            </div>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Event name<strong class="text-c-warning">*</strong></p>
                <input placeholder="Text" [(ngModel)]="eventName" appRemoveLeadingSpace>
            </div>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Start date
                    <!-- <strong class="text-c-warning">*</strong> -->
                </p>
                <input class="bg-transparent" min="{{today}}" type="date" placeholder="Text" [(ngModel)]="startDate">
            </div>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Start time
                    <!-- <strong class="text-c-warning">*</strong> -->
                </p>
                <input type="time" class="bg-transparent" placeholder="Text" [(ngModel)]="startTime">
            </div>
            <div *ngIf="startDate" class="row togglebtn" (click)="isEndDate = !isEndDate">
                <h3 *ngIf="isEndDate">-</h3>
                <h3 *ngIf="!isEndDate">+</h3>
                <h5> End Date & Time</h5>
            </div>
            <div *ngIf="isEndDate">
                <div class="event-post-input">
                    <p style="margin-bottom: 8px;">End date
                        <!-- <strong class="text-c-warning">*</strong> -->
                    </p>
                    <input type="date" min="{{startDate}}" class="bg-transparent" placeholder="Text"
                        [(ngModel)]="endDate">
                </div>
                <div class="event-post-input">
                    <p style="margin-bottom: 8px;">End time
                        <!-- <strong class="text-c-warning">*</strong> -->
                    </p>
                    <input type="time" placeholder="Text" class="bg-transparent" [(ngModel)]="endTime">
                </div>
            </div>

            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Event Description
                    <!-- <strong class="text-c-warning">*</strong> -->
                </p>
                <textarea class="job-post-text-area" rows="6" placeholder="Add event details " [(ngModel)]="description"
                    appRemoveLeadingSpace></textarea>
                <!-- <p class="text-c-warning" *ngIf="inv_description">Invalid input</p> -->
            </div>
            <!-- contact section -->
            <p class="ev-post-text1 mt-2">Contact (Optional)</p>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Contact Number</p>
                <input id="cphno" placeholder="Enter number(Eg: 94862553634)" type="number" [(ngModel)]="contactno"
                    (ngModelChange)="typingContactNo()" appRemoveLeadingSpace>
            </div>
            <p class="text-c-warning" *ngIf="inv_contact_no">Invalid number</p>

            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Email Id</p>
                <input placeholder="Enter text(Eg: 5km@gmail.com)" type="email" [(ngModel)]="contactemail"
                    (ngModelChange)="typingEmail()" appRemoveLeadingSpace>
            </div>
            <p class="text-c-warning" *ngIf="inv_email">Invalid email</p>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Address
                    <!-- <strong class="text-c-warning">*</strong> -->
                </p>
                <textarea rows="3" placeholder="Enter Address/ Venue here " [(ngModel)]="address"
                    appRemoveLeadingSpace></textarea>
                <!-- <p class="text-c-warning" *ngIf="inv_description">Invalid input</p> -->
            </div>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Link (Optional)</p>
                <input placeholder="Enter event registration/ booking/ meeting link" type="text" [(ngModel)]="link"
                    appRemoveLeadingSpace>
            </div>
            <p class="ev-post-text1 mt-2">Price (optional)</p>
            <div class="event-post-input">
                <p style="margin-bottom: 8px;">Add event registration fees </p>
                <input placeholder="Text" type="text" [(ngModel)]="price" appRemoveLeadingSpace>
            </div>

            <div class="input-margin-1">
                <label class="np-font-1 np-font-color-1 text-body">Tags<span
                        class="np-font-2 np-font-color-1 text-body">(optional)</span></label>
                <div class="np-font-3 np-font-color-1"> Adding Tags will help your post to reach with more people</div>
                <div style="margin-top: 10px;position: relative;">
                    <div class="d-flex" style="gap: 15px;width: 100%;flex-flow: row wrap;">
                        <div class="event-tag np-font-4 np-font-color-3" *ngFor="let t of tags">
                            <div>{{t}}</div> <img class="cursor" (click)="deleteTag(t)"
                                src="./../../../../assets/icons/cancel_black.svg" height="20" width="20">
                        </div>
                        <input class="np-custom-input-2 bg-transparent" placeholder="Enter your tag here"
                            [(ngModel)]="tag" (keyup)="typingTag($event)" appRemoveLeadingSpace>
                    </div>
                </div>
                <div class="np-border-1"></div>
                <div class="np-font-3"> Separate tags with a comma(eg: election,politics)</div>
            </div>
            <button class="btn no-btn not-enable-button  not-text-3" style="width:30%" (click)="step = 2;"
                [disabled]=" !eventName ||  inv_contact_no || inv_email">NEXT</button>
        </div>
    </ng-container>
    <ng-container *ngIf="step==2">
        <ng-container *ngTemplateOutlet="imageSelector"></ng-container>
        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Event name</p>
                <p class="job-post-text-6">{{eventName}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Event Type</p>
                <p class="job-post-text-6">{{eventType}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Date</p>
                <p class="job-post-text-6">{{startDate}} {{endDate ? "to "+endDate : ""}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Time</p>
                <p class="job-post-text-6">{{startTime}} {{endTime ? "- "+endTime : ""}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Description</p>
                <p class="job-post-text-6">{{description}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div *ngIf="address" class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Address</p>
                <p class="job-post-text-6">{{address}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div *ngIf="contactno" class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Phone number</p>
                <p class="job-post-text-6">{{contactno}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div *ngIf="contactemail" class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Email</p>
                <p class="job-post-text-6">{{contactemail}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div *ngIf="link" class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Link</p>
                <a href="{{link}}" class="job-post-text-6">{{link}}</a>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div *ngIf="price" class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Price</p>
                <p class="job-post-text-6">{{price}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <button class="btn no-btn not-enable-button  not-text-3" [disabled]="!eventName" style="width:30%"
            (click)="createEventPost()">Create Event Post</button>
    </ng-container>
    <ng-container *ngIf="step==3">
        <div class="job-post-status-div">
            <div>
                <div class="job-post-success-image-div">
                    <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
                </div>
                <h3 class="job-post-text-7 text-center" style="margin-bottom: 12px;">Job post created successfully!
                </h3>
                <p class="job-post-text-8 job-post-color-1 text-center">Share it with people to create awareness</p>
            </div>

            <!--    <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="shareJobPost()">Share</button>-->

        </div>
    </ng-container>
</div>