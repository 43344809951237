import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpCoreInterceptor } from './core/interceptor/http.interceptor';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { MatIconModule } from '@angular/material/icon';
import { DragScrollModule } from 'ngx-drag-scroll';
import { DownloadLinkComponent } from './download-link/download-link.component';
import {MatRadioModule} from '@angular/material/radio';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from './layout/layout.module';
import { CommonUtilityModule } from './pages/common-utility/common-utility.module';
import { AdsenseModule } from 'ng2-adsense';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
//import 'zone.js/plugins/task-tracking';
import { SharedModule } from './shared/shared.module';
import { AccountModule } from './account/account.module';

import { GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
import { AngularFireModule} from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from "./../app/core/config/config";
import {YouTubePlayerModule} from '@angular/youtube-player';

import {
  SocialAuthServiceConfig,
  SocialAuthService
} from 'angularx-social-login';
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NewsModule } from './pages/news/news.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    DialogContentComponent,
    DownloadLinkComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(environment.firebase),
    BrowserTransferStateModule,
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    YouTubePlayerModule,
    NgbModule,
    MatRadioModule,
    CommonUtilityModule,
    OverlayModule,
    SharedModule,
    AccountModule,
    NewsModule,
  //  LayoutModule,
    MatIconModule,
 //   ServiceWorkerModule,
    PlatformModule,
    DragScrollModule,
    LottieModule.forRoot({ player: playerFactory }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDFJN-QCh-tcHxufHpH3TU861N2ICVb9Gw',
      libraries:['places']
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-center-center',
      preventDuplicates: true
    }
    ),
     AdsenseModule.forRoot({
      adClient: 'ca-pub-2898290509296226',
      adSlot: '3562656391'
    }),
     ServiceWorkerModule.register('ngsw-worker.js', {
       enabled: environment.production,
       // Register the ServiceWorker as soon as the application is stable
       // or after 30 seconds (whichever comes first).
       registrationStrategy: 'registerWhenStable:30000'
     })
  ],
  exports:[],
  providers: [
     NgbActiveModal,
     SocialAuthService,
     {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('973155372103-pm3mr5mmc9eq0mo5pbn4ucr0i6bpr503.apps.googleusercontent.com'),
        //    provider: new GoogleLoginProvider('1097225312230-tmd1t7m0iocoku4l88t7t6m1achkgh8f.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('758121711505008'),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCoreInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
