<div>

<div class="modal-content">
      <div class="d-flex align-center react-cust-title">
        <div class="reaction-list-back-div mobile-only cursor" data-bs-dismiss="modal" (click)="closeModal()">
            <img class="reaction-list-back-icon" src="./../../../../assets/icons/back-button.svg">
        </div>
      <div class="modal-title-text">{{count}} People reacted to this</div>
        <div data-bs-dismiss="modal" class="cursor"><button class="no-btn list-close-btn" (click)="closeModal()"><img src="./../../../../assets/icons/close_black_24dp.svg" class="modal-close-icon"></button></div>
      </div>
     <div class="modal-body">
      <div class="follower-details-list">
            <div class="follower-details-desc d-flex space-between align-center" *ngFor="let people of followers_list">
               <div class="d-flex align-center">
                 <div class="react-image-div">
                   <img class="follower-image" [src]="people?.avatar">
                   <img class="reaction-type-image" src="./../../../../assets/icons/like-filled.svg">
                </div>
                  <div class="react-name-text">{{people?.name}}</div>
               </div>

             <!-- <div>
                  <button class="followers-follow-btn">Follow</button>
               </div>--> 
            </div>

         <!--  <div class="react-name-text text-center" *ngIf="anonymous_count>0 && count>0">+{{anonymous_count}} likes</div>-->

      </div> 
   </div>  
  </div>

</div>