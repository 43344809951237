
<div *ngIf="isBrowser">

  <div class="posts-list-div" style="max-width: 100%;position: relative;">
  <div *ngFor="let post of display_posts;index as a">   
  

    <!--post card-->
    <div class="new-post-detail-card" *ngIf="post['type']=='Post'"  style="max-width: 100%;position: relative;">

      <div class="new-post-rejected-overlay" *ngIf="post['status']=='rejected'">
             <div class="new-post-rejected-overlay-center">
                 <img src="./../../../../assets/icons/post-rejected-logo.svg" alt="rejected-icon" height="24" width="24" style="display: block;margin: 8px auto;">
                 <div class="new-post-card-font-3 text-center text-capitalize">This post was rejected by admin</div>
             </div>
      </div>
      <div class="new-post-card-preheader new-post-card-font-2 new-post-card-color-1" (click)="goToPostDetail(a)" *ngIf="post.preheader_like_user && !post.preheader_comment_user">
          <span class="new-post-card-font-1 new-post-card-color-1">{{post?.preheader_like_user}}</span> reacted on this post
      </div>

      <div class="new-post-card-preheader new-post-card-font-2 new-post-card-color-1" (click)="goToPostDetail(a)" *ngIf="post?.preheader_comment_user">
        <span class="new-post-card-font-1 new-post-card-color-1">{{post?.preheader_comment_user}}</span> commented on this post
    </div>

      <div class="new-post-card-margin" *ngIf="post?.preheader_comment_user || post?.preheader_like_user"></div>
    
      <div class="new-post-card-name-div">

        <div class="new-post-card-image-div" [ngStyle]="{'background-image':'url('+post.author.image+')'}" (click)="goToAuthorProfile(post['author'],post['author_type'],post['creator_details'])"></div>
        <div>
            <div class="new-post-card-font-1 new-post-card-color-1 new-post-card-author-name new-text-eclipse-1" (click)="goToAuthorProfile(post['author'],post['author_type'],post['creator_details'])">{{post['author']['name']}}</div>
            <div class="d-flex align-center new-post-card-timestamp">
              <div class="new-post-card-font-2 new-post-card-color-1">{{post['created_date']}}</div>
              <div class="new-post-card-circle" *ngIf="!post?.author.is_followed"></div>
              <div class="new-post-card-font-1 new-post-card-color-2 cursor" *ngIf="!post?.author.is_followed" (click)="followAuthor(post['author'],loginData,post['author_type'],a)">Follow</div>
             <!-- <div class="new-post-card-font-2 new-post-card-color-1 cursor" *ngIf="post?.author.is_followed">{{post?.author_classification}}</div>-->
            </div>
        </div>
        <div class="new-post-card-more-div">
          <img class="new-post-card-icon" src="./../../../../assets/icons/new-vert-more-black-icon.svg" alt="more-icon">
        </div>
      </div>

       <!--Image Div-->
<div class="pd-image-div" style="max-width: 100%;position: relative" id="videocontainer">
<ngb-carousel [showNavigationArrows]="((post['images'].length+ post['videos'].length)>0 && post.thumbnail) || ((post['images'].length+ post['videos'].length)>1 && !post.thumbnail)" [keyboard]="true" [interval]="0" [showNavigationIndicators]="((post['images'].length+ post['videos'].length)>0 && post.thumbnail) || ((post['images'].length+ post['videos'].length)>1 && !post.thumbnail)" (slide)="slideChange()" [pauseOnFocus]="false" [pauseOnHover]="false" style="max-width: 100%;position: relative;">
  
  <ng-template ngbSlide *ngIf="post['thumbnail']">
    <div class="ap-img-wrapper">
      <img [src]="post.thumbnail" [alt]="((post.context && post.context.alt_tag)?post.context.alt_tag:post?.submitted_headline)" style="width: 100%;position: relative;max-height: 80vh;object-fit: contain;min-height:30vh;" error="this.display='none'">
    </div>
  </ng-template>
  
  <ng-template ngbSlide *ngFor="let image of post['images']">
    <div class="ap-img-wrapper">
      <img [src]="image" [alt]="((post.context && post.context.alt_tag)?post.context.alt_tag:post?.submitted_headline)" style="width: 100%;position: relative;max-height: 80vh;object-fit: contain;min-height:30vh;" error="this.display='none'">
    </div>
  </ng-template>

  <ng-template ngbSlide *ngFor="let video of post['videos'];index as v">
    <div class="ap-img-wrapper" (click)="openVideoModal(videoContent,a,v)">
     <!-- <div class="video-btn-div">
        <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
       </div>
      <img [src]="video['thumbnail']" alt="" style="width: 100%;position: relative;min-height:40vh;background-color: #A7AEB4;max-height: 80vh;object-fit: cover;" error="this.display='none'">-->
      <ng-container *ngIf="video.src.indexOf('youtube')==-1 && !video.youtube_id">
      <video width="100%" height="auto" preload="metadata" [poster]="video?.thumbnail" webkit-playsinline="webkit-playsinline" controls controlslist="nodownload" [id]="a+'-postslist-'+v" (play)="playingVideo(a+'-postslist-'+v)" *ngIf="video?.player!=='vimeo'">
         <source [src]="video.src">
      </video>

     <div *ngIf="video?.player=='vimeo'">
          <app-post-video-player [url]="video.vimeo_url" [id]="a+1"></app-post-video-player>
     </div>
     </ng-container>

     <ng-container *ngIf="video.src.indexOf('youtube')!==-1 || video.youtube_id">
      <youtube-player [width]="youtubewidth" [height]="(youtubewidth*9)/16" [videoId]="video.youtube_id"></youtube-player>
    </ng-container>

    </div>
  </ng-template>

</ngb-carousel>
  </div> 
    <!--End Image Div-->

 <!-- <div class="new-post-card-views-div" *ngIf="(post?.videos && post?.videos.length>0) || (post?.images && post?.images.length>0)">
      <div class="d-flex align-center" style="gap: 3px;" *ngIf="post?.likes && post.likes>0">
        <div class="new-post-reaction-series-div">
         <div class="cursor" style="position: relative;" (click)="openLikesModal(likesContent,post['post_id'])">
          <img class="new-post-card-icon overlap-icon" src="./../../../../assets/icons/new-like-reaction-icon.svg">
          <img class="new-post-card-icon-3 overlap-icon" *ngIf="post?.likes>1" src="./../../../../assets/icons/new-love-reaction-icon.svg">
         </div>
        </div>
        <div class="new-post-card-font-2 new-post-card-color-1 cursor" (click)="openLikesModal(likesContent,post['post_id'])">{{post?.likes}}</div>
      </div>
      <div class="new-post-card-font-2 new-post-card-color-1">{{right_display}}</div>
  </div>-->

  <div class="new-post-card-title-div" (click)="goToPostDetail(a)">
    <h2 class="new-post-card-font-4 new-post-card-color-1 new-headline-font">{{post['headline']}}</h2>
    <!--    <div class="pd-story pd-font-6" [innerHtml]="post['story']"></div> -->
        <div class="new-post-card-font-2 new-post-card-color-1 new-description-font" [ngClass]="post['read_more']?'pd-story':'pd-story-max'" [innerHtml]="post['story']" [id]="a+'-pd'"></div>
        <div class="new-post-card-font-2 new-post-card-color-1 pd-read-more" *ngIf="post['read_more']"><span class="text-blue text-underline cursor">Read More</span></div>
  </div>

  <div class="new-post-card-views-div">
    
    <div class="d-flex align-center" style="gap: 3px;" *ngIf="post?.likes && post.likes>0">
      <div class="new-post-reaction-series-div" *ngIf="post?.list_emotions.length>0">
       <div style="position: relative;" (click)="openLikesModal(likesContent,post['post_id'],a)">
        <img *ngFor="let em of post?.list_emotions;index as z" class="new-post-card-icon overlap-icon" [ngClass]="z==0?'new-post-card-icon':'new-post-card-icon-3'" [src]="reactions[em]" [alt]="reactions[em]">
       </div>
      </div>

      <div class="new-post-reaction-series-div" *ngIf="post?.list_emotions.length<1">
        <div style="position: relative;" (click)="openLikesModal(likesContent,post['post_id'],a)">
         <img class="new-post-card-icon overlap-icon" alt="like-icon" src="./../../../../assets/icons/new-like-reaction-icon.svg">
         <img class="new-post-card-icon-3 overlap-icon" alt="love-icon" src="./../../../../assets/icons/new-love-reaction-icon.svg" *ngIf="post?.likes>1">
        </div>
       </div>
      <div class="new-post-card-font-2 new-post-card-color-1 cursor" (click)="openLikesModal(likesContent,post['post_id'],a)">{{post?.likes}}</div>
    </div>
    <div *ngIf="!post.likes || post.likes==0">{{left_display}}</div>
    <div class="new-post-card-font-2 new-post-card-color-1">{{post?.right_display}}</div>
</div>

  <div class="new-post-card-margin-2"></div>
  
  <div class="new-post-card-reaction-div">

    <div class="reactions-popup-div" *ngIf="post?.click_reactions">
      <app-like-reactions (liked)="likePost(loginData,post?.post_id, a, $event);post.click_reactions=false;"></app-like-reactions>
    </div>

     <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor" (click)="!post.is_liked?[openReactions(a),$event.stopPropagation()]:unlikePost(post?.post_id, a)">
      <img class="new-post-card-icon cursor" src="./../../../../assets/icons/new-like-unfilled.svg" *ngIf="!post?.is_liked">
      <img class="new-post-card-icon cursor" src="./../../../../assets/icons/new-like-icon.svg" *ngIf="post.is_liked">Like</div>
     <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor" (click)="commentOnPost(post.post_id,a,loginData)"><img class="new-post-card-icon" src="./../../../../assets/icons/new-comment-icon.svg" alt="comment-icon">Comment</div>
     <div class="new-post-card-font-3 new-post-card-color-1 icon-flex cursor" (click)="sharePost(post?.post_id,a, shareData)"><img class="new-post-card-icon cursor" src="./../../../../assets/icons/new-share-icon.svg" alt="share-icon">Share</div>
  </div>

<ng-container *ngIf="post?.can_comment">
  <div class="new-post-card-margin-2"></div>

  <div class="new-post-card-comment-input-div">
    <div class="new-post-card-image-div" [ngStyle]="{'background-image':'url('+cur_user.avatar+')'}"></div>
    <div class="new-post-card-input-parent">
       <input class="new-post-card-input" #cmtinput [(ngModel)]="cmttext">
       <div class="new-post-card-send-button-div cursor" (click)="postComment(post.post_id,a,cmttext);cmttext=''">
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.4 20.3995L20.85 12.9195C21.66 12.5695 21.66 11.4295 20.85 11.0795L3.4 3.59953C2.74 3.30953 2.01 3.79953 2.01 4.50953L2 9.11953C2 9.61953 2.37 10.0495 2.87 10.1095L17 11.9995L2.87 13.8795C2.37 13.9495 2 14.3795 2 14.8795L2.01 19.4895C2.01 20.1995 2.74 20.6895 3.4 20.3995Z" [style.fill]="(cmttext && cmttext.trim()!=='')?'#3E7EFF':'#BBBBBB'"/>
             </svg>
       </div>
    </div>
  </div>
</ng-container>


  <div class="new-post-card-comment-div">
    <!--  <div class="new-post-card-font-4 new-post-card-font-color-1 mobile-font-1" *ngIf="post?.all_comments && post.all_comments.length>0">{{post?.all_comments.length}} {{post?.all_comments.length>1?'Comments':'comment'}}</div> -->
      
      <div *ngIf="post?.all_comments">
        <div class="new-post-card-comment" *ngFor="let c of post?.all_comments">
         
          <div class="new-post-card-image-div" [ngStyle]="{'background-image':'url('+c.avatar+')'}"></div>
          <div class="new-post-card-comment-text-div">
            <div class="new-post-delete-comment-icon" *ngIf="c?.is_self">
              <img class="new-post-card-icon-2 cursor" alt="delete-icon" src="./../../../../assets/icons/new-delete-icon.svg">
           </div>
            <div class="icon-flex new-post-card-comment-name">
              <div class="new-post-card-font-5 new-post-card-color-1 new-text-eclipse-1">{{c?.username}}</div>
              <div class="new-post-card-font-4 new-post-card-color-1 mobile-font-1 new-text-eclipse-2">{{c?.time_lapsed}}</div>
            </div>

            <div class="new-post-card-font-2 new-post-card-color-1 mobile-font-1">{{c?.comment}}</div>
          </div>
        </div>
      </div>
  </div>

  </div>
  <!--New post card end--> 
    <!--post card end-->

 <div *ngIf="type && type=='feed_with_ads'">
    <div class="np-g-a-div-3" *ngIf="a==1">
      <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [adSlot]="3663804145"
        [layoutKey]="'-6t+ed+2i-1n-4w'"
        [adFormat]="'fluid'"
        [className]="'np-n-border'"
      ></ng-adsense>
    </div>

    <div class="np-g-a-div-3" *ngIf="a==3">
      <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [layoutKey]="'-6t+ed+2i-1n-4w'"
        [adSlot]="2430613520"
        [adFormat]="'fluid'"
        [className]="'np-n-border'"
      ></ng-adsense>
    </div>

    <div class="np-g-a-div-3" *ngIf="a==5">
      <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [layoutKey]="'-6t+ed+2i-1n-4w'"
        [adSlot]="4973016389"
        [adFormat]="'fluid'"
        [className]="'np-n-border'"
      ></ng-adsense>
    </div>

    <div class="np-g-a-div-3" *ngIf="a==7">
      <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [layoutKey]="'-6t+ed+2i-1n-4w'"
        [adSlot]="1963709668"
        [adFormat]="'fluid'"
        [className]="'np-n-border'"
      ></ng-adsense>
    </div>

    <div class="np-g-a-div-3" *ngIf="a==9">
      <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [layoutKey]="'-6t+ed+2i-1n-4w'"
        [adSlot]="7715649668"
        [adFormat]="'fluid'"
        [className]="'np-n-border'"
      ></ng-adsense>
    </div>
    </div>


    <!--Ads for author profile start-->

    <div *ngIf="type && type=='author_feed'">
      
      <div class="np-g-a-div-3" *ngIf="a==0">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="8680460556"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==2">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="9181301232"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>


      <div class="np-g-a-div-3" *ngIf="a==5">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="5096438765"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==8">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="7092046716"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==11">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="6384334231"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==14">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="8951923294"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==17">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="6341485097"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==20">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="3523750066"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==23">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="3140606686"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>

      <div class="np-g-a-div-3" *ngIf="a==26">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="4688109182"
          [layoutKey]="'-6r+di+5g-2m-8y'"
          [adFormat]="'fluid'"
          [className]="'np-n-border'"
        ></ng-adsense>
      </div>



  </div>
         <!--Ads for author profile end-->

    
</div>
</div>

<!--likes list div-->
<ng-template #likesContent let-modal>
<app-followers-list (close_modal)="closeModal()" [id]="cur_post_id"></app-followers-list>
</ng-template>
 <!--End likes list div-->

    <!--Login modal start-->
  <ng-template #loginData let-modal>
    <div class="login-modal">
      <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
      <app-login (login_successfull)="modal.close();login_successfull();" [is_modal]="true"></app-login>
    </div>
  </ng-template>
<!--End login modal-->

<!--Share Component-->
<ng-template #shareData let-modal>
<div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
<app-share [url]="share_post_url" (share_success)="postShared($event)"></app-share>
</ng-template>
<!--zEnd Share Component-->
        
 <!--Vimeo Player-->
 <ng-template #videoContent let-modal>
<div class="d-flex flex-end" style="padding: 1em;justify-content: flex-end;">
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-video-player [url]="cur_url" [type]="cur_video_type"></app-video-player>
</div>
</ng-template>



</div>

