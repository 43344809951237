import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { ActivatedRoute, Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FoodService } from 'src/app/core/services/food/food.service';
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit,OnDestroy {

  order:any;
  id:any;
  shipping_charge=0;
  address:any;
  from='';
  foodcart=[];
  food_order_status:any;

  constructor(public commonService:CommonService,public foodService:FoodService,public ngbModal:NgbModal, public route:ActivatedRoute, public router:Router) { }

  ngOnInit(): void {

    if(this.ngbModal.hasOpenModals()){
         this.ngbModal.dismissAll();
    }
    this.route.queryParams.subscribe((data:any)=>{
      if(data['from']){
        this.from= data['from'];
      }
    })
    this.route.params.subscribe((data:any) => {
      if (data['id']) {
         this.id = data['id'];
     //    this.id= 78239
         this.orderDetail(this.id)
      }
   })
   let cart=this.commonService.getLocalStorage('newcart');
  }

  goToCart(){
      this.router.navigate(['/user/orders']);
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  openSupportModal(data:any){
    this.ngbModal.open(data,{size:'md',centered: true,backdrop:'static'});
   }

   getTax(tax:any){
       return parseInt(tax)<parseFloat(tax)?parseInt(tax)+1: parseInt(tax)
   }

  getTotal(order:any){
    let total=0;
    if(order){
    if(parseInt(order['product'])){
      total= total+order['product'];
    }
    if(parseFloat(order['tax'])){
      total= total+order['tax'];
    }
    if(parseInt(order['shipping'])){
      total= total+order['shipping'];
    }
    if(order['fees']){
      total= total+order['fees'];
    }
    if(order['discount']){
      total= total-order['discount'];
    }
  }
    return parseInt(`${total}`)<parseFloat(`${total}`)?parseInt(`${total}`)+1: parseInt(`${total}`)

  }

  get_display_date = (date:any) => {
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    return `${da} ${mo} ${ye}`;
  }

  orderDetail(id:number){
     this.commonService.orderDetails(id).subscribe((data:any)=>{
         if(data['StatusCode']==200){
          this.order = data['Result']['order'];
          this.shipping_charge= this.order['delivery']['cost']?this.order['delivery']['cost']:0;
          this.address= this.order['address'];
          if(this.order.type=='Menu Order'){
            let date_format = this.get_display_date(new Date(`${this.order.month}-${this.order.date}-${this.order.year}`)).split(' ').join('');
            this.firebaseOrderDetail(this.id, date_format);
          }
        //  this.getTotal(this.order['line_items']);
         }
     }) 
  }

  firebase_order:any;
  order_subscribe:any;
   firebaseOrderDetail(id:any,date:any){
     /*    this.order_subscribe=this.foodService.getFirebaseOrderDetail(date, id).subscribe((data:any)=>{
            if(data && data.length>0){
            this.firebase_order= data[0];
            if(this.firebase_order['status']){
                this.food_order_status= this.firebase_order['status']
            }else{
               if(this.firebase_order['order_status']=='placed'){
                 this.food_order_status= 'Order Accepted';
               }else if(this.firebase_order['order_status']=='preparing'){
                 this.food_order_status= 'Food Preparing';
               }else if(this.firebase_order['order_status']=='ready'){
                 this.food_order_status= 'Food Prepared';
               }else if(this.firebase_order['order_status']=='picked'){
                 this.food_order_status= 'Picked From Restaurant';
               }else if(this.firebase_order['order_status']=='delivered'){
                 this.food_order_status= 'Delivered';
               }
            }
          }
        }) */
   }
 
   ngOnDestroy(){
       if(this.order_subscribe){
      this.order_subscribe.unsubscribe();
      }
   }

}
