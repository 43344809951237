<div class="login-wrapper" id="loginTop">
<div class="login-main-div" id="modallogin">

 <!--3km top logo-->  
 <div class="mobile-only" id="nomodal1">
  <div class="login-3km-logo-div">
    <img class="login-3km-logo-image" src="./../../../assets/5km-logo/without bg connecting_neighbourhoods.png">
  </div>
 <!-- <p class="login-text text-center">Connecting Neighbourhoods</p> -->
</div> 
   <!--3km top logo end-->

   <!--Desktop Only card--> 
<div class="desktop-card" id="nomodal2" style="position: relative;min-height: 95vh">
  <div>
   <div class="login-3km-logo-div">
    <img class="login-3km-logo-image" src="./../../../assets/5km-logo/without bg connecting_neighbourhoods.png">
  </div>
 <!--<div class="login-text text-center">Connecting Neighbourhoods</div> --> 

  <div class="get-app-div">
    <!-- <div class="login-text-4 text-center">Get the app</div>

    <div class="app-play-store-div">
         <div class="google-play-div cursor">
              <img class="google-play-logo" src="./../../../assets/icons/googleplay-large.png">
         </div>

        <div class="app-store-div cursor">
          <img class="app-store-logo" src="./../../../assets/icons/appstore-large.png">
        </div>
    </div> -->
 </div>

  <div class="sakal-bak-div">
         <img class="sakal-bak-logo" src="./../../../assets/logos/logo.png">
  </div>
</div>

</div>
   <!--Desktop Only card end--> 

   
   <!--Login mobile card-->
<div class="login-mobile-card-outer-div" style="position: relative;">
<div class="login-mobile-card" id="modalloginmobilecard">
  <div *ngIf="current_login_state=='phone_no'">

  <div>
    <p class="login-text-2 text-center">Login to Continue</p>
  </div> 

<!--Social login options div-->


  <div class="social-login-main-div mt-px-40" style="position: relative;">

    <div class="social-login-main-div-cover" *ngIf="!enableButtons">
    </div>

    <div class="facebook-login-div cursor" (click)="loginWithFacebook()">
        <div class="login-fb-image-div" (click)="loginWithFacebook()"><img class="login-fb-image" src="./../../../assets/icons/facebook-icon.svg"></div>
        <div class="d-flex align-center login-text-3 login-fb-text justify-center" (click)="loginWithFacebook()">Login with Facebook</div>
    </div>

    <div class="facebook-login-div google-login-div cursor" (click)="loginWithGoogle()">
        <div class="login-fb-image-div" (click)="loginWithGoogle()"><img class="login-fb-image" src="./../../../assets/icons/google-icon.svg"></div>
        <div class="d-flex align-center login-text-3 login-google-text justify-center" (click)="loginWithGoogle()">Login with Google</div>
    </div>
  </div> 
<!--Social login options div end-->

 <div class="login-divider"></div>

  <p class="login-text-4 text-center" style="padding-top:60px">Login with OTP/Signup</p> 

  <div class="login-input-div">
    <div style="position: relative;" [formGroup]="loginForm">
     <div class="ph-code">+91</div> <input type="number" class="login-phno-input" formControlName="phone_no" name="ph" id="ph" placeholder="Enter phone number">
    </div>

 <!--  <div style="position: relative;" [formGroup]="otpForm" *ngIf="current_login_state=='otp'">
         <input class="login-otp-input" formControlName="otp" name="otp" id="otp">
     </div> --> 

  <!--  <div [formGroup]="passwordLoginForm" *ngIf="current_login_state=='password_login'">
     <div style="position: relative;" > <div class="ph-code">+91</div> <input class="login-phno-input" formControlName="phone_no" name="ph" id="ph"></div>
      <div class="margin-top-10" *ngIf="passwordloginform.phone_no.errors && passwordloginform.phone_no?.dirty">
        <p class="text-danger" *ngIf="passwordloginform.phone_no.errors.required">Enter phone number</p>
        <p class="text-danger" *ngIf="!passwordloginform.phone_no.errors.required">Enter valid phone number</p>
      </div>
  
      <div style="margin-top: 20px;"><input class="login-otp-input" placeholder="Enter password" formControlName="password" name="password" id="password" type="password"></div>
      <div class="margin-top-10" *ngIf="passwordloginform.password.errors && passwordloginform.password?.dirty">
        <p class="text-danger" *ngIf="passwordloginform.password.errors.required">Enter password</p>
        <p class="text-danger" *ngIf="!passwordloginform.password.errors.required">Enter valid password</p>
      </div>

        <div class="margin-top-10" *ngIf="otpform.otp.errors && otpform.otp?.dirty">
        <p class="text-danger" *ngIf="otpform.otp.errors.required">Enter Otp</p>
        <p class="text-danger" *ngIf="!otpform.otp.errors.required">Invalid Otp</p>
    </div>

     </div> --> 

     <div class="margin-top-10" *ngIf="form.phone_no.errors && form.phone_no?.dirty">
      <p class="text-danger" *ngIf="form.phone_no.errors.required">Enter phone number</p>
      <p class="text-danger" *ngIf="!form.phone_no.errors.required">Enter valid phone number</p>
    </div>

  

  

      <div class="login-btn-div">
        <button class="login-btn login-text-5" [disabled]="loginForm.invalid || !did_check || loading" (click)="sendLoginOtp();did_check=false;">Send Otp</button>
      </div>
  </div>

    <p class="login-text-6 text-center cursor" (click)="goToLoginViaPassword()">Login via Password</p>

</div>

<!--Enter OTP section-->
   <div class="login-otp-div" *ngIf="current_login_state=='otp'">
      <div class="login-text-8">
         A 4-digit OTP has been sent to {{form.phone_no.value}} via SMS. <span class="text-login-blue text-underline cursor" style="white-space: nowrap;" (click)="changePhoneNumber()">Change Phone Number</span>
      </div>

      <div class="login-otp-text-div" style="position: relative;">

        <div class="otp-phone-no">
          <!-- <div [ngClass]="otp[0]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[0]?otp[0]:'x'}}</div>
           <div [ngClass]="otp[1]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[1]?otp[1]:'x'}}</div>
           <div [ngClass]="otp[2]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[2]?otp[2]:'x'}}</div>
           <div [ngClass]="otp[3]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[3]?otp[3]:'x'}}</div>  -->

           <div><input placeholder="x" [ngClass]="otp1.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp1 id="otp-1" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(1,$event)" (focus)="otpInputFocus(1)"></div>
           <div><input placeholder="x" [ngClass]="otp2.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp2 id="otp-2" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(2,$event)" (focus)="otpInputFocus(2)"></div>
           <div><input placeholder="x" [ngClass]="otp3.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp3 id="otp-3" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(3,$event)" (focus)="otpInputFocus(3)"></div>
           <div><input placeholder="x" [ngClass]="otp4.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp4  id="otp-4" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(4,$event)" (focus)="otpInputFocus(4)"></div>

          </div>
        <p class="wrong-otp-text text-center" *ngIf="incorrect_otp">Incorrect OTP</p>
      </div>

      <div class="login-btn-div">
        <button class="otp-btn login-text-9 text-login-blue" [disabled]="!otp1.value || !otp2.value || !otp3.value || !otp4.value" (click)="verifyLoginOtp(otp1.value,otp2.value,otp3.value,otp4.value)">Submit OTP</button>
      </div>
      <p class="otp-text text-center cursor" (click)="(remaining_min<0)?resendConfirmationCode():''">Resend Confirmation Code <span class="otp-timer" *ngIf="remaining_min>=0">(0{{remaining_min}}:{{remaining_sec<10?"0"+remaining_sec:remaining_sec}})</span></p>
   </div>

   <!--Enter OTP section ends-->

   <!--Login via password section-->
   <div class="login-password-div" style="position: relative;" *ngIf="current_login_state=='password_login'">

    <div>
      <p class="login-text-2 text-center">Login Via Password</p>
    </div> 

    <div class="login-password-form-div" [formGroup]="passwordLoginForm">

      <p *ngIf="invalid_message" id="inv-msg" class="text-danger-2 text-center" style="text-transform: capitalize;color:red">Invalid Phone number/Password</p>

   <div>
      <label class="login-text-12">Phone Number</label>
    <div style="position: relative;">
      <div class="ph-code">+91</div> <input type="number" class="login-phno-input-2" formControlName="phone_no" name="ph" id="pwph" placeholder="Enter phone number">
    </div>

    <div class="margin-top-10" *ngIf="passwordloginform.phone_no.errors && passwordloginform.phone_no?.dirty">
      <p class="text-danger" *ngIf="passwordloginform.phone_no.errors.required">Enter phone number</p>
      <p class="text-danger" *ngIf="!passwordloginform.phone_no.errors.required  && passwordloginform.phone_no.errors.numberstringvalidator">Enter valid phone number</p>
    </div>

  </div>


  <div class="login-password-input-div" style="position: relative;">
   <label class="login-text-12">Password</label>
  <div style="position: relative;">
    <div class="pwd-eye cursor" (click)="changeInputType()" *ngIf="passwordloginform.password.value.length>0"><i class="text-blue" [ngClass]="input_type=='text'?'fa fa-eye-slash':'fa fa-eye'"></i></div> <input class="login-phno-input-3" formControlName="password" name="pw" id="pw" [type]="input_type" placeholder="Enter password">
  </div>
  <p class="login-text-7 cursor" style="font-size: 12px;text-align: right;" (click)="forgotPassword()">Forgot Password?</p>

  <div class="margin-top-10" *ngIf="passwordloginform.password.errors && passwordloginform.password?.dirty">
    <p class="text-danger" *ngIf="passwordloginform.password.errors.required">Enter password</p>
    <p class="text-danger" *ngIf="!passwordloginform.password.errors.required">Enter valid password</p>
  </div>
  
</div>

<div class="login-btn-div">
  <button class="login-text-5 login-password-btn" [disabled]="passwordLoginForm.invalid || !is_pwd_valid_number" (click)="loginViaPassword()">Login</button>
</div>

  </div>

  <div class="login-pwd-divider-div">
    <div class="login-pwd-divider" style="flex: 1;"></div>
    <div class="login-text-4 text-center" style="width: 40px">Or</div>
    <div class="login-pwd-divider" style="flex: 1;"></div>
  </div>

  <div class="password-otp-btn">
    <button *ngIf="start_with!=='password'" class="login-btn login-text-5"  (click)="goToLoginOtp()">Send Otp</button>
    <div *ngIf="start_with=='password'">
    <button class="login-btn login-text-5"  (click)="goToLoginOtp()">Sign Up</button>
  </div>
  </div>

   </div>
    <!--Login via password section ends-->

    <!-- Reset password step 1-->
    <div class="reset-password-div-1" *ngIf="current_login_state=='reset_password_1'">
      <div>
        <p class="login-text-2 text-center" style="text-transform: uppercase;">Reset Your Password</p>
      </div> 
  
      <div class="login-text-11 step-1-div text-center">Step 1: Enter Your Phone Number</div>

      <div class="login-password-form-div" [formGroup]="resetPasswordForm">
        <div>
           <label class="login-text-4">Phone Number</label>
         <div style="position: relative;">
           <div class="ph-code">+91</div> <input class="login-phno-input-2" formControlName="phone_no" name="ph" id="ph" placeholder="Enter phone number">
         </div>
     
         <div class="margin-top-10" *ngIf="resetpasswordform.phone_no.errors && resetpasswordform.phone_no?.dirty">
           <p class="text-danger" *ngIf="resetpasswordform.phone_no.errors.required">Enter phone number</p>
           <p class="text-danger" *ngIf="!resetpasswordform.phone_no.errors.required">Enter valid phone number</p>
         </div>
     
       </div>

       <div class="login-forg-btn-div">
        <button class="login-text-5 login-password-btn" [disabled]="resetPasswordForm.invalid || !is_forgot_pwd_valid_number || loading" (click)="sendForgotPasswordOtp()">Send OTP</button>
      </div>

      
    </div>
    <div class="login-divider-2"></div>

    <div class="login-text-11 text-center cursor" (click)="goBackToLogin()"><img class="left-arrow" src="./../../../assets/icons/left-arrow.svg">Go Back To Login</div>


</div>
    <!-- Reset password step 1 ends-->


    <div class="reset-password-div-1" *ngIf="current_login_state=='reset_password_2'">
      <div>
        <p class="login-text-2 text-center" style="text-transform:uppercase;">Reset Your Password</p>
      </div>

      <div class="reset-password-2-form">
      <p class="login-text-11 step-1-div text-center">Step 2: A 4-digit OTP has been sent to {{resetpasswordform.phone_no.value}} via SMS. <span class="text-blue text-underline cursor" style="white-space: nowrap;" (click)="changeResetPasswordPhoneNumber()">Change Phone Number</span> </p>

      <div class="login-otp-text-div-3" style="position: relative;">
        <div class="otp-phone-no">
          <!-- <div [ngClass]="otp[0]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[0]?otp[0]:'x'}}</div>
           <div [ngClass]="otp[1]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[1]?otp[1]:'x'}}</div>
           <div [ngClass]="otp[2]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[2]?otp[2]:'x'}}</div>
           <div [ngClass]="otp[3]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[3]?otp[3]:'x'}}</div>  -->

           <div><input placeholder="x" [ngClass]="otp1.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" #otp1 id="otp-1" maxlength="1" (keyup)="otpEntered(1,$event)" (focus)="otpInputFocus(1)"></div>
           <div><input placeholder="x" [ngClass]="otp2.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" #otp2 id="otp-2" maxlength="1" (keyup)="otpEntered(2,$event)" (focus)="otpInputFocus(2)"></div>
           <div><input placeholder="x" [ngClass]="otp3.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" #otp3 id="otp-3" maxlength="1" min="0" max="9" (keyup)="otpEntered(3,$event)" (focus)="otpInputFocus(3)"></div>
           <div><input placeholder="x" [ngClass]="otp4.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'"  oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" type="number" #otp4  id="otp-4" maxlength="1" min="0" max="9" (keyup)="otpEntered(4,$event)" (focus)="otpInputFocus(4)"></div>

          </div>
         <p class="wrong-otp-text text-center" *ngIf="incorrect_otp">Incorrect OTP</p>
      </div>

      <div class="login-btn-div">
        <button class="otp-btn login-text-9 text-login-blue" [disabled]="!otp1.value || !otp2.value || !otp3.value || !otp4.value" (click)="verifyResetPasswordOtp(otp1.value,otp2.value,otp3.value,otp4.value)">Submit OTP</button>
      </div>
      <p class="otp-text text-center cursor" (click)="(remaining_min<0)?resendForgotPasswordOtp():''">Resend Confirmation Code <span class="otp-timer" *ngIf="remaining_min>=0">(0{{remaining_min}}:{{remaining_sec<10?"0"+remaining_sec:remaining_sec}})</span></p>
    </div>
      <div class="login-divider-2"></div>

      <div class="login-text-11 text-center cursor" (click)="goBackToLogin()"><img class="left-arrow" src="./../../../assets/icons/left-arrow.svg">Go Back To Login</div>
  
  
    </div>
<!--Reset password 2 ends-->

<!--Reset password step 3 start-->
<div class="reset-password-div-1" *ngIf="current_login_state=='reset_password_3'">
  <div>
    <p class="login-text-2 text-center" style="text-transform: uppercase;">Reset Your Password</p>
  </div>


  <div class="reset-password-2-form" [formGroup]="changePasswordForm">

    <p class="login-text-11 step-2-div text-center">Step 3: Reset Your Password</p>

  <div class="login-password-input-div" style="position: relative;">
   <label class="login-text-12">Create New Password</label>
   <div style="position: relative;">
     <div class="pwd-eye cursor" (click)="changeResetPasswordInputType1()" *ngIf="changepasswordform.password.value.length>0"><i class="text-blue" [ngClass]="change_input_type_1=='text'?'fa fa-eye-slash':'fa fa-eye'"></i></div> <input class="login-phno-input-3" formControlName="password" name="pw" id="pw" [type]="change_input_type_1" placeholder="Enter new password">
   </div>
 
   <div class="margin-top-10" *ngIf="changepasswordform.password.errors && changepasswordform.password?.dirty">
     <p class="text-danger" *ngIf="changepasswordform.password.errors.required">Enter password</p>
     <p class="text-danger" *ngIf="changepasswordform.password.errors.minlength">Enter valid password</p>
   </div>
   
 </div>

 <div class="login-password-input-div-3" style="position: relative;">
  <label class="login-text-12">Re-enter New Password</label>
  <div style="position: relative;">
    <div class="pwd-eye cursor" (click)="changeResetPasswordInputType2()" *ngIf="changepasswordform.confirm_password.value.length>0"><i class="text-blue" [ngClass]="change_input_type_2=='text'?'fa fa-eye-slash':'fa fa-eye'"></i></div> <input class="login-phno-input-3" formControlName="confirm_password" [type]="change_input_type_2" placeholder="Enter password">
  </div>
  <p class="text-danger" *ngIf="changepasswordform.confirm_password.dirty && changePasswordForm.errors?.['passwordMatch']">Password doesn't match</p>
 <div class="margin-top-10" *ngIf="changepasswordform.confirm_password.errors && changepasswordform.confirm_password?.dirty">
  <p class="text-danger" *ngIf="!changepasswordform.confirm_password.errors.required && changePasswordForm.errors?.['passwordMatch']">Enter password</p>
 </div>
 
</div>
 
 <div class="login-btn-div">
   <button class="login-text-5 login-password-btn" [disabled]="changePasswordForm.invalid" (click)="changePassword()">Save and Login</button>
 </div>
</div>

<div class="login-divider-2"></div>

<div class="login-text-11 text-center cursor" (click)="goBackToLogin()"><img class="left-arrow" src="./../../../assets/icons/left-arrow.svg">Go Back To Login</div>


</div>

<!--Reset password step 3 start ends-->


<!--Profile info div-->

<!--Reset password step 3 start-->
<div class="reset-password-div-1" *ngIf="current_login_state=='signup_state'">
  <div>
    <p class="login-text-2 text-center title-2" style="text-transform: uppercase;">finish Your Profile</p>
  </div>


  <div class="reset-password-2-form" [formGroup]="signupForm">

  <div class="login-password-input-div-2" style="position: relative;">
   <label class="login-text-12">First Name</label>
   <div style="position: relative;">
      <input class="login-phno-input-3" formControlName="firstname" placeholder="First name" type="text">
   </div>
 
   <div class="margin-top-10" *ngIf="signupform.firstname.errors && signupform.firstname?.dirty">
     <p class="text-danger" *ngIf="signupform.firstname.errors.required">Enter Firstname</p>
     <p class="text-danger" *ngIf="!signupform.firstname.errors.required && signupform.firstname.errors.alphanumericValidator">Invalid Format</p>
   </div>
   
 </div>


 <div class="login-password-input-div-2" style="position: relative;">
  <label class="login-text-12">Last Name</label>
  <div style="position: relative;">
    <input class="login-phno-input-3" formControlName="lastname" placeholder="Last name" type="text">
  </div>

  <div class="margin-top-10" *ngIf="signupform.lastname.errors && signupform.lastname?.dirty">
    <p class="text-danger" *ngIf="signupform.lastname.errors.required">Enter Lastname</p>
    <p class="text-danger" *ngIf="!signupform.lastname.errors.required && signupform.lastname.errors.alphanumericValidator">Invalid Format</p>
  </div>
</div>

 <div class="login-password-input-div-2" style="position: relative;">
  <label class="login-text-12">Create Password</label>
  <div style="position: relative;">
    <div class="pwd-eye cursor" (click)="changeResetPasswordInputType3()" *ngIf="signupform.password.value.length>0"><i class="text-blue" [ngClass]="change_input_type_3=='text'?'fa fa-eye-slash':'fa fa-eye'"></i></div> <input class="login-phno-input-3" formControlName="password" [type]="change_input_type_3" placeholder="Create password">
  </div>

 <div class="margin-top-10" *ngIf="signupform.password.errors && signupform.password?.dirty">
   <p class="text-danger" *ngIf="signupform.password.errors.required">Enter password</p>
   <p class="text-danger" *ngIf="signupform.password.errors.minlength">Password length should be 6 characters at least.</p>
 </div>
 
</div>


<div class="login-password-input-div-2" style="position: relative;">
  <label class="login-text-12">Re-Enter Password</label>
  <div style="position: relative;">
    <div class="pwd-eye cursor" (click)="changeResetPasswordInputType4()" *ngIf="signupform.confirm_password.value.length>0"><i class="text-blue" [ngClass]="change_input_type_4=='text'?'fa fa-eye-slash':'fa fa-eye'"></i></div> <input class="login-phno-input-3" formControlName="confirm_password" [type]="change_input_type_4" placeholder="Confirm password">
  </div>

  <p class="text-danger" *ngIf="signupform.confirm_password.dirty && signupForm.errors?.['passwordMatch']">Password doesn't match</p>

 <div class="margin-top-10" *ngIf="signupform.confirm_password.errors && signupform.confirm_password?.dirty">
   <p class="text-danger" *ngIf="signupform.confirm_password.errors.required">Confirm password</p>
  </div>
 
</div>
 
 <div class="login-btn-div">
   <button class="login-text-5 login-password-btn" [disabled]="signupForm.invalid" (click)="signup()">Save and Continue</button>
 </div>
</div>

</div>

<!--Profile div ends-->

</div>
<!--Login mobile card end-->

<div class="login-desk-footer-div cursor">
    <p class="login-text-7 text-center"><a [routerLink]="['/contact']">Help and Support</a></p>
</div>

</div>

</div>
<div class="login-footer-div mobile-only cursor" id="mobilehelpsupport">
    <p class="login-text-7 text-center"><a [routerLink]="['/contact']">Help and Support</a></p>
</div>
</div>