<div class="cart-outer-div">
    <div class="profile-info-text-2 modal-title d-flex align-center"><img class="back-icon cursor" (click)="goToList()" src="./../../../../assets/icons/back-button.svg">CART SUMMARY</div>
   <ng-container *ngIf="myproducts && myproducts.length>0">
    <div class="cart-products-div">

        <div *ngFor="let product of myproducts; index as i;">

            <div class="d-flex space-between mb-px-20 cart-product-card-outer-div">
                <div class="cart-product-card">
                    <div style="position: relative;">
                        <img class="news-post-red-close-icon cart-product-discard-icon mobile-only" (click)="removeCartItem(i, product.catalog_id)" src="./../../../../assets/icons/red-close-icon.svg">
                        <img [src]="product['image']" class="cart-image"></div>
                    <div>

                        <div class="poppins-medium font-px-18 mb-1 text-eclipse">{{product?.name}}</div>

                        <div class="d-flex column-gap-20 align-center mb-2">
                            <div class="blue font-px-18">
                                <i class="fa fa-rupee blue"></i>{{product?.selected_price}}
                            </div>
                            <div class="font-grey font-px-16 line-through" *ngIf="product.price>product.strike_price">
                                <i class="fa fa-rupee font-grey line-through"></i>{{product?.strike_price}}
                            </div>
                        </div>

                        <!--Count increment/decrement-->
                        <div class="count-controller">
                             <div (click)="decrement(i)" class="cursor"><img src="./../../../../assets/shop/minus-XS-light.svg"></div>
                             <div class="font-px-18">{{product?.quantity}}</div>
                             <div (click)="increment(i)" class="cursor"><img src="./../../../../assets/shop/plus-XS-light.svg"></div>
                        </div>
                        <!--End count increment/decrement-->

                    </div>
                </div>

                <div class="no-mobile">
                    <img (click)="removeCartItem(i, product.catalog_id)" class="news-post-red-close-icon" src="./../../../../assets/icons/red-close-icon.svg">
                </div>
            </div>
            <div class="divider-line-2" *ngIf="i<myproducts.length-1"></div>
        </div>
    </div>

      <div class="mt-px-40">
          <button class="btn-blue-1 poppins-light" (click)="goToCheckout(loginData)" style="padding: 10px 20px;border-radius: 10px;"><img src="./../../../../assets/shop/shopping_cart.svg" class="mr-px-10">Proceed To Checkout</button>
      </div>

    </ng-container>

    <ng-container *ngIf="!myproducts || myproducts.length==0">
      
    <div class="cart-empty-div">
        <div class="profile-info-text-2 modal-title text-center">NO ITEMS AVAILABLE</div>
    </div>
    </ng-container>

    <!--Login modal start-->
    <ng-template #loginData let-modal>
        <div class="login-modal">
          <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
          <app-login (login_successfull)="modal.close();login_successfull();" [is_modal]="true"></app-login>
        </div>
      </ng-template>
   <!--End login modal-->

</div>