
<div class="food-checkout-main-div my-orders-main-div" id="myordersmain">
  
  <div class="back-btn-main-div category-back-title mobile-only mb-px-20 d-flex" style="position: sticky;z-index: 10;justify-content:flex-start;top:0px;box-shadow: none;margin-top: 10px;">
    <div class="cursor mobile-only" (click)="goToShop()"><img class="back-icon cursor" src="./../../../../assets/icons/back-button.svg"></div>
    <div class="shop-category-title mobile-only">My Orders</div>
  </div>

<div class="my-orders-inner-div">

  <div class="my-orders-top-nav">
    
    <div class="my-orders-nav-options">
       <div class="nav-border-bottom"></div>
      <div class="my-orders-nav cursor text-center my-orders-font-1" [ngClass]="{'active-border-bottom':selected_type=='food'}" (click)="toggleType('food')">Food</div>
      <div class="my-orders-nav cursor text-center my-orders-font-1" [ngClass]="{'active-border-bottom':selected_type=='shop'}" (click)="toggleType('shop')" (click)="toggleType('shop')">Shop</div>
    </div>
  </div>

 <!--Order list-->
<div *ngIf="myorders">
 <div *ngFor="let order of myorders"> 
  <div class="my-orders-order-card" *ngIf="order['order_type']!=='menu' || (order['order_type']=='menu' && order['restro']['status']!=='created')">
     
    <div class="my-orders-order-card-top">
    
      <div class="my-orders-font-2 my-orders-order-card-div-1">
        <div class="my-orders-font-2 flex-1" [ngStyle]="{color: order.center_color}" *ngIf="order['order_type']!=='menu'"> {{order.center_status}}</div>
        <div class="my-orders-font-2 flex-1" *ngIf="order['order_type']=='menu'"> {{order['restro']['status'] | titlecase}}</div>

         <div class="my-orders-font-2 flex-1 my-orders-order-card-div-1-text-right" *ngIf="order.center_status!=='Delivery not booked'">on {{order.center_time | date:'medium'}}</div> 
     </div>   

     <div class="my-orders-font-2 my-orders-order-card-div-1">
      <div class="my-orders-font-2 flex-1">Total</div>

       <div class="my-orders-font-2 flex-1 my-orders-order-card-div-1-text-right"><i class="fa fa-rupee"></i>{{getTotal(order['total'])}}</div> 
   </div>  

   <div class="my-orders-font-2 my-orders-order-card-div-1">
    <div class="my-orders-font-2 flex-1">Restaurant</div>

     <div class="my-orders-font-2 flex-1 my-orders-order-card-div-1-text-right text-blue" style="width: 100%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{order?.soldby}}</div> 
   </div>  

   <div class="my-orders-font-2 my-orders-order-card-div-2 desktop-only">

    <div class="my-orders-font-2 flex-1 text-right desktop-only" style="font-weight: 600;flex:auto;width:100%">#{{order?.project_id}}</div>
  </div> 

    </div>

    <div class="my-orders-order-card-detail">
      <div class="my-orders-font-2 flex-1 text-right mobile-only" style="font-weight: 600;">#{{order?.project_id}}</div>
      
      <div *ngFor="let item of order?.line_items">
        <div class="my-orders-font-2 d-flex flex-1" style="gap:6px">
          <ng-container *ngIf="item?.is_veg">
          <div class="align-self-center" [ngClass]="item?.is_veg?'veg-symbol':'non-veg-symbol'" style="flex-basis: 24px;min-width: 24px;">
            <div class="veg-symbol-circle" [ngClass]="item?.is_veg?'veg-symbol-circle':'non-veg-symbol-circle'" ></div>
          </div>
        </ng-container>
          {{item?.name}}</div>
      </div>

      <div>
        <button class="btn no-btn all-orders-btn my-orders-font-2 text-white" (click)="goToOrderDetail(order?.project_id)">View order details</button>
      </div>
    </div>

  </div>
</div>
</div>
<!--Orders list end-->


<!--Orders skeleton-->
<div *ngIf="!myorders">
 <div class="order-sk-card" *ngFor="let card of skeleton_data">
   <div class="order-sk-card-top" >

   </div>

   <div class="order-sk-card-detail" >
     
  </div>

 </div>
</div>
<!--Orders skeleton end -->
<!--success div-->

<!--End success div-->
</div>
</div>


<!--<div class="cart-outer-div checkout-outer-div all-orders-div">
    <div class="back-btn-main-div category-back-title mb-px-20" style="position: sticky;z-index: 10;justify-content:flex-start;top:0px;">
        <div class="cursor" (click)="goToShop()"><img class="back-icon cursor" src="./../../../../assets/icons/back-button.svg"></div>
        <div class="shop-category-title">Orders</div>
    </div>

    <div class="all-orders-list-div">
     
        <ng-container *ngIf="myorders && myorders.length>0">
        <div *ngFor="let order of myorders">
            <div class="cart-summary-product-card d-flex space-between align-center mb-px-20" style="gap:15px" *ngFor="let product of order['line_items']">
                <div class="d-flex align-center" style="gap:10px">
                    <img [src]="product.image" class="all-orders-checkout-image">
                   <div>
                    <div class="poppins-medium checkout-font-3 text-eclipse">{{product?.name}}</div>
                    <div class="lato-medium checkout-font-3 font-grey">Quantity: {{product.quantity}}</div>
                  </div>
                </div>

                <div class="blue checkout-font-3">
                  <i class="fa fa-rupee blue"><span class="poppins-medium blue">{{product.subtotal}}</span></i>
                </div>

            </div>

           <div class="d-flex space-between mt-px-20">
            <div class="checkout-font-3 poppins-medium" style="margin-bottom: 0px;">
                <div [ngStyle]="{color: order.center_color}"> <i class="fa fa-circle" style="size: 21px;" [ngStyle]="{color: order.center_color}"></i>
                 {{order.center_status}}</div>
      
                 <div class="checkout-font-3" style="margin-left: 20px;" *ngIf="order.center_status!=='Delivery not booked'">on {{order.center_time | date}}</div>
                 
             </div>   

             <div>
                 <p class="blue cursor text-underline" (click)="goToOrderDetail(order?.project_id)">View Details</p>
             </div>
            </div>   
                    <div class="divider-line"></div>

       </div>
  </ng-container>

  <ng-container>
    <div *ngIf="!is_from_backend || !myorders">
      <div class="food-category-item" style="margin-bottom: 20px;" *ngFor="let category of skeleton_data">
        <div class="d-flex">
          <div class="list-sk-image"></div>
         <div class="flex-auto">
          <div class="list-sk-body-1 flex-auto"  style="min-width:100%"></div>
          <div class="list-sk-body-1 flex-auto"></div>
        </div>
        </div> 
        <div class="food-category-sk-name text-center"></div>
         <div class="food-category-sk-name text-center"></div>
         <div class="divider-line"></div>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="myorders && is_from_backend && myorders.length==0">
<div style="min-height: 50vh" class="d-flex align-center justify-center">
    <div class="shop-category-title">No orders available</div>
</div>
  </ng-container>
</div>
</div>-->