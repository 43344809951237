import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd, ResolveEnd } from '@angular/router';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { CommonService } from './core/services/common/common.service';
import { inject } from '@angular/core/testing';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AngularFireMessaging,
  SERVICE_WORKER,
} from '@angular/fire/compat/messaging';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = '5km';
  cur_menu = 'home';
  show_menu = true;
  url: any;

  posttypes = ['News/ Post', 'Event', 'Job', 'Poll', 'Property'];

  not_title = 'Title of the notification';
  not_body = 'Notification body';
  not_image = '';
  not_link = '';

  user_extra: any;
  user: any;
  user_info = this.commonservice.getLocalStorage('user');

  latitude: any;
  longitude: any;
  current_location = 'Select location';
  side_menu = false;
  show_pages = true;
  browserName: any = this.detectBrowserName();
  browserVersion: any = this.detectOs();
  platform: any = this.detectOs();

  myWorker: any;

  @ViewChild('newPostContent', { static: true }) newspost!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public cdr: ChangeDetectorRef,
    private afMessaging: AngularFireMessaging,
    public renderer: Renderer2, public modal: NgbModal, private router: Router, public commonservice: CommonService) {

  }

  dynamic_tabs:any=[{name:'Home',link:'/home',name_lc:'home'},{name:'Feed',link:'/feed/home',name_lc:'feed'}];

  getDynamicMenu(){
     this.commonservice.getDynamicMenu().subscribe((data:any)=>{
          this.dynamic_tabs=[...this.dynamic_tabs,...data['data']['result']['categories']];
          this.dynamic_tabs.forEach((data:any)=>{
             if(!data['link']){
             data['link']=`/feed/${data['name']}`;
             }
             data['name_lc']= data['name'].toLowerCase();
          })
     })
  }
  
  ngOnInit(): void {
    this.getDynamicMenu();
    this.user = localStorage.getItem('token');
    let user_details_extra = this.commonservice.getLocalStorage('user_details_extra');
    if (this.user) {
      if (!user_details_extra) {
        this.getUserData();
      } else {
        this.user_extra = user_details_extra;
        console.log(this.user_extra);
      }
      this.getUserpages();
    }



    this.commonservice.user_data.subscribe((data: any) => {
      this.user = localStorage.getItem('token');
      this.user_info = this.commonservice.getLocalStorage('user');
      let user_e = this.commonservice.getLocalStorage('user_details_extra');
      if (!user_e) {
        this.getUserData();
      }
      if (this.user) {
        this.getUserpages();
      }
    })

    this.router.events.subscribe((data: any) => {
      if (data instanceof NavigationEnd) {
        console.log(data['url']);
        this.url = data['url'];
        let split = data['url'].split('/');
        this.assignMenu(split[1], split[2]);
      }
    })

    this.commonservice.page_change.subscribe((data: any) => {
      console.log('detecting page change');
      this.current_selected_page = this.commonservice.getLocalStorage('current_page');
      console.log(this.user_pages);
      console.log(this.current_selected_page);
    })

    if (isPlatformBrowser(this.platformId)) {
      let id = localStorage.getItem('visitorid');
      let ip = localStorage.getItem('ip');
      if (!id) {
        const fpPromise = FingerprintJS.load();
        ; (async () => {
          // Get the visitor identifier when you need it.
          const fp = await fpPromise
          const result = await fp.get()
          this.commonservice.setLocalStorage('visitorid', result.visitorId);
        })();
      }

      this.getToken();
      this.listen();
      this.requestLocationPermission();
      if (!ip) {
        this.getIpAddress();
      }
      // this.getLocation();
    }

    this.cdr.detectChanges();
  }

  login_success_type = '';

  createNewPost(data: any, post: any) {
    this.closeMenu();
    let user = localStorage.getItem('token');
    if (user) {
      if (this.user_extra['is_verified']) {
        this.modal.open(post, { size: 'lg' });
      } else {
        this.router.navigate(['/verification'])
      }
    } else {
      this.login_success_type = 'newpost';
      this.modal.open(data, { size: 'md' });
    }
  }

  getToken() {
    let is_registered = this.commonservice.getLocalStorage('is_token_registered');
    this.afMessaging.getToken.subscribe((token: any) => {
      console.log('Token found');
      console.log(token);
      if (!token) {
        this.commonservice.setLocalStorage('is_token_registered', false);
        this.commonservice.setLocalStorage('is_location_subscribed', false);
        setTimeout(this.askUserNotificationPermission, 5000);
      } else if (token && !is_registered) {
        this.saveTokenToServer(token);
      }
      /*if(token){
          this.getLocation();
        }*/
    })

  }

  confirmPermission() {
    this.closePermissionModal();
    this.requestPermission();
  }

  maybeLater() {
    this.closePermissionModal();
  }

  closePermissionModal() {
    let dialog = document.getElementById('notperm');
    if (dialog) {
      dialog.style.top = '-100vh';
    }
  }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          console.log('Permission granted! Save to the server!', token);
          this.browserName = this.detectBrowserName();
          this.browserVersion = this.detectBrowserVersion();
          this.platform = this.detectOs();
          this.saveTokenToServer(token);
          this.getLocation();
        },
        (error) => { console.error(error); },
      );
  }


  openPostOptions(modal: any, data: any) {
    this.closeMenu();
    let user = localStorage.getItem('token');
    if (!this.user_extra || !this.user_extra['is_verified']) {
      this.getUserData();
    }
    if (user && this.user_extra) {
      if (this.user_extra['is_verified']) {
        this.confirmOpenPostOptions();
      } else {
        this.router.navigate(['/verification'])
      }
    } else {
      this.login_success_type = 'newpost';
      this.modal.open(data, { size: 'md' });
    }
  }

  createPostType(type: any, data: any) {
    this.closePostOptions();
    this.modal.open(data, { size: 'lg', backdrop: 'static' });
    /*if (type == 'News') {
      this.modal.open(data, { size: 'lg', backdrop: 'static' });
    } else if (type == 'Job') {
      this.modal.open(data, { size: 'lg', backdrop: 'static' });
    }*/
  }

  confirmOpenPostOptions() {
    let elem = document.getElementById('postoptions');
    if (elem) {
      elem.style.display = 'flex';
      elem.classList.remove('new-c-modal-close');
      elem.classList.add('new-c-modal');
    }
  }

  closePostOptions() {
    let elem = document.getElementById('postoptions');
    if (elem) {
      elem.style.display = 'none';
      elem.classList.remove('new-c-modal');
      elem.classList.add('new-c-modal-close');
    }
  }

  saveTokenToServer(token: any) {
    let user = this.commonservice.getLocalStorage('token');
    let visitorid = this.commonservice.getLocalStorage('visitorid');
    let payload = {
      token: token,
      type: this.browserName,
      version: this.browserVersion,
      os: this.platform,
      app: '5km',
      user_id: 0,
      device_id: visitorid ? visitorid : ''
    }
    if (user) {
      this.commonservice.setFireToken(token, true);
      let id = this.commonservice.getLocalStorage('user_id');
      payload['user_id'] = id;
      this.commonservice.saveTokenToServer(payload).subscribe((data: any) => {
        if (data['status'] == 'success') {
          this.commonservice.setLocalStorage('is_token_registered', true);
        }
      })
    } else {
      this.commonservice.setFireToken(token, false);
      this.commonservice.saveTokenToServerNoUser(payload).subscribe((data: any) => {
        if (data['status'] == 'success') {
          this.commonservice.setLocalStorage('is_token_registered', true);
        }
      })
    }
  }

  listen() {
    this.afMessaging.messages.subscribe((message: any) => {
      console.log(message);
      this.not_title = message.notification.title;
      this.not_body = message.notification.body;
      this.not_image = message.notification.image;
      this.not_link = message.data.link;

      let dialog = document.getElementById('notdialog');
      if (dialog) {
        dialog.style.right = "10px";
      }
      setTimeout(function () {
        let dialog = document.getElementById('notdialog');
        if (dialog) {
          dialog.style.right = "-400px";
        }
      }, 9600);
    })

  }

  askUserNotificationPermission() {
    let dialog = document.getElementById('notperm');
    if (dialog) {
      dialog.style.top = '0px';
    }
  }

  openNotification() {
    window.open(this.not_link, "_blank")
  }

  createNewPage(data: any, page: any) {
    this.closeMenu();
    if (!this.user_extra || !this.user_extra['is_verified']) {
      this.getUserData();
    }
    let user = localStorage.getItem('token');
    if (user && this.user_extra) {
      if (this.user_extra['is_document_verified']) {
        this.modal.open(page, { size: 'lg' });
      } else {
        this.router.navigate(['/verification']);
      }
    } else {
      this.login_success_type = 'newpage';
      this.modal.open(data, { size: 'md' });
    }
  }

  openSearchModal(data: any) {
    this.modal.open(data, { size: 'lg', centered: true });
  }

  openMapModal(data: any) {
    this.modal.open(data, { size: 'md', centered: true });
  }

  loginSuccessfull() {
    let user = localStorage.getItem('token');
    let id = this.commonservice.getLocalStorage('user_id');
    this.user_info = this.commonservice.getLocalStorage('user');
    this.getUserpages();
    let token = this.commonservice.getLocalStorage('ftoken');
    if (token) {
      this.commonservice.updateToken({ token: token, user_id: id }).subscribe((data: any) => {

      })
      this.commonservice.setLocalStorage('is_f_user', true);
    }
  }

  pageCreated(page: any) {
    this.selectPage(page);
    this.user_pages.push(page);
    this.closeModal();
  }

  goToLogin() {
    this.router.navigate(['/account/login'], { queryParams: { return_url: this.url } });
  }

  goToMyOrders() {
    this.router.navigate(['/shop/orders']);
  }

  logout() {
    let token = this.commonservice.getLocalStorage('ftoken');
    let a_token = this.commonservice.getLocalStorage('token');
    if (token) {
      this.commonservice.updateToken({ token: token, user_id: 0 }).subscribe((data: any) => {
        console.log(data);
      })
    }
    /*this.commonservice.setLocalStorage('is_f_user',false);
    this.commonservice.deleteLocalStorage('user');
    this.commonservice.deleteLocalStorage('token');
    this.commonservice.deleteLocalStorage('user_id');
    this.commonservice.deleteLocalStorage('language');
    this.commonservice.deleteLocalStorage('final_posts');
    this.commonservice.deleteLocalStorage('current_page');
    this.commonservice.deleteLocalStorage('proxy_user_ids');
    this.commonservice.changeUserData();*/
    this.commonservice.logout();
    this.user_pages = [];
  }

  user_pages: any[] = [];
  proxy_user_ids: any = [];
  current_selected_page: any = 0;

  getUserpages() {
    this.commonservice.getUserPages().subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.user_pages = data['data']['result']['pages'];
        for (let page of this.user_pages) {
          this.proxy_user_ids.push(page['proxy_user_id']);
        }
        this.commonservice.setLocalStorage('proxy_user_ids', this.proxy_user_ids);
        this.commonservice.setLocalStorage('user_pages', this.user_pages);
        this.current_selected_page = this.commonservice.getLocalStorage('current_page');
        this.cdr.detectChanges();
        console.log(this.user_pages);
        console.log(this.current_selected_page);
      }
    })
  }

  closeModal() {
    this.modal.dismissAll();
  }

  closeCreatePageModal(event: any) {
    if (event > 2) {
      this.getUserpages();
    }
    this.modal.dismissAll();
  }

  selectPage(page: any) {
    this.current_selected_page = page;
    this.commonservice.setLocalStorage('current_page', this.current_selected_page);
    this.goToUserPage(page['proxy_user_id']);
  }

  selectUser() {
    this.current_selected_page = { proxy_user_id: '', page_id: '' };
    this.commonservice.setLocalStorage('current_page', this.current_selected_page);

    let user = localStorage.getItem('user');
    let type = 'user';
    if (user) {
      let u = JSON.parse(user);
      if (u['creator_id'] > 0) {
        type == 'creator';
      }
      this.router.navigate([`/author/profile/${u['user_id']}-${type}`]);
    }
  }

  openNewPostModal() {
    //  this.modal.open(data,{size:'lg',backdrop:'static'});
    // this.router.navigate(['/user/coming-soon'])
    let p_modal = document.getElementById('postmodal');
    let body = document.getElementById('bodymain');
    if (p_modal) {
      p_modal.style.display = 'flex';
    }
    if (body) {
      body.style.overflow = 'hidden';
    }
  }

  getUserData() {
    this.commonservice.userDetails().subscribe((data: any) => {
      this.commonservice.setLocalStorage('user_details_extra', data['data']['result']);
      this.user_extra = data['data']['result'];
    })
    /* let page=this.commonservice.getLocalStorage('current_page');
     if(page){
         this.current_selected_page= page;
     }*/
  }

  goToUserPage(id: any) {
    this.router.navigate([`/author/profile/${id}-user`]);
  }

  assignMenu(menu: string, sec?: any) {
    console.log(sec);
    if (menu == 'home') {
      this.cur_menu = "home";
    } else if (menu == 'shop') {
      this.cur_menu = "shop";
    } else if (menu == 'food') {
      this.cur_menu = "food";
    } else if (menu == 'feed' && sec=='home') {
      this.cur_menu = "feed";
    }else if (menu == 'feed' && sec!=='home') {
      this.cur_menu = sec.split('?')[0].toLowerCase();
    }
    else if (menu == 'business') {
      this.cur_menu = "business";
    } else {
      //this.cur_menu="news";
    }
  }

  tagsstringify(tags:any){
     return JSON.stringify(tags);
  }

  checkMenu() {
    let category = this.url.split("/");
    if (category[0] == 'home' || category[0] == 'account') {
      this.show_menu = false;
    }
  }

  getIpAddress() {
    this.commonservice.getIpAddress().subscribe((data: any) => {
      this.commonservice.setLocalStorage('ip', data.ip);
    })
  }

  openMenu() {
    this.side_menu = true;
    let split = this.url.split('/');
    if (split[1] == 'home' || split[1] == 'terms-and-conditions' || split[1] == '') {
      let menu = document.getElementById('newdesktopnav');
      let overlay = document.getElementById('blackoverlay');
      let body = document.getElementById('bodymain');
      if (menu) {
        menu.style.visibility = 'visible';
        menu.style.left = "-20px";
      }
      /*  if(body && window.innerWidth<900){
           body.style.overflow="hidden";
        }*/
      if (overlay) {
        overlay.style.display = 'block';
      }
    }
  }

  openMobileMenu() {
    this.side_menu = true;
    let split = this.url.split('/');
    let menu = document.getElementById('newdesktopnav');
    let body = document.getElementById('bodymain');
    if (menu) {
      menu.style.visibility = 'visible';
      menu.style.left = "-20px";
      //  menu.click();
    }
    if (body) {
      body.style.overflow = "hidden";
    }
    /* if(overlay){
       overlay.style.display='block';
     }*/
  }

  goToAppStore() {
    window.open('http://play.google.com/store/apps/details?id=com.bulbandkey.threekmseller&hl=en_IN&gl=US', "_blank")
  }

  goToAuthorProfile(data: any) {
    let user = localStorage.getItem('user');
    let type = 'user';
    if (user) {
      let u = JSON.parse(user);
      if (u['creator_id'] > 0) {
        type == 'creator';
      }
      this.router.navigate([`/author/profile/${u['user_id']}-${type}`]);
    } else {
      this.modal.open(data, { size: 'md' });
    }
  }

  openUserProfileModal(data: any) {
    this.modal.open(data, { size: 'xl', modalDialogClass: 'cust-modal', backdrop: 'static' });
  }

  closeMenu() {
    this.side_menu = false;
    let menu = document.getElementById('newdesktopnav');
    //   let overlay= document.getElementById('blackoverlay');
    let body = document.getElementById('bodymain');
    if (menu && window.innerWidth < 900) {
      menu.style.visibility = 'hidden';
      menu.style.left = "-100vw";
    } else if (menu) {
      menu.style.visibility = 'hidden';
      menu.style.left = "-30vw";
    }
    if (body) {
      body.style.overflowY = "scroll";
    }
  }

  changeMenu(menu: string) {
    if (this.cur_menu !== menu) {
      this.cur_menu = menu;
    }
  }

  selected_location(event: any) {
    if (event) {
      this.current_location = event;
      this.commonservice.setLocalStorage('is_location_subscribed', false);
      this.locationBasedTopicSubscriptions();
    /*  this.afMessaging.getToken.subscribe((token: any) => {
        if (token) {
          this.locationBasedTopicSubscriptions();
        }
      })*/
    }
    this.modal.dismissAll();
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  /*
  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  */

  detectOs() {
    var OSName = "";
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
    return OSName;
  }


  getLocation() {
    let val = this.commonservice.getLocalStorage('location');
    let topicSubscription = this.commonservice.getLocalStorage('is_location_subscribed');
    if (val) {
      this.latitude = val['lat'];
      this.longitude = val['lng'];
      this.current_location = val['location'];
    }
    if (!topicSubscription) {
      this.locationBasedTopicSubscriptions();
    }
  }

  setUserLocation(payload: any) {
    this.commonservice.getUserLocation(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.current_location = data['Result']['location'];
        let location = {
          location: data['Result']['location'],
          lat: payload['lat'],
          lng: payload['lng']
        };
        this.commonservice.setLocalStorage('location', location);
        this.locationBasedTopicSubscriptions();
      }
    })
  }

  locationBasedTopicSubscriptions() {
    let ls = this.commonservice.getLocalStorage('is_location_subscribed');
    let visitor_id = localStorage.getItem('visitorid');

    this.afMessaging.getToken.subscribe((nottoken) => {
      if (nottoken && !ls && visitor_id) {
        let val = this.commonservice.getLocalStorage('location');
        let token = this.commonservice.getLocalStorage('token');
        let id = this.commonservice.getLocalStorage('user_id');

        let payload = {
          location: {
            lat: val['lat'],
            lng: val['lng']
          },
          user_id: id?id:0,
          token: nottoken,
          device_id: visitor_id
        }
        this.commonservice.subscribeToTopics(payload).subscribe((data: any) => {
          console.log(data);
          if (data['status'] == 'success') {
            this.commonservice.setLocalStorage('is_location_subscribed', true);
          }
        })
      }
    })
  }

  getGeoLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.setUserLocation({ lat: this.latitude, lng: this.longitude });
    })
  }

  checkGeoLocation() {
    let val = this.commonservice.getLocalStorage('location');
    let ls = this.commonservice.getLocalStorage('is_location_subscribed');

    console.log(val);
    if (!val) {
      this.getGeoLocation();
    } else if (!ls) {
      this.locationBasedTopicSubscriptions();
    }
    if (val) {
      this.getLocation();
    }

  }


  requestLocationPermission = () => {

    let self = this;
    console.log("Requesting location permission");
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        console.log(result.state);
        self.checkGeoLocation();
      } else if (result.state === "prompt") {
        console.log(result.state);
        this.commonservice.deleteLocalStorage('location');
        this.commonservice.deleteLocalStorage('is_location_subscribed');
        this.getGeoLocation();
      } else if (result.state === "denied") {
        console.log(result.state);
      }
      result.addEventListener("change", () => {
        console.log(result.state);
      });
    });
  }

  revealPosition = (data: any) => {
    console.log(data);
  }

  positionDenied() {

  }
}
