
<ng-container *ngIf="step==1">
  <div class="pd-interested-image-div" *ngIf="advertisement">
     <img class="pd-interested-back-btn cursor" (click)="closeModal()" src="./../../../../assets/icons/back-button.svg">
     <img class="pd-interested-back-image" [src]="advertisement['context']['background_banner'][0]">
  </div>
  <div class="pd-interested-form-div">
     <h2 class="pd-interested-text-1">Thank you for your interest</h2>
     <p class="pd-interested-text-2 pd-interested-color-1 pd-interested-margin-1">Please fill the below details, our team will contact you.</p>
     <div class="pd-interested-form-input-div">
        <p class="pd-interested-text-3 pd-interested-color-1">Name*</p>
        <input type="text" placeholder="Enter text(E.g: Rohit Sharma)" [(ngModel)]="name" (ngModelChange)="nameTyped()">
        <p class="text-c-warning" *ngIf="invalid_name">Enter valid name</p>
      </div> 

     <div class="pd-interested-form-input-div">
      <p class="pd-interested-text-3 pd-interested-color-1">Phone Number*</p>
      <input type="text" placeholder="Enter phone number(E.g: 9686685885)" [(ngModel)]="phone_no" (ngModelChange)="phoneNoTyped()">
      <p class="text-c-warning" *ngIf="invalid_phone_no">Enter valid 10 digit phone number</p>
    </div>

   <button class="btn no-btn pd-interested-btn-1 pd-interested-text-2" [disabled]="invalid_name || invalid_phone_no" (click)="submitResponse()">Submit</button>
  </div>
</ng-container>

<ng-container *ngIf="step==2">
  <div class="pd-interested-success-div">
  <div class="pd-interested-success-image-div">
    <img height="40" width="40" src="./../../../assets/icons/success-tick.svg">
  </div>
  <h2 class="pd-interested-text-1 text-center">Request submitted successfully!</h2>
  <p class="pd-interested-text-2 pd-interested-color-1 pd-interested-margin-1 text-center">Our team will contact you shortly</p>
  <button class="btn no-btn pd-interested-btn-2 pd-interested-text-2" (click)="closeModal()">Okay</button>
</div>
</ng-container>