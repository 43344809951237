<div class="success-div">

    <div>
    <div class="poppins-semibold success-font-1 font-white">THANK YOU FOR YOUR ORDER!</div>

    <div class="success-image-div">
          <img class="success-tick-icon" src="./../../../../assets/icons/success-tick.svg">
          <img class="success-tick-spread-icon" src="./../../../../assets/icons/success-tick-spread.svg">
    </div>

    <div class="mt-px-40 d-flex justify-center">
        <button class="btn-blue-1 poppins-medium no-btn" style="padding: 10px 20px;" (click)="goToOrderDetail()">Order Details <img src="./../../../../assets/icons/white-right-arrow-icon.svg" class="ml-px-10"></button>
    </div>
</div>
</div>

