<h2 style="text-align: center;margin-top: 1em;">Jansevak Results</h2>

<div class="outer-div">
<div>
    <div style="margin-bottom: 1em;border-bottom: 2px solid rgb(87, 86, 86);padding: 1em;" *ngFor="let result of contest_results;index as i">  
        <div>
           <h5>{{i+1}}. {{result?.title}}</h5>
           <p>Total Responses: {{result?.total_responses}}</p>
           <table style="width: 100%;">
               <thead>
                   <tr>
                    <th>Name</th>
                    <th>Count</th>
                    <th>Percentage</th>
                   </tr>
               </thead>
              
            <tbody>
             <tr *ngFor="let con of result.contestants;index as i;">
                <td>{{i+1}}.{{con.name}}</td>
                <td>{{con.count}}</td>
                <td>{{con.perc}}%</td>
             </tr>
            </tbody>
           </table>
        </div>
    </div>
</div>
</div>