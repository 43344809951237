import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit,Inject, ViewEncapsulation, HostListener, PLATFORM_ID} from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';
import { NewsService } from 'src/app/core/services/news/news.service';
import * as urlSlug from 'url-slug';
import {config} from './../../../core/config/config';

@Component({
  selector: 'app-show-reel',
  templateUrl: './show-reel.component.html',
  styleUrls: ['./show-reel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }

    .cust-modal{
       width: 100vw;
       height: 100vh;
    }

    @media (max-width: 900px) {
 
      .carousel-indicators li {
        position: relative;
        top: 42px;
        width : 8px!important;
        background: #D5D5D5 0% 0% no-repeat padding-box;
        height: 8px !important;
        border-radius: 50% !important;
      }
      
      .carousel-indicators .active {
        width : 10px!important;
        background-color: #3E7EFF;
        background: #3E7EFF 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 41px;
        height: 10px !important;
        border-radius: 50% !important
      }
    }
  
    @media (min-width: 900px) {
       .carousel-indicators li {
        width : 10px!important;
        height: 10px !important;
        border-radius: 50% !important
      }
      
       .carousel-indicators li {
        position: relative;
        top: 51px;
        width : 8px!important;
        background: #D5D5D5 0% 0% no-repeat padding-box;
        height: 8px !important;
        border-radius: 50% !important;
      }
      
        .carousel-indicators .active {
        width : 10px!important;
        background-color: #3E7EFF;
        background: #3E7EFF 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 50px;
        height: 10px !important;
        border-radius: 50% !important
      }
    }
  `]
})
export class ShowReelComponent implements OnInit {
  all_posts=[];
  all_posts_ids=[];

  is_liked=false;
  is_commented=true;
  click_reactions=false;

  comments_list=[];

  is_content_loading=false;
  skeleton_posts=[1,2,3,4,5,6,7,8,9,10];

  showNavigationArrows = false;
  showNavigationIndicators = true;
  images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);

  lang= this.commonService.getLocalStorage('language');
  //lang='hi';

  category_id=this._route.snapshot.queryParams['id']?this._route.snapshot.queryParams['id']:'';
  tags=this._route.snapshot.queryParams['tags']?JSON.parse(this._route.snapshot.queryParams['tags']):[];
  category_name=this._route.snapshot.queryParams['name']?this._route.snapshot.queryParams['name']:'';
  page_no=1;
  curpage_post_ids=[];
  display_posts:any=[];
  d_posts=[];
  is_local_loaded= false;

  cur_url:any;
  cur_video_type:any;
  token=  this.commonService.getLocalStorage('token');
  cur_index:any;
  cur_post_id:any;
  cur_user= this.commonService.getLocalStorage('user');

  @HostListener('document:click', ['$event']) onDocumentClick(event:any) {4
      if(this.cur_index){
      this.display_posts[this.cur_index]['click_reactions']= false;
      }
      event.stopPropagation();
  }

  constructor(@Inject(PLATFORM_ID) private platform_id:Object, confi: NgbCarouselConfig,public router:Router,public modal:NgbModal,public _route:ActivatedRoute, public news_service:NewsService,
              public cdr:ChangeDetectorRef, public commonService:CommonService, 
              public meta:Meta, public ngbModal:NgbModal, public title:Title) {
    // customize default values of carousels used by this component tree
    this.commonService.user_data.subscribe((data)=>{
      this.token=  this.commonService.getLocalStorage('token');
    })
  }
  ngOnInit(): void {
      if(!this.lang){
        this.lang='en';
      }
      this.title.setTitle('3km Showreel');
      this.meta.addTag({ property:'og:site_name', content: '3km'});
      this.meta.addTag({ property:'og:type', content: 'website'});
      this.meta.addTag({property:'og:title', content: '3km Showreel'});
      this.meta.addTag({property:'title', content: '3km Showreel'});
      this.meta.addTag({ property:'og:description', content: 'Showcase of the best of our best!'});
      this.meta.addTag({ property:'description', content: 'Showcase of the best of our best!'});
      this.meta.addTag({ property:'og:image', content: 'https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/0/1cca0c70-bcec-11ec-9ae7-11f8d124d848.png'});
      this.meta.addTag({ property:'og:url', content: `https://3km.in/showreel`});
      if(isPlatformBrowser(this.platform_id)){
        this.getLocalPosts();
      }
      this.cdr.detectChanges();
  }

  getLocalPosts(){
     let posts= this.commonService.getLocalStorage('showreel_posts');
     if(posts){
       this.is_local_loaded= true;
       this.display_posts= posts;

       setTimeout(()=>{
        this.fetchShowReelPosts();
       },1500);
     }else{
       this.is_content_loading= true;
       this.fetchShowReelPosts();
      }
  }

  moveToTop(){
    let top=document.getElementById('top');
    top?.scrollIntoView({behavior:'smooth'});
  }
  
  openLikesModal(content:any,a:any){
    this.cur_post_id= a;
    this.modal.open(content,{ scrollable: true,size:'lg'})
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  fetchShowReelPosts(){
    this.curpage_post_ids=[];

    if(this.is_local_loaded){
      this.display_posts=[];
      this.is_local_loaded= false;
    }


      let payload={
          page: this.page_no,
          token: this.token,
          lang: this.lang
      }
      this.news_service.showReelPosts(payload).subscribe((data:any)=>{
            for(let post of data['data']['result']['posts']){
               let post_info=post;
               post_info['read_more']= true;
               post_info['can_comment']= false;
               post_info['comments_length']= 5;
               post_info['click_reactions']= false;
               post_info['all_comments']=[];
               this.display_posts.push(post_info);
            }  
            this.is_content_loading=false;
      })
      setTimeout(()=>{
          this.dom();
      },3000)
  }

  back_to_news_home(){
      this.router.navigate(['/home'])
  }

  toggle_like_button(type:string){
      if(type=='like'){
        this.is_liked=true;
        this.click_reactions=true;
      }else{
      this.is_liked=false;
       this.click_reactions=false;
      }
  }

  openModal(content:any){
    this.modal.open(content,{ scrollable: true,size:'lg'})
  }

  openVideoModal(content:any, i:any, v:any){
    let obj= this.display_posts[i];
    this.cur_url= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? obj['videos'][v]['vimeo_url']:obj['videos'][v]['src'];
    this.cur_video_type= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? 'vimeo':'normal';
    this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'})
  }

  dom(){
      for(let i=0;i<this.display_posts.length;i++){
          let elem=document.getElementById(`${i}-pd`);
          if(elem && elem?.clientHeight<100){
              this.display_posts[i]['read_more']=false;
              elem.classList.remove('pd-story');
              elem.classList.add('pd-story-max');
          }
      }
      this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  likePost(data:any,id:any,i:any,event:any){
      //  this.display_posts[i]['click_reactions']= false;
        if(!this.token){
           this.ngbModal.open(data,{size:'md'});
        }
        else{
          this.display_posts[i]['is_liked']=true;
          let payload={
             module:'news_post',
             entity_id: id,
             emotion: event
          }

          this.news_service.like(payload).subscribe((data:any)=>{
              if(data['status']=='success'){
                 this.display_posts[i]['is_liked']= true;
                 this.display_posts[i]['likes']++;
              }else{
                this.display_posts[i]['is_liked']= false;
                this.display_posts[i]['likes']--;
              }
          });
        }
  }

  unlikePost(id:any,i:any){
      this.display_posts[i]['is_liked']= false;
      let payload={
         module:'news_post',
         entity_id: id
        }

      this.news_service.like(payload).subscribe((data:any)=>{
          if(data['status']=='success'){
             this.display_posts[i]['is_liked']= false;
             this.display_posts[i]['likes']--;
          }else{
            this.display_posts[i]['is_liked']= true;
            this.display_posts[i]['likes']++;
          }
      });
}

  onScroll(){
      this.is_content_loading= true;
      this.page_no++;
      this.fetchShowReelPosts();      
  }

  openReactions(i:any){
         if(this.cur_index){
          this.display_posts[this.cur_index]['click_reactions']= false;
         }
         this.display_posts[i]['click_reactions']= true;
         this.cur_index=i;
  }

  login_successfull(){
    this.token=  this.commonService.getLocalStorage('token');
    this.cur_user= this.commonService.getLocalStorage('user');
  }

  commentOnPost(id:number,i:number,data:any){
    if(!this.token){
      this.ngbModal.open(data,{size:'md'});
   }
    else{
      this.display_posts[i]['can_comment']=true;
       this.loadComments(id,i);
    }
  }

  loadComments(id:number,i:number){
      let payload={
        entity_id: id,
        module: 'news_post'
      }
      this.news_service.getComments(payload).subscribe((data:any)=>{
        this.display_posts[i]['all_comments']= data['data']['result']['comments'];
        this.display_posts[i]['all_comments']= this.display_posts[i]['all_comments'].slice().reverse();
      })
  }

  postComment(id:number,i:number,comment:string){
    let payload={
      entity_id: id,
      module: 'news_post',
      comment: comment
    }
     let new_comment={
       avatar: this.cur_user.avatar,
       comment: comment
     }
     this.display_posts[i]['all_comments']=[new_comment,...this.display_posts[i]['all_comments']]
     this.display_posts[i]['comments_length']=5;

     this.news_service.postComment(payload).subscribe((data:any)=>{
        if(data['status']=='success'){
           this.display_posts[i]['all_comments']= data['data']['result']['comments'].slice().reverse();
        }
     })
  }

  url= config.domain;
  share_post_url='';
  post:any;

  urlSlugConverter(headline:string){
    let url= urlSlug.convert(`${headline}`,
    {camelCase: false});
    return url;
  }

  cur_post_index:any;

  sharePost(id:number,i:number,data:any){
    this.cur_post_index= i;
    this.post= this.display_posts[i];  
    this.meta.addTag({ property:'og:site_name', content: '3km'});
    this.meta.addTag({ property:'og:type', content: 'website'});
  //  this.title.setTitle(this.post.headline);
    if(this.post.story.replace( /(<([^>]+)>)/ig, '')){
      this.meta.addTag({ property:'og:description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
      this.meta.addTag({ property:'description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
    }
    if(this.post.images[0]){
      let image = this.post.images[0].replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/','https://cdn.bulbandkey.com/fit-in/400x400/')
      this.meta.addTag({ property:'og:image', content: image});
    } else if(this.post.videos[0]){
      this.meta.addTag({ property:'og:video', content: this.post.videos[0]['src']});
      this.meta.addTag({ property:'og:video:secure_url', content: this.post.videos[0]['src']});
      this.meta.updateTag({ property:'og:type', content: 'video.other'});
      this.meta.updateTag({ property:'og:video:type', content: 'video/mp4'});
      if(this.post.videos[0].thumbnail){
      this.meta.addTag({ property:'og:image', content: this.post.videos[0].thumbnail});
      }
    }
    if(this.post.headline.replace( /(<([^>]+)>)/ig, '')){
      this.meta.addTag({ property:'og:title', content: this.post.headline.replace( /(<([^>]+)>)/ig, '')});
    }
    this.meta.addTag({ property:'og:url', content: `https://3km.in/post-detail?id=${this.post.post_id}`});
   
      let slugurl= this.urlSlugConverter(this.post['slug_headline']);
    /*  if(!slugurl || (slugurl.replace(/\-+/g,'')).match(/[0-9]+$/)){
            slugurl= this.post['headline']?this.post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
      } */
      if(!slugurl){
        slugurl='3-km-post-detail';
      }
      this.share_post_url= `${this.url}post-detail/${slugurl}/${id}-${this.lang}`;
      this.ngbModal.open(data,{size:'md',centered:true});
  }

  postShared(event:any){
    this.display_posts[this.cur_post_index]['shares']++;
  }

  loadAllComments(a:any){
    let num=this.display_posts[a]['comments_length'];
    if(num>5){
      this.display_posts[a]['comments_length']= 5;
    }else{
      this.display_posts[a]['comments_length']= this.display_posts[a]['all_comments'].length;
    }
}

  imageSwipeLeft(){
    window.alert('swipe');
  }

  ngOnDestroy(){
    this.commonService.setLocalStorage('showreel_posts', this.display_posts);
    this.title.setTitle('3KM');
  }
}
