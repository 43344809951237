import { Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './../../../core/services/common/common.service';
import {AuthService} from './../../../core/services/auth/auth.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {

  incorrect_otp:boolean= false;
  @Input() phone_no:any;
  @Output() changeNumber:any=new EventEmitter<any>();
  @Output() number_verified:any= new EventEmitter<any>();

  otp='';
  remaining_min:any;
  remaining_sec:any;

  otp_timer:any;

  constructor(public modal:NgbModal, public activeModal:NgbActiveModal, public commonService:CommonService, public authservice:AuthService) { }

  ngOnInit(): void {
    if(this.phone_no){
      this.startTimer();
    }
  }

  changePhoneNumber(){
      this.changeNumber.emit(true)
   //  this.activeModal.close();
  }

  otpEntered(i: any, event: any) {
    let p_input = <HTMLInputElement>document.getElementById(`otp-${i - 1}`);
    let input = <HTMLInputElement>document.getElementById(`otp-${i}`);
    let n_input = <HTMLInputElement>document.getElementById(`otp-${i + 1}`);

    if (event['keyCode'] == 8 || event['keyCode'] == 46 || event['key'] == 'Backspace' || event['key'] == 'Delete') {
      this.otpInputFocus(i - 1);
    }
    else if ((event['keyCode'] > 47 && event['keyCode'] < 58) || (parseInt(event['key']) >= 0 && parseInt(event['key']) <= 9) || event) {
      if (input.value && i < 4) {
        this.otpInputFocus(i + 1);
      }
    }
    else {
      input.value = '';
    }
  }

  otpInputFocus(i: any) {
    this.incorrect_otp = false;
    if (i > 0) {
      let input = <HTMLInputElement>document.getElementById(`otp-${i}`);
      input.focus();
    }
  }

  verifyOtp(o1: any, o2: any, o3: any, o4: any) {
    let payload = {
      otp: `${o1 + o2 + o3 + o4}`,
      phone_no: this.phone_no
    }

    this.authservice.verifyOtpOnly(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        //  this.router.navigate([`${this.return_url?this.return_url:'news/home'}`]);
        this.number_verified.emit(this.phone_no);
      } else {
        this.incorrect_otp = true;
      }
    })
  }


  resendConfirmationCode(){

  }

  otpTimer() {
    this.remaining_sec--;

    if (this.remaining_sec < 0) {
      this.remaining_sec = 59;
      this.remaining_min--;
    }
    if (this.remaining_min < 0) {
      this.destroyTimer();
      this.remaining_sec = 0;
    }
  }

  startTimer() {
    this.remaining_min = 2;
    this.remaining_sec = 0;
    this.otp_timer = setInterval(() => {
      this.otpTimer()
    }, 1000);
  }

  destroyTimer(){
    if(this.otp_timer){
      clearInterval(this.otp_timer);
    }
  }

  sendLoginOtp() {
    let payload = {
      phone_no: this.phone_no
    }
    this.authservice.sendLoginOtp(payload).subscribe((data: any) => {
        if(data['status']=='success'){
            this.destroyTimer();
            this.startTimer();
        }  
    })
  }

  ngOnDestroy(){
     this.destroyTimer();
  }

}
