<div>

  <div *ngIf="!is_content_loading" style="position: relative;">
   
    
  
  <!--Vimeo Player-->    
     <ng-template #videoContent let-modal>
      <div class="d-flex flex-end" style="padding: 1em;justify-content: flex-end;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-video-player [url]="cur_url" [type]="cur_video_type"></app-video-player>
    </div>
  </ng-template>
  
  <div *ngIf="is_refreshed">
  
      <div class="posts-category-list-card-outer-div" *ngFor="let card of final_posts;index as j">

       <!--New home top card-->
        <div class="new-home-layout-top-card-wrapper" *ngIf="card['type']=='news_cat' && card['category']['name']=='News & Politics'">
              
            <div class="new-home-layout-top-card-wrapper-left">

              <div class="new-layout-post-card-wrapper" *ngFor="let post of special_post">
                <div class="news-post-ind-card new-layout-post-card cursor" (click)="viewSpecialPostDetail(post['post_id'])">
              
                  <div class="new-post-card-name-div" style="column-gap: 8px;margin-bottom: 10px">

                    <div class="new-post-card-image-div cursor" [ngStyle]="{'background-image':'url('+post.author.image+')'}"></div>
                    <div>
                        <div class="new-post-card-font-1 new-post-card-color-1 new-post-card-author-name new-text-eclipse-1 cursor">{{post['author']['name']}}</div>
                        <div class="d-flex align-center new-post-card-timestamp">
                          <div class="new-post-card-font-2 new-post-card-color-1">{{post['post_created_date']}}</div>
                       </div>
                    </div>
                  </div>
      
                <div class="post-image-div">
                   <div class="post-image-blur" [ngStyle]="{'background-image': 'url('+post.image+')'}"></div>
                   <div class="video-btn-div" *ngIf="post?.video">
                       <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
                   </div>
                    <img class="post-image" [src]="post.thumbnail?post.thumbnail:post.image" loading="lazy">  
                </div>
        
                <div class="post-footer-div margin-top-10">
                    <div class="post-text-wrapper-div">
                       <div *ngIf="post?.headline" class="post-title-text" >{{post?.headline}}</div>
                       <div *ngIf="!post?.headline" class="post-title-text" >{{post?.description}}</div>
                    </div> 
                </div> 
        
              </div> 
            </div>
            </div>

            <div class="posts-category-list-card new-home-layout-top-card-wrapper-right">
                 <div class="post-card-top">
                     <div class="posts-list-font-1 post-card-top-name"><img class="loc-black-icon margin-right-10" [src]="card['category']['icon']" onerror="this.style.display='none'">{{card['category']['name']}}</div>
                     <div class="posts-list-font-2 cursor" (click)="goToAllPosts(j)">View All<img class="arrow-right-icon margin-left-10" src="./../../../../assets/icons/arrow-right-lg.png"></div>       
                 </div>
             
          <div class="news-posts-carousel-div">
              <div class="opacity-div">
                <div id="nextbtn" class="next-arrow-div" (click)="owlCar.next()">
                  <img class="next-arrow-icon cursor" src="./../../../../assets/icons/next-arrow-lg.png">
                </div>
              </div>
            <owl-carousel-o [options]="customOptionsTop" [id]="'{{j+1}}'" (translated)="getPassedData($event,j)" #owlCar>
              <ng-container *ngIf="card['category']['posts'].length>2">
              <ng-template carouselSlide [id]="post?.post_id" *ngFor="let post of news_first_half;trackBy: trackByFn;index as i">
              
               
                <div class="post-wrapper-div">
                <div class="news-post-ind-card cursor" (click)="viewNewsCategoryPostDetail(post['post_id'],i,'first')">
              
                <div class="post-image-div">
                   <div class="post-image-blur" [ngStyle]="{'background-image': 'url('+post.image+')'}"></div>
                   <div class="video-btn-div" *ngIf="post?.video">
                       <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
                   </div>
                    <img class="post-image" [src]="post.thumbnail?post.thumbnail:post.image" loading="lazy">  
                </div>
        
                <div class="post-footer-div margin-top-10">
                  <div class="post-author-img-wrapper"> 
                     <img class="post-author-img margin-right-10" [src]="post?.author?.image" loading="lazy">    
                  </div>
                    <div class="post-text-wrapper-div">
                       <div *ngIf="post?.headline" class="post-title-text" >{{post?.headline}}</div>
                       <div *ngIf="!post?.headline" class="post-title-text" >{{post?.description}}</div>
                       <div class="post-timeline-text" *ngIf="card['category']['name']=='Trending' || card['category']['name']=='News & Politics'"><div class="c-text-eclipse">{{post?.author?.name}}</div> <div class="timeline-circle"></div> <div class="c-text-eclipse-2">{{post?.post_created_date}}</div></div>
                       <div class="post-timeline-text" *ngIf="card['category']['name']!=='Trending' && card['category']['name']!=='News & Politics'"><div class="c-text-eclipse" style="min-width: 100%;">{{post?.author?.name}}</div></div>
                    </div> 
                </div> 
        
              </div> 
            </div>
              </ng-template> 
            </ng-container>
              
            </owl-carousel-o> 

           </div>
            <div style="position: relative;margin-top: 8px;">
             
             
          <div class="news-posts-carousel-div">
              <div class="opacity-div">
                <div id="nextbtn" class="next-arrow-div" (click)="owlCarDup.next()">
                  <img class="next-arrow-icon cursor" src="./../../../../assets/icons/next-arrow-lg.png">
                </div>
              </div>
            <owl-carousel-o [options]="customOptionsTop" [id]="'{{j+10+1}}'" (translated)="getPassedData($event,j)" #owlCarDup>
              <ng-container >
              <ng-template carouselSlide [id]="post?.post_id" *ngFor="let post of news_second_half;index as i">
              
               
                <div class="post-wrapper-div" style="max-width: 100%;">
                <div class="news-post-ind-card cursor" (click)="viewNewsCategoryPostDetail(post['post_id'],i,'second')">
              
                <div class="post-image-div">
                   <div class="post-image-blur" [ngStyle]="{'background-image': 'url('+post.image+')'}"></div>
                   <div class="video-btn-div" *ngIf="post?.video">
                       <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
                   </div>
                    <img class="post-image" [src]="post.thumbnail?post.thumbnail:post.image" loading="lazy">  
                </div>
        
                <div class="post-footer-div margin-top-10">
                  <div class="post-author-img-wrapper"> 
                     <img class="post-author-img margin-right-10" [src]="post?.author?.image" loading="lazy">    
                  </div>
                    <div class="post-text-wrapper-div">
                       <div *ngIf="post?.headline" class="post-title-text" >{{post?.headline}}</div>
                       <div *ngIf="!post?.headline" class="post-title-text" >{{post?.description}}</div>
                       <div class="post-timeline-text" *ngIf="card['category']['name']=='Trending' || card['category']['name']=='News & Politics'"><div class="c-text-eclipse">{{post?.author?.name}}</div> <div class="timeline-circle"></div> <div class="c-text-eclipse-2">{{post?.post_created_date}}</div></div>
                       <div class="post-timeline-text" *ngIf="card['category']['name']!=='Trending' && card['category']['name']!=='News & Politics'"><div class="c-text-eclipse" style="min-width: 100%;">{{post?.author?.name}}</div></div>
                    </div> 
                </div> 
        
              </div> 
            </div>
              </ng-template> 
            </ng-container>
              
            </owl-carousel-o> 
            </div>



            </div>
         </div>
         </div>
             <!--New home top card end-->

        <div class="posts-category-list-card"  *ngIf="card['type']=='news_cat' && card['category']['name']!=='News & Politics'">
  
           <div class="post-card-top">
               <div class="posts-list-font-1 post-card-top-name"><img class="loc-black-icon margin-right-10" [src]="card['category']['icon']" onerror="this.style.display='none'">{{card['category']['name']}}</div>
               <div class="posts-list-font-2 cursor" (click)="goToAllPosts(j)">View All<img class="arrow-right-icon margin-left-10" src="./../../../../assets/icons/arrow-right-lg.png"></div>       
           </div>
       
    <div class="news-posts-carousel-div">
      
        <div class="opacity-div">
          <div id="nextbtn" class="next-arrow-div" (click)="owlCar.next()">
            <img class="next-arrow-icon cursor" src="./../../../../assets/icons/next-arrow-lg.png">
          </div>
        </div>
        
      <owl-carousel-o [options]="customOptions" [id]="'{{j+1}}'" (translated)="getPassedData($event,j)" #owlCar>
        <ng-container *ngIf="card['category']['posts'].length>2">
        <ng-template carouselSlide [id]="post?.post_id" *ngFor="let post of card['category']['posts'];trackBy: trackByFn;index as i">
        
         
          <div class="post-wrapper-div" style="max-width: 100%;">
          <div class="news-post-ind-card cursor" (click)="viewPostDetail(post['post_id'],j,i,'en')">
        
          <div class="post-image-div">
             <div class="post-image-blur" [ngStyle]="{'background-image': 'url('+post.image+')'}"></div>
             <div class="video-btn-div" *ngIf="post?.video">
                 <img class="video-btn" src="./../../../../assets/icons/play-btn.svg">
             </div>
              <img class="post-image" [src]="post.thumbnail?post.thumbnail:post.image" loading="lazy">  
          </div>
  
          <div class="post-footer-div margin-top-10">
            <div class="post-author-img-wrapper"> 
               <img class="post-author-img margin-right-10" [src]="post?.author?.image" loading="lazy">    
            </div>
              <div class="post-text-wrapper-div">
                 <div *ngIf="post?.headline" class="post-title-text" >{{post?.headline}}</div>
                 <div *ngIf="!post?.headline" class="post-title-text" >{{post?.description}}</div>
                 <div class="post-timeline-text" *ngIf="card['category']['name']=='Trending' || card['category']['name']=='News & Politics'"><div class="c-text-eclipse">{{post?.author?.name}}</div> <div class="timeline-circle"></div> <div class="c-text-eclipse-2">{{post?.post_created_date}}</div></div>
                 <div class="post-timeline-text" *ngIf="card['category']['name']!=='Trending' && card['category']['name']!=='News & Politics'"><div class="c-text-eclipse" style="min-width: 100%;">{{post?.author?.name}}</div></div>
              </div> 
          </div> 
  
        </div> 
      </div>
        </ng-template> 
      </ng-container>
        
      </owl-carousel-o> 
      
    </div>
  
  </div>
     
     <!-- <div class="np-g-a-div" *ngIf="card['category']['name']=='Trending'">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="9603437633"
          [adFormat]="'auto'"
          [className]="'np-n-border'"
          [fullWidthResponsive]="true"
        ></ng-adsense>
  
      <div class="np-g-a-div" *ngIf="card['type']=='news_cat' && card['category']['name']=='Trending'">
        <ng-adsense
          [adClient]="'ca-pub-2898290509296226'"
          [display]="'block'"
          [adSlot]="5444700779"
          [adFormat]="'auto'"
          [className]="'np-n-border'"
          [fullWidthResponsive]="true"></ng-adsense>
      </div>
      -->

      <div class="new-home-layout-banner-div" *ngIf="card['type']=='news_cat' && card['category']['name']=='Entertainment (3km Originals)'">
         
        <div class="new-home-layout-banner-div-left">
          <ngb-carousel id="topslide-carousel" [showNavigationArrows]="topslides.length>1" [showNavigationIndicators]="false" style="max-width: 100%;position: relative;">
            <ng-template ngbSlide *ngFor="let b of topslides">
              <div class="picsum-img-wrapper topslide-page-image-div">
               <img class="topslide-page-image cursor" (click)="goToUrl(b,videoContent)" [src]="b.images[0]">
              <!--   <img class="topslide-page-image" [src]="amz_img">  -->
              </div>
            </ng-template>
          </ngb-carousel>
        </div>

        <div class="new-home-layout-banner-div-right">
            <!-- <div class="new-home-download-banner">
                 <div class="new-home-download-banner-logo-wrapper">
                   <img class="new-home-download-banner-logo" src="./../../../../assets/new-logo/new-3km-logo.svg">
                 </div>
                  <div class="post-title-tex text-white text-center new-desktop-only">Download the app now! and get unlimited breaking news<br> and entertainment content its free</div>
                  <div class="post-title-tex text-white text-center new-mobile-only">Download the app now! <br> and get unlimited breaking news and <br>entertainment content its free</div>
                  <img class="new-home-download-banner-google-play-img cursor" (click)="goToAppStore()" src="./../../../../assets/new-logo/google-play-image.svg">    
            </div> -->

            <ng-adsense
            [adClient]="'ca-pub-2898290509296226'"
            [display]="'block'"
            [adSlot]="9603437633"
            [adFormat]="'fluid'"
            [className]="'new-home-poll-top-ad-inner'"
            [fullWidthResponsive]="true"></ng-adsense>

        </div>

      </div>
      

    <ng-container *ngIf="card['type']=='quiz'">
      <div class="new-home-poll-top-a new-ad-mobile-only" *ngIf="card['quiz']['type']=='poll'">
        <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [adSlot]="3024243886"
        [adFormat]="'fluid'"
        [className]="'new-home-poll-top-ad-inner'"
        [fullWidthResponsive]="true"></ng-adsense>
       </div>

       <div class="new-home-poll-top-a new-ad-mobile-only" *ngIf="card['quiz']['type']=='quiz'">
        <ng-adsense
        [adClient]="'ca-pub-2898290509296226'"
        [display]="'block'"
        [adSlot]="5838680309"
        [adFormat]="'fluid'"
        [className]="'new-home-poll-top-ad-inner'"
        [fullWidthResponsive]="true"></ng-adsense>
       </div>
      </ng-container>

  
  <div class="home-poll-outer-div" *ngIf="card['type']=='quiz'">
       <div class="home-poll-left-div">
          <app-poll [poll_id]="card['quiz']['quiz_id']" [poll]="card['quiz']" (login)="login()"></app-poll>
       </div>
       <div class="home-poll-right-div" *ngIf="card['quiz']['type']=='quiz'">
           <div class="new-home-poll-top-a new-ad-desktop-only">
            <ng-adsense
            [adClient]="'ca-pub-2898290509296226'"
            [display]="'block'"
            [adSlot]="5838680309"
            [adFormat]="'fluid'"
            [className]="'new-home-poll-top-ad-inner'"
            [fullWidthResponsive]="true"></ng-adsense>
           </div>

           <div class="new-home-poll-bottom-a">
            <ng-adsense
            [adClient]="'ca-pub-2898290509296226'"
            [display]="'block'"
            [adSlot]="7941528644"
            [adFormat]="'fluid'"
            [className]="'new-home-poll-top-ad-inner'"
            [fullWidthResponsive]="true"></ng-adsense>
           </div>
       </div>

       <div class="home-poll-right-div" *ngIf="card['quiz']['type']=='poll'">
          <div class="new-home-poll-top-a new-ad-desktop-only">
            <ng-adsense
            [adClient]="'ca-pub-2898290509296226'"
            [display]="'block'"
            [adSlot]="3024243886"
            [adFormat]="'fluid'"
            [className]="'new-home-poll-top-ad-inner'"
            [fullWidthResponsive]="true"></ng-adsense>
           </div>
      
           <div class="new-home-poll-bottom-a">
            <ng-adsense
            [adClient]="'ca-pub-2898290509296226'"
            [display]="'block'"
            [adSlot]="5890080378"
            [adFormat]="'fluid'"
            [className]="'new-home-poll-top-ad-inner'"
            [fullWidthResponsive]="true"></ng-adsense>
           </div>
       </div>
</div>
  
  
 
<div class="new-home-poll-top-a new-ad-mobile-only" *ngIf="card['type']=='banner' && card['bannertype']=='RWC'">
  <ng-adsense
  [adClient]="'ca-pub-2898290509296226'"
  [display]="'block'"
  [adSlot]="9603437633"
  [adFormat]="'fluid'"
  [className]="'new-home-poll-top-ad-inner'"
  [fullWidthResponsive]="true"></ng-adsense>
 </div>

  
      <!--Advertisement div-->
  <div class="new-home-banner-div" *ngIf="card['type']=='banner' && card['bannertype']=='RWC'">
   <div class="new-home-banner-div-left">
      <div class="posts-category-adv-card" style="position: relative;">
        <div class="next-adv-arrow-div" (click)="owlCar.next()">
           <img class="next-arrow-icon cursor" src="./../../../../assets/icons/next-arrow-lg.png">
         </div>
  
           <div class="opacity-adv-div">
           </div>
          
     <div class="news-posts-carousel-div np-adv-carousel-div new-layout-carousel-ad">
       
              <owl-carousel-o [options]="customOptionsAdv" [id]="'{{j+1}}'" #owlCar>
                  <ng-template carouselSlide *ngFor="let adv of card['banners'][0]['imageswcta'];index as i">
                     <div class="np-rwc-adv-image-div" style="max-height: 90%;">
  
                       <img class="np-rwc-adv-image" [src]="adv['image']"> 
                     </div>
                  </ng-template>  
              </owl-carousel-o> 
      </div>
  
     </div>
   </div>

  <div class="new-home-banner-div-right">
    <div class="new-home-poll-top-a new-ad-desktop-only">
      <ng-adsense
      [adClient]="'ca-pub-2898290509296226'"
      [display]="'block'"
      [adSlot]="9603437633"
      [adFormat]="'fluid'"
      [className]="'new-home-poll-top-ad-inner'"
      [fullWidthResponsive]="true"></ng-adsense>
     </div>

     <div class="new-home-poll-bottom-a">
      <ng-adsense
      [adClient]="'ca-pub-2898290509296226'"
      [display]="'block'"
      [adSlot]="5444700779"
      [adFormat]="'fluid'"
      [className]="'new-home-poll-top-ad-inner'"
      [fullWidthResponsive]="true"></ng-adsense>
     </div>
  </div>
</div>
  </div>
      <!--End Advertisement div-->
</div>

<!--SEO paragraph-->

      <div class="new-seo-div">
        <h4 style="margin-bottom: 5px">5km is India’s top hyperlocal platform</h4>

        <p>You can discover a variety of content, right from breaking news, event alerts and local updates across categories of politics, entertainment, sports, food, lifestyle, education, technology, finance, business etc. The platform supports content from all vernacular languages in text, image and video format. Majority of the content is sourced as user generated content from verified reporters, influencers and bloggers. 5km is a Made in India platform which serves content in order of the geo proximity of content source.</p>
        
        <h4 style="margin-bottom: 5px">Advertise with 5km</h4>
        
        <p>5km is a one-stop solution for businesses to advertise. Experience our unique data-driven approach to campaigns with strong emphasis on quality. Our experienced in-house team consists of creative writers, designers, and filmmakers who specialize in emotion driven storytelling. The team can deliver on budget friendly custom content for your brand.
         
        We also offer programmatic ads with precise targeting of your ideal audience including the regional audience of tier II and tier III cities. The inventory includes all formats such as banner ads, carousel ads and video ads. 5km is committed to ensuring a safe space for advertisers. Contact us so that we can help your brand grow by achieving strong business results and reach out to the next billion internet users of India.</p>
        
        <h4 style="margin-bottom: 5px">Participate in 5km Partner Program</h4>
        
        <p>5km is India’s best platform for content creators to publish, share and monetize their content. We are open to all content creators. 5km invites all news reporters, journalists, influencers, bloggers, event promoters, PR professionals and movie critics to publish their content. Brands can also operate as a Page on the platform.
        
        5km offers view based monetization to verified content creators and is a superb opportunity for influencers and creators to generate side income. The partner program also presents an opportunity for individuals who have built or are a part of common interest groups and can share content i.e. make money by sharing content!
        
        All participating content creators have to complete identity verification only after which their content is made public. This ensures accountability from creators, authenticity of the content and makes a platform a safe place.</p>
        
        
      </div>
<!--SEO paragraph end-->
</div>
  <!--Skeleton-->
  <div class="posts-skeleton" style="position: relative;width: 100%;display: block;" *ngIf="is_content_loading">
     <div class="sk-search-bar mb-em-2"></div>
      <div class="sk-posts-category-outer-div mb-em-1" *ngFor="let i of skeleton_posts">
   
        <div class="sk-posts-category-div">

           <div class="sk-post-sample-1">
             <div class="sk-post-image mb-em-1"></div>
             <div class="d-flex align-center">
             <div class="sk-circle mr-px-10"></div>
             <div class="sk-rect-div">
               <div class="sk-sm-rect mb-px-10"></div>
               <div class="sk-sm-rect"></div>
             </div>
            </div>
           </div>
  
           <div class="sk-post-sample-2">
            <div class="sk-post-image mb-em-1"></div>
            <div class="d-flex align-center">
            <div class="sk-circle mr-px-10"></div>
            <div class="sk-rect-div">
              <div class="sk-sm-rect mb-px-10"></div>
              <div class="sk-sm-rect"></div>
            </div>
           </div>
          </div>
  
          <div class="sk-post-sample-3">
            <div class="sk-post-image mb-em-1"></div>
            <div class="d-flex align-center">
            <div class="sk-circle mr-px-10"></div>
            <div class="sk-rect-div">
              <div class="sk-sm-rect mb-px-10"></div>
              <div class="sk-sm-rect"></div>
            </div>
           </div>
          </div>

        </div>
      </div>
      </div>
      <!--Skeleton End-->
  </div>

  