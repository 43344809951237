import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { config } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  validatePhoneNumber(payload:any){
   return this.http.post(`${config.endpoint}mobile/user/check`,payload)
  }

  sendLoginOtp(payload:any){
    return this.http.post(`${config.endpoint}mobile/creator/sendotp`,payload)
  }

  verifyOtp(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/verifyotp`,payload)
  }

  verifyPassword(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/login`,payload)
  }

  resetPassword(payload:any){
    return this.http.post(`${config.endpoint}web/user/change_forgot_password`,payload)
  }

  register(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/register`,payload)
  }

  socialLogin(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/sociallogin`,payload)
  }

  //response is either true or false
    //response is either true or false
        //response is either true or false


  verifyOtpOnly(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/verifyotp_only`,payload)
  }

  changePassword(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/change_password`,payload)
  }
}
