import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { LikeReactionsComponent } from './like-reactions/like-reactions.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import {
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PollComponent } from './poll/poll.component';
import { AccountModule } from 'src/app/account/account.module';
import { ShareComponent } from './share/share.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NewPostComponent } from './new-post/new-post.component';
import { CartComponent } from './cart/cart.component';
import { CommonUtilityRoutingModule } from './common-utility-routing.module';
import { MyCartComponent } from './my-cart/my-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyCheckoutComponent } from './my-checkout/my-checkout.component';
import { SuccessComponent } from './success/success.component';
import { AddressListComponent } from './address-list/address-list.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { AgmCoreModule } from '@agm/core';
import { CartMinimumComponent } from './cart-minimum/cart-minimum.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ModalComponent } from './modal/modal.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { PostLocationComponent } from './post-location/post-location.component';
import { CommonSearchComponent } from './common-search/common-search.component';
import { LottieModule } from 'ngx-lottie';
import { PostThumbnailComponent } from './new-post/post-thumbnail/post-thumbnail.component';
import { ImageCropperModule } from 'ngx-image-cropper';
@NgModule({
  declarations: [
    NotificationComponent,
    NewPostComponent,
    LikeReactionsComponent,
    ProfileInfoComponent,
    VerifyOtpComponent,
    ChangePasswordComponent,
    PollComponent,
    ShareComponent,
    CartComponent,
    MyCartComponent,
    CheckoutComponent,
    MyCheckoutComponent,
    SuccessComponent,
    AddressListComponent,
    AddAddressComponent,
    CartMinimumComponent,
    AllOrdersComponent,
    OrderDetailComponent,
    ModalComponent,
    ComingSoonComponent,
    PostLocationComponent,
    CommonSearchComponent,
    PostThumbnailComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ShareButtonsModule,
    ShareIconsModule,
    LottieModule,
    CommonUtilityRoutingModule,
    AccountModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDFJN-QCh-tcHxufHpH3TU861N2ICVb9Gw',
      libraries: ['places'],
    }),
    ImageCropperModule,
  ],
  exports: [
    NotificationComponent,
    LikeReactionsComponent,
    CartMinimumComponent,
    ProfileInfoComponent,
    PollComponent,
    ShareComponent,
    NewPostComponent,
    CommonSearchComponent,
  ],
})
export class CommonUtilityModule {}
