import { NgModule } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { PostsPageComponent } from './posts-page/posts-page.component';
import { AdsPeoplePageComponent } from './ads-people-page/ads-people-page.component';
import { NewsHomeComponent } from './news-home/news-home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TopslidePageComponent } from './ads-page/topslide-page.component';
import { ShopHomeComponent } from '../shop/shop-home/shop-home.component';
import { AllPostsComponent } from './all-posts/all-posts.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
//import { OwlModule } from 'ngx-owl-carousel';
import { CommonUtilityModule } from '../common-utility/common-utility.module';
import { FollowersListComponent } from './followers-list/followers-list.component';
import { NgbCarouselModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccountModule } from 'src/app/account/account.module';
import { SinglePostComponent } from './single-post/single-post.component';
import { PostVideoPlayerComponent } from './post-video-player/post-video-player.component';
import { FeaturedStoriesComponent } from './featured-stories/featured-stories.component';
import { ShowReelComponent } from './show-reel/show-reel.component';
import { PollDetailComponent } from './poll-detail/poll-detail.component';
import { PostsListComponent } from './posts-list/posts-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HelpAndSupportComponent } from './help-and-support/help-and-support.component';
import { AdsenseModule } from 'ng2-adsense';
import { PostDetailSsrComponent } from './post-detail-ssr/post-detail-ssr.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { slicepipe } from './slice-pipe';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NewsTagsComponent } from './news-tags/news-tags.component';

@NgModule({
  declarations: [
    PostsPageComponent,
    AdsPeoplePageComponent,
    NewsHomeComponent,
    TopslidePageComponent,
    ShopHomeComponent,
    AllPostsComponent,
    FollowersListComponent,
    SinglePostComponent,
    PostVideoPlayerComponent,
    FeaturedStoriesComponent,
    ShowReelComponent,
    PollDetailComponent,
    PostsListComponent,
    HelpAndSupportComponent,
    PostDetailSsrComponent,
    NewHomeComponent,
    slicepipe,
    NewsTagsComponent,
  ],
  imports: [
    CommonModule,
    NewsRoutingModule,
    SharedModule,
    CarouselModule,
    YouTubePlayerModule,
    //  OwlModule,
    ReactiveFormsModule,
    FormsModule,
    CommonUtilityModule,
    NgbCarouselModule,
    NgbModalModule,
    AccountModule,
    InfiniteScrollModule,
    AdsenseModule,
  ],
  exports: [
    FollowersListComponent,
    PostVideoPlayerComponent,
    PostsListComponent,
    AllPostsComponent,
    TopslidePageComponent,
    FeaturedStoriesComponent,
  ],
})
export class NewsModule {}
