import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';
import {FormControl,FormBuilder,FormGroup, Validators} from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

  menu='profile';
  user:any= this.commonService.getLocalStorage('user');
  is_verified=false;
  user_extra:any= this.commonService.getLocalStorage('user_details_extra');
  profile_form!:FormGroup;

  name_edit:boolean= false;
  number_edit:boolean= false;
  email_edit:boolean= false;
  avatar_update:boolean=false;

  cur_gender_image:any;

  constructor(public ngbmodal:NgbModal, public commonService:CommonService, public formBuilder:FormBuilder, private authservice:AuthService) { }

  ngOnInit(): void {
    if(this.user_extra['is_verified']){
       this.is_verified= true;
    }    
    //this.getUserDetails();
    if(this.user){
       this.initProfileForm(this.user);
    }
  }

  initProfileForm(user:any){
     this.profile_form= this.formBuilder.group({
         firstname: [user.firstname,[Validators.required]],
         lastname: [user.lastname,[Validators.required]],
         email: [user.email],
         gender: [user.gender],
         dob: [user.dob],
         avatar: [user.avatar],
         phone_no: [user.phone_no],
         change_phone_no:[user.phone_no]
     })
 
     if(!user['gender']){
         this.cur_gender_image='';
     }
     else if(user['gender']=='female'){
      this.cur_gender_image='./../../../../assets/profile/male-icon.svg';
     }else if(user['gender']=='male'){
      this.cur_gender_image='./../../../../assets/profile/female-icon.svg';
     }else if(user['gender']=='other'){
      this.cur_gender_image='./../../../../assets/profile/other-icon.svg';
     }
  }

  reinitProfileForm(){
     this.initProfileForm(this.user);
     this.profile_form.markAllAsTouched();
  }

  reInit(){
    this.initProfileForm(this.user);
    this.avatar_update= false;
  }

  get form(){
    return this.profile_form.controls;
  }

  changeMenu(data:string){
     this.menu=data;
  }

    logout(){
      let token= this.commonService.getLocalStorage('ftoken');
      let a_token= this.commonService.getLocalStorage('token');
      if(token){
       this.commonService.updateToken({token:token,user_id:0}).subscribe((data:any)=>{
            console.log(data);
       })
      }
      this.commonService.deleteLocalStorage('user');
      this.commonService.deleteLocalStorage('token');
      this.commonService.deleteLocalStorage('language');
      this.commonService.deleteLocalStorage('final_posts');
      this.commonService.changeUserData();
      this.ngbmodal.dismissAll();
  }

  getUserDetails(){
     this.commonService.userDetails().subscribe((data:any)=>{
       if(data['status']=='success')
       this.user= data['data']['result'][0];
     })
  }

  updateUserDetails(payload:any){
   /* avatar: "https://ui-avatars.com/api/?rounded=true&background=random&name=Sachin +M Y"
    creator_id: -1
    dob: null
    email: "bulbandkey@gmail.com"
    firstname: "Sachin "
    gender: "male"
    lastname: "M Y"
    phone_no: "9686464589"
    token: "62270733d8e40dec0775f3ba"
  }*/
    this.commonService.updateUserProfile(payload).subscribe((data:any)=>{
          if(data['status']=='success'){
             let user=data['data']['result']['user'];
             this.user['creator_id']= user['creator_id'];
             this.user['avatar']= user['avatar'];
             this.user['dob']= user['dob'];
             this.user['firstname']= user['firstname'];
             this.user['lastname']= user['lastname'];
             this.user['gender']= user['gender'];
             this.user['phone_no']= user['phone_no'];

             this.reinitProfileForm();
             this.commonService.setLocalStorage('user', this.user);
             let u=this.commonService.getLocalStorage('user');
             this.commonService.changeUserData();
             this.profile_form.markAsUntouched();
             this.avatar_update= false;
          }
    })
  }

  cancelNameEdit(){
     this.form['firstname'].reset(this.user.firstname);
     this.form['lastname'].reset(this.user.lastname);
     this.name_edit=!this.name_edit;
  }

  cancelEmailEdit(){
    this.form['email'].reset(this.user.email);
    this.email_edit=!this.email_edit;
 }

  updateUserProfile(){
     let payload={
         firstname: this.form['firstname'].value,
         lastname: this.form['lastname'].value,
         email: this.form['email'].value,
         dob: new Date(this.form['dob'].value).toISOString(),
         avatar: this.form['avatar'].value,
         gender: this.form['gender'].value
     }
     this.updateUserDetails(payload);
  }

  genderEdit(){
     this.form['gender'].markAsTouched();
  }

  editDob(){
   this.form['dob'].markAsTouched();
}

  cancelGenderEdit(){
     this.form['gender'].reset(this.user.gender);
  }

  cancelDobEdit(){
   this.form['dob'].reset(this.user.dob);
}

  openOtpModal(modal:any){
      let payload = {
        phone_no: this.form['phone_no'].value
      }
      this.authservice.sendLoginOtp(payload).subscribe((data: any) => {
         if(data['status']=='success'){
           this.ngbmodal.open(modal,{size:'md',backdrop:'static'});
         }
      })
  }

  phoneNumberVerified(){
      this.form['phone_no'].setValue(this.form['change_phone_no'].value);
      this.number_edit=false;
  }

  fileSelected(event:any){
      if(event['target']['files'][0]){
          let image= event['target']['files'][0];
          if(image['name'].indexOf('jpeg')!==-1  || image['name'].indexOf('jpg')!==-1 || image['name'].indexOf('png')!==-1){
               
            this.commonService.uploadImage(image).subscribe((data:HttpEvent<any>)=>{
               if (data.type === HttpEventType.Response) {
                 if(data['body']['status']=='success'){
                      this.form['avatar'].setValue(data['body']['photo']['id']);
                      this.avatar_update= true;                
                   }
             }
         /*    if (data.type === HttpEventType.UploadProgress) {
                 const percentDone = Math.round(100 * data.loaded / data.total);
             } 
           //  this.image_link=[];
             */
           }) 

          }
      } 
  }

  openChangePasswordModal(modal:any){
    this.ngbmodal.open(modal,{size:'md',backdrop:'static'});
  }
}
