import { Component, OnInit, NgZone, ViewChild, ElementRef, Output,EventEmitter, ChangeDetectorRef} from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-post-location',
  templateUrl: './post-location.component.html',
  styleUrls: ['./post-location.component.scss']
})
export class PostLocationComponent implements OnInit {

  latitude:any;
  longitude:any;

  g_lat:any=0;
  g_lang:any=0;


  zoom=12;
  geoCoder:any;
  address:any;
  location:any;

  @Output() success=new EventEmitter();
  @Output() cancel=new EventEmitter();

  place:any='';

  @ViewChild('postsearch',{static:false,read:ElementRef}) public searchElementRef:any;

  constructor(public apiloader:MapsAPILoader, 
    private ngZone:NgZone, public commonService:CommonService, public cdr:ChangeDetectorRef) { 
    }

  type='select';
  searchterm='';
  
  ngOnInit(): void {


    this.apiloader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder;
    this.setCurrentLocation();
    this.userLocation({lat: this.latitude, lng: this.longitude});

    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
     autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //this.getAdressInfo([place]);
          //set latitude, longitude and zoomffffffffffffffffffff
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.g_lat= this.latitude;
          this.g_lang= this.longitude;
          this.zoom = 12;
        //  this.getAddress(this.latitude,this.longitude);
          this.place= `${place['name']},${place['formatted_address']}`;
          this.userLocation({lat: this.latitude, lng: this.longitude});
       });
      });
    });
    this.cdr.detectChanges();
  }
  
  userLocation(payload:any){
    this.commonService.getUserLocation(payload).subscribe((data:any)=>{         
          if(data['status']=='success'){
             if(payload['lat']!==this.g_lat){
              this.place= data['Result']['location'];
              this.searchterm= this.place;
           }
              this.location= {
                              location:data['Result']['location'],
                              lat: payload['lat'],
                              lng: payload['lng']
                             };
          }
    this.commonService.setLocalStorage('post_location',this.location);
    })
   }

   
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.userLocation({lat: this.latitude, lng: this.longitude});
        this.zoom = 12;
     //   this.getAddress(this.latitude,this.longitude);
      });
    }
  }

 
  markerDragEnd($event:any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.userLocation({lat: this.latitude, lng: this.longitude});
  }

  closeAddressModal()
  {
    this.cancel.emit();
  }

  addresstype:any='home';

  selectAddressType(type:any){
     this.addresstype= type;
  }

  addAddress(){
      this.success.emit(this.location)
  }

  closeModal(){
    this.cancel.emit();
  }
}

