import { Component, OnInit, Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input() url:any;
  @Input() type:any;
  sec_url:any;
  constructor(public dom:DomSanitizer) { }

  ngOnInit(): void {
    this.sec_url= this.dom.bypassSecurityTrustResourceUrl(this.url);

  }

}
