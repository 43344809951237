import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-interested-form',
  templateUrl: './interested-form.component.html',
  styleUrls: ['./interested-form.component.scss']
})
export class InterestedFormComponent implements OnInit {

  @Input() advertisement:any;
  @Output() close= new EventEmitter<any>();

  name:any;
  phone_no:any;

  step=1;

  invalid_name= true;
  invalid_phone_no= true;

  sec_url:any;

  constructor(public commonService:CommonService) { }  

  ngOnInit(): void {
   
  }

  closeModal(){
     this.close.emit();
  }

  nameTyped(){
    if(!this.name || !this.name.trim()){
       this.invalid_name= true;
    }else{
       this.invalid_name= false;
    }
  }

  phoneNoTyped(){
    if(isNaN(this.phone_no) || this.phone_no.trim().length!==10){
       this.invalid_phone_no= true;
    }else{
       this.invalid_phone_no= false;
    }
  }

  submitResponse(){
      let payload={
         submission:{name: this.name.trim(), phone_no: this.phone_no.trim()},
         adv_id: this.advertisement['adv_id']
      }
      this.commonService.submitResponse(payload).subscribe((data:any)=>{
          if(data['StatusCode']== 200){
              this.step=2;
          }
      })
  }
}
