export const config ={
    endpoint : "https://bulbandkey.com/gateway/sakal/",
  //  endpoint : "http://127.0.0.1:8033/gateway/sakal/",
    assetUrl : "https://content-cdn.bulbandkey.com",
    domain:"https://5km.city/",
  //  domain:"http://127.0.0.1:4200/",
}

export const assetsUrl = {
	url: 'https://s3.ap-south-1.amazonaws.com/bulbandkey-webapp/'
}

export const environment = {
  firebase: {
    apiKey: "AIzaSyDvToPXi50U--k24TsIubH_MvhAnLBNegw",
    authDomain: "km-production-a9806.firebaseapp.com",
    databaseURL: "https://km-production.firebaseio.com/",
   // databaseURL:"https://km-production-a9806-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "km-production-a9806",
    storageBucket: "km-production-a9806.appspot.com",
    messagingSenderId: "973155372103",
    appId: "1:973155372103:web:ed4082d68a14cbc4d47562",
    measurementId: "G-JB5H2QMJEX",
    vapidKey:'BJExO_q42S-9xp5eQgNxO4-rw_rYAF7_glMyKbe9DZTox8BY_qC5472tKzAlhQNP4luJe667Zmj9yMaKCcW6uCY'
  },
  production: true
};