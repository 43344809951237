import { Component, OnInit } from '@angular/core';
import {ReportService} from './.././../../services/report/report.service';

@Component({
  selector: 'app-jansevak-contest',
  templateUrl: './jansevak-contest.component.html',
  styleUrls: ['./jansevak-contest.component.scss']
})
export class JansevakContestComponent implements OnInit {

  contest_results=[];

  constructor(public service:ReportService) { }

  ngOnInit(): void {
    this.fetchResults();
  }

  fetchResults(){
    this.service.fetchJansevakContestResults().subscribe((data:any)=>{
      this.contest_results=data['Result'];
    })
 }
}
