import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss']
})
export class AllOrdersComponent implements OnInit {

  myorders:any;
  is_from_backend= false;
  skeleton_data:any=[1,2,3,4,5,6,7,8,9,10,11,12];

  selected_type='food';
  page=0;

  constructor(public commonService:CommonService, public modal:NgbModal, public router:Router) { }

  ngOnInit(): void {
    this.closeModal();
    this.getAllOrders(this.selected_type);
  }

  goToShop(){
    if(this.selected_type=='food'){
      this.router.navigate(['/food/home']);
    }else{
    this.router.navigate(['/shop/home']);
    }
  }

  getTotal(order:any){
      let total=0;
      if(order){
      if(parseInt(order['product'])){
        total= total+order['product'];
      }
      if(parseFloat(order['tax'])){
        total= total+order['tax'];
      }
      if(parseInt(order['shipping'])){
        total= total+order['shipping'];
      }
      if(order['fees']){
        total= total+order['fees'];
      }
      if(order['discount']){
        total= total-order['discount'];
      }
    }
      return total;
  }

  getAllOrders(type:any){
     let payload={
         page:this.page+1,
         type: this.selected_type
     }
     this.commonService.myOrders(payload).subscribe((data:any)=>{
        this.myorders= data['Result']['orders'];
        this.is_from_backend= true;
     })
  }

  toggleType(type:any){
     if(type!==this.selected_type){
        this.myorders= undefined;
        this.page= 0;
        this.selected_type= type;
        this.getAllOrders(this.selected_type);
     }
  }

  goToOrderDetail(id:number){
     this.router.navigate([`/user/order-detail/${id}`]);
  }

  closeModal(){
    this.modal.dismissAll();
  }

}
