
 <!--<div style="display: flex;margin-bottom: 1em;">
    <input type="text" class="form-control map-search" placeholder="Search Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" id="search1" #search>
    <button class="btn btn-sm btn-primary" (click)="confirm_location()">Search</button>
  </div> --> 

  <div style="position: relative;margin:20px 5px 0px;z-index: 999999999999999;">
     <input class="map-c-input" style="position: relative;" (keydown.enter)="$event.preventDefault()" placeholder="Search for a location" [(ngModel)]="searchterm" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"  #postsearch>
     <div style="position: absolute;top:8px;right:10px;" (click)="searchterm='';postsearch.click()"><img src="./../../../../assets/icons/close_black_24dp.svg" height="20" width="20"></div>
</div>

<agm-map [latitude]="latitude" [longitude]="longitude">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
    (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>

<div class="map-body"> 
   <div class="location-input">
      <label class="input-label">Your Location</label>
      <input class="input-no-border input-label" [value]="place" placeholder="Your location" disabled>
   </div>

    <div>
       <button class="no-btn blue-btn" style="margin-right: 20px;" (click)="confirmLocation()">Confirm and Proceed</button>
       <button class="no-btn cancel-btn" (click)="closeModal()">Cancel</button>
    </div>

</div>