import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { config } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class PollsService {

  constructor(private http:HttpClient) { }

  public getPollDetails(obj: { token: string | null; poll_id: number; action: string; }){
    return this.http.post(`${config.endpoint}mobile/quiz/getactivepoll`, obj);
  }

  public submitPollAnswer = (req_obj: { quiz_id: any; selected_option: any; token: string | null; }) => {
    return this.http.post(`${config.endpoint}mobile/quiz/submit_poll_answer`, req_obj)
  }

  public submitQuizAnswer = (req_obj: { quiz_id: any; selected_option: any; token: string | null; }) => {
    return this.http.post(`${config.endpoint}mobile/quiz/submit_answer`, req_obj)
  }

}
