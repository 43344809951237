import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { config } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor(private http:HttpClient) { }

  fetchNewsFeed(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/allposts`,payload);
  }

  fetchCommonNewsFeed(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/common/allposts`,payload);
  }

}
