<div class="verify-otp-main-div">
    <div class="heading-4 verify-otp-title">Change Mobile Number</div>

    <div class="vo-divider"></div>

<!--Enter OTP section-->
<div class="p-verify-otp-div">
    <div class="login-text-8 verify-otp-text">
       A 4-digit OTP has been sent to {{phone_no}} via SMS. <span class="text-login-blue text-underline cursor" style="white-space: nowrap;" (click)="changePhoneNumber()">Change Phone Number</span>
    </div>

    <div class="login-otp-text-div" style="position: relative;">

      <div class="otp-phone-no">
        <!-- <div [ngClass]="otp[0]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[0]?otp[0]:'x'}}</div>
         <div [ngClass]="otp[1]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[1]?otp[1]:'x'}}</div>
         <div [ngClass]="otp[2]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[2]?otp[2]:'x'}}</div>
         <div [ngClass]="otp[3]?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'">{{otp[3]?otp[3]:'x'}}</div>  -->

         <div><input placeholder="x" [ngClass]="otp1.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp1 id="otp-1" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(1,$event)" (focus)="otpInputFocus(1)"></div>
         <div><input placeholder="x" [ngClass]="otp2.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp2 id="otp-2" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(2,$event)" (focus)="otpInputFocus(2)"></div>
         <div><input placeholder="x" [ngClass]="otp3.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp3 id="otp-3" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(3,$event)" (focus)="otpInputFocus(3)"></div>
         <div><input placeholder="x" [ngClass]="otp4.value?(incorrect_otp?'otp-phone-no-digit-wrong':'otp-phone-no-digit-filled'):'otp-phone-no-digit'" type="number" #otp4  id="otp-4" oninput="javascript: if (this.value.length > 1) this.value = this.value.slice(0, 1);" maxlength="1" (keyup)="otpEntered(4,$event)" (focus)="otpInputFocus(4)"></div>

        </div>
      <p class="wrong-otp-text text-center" *ngIf="incorrect_otp">Incorrect OTP</p>
    </div>

    <div class="p-verify-btn-div">
      <button class="otp-btn login-text-9 verify-otp-text text-login-blue" [disabled]="!otp1.value || !otp2.value || !otp3.value || !otp4.value" (click)="verifyOtp(otp1.value,otp2.value,otp3.value,otp4.value)">Submit OTP</button>
    </div>
    <p class="otp-text verify-otp-text-2 text-center cursor" (click)="(remaining_min<0)?resendConfirmationCode():''">Resend Confirmation Code <span class="otp-timer" *ngIf="remaining_min>=0">(0{{remaining_min}}:{{remaining_sec<10?"0"+remaining_sec:remaining_sec}})</span></p>
 </div>

</div>