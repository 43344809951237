<div *ngIf="poll.image && poll?.status=='active'" class="poll_wrapper">

  <div *ngIf="poll.image" class="poll_wrapper_blurr"><img [src]="poll.image" alt=""> </div>
<!--<div *ngIf="poll.image" class="poll_wrapper_blurr"><img [src]="poll.image" alt=""> </div>-->
  <div style="z-index: 20;" *ngIf="(poll.item_type == 'quiz')" class="">
    <div *ngIf="(poll.type == 'quiz')" [ngStyle]="{'background': (poll.background)?poll.background: ''}"
      class="post_quiz">

    <!--   <img [src]="amz_img" alt="amz_img"> -->

           <img [src]="poll.image" alt="post_image">

      <div [ngClass]="{'center_poll': (poll.is_centered)}" id="poll_overlay" class="post_quiz_block animate__tada">
        <div class="post_quiz_block_question">
          {{poll?.question}}
        </div>
        <div class="post_quiz_block_answers">
          <div
            [ngClass]="{'post_quiz_block_answers_option_correct': (poll.is_answered && (options.text == poll.answer)), 'post_quiz_block_answers_option_wrong': (poll.is_answered && ((options.text != poll.answer) && (options.text == poll.selected_option)))}"
            [attr.id]="'poll'+poll.id+'_'+i" (click)="quizAnswerSelected(poll,options, i, loginData);" *ngFor="let options of poll.options; let i=index"
            class="post_quiz_block_answers_option">
            <div class="post_quiz_block_answers_option_index">
              <span style="line-height: 1;">{{options?.bullets}}</span>
              <img *ngIf="options.text == poll.answer" style="width: 55%;" src="assets/icons/quiz_correct.svg" alt="">
              <img *ngIf="options.text != poll.answer" style="width: 55%;" src="assets/icons/quiz_wrong.svg" alt="">
            </div>
            <div class="post_quiz_block_answers_option_label">
              {{options?.text}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(poll.type == 'poll' && (poll.options.length == 2))" class="post_quiz">
    <!--   <img [src]="amz_img" alt="poll_image"> -->
           <img [src]="poll.image" alt="poll_image">

      <div class="post_quiz_block poll_block">
        <div class="post_poll_block_question">
          {{poll?.question}}
        </div>
        <div class="post_poll_block_answer">
          <div class="post_division">
            <div
              [ngStyle]="{'width': option.d_percent, 'backgroundColor': (poll.is_answered && (option.text == poll.selected_option))? 'rgb(167 174 180 / 42%)':''}"
              [attr.id]="'poll'+poll.quiz_id+'_'+i" (click)="pollAnswerSelected(poll,option, i, loginData)" *ngFor="let option of poll.options;let i=index"
              class="post_poll_block_answer_option_value">
            </div>
          </div>
          <div [ngStyle]="{'height': (poll.is_answered && poll.long_answer) ? '70px': '50px'}"
            *ngFor="let option of poll.options" class="post_poll_block_answer_option">
            <span>{{option?.text}}</span>
            <span *ngIf="poll.is_answered">{{option?.d_percent}}</span>
          </div>
        </div>
      </div>
    </div>

      <!--Login modal start-->
      <ng-template #loginData let-modal>
        <div class="login-modal">
          <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
          <app-login (login_successfull)="modal.close();loginSuccessfull();" [is_modal]="true"></app-login>
        </div>
      </ng-template>
   <!--End login modal-->

    <div *ngIf="(poll.type == 'poll' && (poll.options.length > 2))"
      [ngStyle]="{'background': (poll.background)?poll.background: ''}" class="post_quiz">
    <!--   <img [src]="amz_img" alt="post_image">-->

           <img [src]="poll.image" alt="post_image">
      <div [ngClass]="{'center_poll': (poll.is_centered)}" id="poll_overlay" class="post_quiz_block animate__tada">
        <div class="post_quiz_block_question">
          {{poll?.question}}
        </div>
        <div class="post_quiz_block_answers">
          <!-- <div class="post_division multiple_option_poll">
              <div [ngStyle]="{'width': option.d_percent, 'backgroundColor': (post.is_answered && (option.text == post.selected_option))? 'rgb(167 174 180 / 42%)':''}" [attr.id]="'poll'+post.quiz_id+'_'+i" *ngFor="let option of post.options;let i=index"  class="post_poll_block_answer_option_value">
              </div>
            </div> -->
          <div (click)="pollAnswerSelected(poll,options, i, loginData)" *ngFor="let options of poll.options; let i=index"
            class="post_quiz_block_answers_option multiple_poll_options">
            <div [attr.id]="'poll'+poll.quiz_id+'_'+i"
              [ngStyle]="{'width': options.d_percent, 'backgroundColor': (poll.is_answered)? (options.text == poll.selected_option)? 'rgb(19 93 221 / 72%)':'rgb(167 174 180 / 42%)' : '' }"
              class="multiple_poll_selector">

            </div>
            <div class="post_quiz_block_answers_option_index">
              <span style="line-height: 1;">{{options?.bullets}}</span>
            </div>
            <div class="post_quiz_block_answers_option_label">
              {{options?.text}} <span *ngIf="poll.is_answered">- {{options?.d_percent}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="((!poll) || poll?.status=='expired') && !poll.expire_image" class="poll_wrapper">
<div class="no_results">
  <span class="no_results1">Poll has been removed or is expired</span>
</div>  
</div>

<div *ngIf="((!poll) || poll?.status=='expired') && poll.expire_image" class="poll_wrapper">

  <div class="poll_wrapper_blurr"><img [src]="poll.expire_image" alt=""> </div>

  <div style="z-index: 20;" class="">
      <div [ngStyle]="{'background': (poll.background)?poll.background: ''}" class="post_quiz">
        <img [src]="poll.expire_image" alt="post_image">
      </div>
  </div>
</div>

<div *ngIf="loading" class="poll_wrapper">
  <div class="no_results">
    <span>Fetching Poll Details...</span>
  </div>
</div>