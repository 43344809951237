<div class="only-mob-ad">

    <!--Advertisement div-->      
          
     <div *ngIf="billboard && billboard.length>0" style="margin-bottom:20px;">
       
              <owl-carousel-o [options]="customOptionsAdv" [id]="'billboard-1'" #owlBill>
                  <ng-template carouselSlide *ngFor="let adv of billboard;index as i">

                       <div class="billboard-image-div">
                      
                               <div class="post-image-content bg-grey" [ngStyle]="{'background-image': 'url('+adv+')'}"></div>
                          </div>

                  </ng-template>  
              </owl-carousel-o> 
      </div>
  
      <!--End Advertisement div-->


    <p class="topslide-text no-mobile">Sponsored</p>
    <!--Carousel-->

 <!--   <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel slide">

      <div class="carousel-inner">
        <div class="carousel-item active cursor" *ngFor="let b of banners">
          <img [src]="b.images[0]" class="d-block w-100" alt="...">
        
        </div>
      </div>-->
    <div class="topslide-page-card desktop-only-margin-top-20" *ngIf="topslides && topslides.length!==0">
      <ngb-carousel id="topslide-carousel" [showNavigationArrows]="topslides.legnth>1" [showNavigationIndicators]="topslides.length>1" style="max-width: 100%;position: relative;">
        <ng-template ngbSlide *ngFor="let b of topslides">
          <div class="picsum-img-wrapper topslide-page-image-div">
           <img class="topslide-page-image cursor" (click)="goToUrl(b,videoContent)" [src]="b.images[0]">
          <!--   <img class="topslide-page-image" [src]="amz_img">  -->
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    
    <div *ngIf="topslides && topslides.length==0">
         <div class="sk-topslide-page-adv-card"></div>
    </div>
      
  <!--       <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>

  <div class="carousel-indicators" style="position:fixed;bottom:-10px">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>   -->

      <!--Vimeo Player-->
    
   <ng-template #videoContent let-modal>
    <div class="d-flex flex-end" style="padding: 1em;justify-content: flex-end;">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-video-player [url]="cur_url" [type]="cur_video_type"></app-video-player>
  </div>
    </ng-template>
  
    <!--Vimeo Player end-->

    </div>

      