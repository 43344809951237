import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, Subject } from 'rxjs';
import { config } from '../../config/config';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  view_not = new Subject();
  move_to_top = new Subject();
  r_view_not = new Subject();
  user_data = new Subject();
  location_update = new Subject();
  menu_change = new Subject();
  detect_menu_change = new Subject();
  post_modal_open = new Subject();

  menu_hamburger = new Subject();
  page_change = new Subject();

  view = false;

  constructor(public http: HttpClient) {}

  toggleViewNotification(value: boolean) {
    this.view_not.next(value);
  }

  togglerViewNotification(value: boolean) {
    this.r_view_not.next(value);
  }

  moveToTop() {
    this.move_to_top.next(true);
  }

  setLocalStorage(field: any, data: any) {
    localStorage.setItem(field, JSON.stringify(data));
  }

  getIpAddress() {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  deleteLocalStorage(field: any) {
    localStorage.setItem(field, '');
    localStorage.removeItem(field);
  }

  openPostModal() {
    this.post_modal_open.next(true);
  }

  getLocalStorage(data: any) {
    let value = localStorage.getItem(data) ? localStorage.getItem(data) : '';
    if (value) {
      return JSON.parse(value);
    }
    return value;
  }

  menuChange(menu: string) {
    this.menu_change.next(menu);
  }

  updateMenuHamberger() {
    this.menu_hamburger.next(true);
  }

  changeUserData() {
    this.user_data.next(true);
  }

  locationUpdate() {
    this.location_update.next(true);
  }

 getNotificationList(payload:any){
  return this.http.post(`${config.endpoint}mobile/notification/list`,payload);
 }


 subscribeToTopics(payload:any){
   return this.http.post(`${config.endpoint}web/topics/subscribe`,payload);
 }

 updateUserProfile(payload:any){
    return this.http.post(`${config.endpoint}mobile/user/update`,payload);
 }

 createPage(payload:any){
   return this.http.post(`${config.endpoint}mobile/user_page/create`,payload);
 }

 getDynamicMenu(){
    return this.http.get(`${config.endpoint}tab/list`);
 }

 uploadImage(file: File, path?: string) {
  let payload = new FormData();
  payload.append('file', file);
  payload.append('filename', `${new Date()}.png`);
  payload.append('storage_url', path ?? '');

  return this.http.post(`${config.endpoint}addphoto`, payload, {
    reportProgress: true,
    observe: 'events',
  });
}
 uploadFileToSignedUrl(url:any,file:any){

  let f=file.split(',')[1];
  const req = new HttpRequest(
    'PUT',
    url,
    f,{reportProgress: true}
   );

  return this.http.request(req);
  /*
  return this.http.put(url,file,{
    reportProgress: true,
    observe: 'events'
});  */
}

  userDetails() {
    return this.http.get(`${config.endpoint}web/user/user_details`);
  }

  getSignedUrl(payload: { fileextention: String; modulename: String }) {
    return this.http.post(
      `${config.endpoint}mobile/media/getsigneduploadurl`,
      payload
    );
  }

  uploadImage64(photo: any) {
    return this.http.post(
      `https://bulbandkey.com/endpoint/pula/cake/addphotoraw/`,
      photo
    );
  }

  uploadVideo(video: any) {
    const subject = new Subject<number>();

    return this.http.post(`${config.endpoint}addvideo`, video, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getUserLocation(payload: any) {
    return this.http.post(`${config.endpoint}user/location`, payload);
  }

  userAddressList() {
    return this.http.get(`${config.endpoint}mobile/address/list`);
  }

  addUserAddress(payload: any) {
    return this.http.post(`${config.endpoint}mobile/address/add`, payload);
  }

  shipRates(payload: {
    creator_id: number;
    latitude: number;
    longitude: number;
    weight: number;
    pincode: number;
  }) {
    return this.http.post(`${config.endpoint}mobile/shop/shiprates`, payload);
  }

  shopCheckout(payload: {
    customer_firstname: string;
    customer_lastname: string;
    customer_phone: string;
    distance: string;
    drop_location: {};
    products: any;
    shipping_amount: number;
  }) {
    return this.http.post(`${config.endpoint}mobile/shop/checkout`, payload);
  }

  myOrders(payload: any) {
    return this.http.get(
      `${config.endpoint}mobile/customer/orders/list?page=${payload['page']}&type=${payload['type']}`
    );
  }

  orderDetails(id: number) {
    return this.http.get(
      `${config.endpoint}mobile/customer/orders/view?id=${id}`
    );
  }

  createPost(payload: any) {
    return this.http.post(`${config.endpoint}mobile/newspost/save`, payload);
  }

  getUserPages() {
    return this.http.get(`${config.endpoint}mobile/user_page/list`);
  }

 submitResponse(payload:any){
  return this.http.post(`${config.endpoint}mobile/newsadv/showinterest`,payload);
}

 /*hideMenu(){
  let menu= document.getElementById('desktopcommonmenu');
  let content= document.getElementById('contentarea');
  let maincontent= document.getElementById('maincontent');
  if(menu){
     menu.style.display='none';
  }
}*/

  saveTokenToServer(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/webdevice/attachtoken`,
      payload
    );
  }

  saveTokenToServerNoUser(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/webdevice/attachtokenwithoutuser`,
      payload
    );
  }

  updateToken(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/webdevice/updatetoken`,
      payload
    );
  }

  setFireToken(token: any, user: boolean) {
    this.setLocalStorage('ftoken', token);
    this.setLocalStorage('is_f_user', user);
  }

  getPostSearchResults(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/newspost/geolistelastic`,
      payload
    );
  }

  getShopSearchResults(payload: any) {
    return this.http.post(`${config.endpoint}mobile/shop/list`, payload);
  }

  hideMenu() {
    let menu = document.getElementById('desktopcommonmenu');
    let content = document.getElementById('contentarea');
    let maincontent = document.getElementById('maincontent');
    if (menu) {
      menu.style.display = 'none';
    }
    if (content) {
      content.style.padding = '20px 0px';
      //    content.style.backgroundColor="#E9E9E9";
    }
    if (maincontent) {
      maincontent.style.backgroundColor = '#F4F3F8';
      maincontent.style.columnGap = '0px';
    }
  }

  showMenu() {
    let menu = document.getElementById('desktopcommonmenu');
    let content = document.getElementById('contentarea');
    if (menu && window.innerWidth > 900) {
      menu.style.display = 'block';
    }
    if (content && window.innerWidth > 900) {
      content.style.padding = '10px 0px 10px 10px';
    } else if (content) {
      content.style.padding = '10px';
    }
    let maincontent = document.getElementById('maincontent');
    if (maincontent && window.innerWidth > 900) {
      maincontent.style.backgroundColor = 'white';
      maincontent.style.columnGap = '10px';
    } else if (maincontent) {
      maincontent.style.backgroundColor = 'white';
    }
  }

  changeUserPage() {
    this.page_change.next(true);
  }

logout(){
  this.setLocalStorage('is_f_user',false);
  this.deleteLocalStorage('user');
  this.deleteLocalStorage('token');
  this.deleteLocalStorage('user_id');
  this.deleteLocalStorage('language');
  this.deleteLocalStorage('final_posts');
  this.deleteLocalStorage('current_page');
  this.deleteLocalStorage('proxy_user_ids');
  this.deleteLocalStorage('user_details_extra');
  this.changeUserData();
 }

}
