import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-like-reactions',
  templateUrl: './like-reactions.component.html',
  styleUrls: ['./like-reactions.component.scss']
})
export class LikeReactionsComponent implements OnInit {

  @Output() liked= new EventEmitter<any>();

  private anim: any;
  private animationSpeed: number = 1;

  constructor() {
    
   }

   options1: AnimationOptions = {
    path: './../../../../assets/lottie-icons/Like (3).json',
  };

  options2: AnimationOptions = {
    path: './../../../../assets/lottie-icons/Heart (1).json',
  };

  options3: AnimationOptions = {
    path: './../../../../assets/lottie-icons/care.json',
  };

  options4: AnimationOptions = {
    path: './../../../../assets/lottie-icons/laugh (1).json',
  };

  options5: AnimationOptions = {
    path: './../../../../assets/lottie-icons/sad.json',
  };

  options6: AnimationOptions = {
    path: './../../../../assets/lottie-icons/angry.json',
  };

  animationCreated(animationItem: any): void {
    console.log(animationItem);
  }

  ngOnInit(): void {

  }

  like(emotion:any){
      this.liked.emit(emotion);
  }

  

}
