<ng-template #imageSelector>
    <div class="">
        <!-- <img class="ev-edit-icon" src="./../../../assets/profile/profile-info-edit.svg" alt=""
            (click)="fileInput.click()"> -->
        <div class="allImg">
            <div class="selectedImage" *ngFor="let url of imageUrls; let i = index">
                <img style=" justify-content: center;" id="previewImg" [src]="url" alt="5km-post-Image" width="158">
                <img (click)="deleteImg(i)" class="deleteImg" src="./../../../assets/icons/red-close-icon.svg" alt=""
                    width="18">
            </div>
        </div>
        <input type="file" id="fileInput" #fileInput (change)="handleFileInput($event)" hidden accept="image/*"
            multiple />

        <button (click)="fileInput.click()" id="addMoreImg" class="btn btn-secondary mt-2"><img
                src="../../../assets/news-thumbnail/add_photo_alternate_black.png" width="20" alt=""> Add More
            Photos</button>
    </div>
</ng-template>




<div class="event-post-main-div p-4">

    <div class="d-flex space-between align-center" *ngIf="step<4" style="margin-bottom: 30px;">
        <div class="d-flex align-center justify-center"><img style="margin-right: 10px;" class="job-post-icon-1 cursor"
                (click)="goBack()" src="./../../../assets/icons/back-button.svg">
            <h2 class="job-post-text-1" style="margin-bottom: 0px;">{{step<3?'Item Details':'Review Details'}}</h2>
        </div>
        <p class="job-post-text-2 job-post-color-1 desktop-only">step {{step}}/3</p>
    </div>
    <div class="job-post-close-icon" *ngIf="step==4" (click)="closeModal()">
        <img class="cursor job-post-close-icon-2" src="./../../../../assets/icons/close_black_24dp.svg">
    </div>
    <ng-container *ngIf="step==1">
        <ng-container *ngTemplateOutlet="imageSelector"></ng-container>

        <p class="ev-post-text1 mt-2">Tell us what you are selling?</p>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Item Name<strong class="text-c-warning">*</strong></p>
            <input placeholder="Text (Include item name or ADD title here)" [(ngModel)]="itemName" maxlength="80" max="80"
                appRemoveLeadingSpace>
        </div>
        <p class="ev-post-text1 mt-2">Description</p>

        <div class="job-post-input-3">
            <p style="margin-bottom: 8px;">Item Description
                <!-- <strong class="text-c-warning">*</strong> -->
            </p>
            <textarea rows="3" class="job-post-text-area"
                placeholder="Text (Include important details like - brand, size, quantity, color, warranty. delivery service, etc. to help people understand what you are selling)"
                [(ngModel)]="description" appRemoveLeadingSpace></textarea>
            <!-- <p class="text-c-warning" *ngIf="inv_description">Invalid input</p> -->
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Item Price<strong class="text-c-warning">*</strong></p>
            <input placeholder="Enter text (Eg: Rs. 500)" [(ngModel)]="itemPrice" appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Contact Number</p>
            <input id="cphno" placeholder="Enter number(Eg: 94862553634)" type="number" [(ngModel)]="contactno"
                (ngModelChange)="typingContactNo()" appRemoveLeadingSpace>
        </div>
        <p class="text-c-warning" *ngIf="inv_contact_no">Invalid number</p>

        <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="step=2"
            [disabled]="!itemName || inv_contact_no">Next</button>

    </ng-container>
    <ng-container *ngIf="step==2">
        <ng-container *ngTemplateOutlet="imageSelector"></ng-container>
        <h2 class="job-post-text-3 job-post-margin-2 mt-3">{{itemName}} for sale</h2>
        <div class="job-post-review-input-1" *ngIf="description">
            <div>
                <p class="job-post-text-5 job-post-color-1">Item Description</p>
                <p class="job-post-text-6">{{description}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="itemPrice">
            <div>
                <p class="job-post-text-5 job-post-color-1">Item Price</p>
                <p class="job-post-text-6">{{itemPrice}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="contactno">
            <div>
                <p class="job-post-text-5 job-post-color-1">Phone Number</p>
                <p class="job-post-text-6">{{contactno}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <button (click)="createSalePost()" class="btn no-btn job-post-btn-1 job-post-text-4" style="width:30%">Create
            POST</button>
    </ng-container>
    <ng-container *ngIf="step==3">
        <div class="job-post-status-div">
            <div>
                <div class="job-post-success-image-div">
                    <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
                </div>
                <h3 class="job-post-text-7 text-center" style="margin-bottom: 12px;">AD created successfully!
                </h3>
                <p class="job-post-text-8 job-post-color-1 text-center">Share it with people to create awareness</p>
            </div>

            <!--    <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="shareJobPost()">Share</button>-->

        </div>
    </ng-container>

</div>