import { DatePipe } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-event-post',
  templateUrl: './event-post.component.html',
  styleUrls: ['./event-post.component.scss'],
})
export class EventPostComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: any;
  imageChangedEvent: any = '../../../../../assets/post-img/event_post.webp';
  step = 1;
  eventType: string = 'Physical';
  eventName?: string;
  isEndDate: boolean = false;
  description?: string;
  // inv_description: boolean = false;
  contactno: any;
  inv_contact_no: boolean = false;
  contactemail: string = '';
  inv_email: boolean = false;
  link?: string;
  tag?: string;
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
  address?: string;
  price?: string;

  tags: string[] = ['Event'];
  finaldesc: string ="";
  latitude: any = 18.530823;
  longitude: any = 73.847466;
  postlocation: any = 'Pune, Maharashtra, India';

  post_pic: any =
    'https://dloef5z0shigg.cloudfront.net/fit-in/400x0/general/f51838e0-b359-11ed-9c9c-97d6053257b8.png';
  today: string | undefined;

  constructor(
    public commonService: CommonService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getLocation();
    const currentDate = new Date();
    this.today = currentDate.toISOString().substring(0, 10);
  }

  getLocation() {
    let val = this.commonService.getLocalStorage('post_location');
    if (val) {
      this.latitude = val['lat'];
      this.longitude = val['lng'];
      this.postlocation = val['location'];
    }
  }

  closeModal() {
    this.close.emit();
  }
  goBack() {
    if (this.step == 3) {
      this.closeModal();
    } else if (this.step > 1) {
      this.step--;
    } else {
      this.closeModal();
    }
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    console.log(event.target.files);
    if (event.target.files.length > 0) {
      this.imageChangedEvent = event.target.files[0];
    }
  }
  // typingDescription() {
  //   let str: string = this.description || '';
  //   if (str.trim() == '') {
  //     this.inv_description = true;
  //   } else {
  //     this.inv_description = false;
  //   }
  // }
  typingContactNo() {
    let str: string = `${this.contactno}`;
    if (this.contactno && str.length > 0 && str.length !== 10) {
      this.inv_contact_no = true;
    } else {
      this.inv_contact_no = false;
    }
  }

  typingEmail() {
    let str: string = this.contactemail;
    if (
      str.trim().length > 0 &&
      (str.indexOf('@') == -1 || str.indexOf('.') == -1)
    ) {
      this.inv_email = true;
    } else {
      this.inv_email = false;
    }
  }

  typingTag(event: any) {
    if (
      (event.keyCode == 13 || event.keyCode == 188) &&
      this.tag &&
      this.tag !== '' &&
      this.tag !== ','
    ) {
      this.tag = this.tag.trim();
      if (this.tag.indexOf(' ') !== -1) {
        this.tag = this.tag.split(' ').join('');
      }
      if (this.tag.indexOf(',') !== -1) {
        this.tag = this.tag.split(',')[0];
      }
      this.tags.push(this.tag);
      this.tag = '';
    }
  }

  deleteTag(tag: any) {
    this.tags = this.tags.filter((a: any) => a !== tag);
  }

  formateDate(date: Date) {
    const data = new Date(date);

    return this.datePipe.transform(data, 'EEE, MMM d, y');
  }

  formatTime(time: any) {
    const timeData = new Date(`2022-01-01T${time}:00`);
    return this.datePipe.transform(timeData, 'h:mm a');
  }

  preparDesc() {
    if (this.description) {
      this.finaldesc = `<p><strong>Description: </strong></p><p>${this.description.trim()}</p>`;
    }
    if (this.startDate) {
      let date = `<p><strong>Date: </strong> ${
        this.endDate
          ? this.formateDate(this.startDate) +
            ' To ' +
            this.formateDate(this.endDate)
          : this.formateDate(this.startDate)
      } </p>`;
      this.finaldesc = `${this.finaldesc} ${date}`;
    }
    if (this.startTime) {
      let date = `<p><strong>Time: </strong> ${
        this.endTime
          ? this.formatTime(this.startTime) +
            ' To ' +
            this.formatTime(this.endTime)
          : this.formatTime(this.startTime)
      } </p>`;
      this.finaldesc = `${this.finaldesc} ${date}`;
    }
    if (this.eventType) {
      this.finaldesc = `${this.finaldesc} <p><strong>Event Type: </strong>${this.eventType} </p>`;
    }
    if (this.contactno) {
      this.finaldesc = `${this.finaldesc} <p><strong>Phone number: </strong><a href="tel:${this.contactno}"> ${this.contactno}</a> </p>`;
    }
    if (this.contactemail) {
      this.finaldesc = `${this.finaldesc} <p><strong>Email: </strong><a href="mailto:${this.contactemail}">${this.contactemail} </a></p>`;
    }
    if (this.address) {
      this.finaldesc = `${this.finaldesc} <p><strong>Address: </strong>${this.address} </p>`;
    }
    if (this.link) {
      this.finaldesc = `${this.finaldesc} <p><strong>Link: </strong><a href=${this.link}>${this.link}</a> </p>`;
    }
    if (this.price) {
      this.finaldesc = `${this.finaldesc} <p><strong>Price: </strong>${this.price} </p>`;
    }
  }

  async filesSelected(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.upload(event.target.files[i]);
    }
  }

  upload(file: any) {
    this.commonService.uploadImage(file).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          this.post_pic = event.body.photo.id;
        }
      },
      error: (err: any) => {},
    });
  }

  createEventPost() {
    this.preparDesc();
    let page= this.commonService.getLocalStorage('current_page');
    let payload: any = {
      headline: this.eventName,
      story: this.finaldesc,
      images: [this.post_pic],
      videos: [],
      tags: this.tags,
      areas: [],
      latitude: this.latitude,
      longitude: this.longitude,
      location: this.postlocation,
      business: [],
      products: [],
    };
    console.log(payload);
    if(page && page['page_id']){
      payload['page_id']= page['page_id'];
    }
    this.commonService.createPost(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.step = 3;
        //  this.msg='Post Uploaded successfully';
      }
    });
  }
}
