import { Component, OnInit, NgZone, ViewChild, ElementRef, Output,EventEmitter} from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';

function numberStringValidator() {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== null &&
      (`${control.value}`.length!==10)
    ) {
      return { numberstringvalidator: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

 // latitude = 15.022729;
 // longitude= 75.156102;
 
 latitude : any;
 longitude:any;

 g_lat:any=0;
 g_lang:any=0;

 user_info:any;

  zoom=12;
  geoCoder:any;
  address:any;

  addressForm!:FormGroup;

  @Output() success=new EventEmitter();
  @Output() cancel=new EventEmitter();

  place:any='';

  @ViewChild('search',{static:false,read:ElementRef}) public searchElementRef:any;

  constructor(public apiloader:MapsAPILoader, public toastr:ToastrService, 
    private ngZone:NgZone, public commonService:CommonService, public formBuilder:FormBuilder) { }

  type='select';
  searchterm='';
  
  ngOnInit(): void {
    this.createAddAddressForm();
    this.getUserData();
    this.apiloader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      this.setCurrentLocation();


    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
     autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

        //  this.getAdressInfo([place]);
          //set latitude, longitude and zoomffffffffffffffffffff
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.g_lat= this.latitude;
          this.g_lang= this.longitude;
          this.zoom = 12;
        //  this.getAddress(this.latitude,this.longitude);
          this.area= `${place['name']},${place['formatted_address']}`;
          this.place= `${place['name']},${place['formatted_address']}`;
          this.userLocation({lat: this.latitude, lng: this.longitude});
       });
      });
    });
    
  }

  createAddAddressForm(){
      this.addressForm= this.formBuilder.group({
           flat_no:['',[Validators.required]],
           landmark: [''],
           firstname: ['',[Validators.required]],
           lastname: ['',[Validators.required]],
           phone_no: ['',[Validators.required,numberStringValidator()]]
      })
  } 

  fillAddressForm(user:any){
        this.addressForm.patchValue({
          firstname:user['firstname'],
          lastname:user['lastname'],
          phone_no:user['phone_no'],
        })
  }
  get form(){
    return this.addressForm.controls;
  }

  getUserData(){
    let user=this.commonService.getLocalStorage('user');
    if(user){
    this.user_info= user;
    this.fillAddressForm(this.user_info);
    this.firstname= this.user_info['firstname'];
    this.lastname= this.user_info['lastname'];
    this.phone_no= this.user_info['phone_no'];
    }
}

 changeAdd(){
    this.type='select';
 }

  address_obj:any;
   firstname:any;
   lastname:any;
   phone_no: any;
   landmark:any;
   flat_no: any;

  
  userLocation(payload:any){
    this.commonService.getUserLocation(payload).subscribe((data:any)=>{         
          if(data['status']=='success'){
             if(payload['lat']!==this.g_lat){
              this.place= data['Result']['location'];
              this.area=data['Result']['location'];;
              this.searchterm= this.place;
           }
             this.address_obj=data['Result']['location_obj'];
             this.zipcode= data['Result']['location_obj']['address']['postalCode'];
              let location= {
                              location:data['Result']['location'],
                              lat: payload['lat'],
                              lng: payload['lng']
                             };
          }
    })
   }

   

   formatAddressObj(address:any){
       let address_obj={
         firstname: this.form['firstname'].value,
         lastname: this.form['lastname'].value,
         phone_no: this.form['phone_no'].value,
         pincode: address['address']['postalCode'],
         street: address['address']['street'],
         state: address['address']['state'],
         flat_no: this.form['flat_no'].value,
         city: address['address']['city'],
         country: address['address']['countryName'],
         landmark: this.form['landmark'].value,
         latitude:this.latitude,
         longitude:this.longitude,
         address_type:'home',
         area: ''
       }
       if(this.area && this.area!=='undefined' && this.area.indexOf('undefined')==-1){
          address_obj['area']= this.area;
       }
       (address);

       return address_obj;
   } 

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.userLocation({lat: this.latitude, lng: this.longitude});
        this.zoom = 12;
     //   this.getAddress(this.latitude,this.longitude);
      });
    }
  }

 
  markerDragEnd($event:any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.userLocation({lat: this.latitude, lng: this.longitude});
  }
  
   zipcode:any;
   area:any;
   city:any;
   state:any;

    getAdressInfo(results:any) {
      for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results[i].address_components.length; j++) {
          for (let k = 0; k < results[i].address_components[j].types.length; k++) {
            if (results[i].address_components[j].types[k] == 'postal_code') {
              if (results[i].address_components[j].long_name) {
                // this.searchedAddress = this.searchTextBox.value;
                this.zipcode = results[i].address_components[j].long_name;
              }
            }
    
            if (results[i].address_components[j].types[k] == 'route' || results[i].address_components[j].types[k]=='sublocality') {
              if (results[i].address_components[j].long_name) {
                // this.searchedAddress = this.searchTextBox.value;
                this.area = results[i].address_components[j].long_name;
              }
            }
    
            if (results[i].address_components[j].types[k] == 'administrative_area_level_2') {
              if (results[i].address_components[j].long_name) {
                // this.searchedAddress = this.searchTextBox.value;
                this.city = results[i].address_components[j].long_name;
              }
            }
    
            if (results[i].address_components[j].types[k] == 'administrative_area_level_1') {
              if (results[i].address_components[j].long_name) {
                // this.searchedAddress = this.searchTextBox.value;
                this.state = results[i].address_components[j].long_name;
              }
            }
          }
        }
      }
      return this.zipcode;
    }
  
  getAddress(latitude:any, longitude:any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.getAdressInfo(results);
          this.zoom = 12;
          this.place = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    }
    )
  }

  addAddress(){
       if(this.type=='select' && this.place){
            this.type= 'edit';
       }else if(this.type=='edit'){
           if(this.addressForm.invalid){
             return; 
           }
           if(this.place && this.zipcode){
            /*  this.address={
                 firstname: this.firstname,
                 lastname: this.lastname,
                 phone_no: this.phone_no,
                 pincode: this.zipcode,
                 state: this.state,
                 flat_no: this.flat_no,
                 city: this.city,
                 landmark: this.landmark,
                 latitude:this.latitude,
                 longitude:this.longitude,
                 address_type:'home',
                 area: this.area   
              } */
              this.confirmLocation();
           }else{
            this.toastr.warning('Please select diffrent location');
           }
       }
  }

  confirmLocation(){
       let new_addr= this.formatAddressObj(this.address_obj);
      this.commonService.addUserAddress(new_addr).subscribe((data:any)=>{
          if(data['status']=='success'){
              this.success.emit(data);
          }
      })
  }

  closeModal(){
    this.cancel.emit();
  }
}
