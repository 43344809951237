

<div class="link-div">
  <div class="main-div">
    <div class="logo-pic-div">
      <img class="logo-pic" src="../../assets/3km-download-logo.png" alt="" srcset="">
    </div>
    <h5 class="km-text">@3km</h5>
    <div (click)="openLink('https://play.google.com/store/apps/details?id=com.bulbandkey.threekmseller&hl=en_IN&gl=US')" class="android-play-div">
      <p class="button-text">Android - Play Store</p>
    </div>
    <div (click)="openLink('https://apps.apple.com/in/app/3km/id1533435804')" class="ios-play-div">
      <p class="button-text">iOS - Apple App Store</p>
    </div>
  </div>
</div>
