import { Component, HostListener, Inject, OnDestroy, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../../core/services/common/common.service';
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

function numberValidator() {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== null &&
      (isNaN(control.value))
    ) {
      return { numberValidator: true };
    }
    return null;
  };
}

function numberStringValidator() {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== null &&
      (`${control.value}`.length!==10)
    ) {
      return { numberstringvalidator: true };
    }
    return null;
  };
}


function alphanumericValidator() {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== null &&
      (!(/[A-Za-z]+/.test(control.value)))
    ) {
      return { alphanumericValidator: true };
    }
    return null;
  };
}


function confirmPasswordValidator(password: string) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== null &&
      (control.value !== password)
    ) {
      return { passwordValidator: true };
    }
    return null;
  };
}

export const matchPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const name = control.get('password');
  const alterEgo = control.get('confirm_password');
  return name && alterEgo && name.value !== alterEgo.value ? { passwordMatch: true } : null;
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  loginForm: any;
  otpForm: any;
  passwordLoginForm: any;
  resetPasswordForm: any;
  changePasswordForm: any;
  signupForm: any;

  @Output() login_successfull= new EventEmitter<any>();
  @Input() is_modal:any;
  @Input() start_with:any;

  is_valid_number = true;
  is_pwd_valid_number = true;
  is_forgot_pwd_valid_number = true;

  current_login_state = "phone_no";
  return_url = this._route['snapshot']['queryParams']['return_url'];
  otp = "2121";
  incorrect_otp = false;

  remaining_min = 2;
  remaining_sec = 0;
  otp_timer: any;

  input_type = "password";
  change_input_type_1 = "password";
  change_input_type_2 = "password";
  change_input_type_3 = "password";
  change_input_type_4 = "password";

  user_info: any;
  invalid_message = false;
  did_check = false;

  loading = false;

  socialUser!: SocialUser;
  isLoggedin!: boolean;

  enableButtons=false;

  // params=JSON.parse(this._route['snapshot']['queryParams']['params']);

  /*
  @HostListener('window:keydown', ['$event'])
 
 
  handleKeyDown(event: KeyboardEvent) {
    if(this.incorrect_otp){
     this.incorrect_otp=false;
     this.otp="";
    }
    if(this.current_login_state=='otp' && this.otp.length<4 && (parseInt(event['key'])>=0 && parseInt(event['key'])<=9)){  
      this.otp=this.otp+event['key'];
    }else if(event['key']=='Backspace' || event['key']=='Delete'){
       this.otp=this.otp.slice(0,-1);
    }else if(event['key']=='Enter' && this.otp.length==4){
       this.verifyLoginOtp();
    } 
  }
 */
  constructor(public formBuilder: FormBuilder, public authservice: AuthService, public router: Router, public _route: ActivatedRoute,
    public commonService: CommonService, public socialAuthService: SocialAuthService, public ngbModal:NgbModal) {
    
  }

  ngOnInit(): void {
     if(!this.is_modal){
      this.hideMenu();
     }
     this.socialAuthService.initState.subscribe((data)=>{
        this.enableButtons= true;
     })
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedin = user != null;
      if(this.socialUser['response']){
          let payload={};
      if(this.socialUser['provider']=='GOOGLE'){
          let payload_c = {
             platform:'google-plus',
            platform_response: {
              platform: 'google-plus',
              user_id: this.socialUser['id'],
              accessToken: this.socialUser['response']['access_token'],
              email: this.socialUser['email'],
              displayName: this.socialUser['name'],
              givenName: this.socialUser['firstName'],
              familyName: this.socialUser['lastName']
              }
            } 
        /*
            let payload_c={
              platform_response:this.socialUser.response
           } */

            payload=payload_c;
        }else if(this.socialUser['provider']=='FACEBOOK'){
              let payload_c={
                 platform: 'facebook',
                 platform_response:this.socialUser.response
              }
              payload= payload_c;
        }

          console.log(payload);

          this.authservice.socialLogin(payload).subscribe((data:any) => {
            console.log(data);
            if(data['status']=='success' && data['data']['result']['user_data']){
              this.commonService.setLocalStorage('token', data['data']['result']['user_data']['token']);
              this.commonService.userDetails().subscribe((res:any)=>{
                this.commonService.setLocalStorage('user', res['data']['result']);
                this.commonService.setLocalStorage('user_details_extra',res['data']['result']);
                this.commonService.setLocalStorage('user_id', res['data']['result']['user_id']);
                let token= this.commonService.getLocalStorage('ftoken');
                if(token){
                 this.commonService.updateToken({token:token,user_id:res['data']['result']['user_id']})
                 this.commonService.setLocalStorage('is_f_user',true);
                }

                this.commonService.changeUserData();
                if(this.return_url){
                this.router.navigateByUrl(this.return_url);
                }
                if(this.is_modal){
                    this.login_successfull.emit(true);
                }
                this.ngbModal.dismissAll();
              })
            }
          })
        }
    });

    this.initializeLoginForm();
    this.initializeOtpForm();
    this.initializePasswordLoginForm();
    this.initializeResetPasswordForm();
    this.initializeChangePasswordForm();
    this.initializeSignupForm();
    if(this.start_with && this.start_with=='password'){
      this.goToLoginViaPassword();
   }
     this.isModal();

    this.form['phone_no'].valueChanges.subscribe((data: any) => {
      if (`${data}`.length == 10) {
        this.validatePhoneNumber();
      }
    })

    this.passwordloginform['phone_no'].valueChanges.subscribe((data: any) => {
      if (data.length == 10 && !isNaN(Number(data))) {

      }
    })

    this.resetpasswordform['phone_no'].valueChanges.subscribe((data: any) => {
      if (data.length == 10 && !isNaN(Number(data))) {
        this.validateForgotPasswordPhoneNumber();
      }
    })

   /* setTimeout(() => {
      this.moveToTop();
    }, 100)*/
  }

  initializeLoginForm() {
    this.loginForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), numberStringValidator()]]
    })
  }

  initializeOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, numberValidator(), Validators.maxLength(4), Validators.minLength(4)]]
    })
  }

  initializePasswordLoginForm() {
    this.passwordLoginForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, numberValidator(), Validators.maxLength(10), Validators.minLength(10),numberStringValidator()]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
  }

  initializeChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]]
    })
  }

  initializeSignupForm() {
    this.signupForm = this.formBuilder.group({
      firstname: ['', [Validators.required, alphanumericValidator()]],
      lastname: ['', [Validators.required, alphanumericValidator()]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]]
    }, {
      validators: matchPassword
    })
  }

  passwordMatchValidator(form: FormGroup) {
    return form.controls['password'].value == form.controls['confirm_password'].value ? null : { 'mismatch': true }
  }

  initializeResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      phone_no: ['', [Validators.required, numberValidator(), Validators.maxLength(10), Validators.minLength(10)]],
    })
  }

  moveToTop() {
    let top = document.getElementById('loginTop');
    top?.scrollIntoView({ behavior: 'smooth' })
  }

  isModal(){
     if(this.is_modal){
       let nomodal1= document.getElementById('nomodal1');
       let nomodal2= document.getElementById('nomodal2');
       let modallogin= document.getElementById('modallogin');
       let modalloginmobile= document.getElementById('modalloginmobilecard');
       let mobilehelpsupport= document.getElementById('mobilehelpsupport');

       if(nomodal1){
         nomodal1.style.display='none';
       }
       if(nomodal2){
        nomodal2.style.display= 'none';
       }
       if(modallogin){
          modallogin.classList.remove('login-main-div');
          modallogin.classList.add('modal-login-main-div');
       }
       if(modalloginmobile){
         modalloginmobile.style.boxShadow='none';
       }
       if(mobilehelpsupport){
          mobilehelpsupport.style.position='relative';
       }
     }
  }

  get form() {
    return this.loginForm.controls;
  }

  get otpform() {
    return this.otpForm.controls;
  }

  get passwordloginform() {
    return this.passwordLoginForm.controls;
  }

  get resetpasswordform() {
    return this.resetPasswordForm.controls;
  }

  get changepasswordform() {
    return this.changePasswordForm.controls;
  }

  get signupform() {
    return this.signupForm.controls;
  }

  validatePhoneNumber() {
    let payload = {
      phone_no: this.form.phone_no.value
    }
    this.authservice.validatePhoneNumber(payload).subscribe((data: any) => {
      this.did_check = true;
      this.is_valid_number = data['data']['result']['exist'] ? data['data']['result']['exist'] : false;
    /*  if(data['data']['result']['firstname'] && data['data']['result']['firstname']=='Temp'){
        this.is_valid_number= false;
      } */
      if (!this.is_valid_number) {
        this.checkInitOtpState();
      }
    })
  }

  validatePasswordPhoneNumber() {
    let payload = {
      phone_no: this.passwordloginform.phone_no.value
    }
    this.authservice.validatePhoneNumber(payload).subscribe((data: any) => {
      this.is_pwd_valid_number = data['data']['result']['exist'] ? data['data']['result']['exist'] : false;
    })
  }

  validateForgotPasswordPhoneNumber() {
    let payload = {
      phone_no: this.resetpasswordform.phone_no.value
    }
    this.authservice.validatePhoneNumber(payload).subscribe((data: any) => {
      this.is_forgot_pwd_valid_number = data['data']['result']['exist'] ? data['data']['result']['exist'] : false;
    })
  }



  changeInputType() {
    if (this.input_type == 'text') {
      this.input_type = 'password';
    } else {
      this.input_type = 'text';
    }
  }

  sendLoginOtp() {
    this.loading = true;
    let payload = {
      phone_no: this.form.phone_no.value
    }
    this.authservice.sendLoginOtp(payload).subscribe((data: any) => {
      this.checkInitOtpState();
      this.loading = false;
    })
  }

  checkInitOtpState() {
    this.initializeOtpForm();
    this.initOtpState();
  }

  sendPasswordLoginOtp() {
    this.form.phone_no.setValue(this.passwordloginform.phone_no.value);
    this.sendLoginOtp();
  }

  sendForgotPasswordOtp() {
    this.loading = true;
    let payload = {
      phone_no: this.resetpasswordform.phone_no.value
    }
    this.authservice.sendLoginOtp(payload).subscribe((data: any) => {
      this.current_login_state = 'reset_password_2';
      this.loading = false;
      this.startTimer();
    })
  }


  resendForgotPasswordOtp() {
    this.destroyTimer();
    this.loading = true;
    let payload = {
      phone_no: this.resetpasswordform.phone_no.value
    }
    this.authservice.sendLoginOtp(payload).subscribe((data: any) => {
      this.current_login_state = 'reset_password_2';
      this.loading = false;
      this.startTimer();
    })
  }

  initOtpState() {
    this.current_login_state = 'otp';
    this.remaining_min = 2;
    this.remaining_sec = 0;
    this.otp_timer = setInterval(() => {
      this.otpTimer()
    }, 1000);
  }

  verifyLoginOtp(o1: any, o2: any, o3: any, o4: any) {
    let payload = {
      otp: `${o1 + o2 + o3 + o4}`,
      phone_no: this.form.phone_no.value
    }

    this.authservice.verifyOtp(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        //  this.router.navigate([`${this.return_url?this.return_url:'news/home'}`]);
        this.incorrect_otp = false;
        this.user_info = data['data']['result'];
        if (!this.is_valid_number || data['data']['result']['firstname']=='Temp') {
          this.current_login_state = "signup_state";
        } else {
          this.commonService.setLocalStorage('user', data['data']['result']);
          this.commonService.setLocalStorage('token', data['data']['result']['token']);
          this.commonService.setLocalStorage('user_id', data['data']['result']['user_id']);
          let token= this.commonService.getLocalStorage('ftoken');
          if(token){
            this.commonService.updateToken({token:token,user_id:data['data']['result']['user_id']}).subscribe((data:any)=>{
               
            })
            this.commonService.setLocalStorage('is_f_user',true);
          }
          this.commonService.changeUserData();
          if(this.return_url){
          this.router.navigateByUrl(this.return_url);
          }
          if(this.is_modal){
            this.login_successfull.emit(true);
        }
          this.ngbModal.dismissAll();
        }
      } else {
        this.incorrect_otp = true;
      }
    })
  }

  verifyResetPasswordOtp(o1: any, o2: any, o3: any, o4: any) {
    let payload = {
      otp: `${o1 + o2 + o3 + o4}`,
      phone_no: this.resetpasswordform.phone_no.value
    }

    this.authservice.verifyOtp(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        this.incorrect_otp = false;
        this.user_info = data['data']['result'];
        //   this.commonService.setLocalStorage('user', data['data']['result']);
        this.current_login_state = 'reset_password_3';
        this.destroyTimer();
      } else {
        this.incorrect_otp = true;
      }
    })

  }

  verifyForgotPasswordOtp() {
    let payload = {
      otp: this.otp,
      phone_no: this.form.phone_no.value
    }

    this.authservice.verifyOtp(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        //  this.router.navigate([`${this.return_url?this.return_url:'news/home'}`]);
        this.commonService.setLocalStorage('user', data['data']['result']);
        this.commonService.changeUserData();
        this.incorrect_otp = false;
        this.router.navigateByUrl(this.return_url);
      } else {
        this.incorrect_otp = true;
      }
    })
  }

  goToLoginViaPassword() {
    if(this.form.phone_no.value){
      this.passwordloginform['phone_no'].setValue(this.form.phone_no.value);
    }
    this.current_login_state = 'password_login';
  }

  loginViaPassword() {
    let payload = {
      password: this.passwordloginform.password.value,
      phone_no: this.passwordloginform.phone_no.value,
    }

    this.authservice.verifyPassword(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        this.commonService.setLocalStorage('user', data['data']['result']);
        this.commonService.setLocalStorage('token', data['data']['result']['token']);
        this.commonService.setLocalStorage('user_id', data['data']['result']['user_id']);
        let token= this.commonService.getLocalStorage('ftoken');
        if(token){
         this.commonService.updateToken({token:token,user_id:data['data']['result']['user_id']}).subscribe((data:any)=>{
          
         })
         this.commonService.setLocalStorage('is_f_user',true);
        }
        this.commonService.changeUserData();
        if(this.return_url){
          this.router.navigateByUrl(this.return_url);
          }
          if(this.is_modal){
            this.login_successfull.emit(true);
        }
          this.ngbModal.dismissAll();      
        } else {
        this.showInvalidMessage();
      }
    })
  }

  showInvalidMessage() {
    this.invalid_message = true;
    setTimeout(() => {
      this.invalid_message = false;
    }, 5000)
  }

  passwordLogin() {
    let payload = {
      password: this.passwordloginform.password.value,
      phone_no: this.passwordloginform.phone_no.value,
    }

    this.authservice.verifyPassword(payload).subscribe((data: any) => {
      if (data['status'] == "success") {
        //   if(this.params){
        //             let obj={id:'8',name:'Trending',tags:[]}
        //   this.router.navigate([`${this.return_url}`],{queryParams:obj});
        //  this.router.navigate([`${this.return_url}`],{queryParamsHandling:'preserve'});

        //     }else{
        //      this.router.navigate(['/news/home']);
        //   }
        this.commonService.setLocalStorage('user', data['data']['result']);
        this.commonService.setLocalStorage('token', data['data']['result']['token']);
        this.commonService.setLocalStorage('user_id', data['data']['result']['user_id']);
        let token= this.commonService.getLocalStorage('ftoken');
        if(token){
         this.commonService.updateToken({token:token,user_id:data['data']['result']['user_id']})
         this.commonService.setLocalStorage('is_f_user',true);
        }
        this.commonService.changeUserData();
        if(this.return_url){
          this.router.navigateByUrl(this.return_url);
        }
        if(this.is_modal){
          this.login_successfull.emit(true);
      }
          this.ngbModal.dismissAll();
      }
    })

  }

  verifyOtp() {

  }

  changePhoneNumber() {
    this.current_login_state = 'phone_no';
    this.otp = "";
    this.incorrect_otp = false;
    this.destroyTimer();
  }

  resendConfirmationCode() {
    this.sendLoginOtp();
  }

  destroyTimer() {
    clearInterval(this.otp_timer);
  }

  otpTimer() {
    this.remaining_sec--;

    if (this.remaining_sec < 0) {
      this.remaining_sec = 59;
      this.remaining_min--;
    }
    if (this.remaining_min < 0) {
      this.destroyTimer();
      this.remaining_sec = 0;
    }
  }

  forgotPassword() {
    this.current_login_state = 'reset_password_1';
  }

  goBackToLogin() {
    this.did_check= false;
    this.current_login_state = "phone_no";
  }

  ngOnDestroy() {
    this.destroyTimer();
    if(!this.is_modal){
      this.showMenu();
    }
  }

  otp_array: any = [];

  otpEntered(i: any, event: any) {
    let p_input = <HTMLInputElement>document.getElementById(`otp-${i - 1}`);
    let input = <HTMLInputElement>document.getElementById(`otp-${i}`);
    let n_input = <HTMLInputElement>document.getElementById(`otp-${i + 1}`);

    if (event['keyCode'] == 8 || event['keyCode'] == 46 || event['key'] == 'Backspace' || event['key'] == 'Delete') {
      this.otpInputFocus(i - 1);
    }
    else if ((event['keyCode'] > 47 && event['keyCode'] < 58) || (parseInt(event['key']) >= 0 && parseInt(event['key']) <= 9) || event) {
      if (input.value && i < 4) {
        this.otpInputFocus(i + 1);
      }
    }
    else {
      input.value = '';
    }
  }

  otpInputFocus(i: any) {
    this.incorrect_otp = false;
    if (i > 0) {
      let input = <HTMLInputElement>document.getElementById(`otp-${i}`);
      input.focus();
    }
  }

  changeResetPasswordPhoneNumber() {
    this.current_login_state = 'reset_password_1';
  }

  startTimer() {
    this.remaining_min = 2;
    this.remaining_sec = 0;
    this.otp_timer = setInterval(() => {
      this.otpTimer()
    }, 1000);
  }

  changeResetPasswordInputType1() {
    if (this.change_input_type_1 == 'text') {
      this.change_input_type_1 = 'password';
    } else {
      this.change_input_type_1 = 'text';
    }
  }

  changeResetPasswordInputType2() {
    if (this.change_input_type_2 == 'text') {
      this.change_input_type_2 = 'password';
    } else {
      this.change_input_type_2 = 'text';
    }
  }

  changeResetPasswordInputType3() {
    if (this.change_input_type_3 == 'text') {
      this.change_input_type_3 = 'password';
    } else {
      this.change_input_type_3 = 'text';
    }
  }

  changeResetPasswordInputType4() {
    if (this.change_input_type_4 == 'text') {
      this.change_input_type_4 = 'password';
    } else {
      this.change_input_type_4 = 'text';
    }
  }

  goToLoginOtp() {
    this.current_login_state = "phone_no";
    if(this.passwordloginform['phone_no'].value){
      this.form['phone_no'].setValue(this.passwordloginform['phone_no'].value)
    }
  }

  changePassword() {
    let payload = {
      phone_no: this.user_info['phone_no'],
      password: this.changepasswordform.password.value
    }

    this.authservice.resetPassword(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.passwordloginform.phone_no.setValue(data['data']['result']['user']['phone_no']);
        this.current_login_state = 'password_login';
      }
    })
  }

  signup() {
    let payload = {
      phone_no: this.form.phone_no.value,
      firstname: this.signupform.firstname.value,
      lastname: this.signupform.lastname.value,
      password: this.signupform.password.value
    }

    this.authservice.register(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.user_info = data['data']['result'];
        this.is_valid_number = true;
        this.is_pwd_valid_number = true;
        //   this.commonService.setLocalStorage('user',data['data']['result']);
        this.passwordloginform.phone_no.setValue(data['data']['result']['phone_no']);
        this.form.phone_no.setValue(data['data']['result']['phone_no']);
        this.current_login_state = 'password_login';
        //    this.current_login_state="phone_no";
      }
    })
  }

  hideMenu(){
    this.commonService.hideMenu();
 }

 showMenu(){
   this.commonService.showMenu();
}

  socialloginstatus: any;

  loginWithGoogle(): void {
    let a: any = this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithFacebook(): void {
    let a:any = this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  logOut(): void {
    this.socialAuthService.signOut();
  }
}
