import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-janasevak-individual-result',
  templateUrl: './janasevak-individual-result.component.html',
  styleUrls: ['./janasevak-individual-result.component.scss']
})
export class JanasevakIndividualResultComponent implements OnInit {

  constructor(private resportService: ReportService, private route:ActivatedRoute) { }

  _id:any;
  value:any;
  categoryData:any ;

  ngOnInit(): void {
     this.route.queryParams.subscribe((params)=>{
      this._id = params['id'];
    });
    this.fetchContestantDetails();
  }

  fetchContestantDetails(){
    let payload = {
      quiz_id : this._id
    }
    
    this.resportService.fetchJansevakContestCategoryResults(payload).subscribe((result:any)=> {
      
        // this.categoryData = result['Result']['contest_results'][0]['contestants'][1]['name'];
        this.categoryData = result['Result']['contest_results'][0];
        // this.categoryData = result['Result']
        
      })
  }

}
