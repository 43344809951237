
 <!--<div style="display: flex;margin-bottom: 1em;">
    <input type="text" class="form-control map-search" placeholder="Search Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" id="search1" #search>
    <button class="btn btn-sm btn-primary" (click)="confirm_location()">Search</button>
  </div> --> 
<div>

  <div class="d-flex align-center map-search-bar" [ngStyle]="{'display':type=='select'?'block':'none'}">
    <input class="form-control" [ngStyle]="{'display':type=='select'?'block':'none'}" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" [(ngModel)]="searchterm" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search><i class="fa fa-times-circle red-font ml-2" [ngStyle]="{'display':type=='select'?'block':'none'}" (click)="searchterm=''" style="font-size: 24px;"></i>
 </div>

<div *ngIf="type=='select'">

  <agm-map [latitude]="latitude" [longitude]="longitude">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
    (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>

</div>
<div class="map-body" *ngIf="type=='edit'" style="padding-bottom: 20px;"> 
   <!--<div class="location-input">
      <label class="input-label">Your Location</label>
      <input class="input-no-border input-label" [value]="place" placeholder="Your location" disabled>
   </div>-->
   
   <div class="checkout-font-2 mb-px-20" *ngIf="place">{{this.place}}<span class="blue ml-2 poppins-light checkout-font-3 text-underline cursor" (click)="changeAdd()">Change</span></div>
<form [formGroup]="addressForm">
   <div class="mt-px-10">
       <label>Flat No./Floor/Building No</label>
       <input class="form-control" type="text" formControlName="flat_no">
       <div class="margin-top-10" *ngIf="form['flat_no'].errors">
        <p class="text-danger" *ngIf="form['flat_no'].errors['required']">This field is required</p>
      </div>
   </div>

   <div class="mt-px-20">
    <label>Landmark</label>
    <input class="form-control" type="text" formControlName="landmark">
</div>

<div class="mt-px-20">
    <label>Firstname</label>
    <input class="form-control" type="text" formControlName="firstname">
    <div class="margin-top-10" *ngIf="form['firstname'].errors">
        <p class="text-danger" *ngIf="form['firstname'].errors['required']">This field is required</p>
      </div>
</div>


<div class="mt-px-20">
    <label>Lastname</label>
    <input class="form-control" type="text" formControlName="lastname">
    <div class="margin-top-10" *ngIf="form['lastname'].errors">
        <p class="text-danger" *ngIf="form['lastname'].errors['required']">This field is required</p>
      </div>
</div>


<div class="mt-px-20 mb-px-20">
    <label>Phone No.</label>
    <input class="form-control" type="number" formControlName="phone_no">
    <div class="margin-top-10" *ngIf="form['phone_no'].errors">
        <p class="text-danger" *ngIf="form['phone_no'].errors['required']">This field is required</p>
        <p class="text-danger" *ngIf="!form['phone_no'].errors['required']">Enter valid phone number</p>
    </div>
</div>


</form>
  <!--  <div class="mt-px-20">
       <button class="no-btn blue-btn" style="margin-right: 20px;" (click)="confirmLocation()">Confirm and Proceed</button>
       <button class="no-btn cancel-btn" (click)="closeModal()">Cancel</button>
    </div>-->
 </div>
 <div class="category-cart-icon">
    <!--<app-cart-minimum [count]="input_count"></app-cart-minimum>-->
    <button class="btn-blue-1 poppins-medium checkout-font-2 addr-add-btn"  (click)="addAddress()">Continue</button>
</div>
</div>