import { Component, OnInit, Input } from '@angular/core';
import { debounce, debounceTime, Subject } from 'rxjs';
import { CommonService } from '../../../core/services/common/common.service';
import * as urlSlug from 'url-slug';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrls: ['./common-search.component.scss']
})
export class CommonSearchComponent implements OnInit {

  placeholder= 'search';
  @Input() type:any;
  term:any;
  term_change= new Subject<string>();

  search_results:any=[];
  shop_results:any=[];

  posts_loading= false;
  products_loading= false;

  @Input() searchtopic:any;
  skeleton_data:any=[1,2,3,4,5,6,7,8,9,10,11,12];

  location=this.commonService.getLocalStorage('location');

  constructor(public commonService:CommonService, public router:Router, public modal:NgbModal) { }

  ngOnInit(): void {
     this.term_change.pipe(debounceTime(600)).subscribe((data:any)=>{
        this.getNewsResults();
        this.getShopResults();
     })
  }

  getNewsResults(){

    this.posts_loading= true;

    let user= this.commonService.getLocalStorage('token');

    let payload={
       query: this.term,
       token: user?user:'',
       lat: this.location['lat'],
       lng: this.location['lng']
    }

    this.commonService.getPostSearchResults(payload).subscribe((data:any)=>{
       this.search_results= data['data']['result']['posts'];
       if(this.search_results.length==0){
         this.post_msg= 'No search results found';
       }
       this.posts_loading= false;
    })
    
  }

  post_msg='Search posts/products';
  product_msg='Search products/posts';

  termChange(event:any){
    if(this.term && this.term!==''){
    this.term_change.next('');
    }else{
      this.shop_results=[];
      this.search_results=[];
      this.post_msg= 'Search posts/products';
      this.product_msg='Search products/posts';
    }
  }

  getShopResults(){
    this.products_loading= true;
    let user= this.commonService.getLocalStorage('token');

    let payload={
       query: this.term,
       token: user?user:'',
       lat: this.location['lat'],
       lng: this.location['lng']
    }

    this.commonService.getShopSearchResults(payload).subscribe((data:any)=>{
       this.shop_results= data['Result']['products'];
       if(this.shop_results.length==0){
         this.product_msg='No search results found';
       }
       this.products_loading= false;
    })
  }

  closeModal(){
     this.modal.dismissAll();
  }

  viewPostDetail(id:number,index:number){

    let post=this.search_results;
    let url= this.urlSlugConverter(post['slug_headline']);
    if(!url || url=='undefined'){
    //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
    //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
        url='3-km-post-detail' 
   }
  //  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);
    this.router.navigate(['/post-detail',url,`${id}`]);
    this.modal.dismissAll();
 }
 
   urlSlugConverter(headline:string){
    let url= urlSlug.convert(headline,
    {camelCase: false});
    return url;
 }
 
}
