import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from,Subject} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class HttpCoreInterceptor implements HttpInterceptor {

  constructor(public router:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   /* let loader= document.getElementById('primeloader');
    if(loader){
      loader.style.display='block';
    }*/
  //  return next.handle(request);
    let user= localStorage.getItem('token');
    let token="";
    if(user){
      token= JSON.parse(user);
    }
  //  let token="61f7929193246b9ca631e625";
    if(request.url.indexOf('https://bulbandkey.com/gateway/')!==-1){
        request = request.clone({ headers: request.headers.set('Authorization',token) });
    }


    return next.handle(request)
    /*.pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        let loader= document.getElementById('primeloader');
        if(loader){
          loader.style.display='none';
        }      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      let loader= document.getElementById('primeloader');
      if(loader){
        loader.style.display='none';
      }
            return throwError(()=>new Error(error.toString()));
    }))*/
  }
}
