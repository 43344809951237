import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @Input() url?:string;
  @Input() image?:string;

  @Output() share_success= new EventEmitter<any>();
  
  show:number=11;
  constructor() { }

  ngOnInit(): void {
  }

  closed(event:any){
  }

  popupClosed(event:any){
  }

  opened(event:any){
    this.share_success.emit(true);
  }

}
