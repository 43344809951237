<div class="common-search-main-div">
<div class="common-search-back-div">
    <img class="cursor" src="./../../../../assets/icons/new_back_icon.svg" (click)="closeModal()"> 
    <div class="common-search-input-wrapper">
        <div class="search-div c-search-div">
            <img class="custom-search-icon" src="./../../../assets/icons/search-24px.png">
            <input type="search" class="custom-search-bar" id="c-search" [placeholder]="searchtopic?searchtopic:placeholder" [(ngModel)]="term" (ngModelChange)="termChange($event)"> 
        </div>
    </div>
</div>


    <div class="common-search-results-div">
           <div class="common-search-menu-div">
               <div class="c-font-1 cursor" [ngClass]="{'c-search-active-border': type=='news'}" (click)="type='news'">News</div>
               <div class="c-font-1 cursor" [ngClass]="{'c-search-active-border': type=='shop'}" (click)="type='shop'">Shop</div>
            <!--   <div class="posts-list-font-2 cursor" [ngClass]="{'c-search-active-border': type=='biz'}" (click)="type='biz'">Restaurant</div> -->
         </div>
         <div class="c-search-inactive-border"></div> 

           <div *ngIf="type=='news'">
            <ng-container *ngIf="search_results.length>0 && !posts_loading">
              <div class="common-search-post-card cursor" (click)="viewPostDetail(result['post_id'],i)" *ngFor="let result of search_results;index as i;">
                   <div class="common-search-post-card-image" [ngStyle]="{'background-image':'url('+result.images[0]+')'}">
                      <div class="post-title-text common-search-post-card-timestamp">{{result?.created_date}}</div>
                   </div>
                   <div class="common-search-post-card-desc">
                       <div class="common-search-post-card-title post-title-text">{{result?.headline}}</div>
                       <div class="common-search-post-card-story post-title-text" [innerHtml]="result?.story"></div>
                   </div>
               </div>
            </ng-container> 
               
               <div class="d-flex align-center justify-center" *ngIf="search_results.length<1 && !posts_loading" style="min-height: 400px;">
                      <div class="posts-list-font-2">{{post_msg}}</div>
               </div>


                 <!--Orders skeleton-->
<div *ngIf="posts_loading">
    <div class="order-sk-card" style="max-height: 80px;" *ngFor="let card of skeleton_data">
      <div class="order-sk-card-top" style="max-height: 20px;">
   
      </div>
   
      <div class="order-sk-card-detail" style="max-height: 60px;">
        
     </div>
   
    </div>
   </div>
   <!--Orders skeleton end -->
           </div>


           <div *ngIf="type=='shop'">

            <ng-container *ngIf="shop_results.length>0 && !products_loading">
            <div class="common-search-post-card cursor" [routerLink]="['/shop/product/detail/'+result.catalog_id]" (click)="closeModal()" *ngFor="let result of shop_results">
                <div class="common-search-post-card-image" [ngStyle]="{'background-image':'url('+result.image+')'}">
                </div>
                <div class="common-search-post-card-desc">
                    <div class="common-search-post-card-title post-title-text">{{result?.name}}</div>
                    <div class="common-search-post-card-story post-title-text" [innerHtml]="result?.business_name"></div>
                    <div class="post-title-text blue" style="margin-top: 10px;color: #3E7EFF"><i class="fa fa-rupee blue mr-1"></i>{{result?.price}}</div>
                </div>
            </div>
            </ng-container>

            <div class="d-flex align-center justify-center" *ngIf="shop_results.length<1 && !products_loading" style="min-height: 400px;">
                <div class="posts-list-font-2">{{product_msg}}</div>
         </div>


           <!--Orders skeleton-->
<div *ngIf="products_loading">
<div class="order-sk-card" style="max-height: 80px;" *ngFor="let card of skeleton_data">
<div class="order-sk-card-top" style="max-height: 20px;">

</div>

<div class="order-sk-card-detail" style="max-height: 60px;">
  
</div>

</div>
</div>
<!--Orders skeleton end -->

        </div>


    </div>
</div>