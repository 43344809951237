import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  @Input() id:any;
  constructor(public router:Router, public modal:NgbModal) { }

  ngOnInit(): void {
  }

  goToAllOrders(){
   // this.router.navigate(['/user/orders']);
  }

  goToOrderDetail(){
     if(this.id){
     this.modal.dismissAll();
     this.router.navigate([`/user/order-detail/${this.id}`]);
     } 
  }

}
