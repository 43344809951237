
 <div class="job-post-main-div">
    <div class="d-flex space-between align-center" *ngIf="step<4" style="margin-bottom: 30px;"><div class="d-flex align-center justify-center"><img style="margin-right: 10px;" class="job-post-icon-1 cursor" (click)="goBack()" src="./../../../assets/icons/back-button.svg"><h2 class="job-post-text-1" style="margin-bottom: 0px;">{{step<3?'Create job post':'Review job post'}}</h2></div><p class="job-post-text-2 job-post-color-1 desktop-only">step {{step}}/3</p></div>
    <div class="job-post-close-icon" *ngIf="step==4" (click)="closeModal()">
        <img class="cursor job-post-close-icon-2" src="./../../../../assets/icons/close_black_24dp.svg">
    </div>
    <ng-container *ngIf="step==1">
    <h2 class="job-post-text-3 job-post-margin-2">Tell us who you are hiring</h2>

    <div class="job-post-input-1">
          <p style="margin-bottom: 8px;">Job Role<strong class="text-c-warning">*</strong></p>
          <input placeholder="Enter text(Eg: sales manager)" [(ngModel)]="role" (ngModelChange)="typingRole()">
    </div>
    <p class="text-c-warning" *ngIf="inv_role">Invalid role</p>

    <div class="job-post-input-1">
        <p style="margin-bottom: 8px;">Job Location<strong class="text-c-warning">*</strong></p>
        <input placeholder="Enter text(Eg: Pune)" [(ngModel)]="location" (ngModelChange)="typingLocation()">
    </div>
    <p class="text-c-warning" *ngIf="inv_location">Invalid location</p>

    <div class="job-post-input-2">
        <p class="job-post-text-4">Type of work<strong class="text-c-warning">*</strong></p>
        <div class="type-of-work-div">
        <div><input style="margin-right: 8px;" type="radio" name="worktype" value="Office Job" id="officejob" [(ngModel)]="worktype" (change)="inv_work=false"> <label for="officejob"> Office job</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="worktype" value="Field Job" id="fieldjob" [(ngModel)]="worktype" (change)="inv_work=false"> <label for="fieldjob"> Field Job</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="worktype" value="Hybrid Job" id="hybridjob" [(ngModel)]="worktype" (change)="inv_work=false"> <label for="hybridjob"> Hybrid Job</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="worktype" value="Work from Home" id="workfromhome" [(ngModel)]="worktype" (change)="inv_work=false"> <label for="workfromhome"> Work from Home</label></div>
        </div>
    </div>
    <p class="text-c-warning" *ngIf="inv_work">Select type of work</p>

    <div class="job-post-input-2">
       <p class="job-post-text-4">Job Type<strong class="text-c-warning">*</strong></p>
       <div class="type-of-work-div">
        <div><input style="margin-right: 8px;" type="radio" name="jobtype" value="Full-Time" id="fulltime" [(ngModel)]="jobtype" (change)="inv_job=false"> <label for="fulltime"> Full-Time</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="jobtype" value="Part-Time" id="parttime" [(ngModel)]="jobtype" (change)="inv_job=false"> <label for="parttime"> Part-Time</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="jobtype" value="Contract" id="contract" [(ngModel)]="jobtype" (change)="inv_job=false"> <label for="contract"> Contract</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="jobtype" value="Internship" id="internship" [(ngModel)]="jobtype" (change)="inv_job=false"> <label for="internship"> Internship</label></div>
        <div><input style="margin-right: 8px;" type="radio" name="jobtype" value="Intern-to-Hire" id="interntohire" [(ngModel)]="jobtype" (change)="inv_job=false"> <label for="interntohire"> Intern-to-Hire</label></div>
       </div>
    </div>
    <p class="text-c-warning" *ngIf="inv_job">Select job type</p>


    <div class="job-post-input-3">
        <p style="margin-bottom: 8px;">Job Description<strong class="text-c-warning">*</strong></p>
        <textarea class="job-post-text-area" rows="6" placeholder="Enter Text (Eg: daily tasks, duties, roles and responsibilities, company’s vision etc.)" [(ngModel)]="description" (ngModelChange)="typingDescription()"></textarea>
        <p class="text-c-warning" *ngIf="inv_description">Invalid input</p>
    </div>

    <p class="job-post-text-4">Monthly Salary</p>
    <div class="job-post-input-4">
        <input placeholder="Enter Text (Eg: fixed salary or ₹ 5000 to ₹10,000)" [(ngModel)]="salary">
    </div>
    <p class="text-c-warning" *ngIf="inv_salary">Invalid salary</p>

    <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="step=2;" [disabled]="inv_role || inv_location || inv_job || inv_work || inv_description || !description || !location || !role">Next</button>
    </ng-container>

    <ng-container *ngIf="step==2">
        <p class="job-post-text-4 job-post-margin-1">Candidate Requirement</p>

        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Years of Experience<strong class="text-c-warning">*</strong></p>
            <select id="experience" name="experience" [(ngModel)]="experience" (ngModelChange)="inv_experience=false;">
                <option disabled selected hidden>Select</option>
                <option id="option">Fresher</option>
                <option>0-1 Years</option>
                <option>1-3 Years</option>
                <option>3-5 Years</option>
                <option>5-10 Years</option>
                <option>10-15 Years</option>
                <option>>15 Years</option>
                <option>Any Experience</option>
            </select>
        </div>
        <p class="text-c-warning" *ngIf="inv_experience">Select experience</p>


        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Educational Qualification<strong class="text-c-warning">*</strong></p>
            <select id="qualification" name="qualification" [(ngModel)]="qualification" (ngModelChange)="inv_qualification=false;">
                <option disabled selected hidden>Select</option>
                <option>10th</option>
                <option>12th</option>
                <option>Diploma/Certification</option>
                <option>Graduate</option>
                <option>Post Graduate</option>
                <option>Not Required</option>
            </select>        
        </div>
        <p class="text-c-warning" *ngIf="inv_qualification">Select qualification</p>


        <p class="job-post-text-4 job-post-margin-1">Company Details</p>

        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Company Name</p>
            <input placeholder="Enter text(Eg: 5km)" [(ngModel)]="companyname" (ngModelChange)="typingCompanyName()">
        </div>
        <p class="text-c-warning" *ngIf="inv_company_name">Invalid name</p>

        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Contact Number</p>
            <input id="cphno" placeholder="Enter number(Eg: 94862553634)" type="number" [(ngModel)]="contactno" (ngModelChange)="typingContactNo()">
        </div>
        <p class="text-c-warning" *ngIf="inv_contact_no">Invalid number</p>

        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Email Id</p>
            <input placeholder="Enter text(Eg: 5km@gmail.com)" type="email" [(ngModel)]="contactemail" (ngModelChange)="typingEmail()">
        </div>
        <p class="text-c-warning" *ngIf="inv_email">Invalid email</p>

        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Link</p>
            <input placeholder="Enter job application link (Eg: https://www.naukri.com/job-listings-BDM)" type="text" [(ngModel)]="link">
        </div>

        <p class="job-post-text-4 job-post-margin-1">Upload Photo/Logo</p>
        <input type="file" style="display: none;" #jobpostimage id="jobpostimage" (change)="filesSelected($event)">

        <div class="job-post-input-5">
            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image cursor" (click)="jobpostimage.click()">
            <img class="job-post-hiring-image" [src]="post_pic">
        </div>

        <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="step=3" [disabled]="inv_experience || inv_qualification || inv_company_name || inv_email || inv_contact_no">Next</button>
    </ng-container>

    <ng-container *ngIf="step==3">
        <!--<div class="job-post-review-image" [ngStyle]="{'background': 'url('+post_pic+'), #FFFFFF'}"></div> --> 

         <div class="job-post-input-5">
            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image cursor" (click)="editDetails()">
            <img class="job-post-hiring-image" [src]="post_pic">
        </div>

         <div class="job-post-review-input-1">
             <div>
                 <p class="job-post-text-5 job-post-color-1">Job Role</p>
                 <p class="job-post-text-6">{{role}}</p>
             </div>

             <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
         </div>

         <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Job Location</p>
                <p class="job-post-text-6">{{location}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Type of Work</p>
                <p class="job-post-text-6">{{worktype}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Job Type</p>
                <p class="job-post-text-6">{{jobtype}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Job Description</p>
                <p class="job-post-text-6">{{description}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Monthly Salary</p>
                <p class="job-post-text-6">{{salary}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Years of Experience</p>
                <p class="job-post-text-6">{{experience}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Educational Qualification</p>
                <p class="job-post-text-6">{{qualification}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Company Name</p>
                <p class="job-post-text-6">{{companyname}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Contact Number</p>
                <p class="job-post-text-6">{{contactno}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Email Id</p>
                <p class="job-post-text-6">{{contactemail}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>

        <div class="job-post-review-input-1">
            <div>
                <p class="job-post-text-5 job-post-color-1">Link</p>
                <p class="job-post-text-6"><a [href]="link" target="_blank">{{link}}</a></p>
            </div>


            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="editDetails()">
        </div>


        <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="createJobPost()">Create Job Post</button>

    </ng-container>


    <ng-container *ngIf="step==4">
        <div class="job-post-status-div">
            <div>
                <div class="job-post-success-image-div">
                    <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
                </div>
                <h3 class="job-post-text-7 text-center" style="margin-bottom: 12px;">Job post created successfully! </h3>
                <p class="job-post-text-8 job-post-color-1 text-center">Share it with people to create awareness</p>
            </div>

       <!--    <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="shareJobPost()">Share</button>--> 
           
        </div>
    </ng-container>
 </div>