<div class="profile-info-outer-div">
    <!--Profile info left menu div-->
   <div class="profile-info-menu-div">
    <ul class="profile-info-menu-list profile-info-menu-item-text">
       <li class="cursor" [ngClass]="{'active-menu':menu=='profile'}" (click)="changeMenu('profile')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-my-post.svg"> My Profile</li>
     <!--  <li class="cursor" [ngClass]="{'active-menu':menu=='myPost'}" (click)="changeMenu('myPost')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-my-post.svg"> My Post</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='savedPost'}" (click)="changeMenu('savedPost')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-saved-post.svg"> Saved Post</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='shoppingCart'}" (click)="changeMenu('shoppingCart')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-shopping-cart.svg"> Shopping Cart</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='wishlist'}" (click)="changeMenu('wishlist')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-wishlist.svg"> Wishlist</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='pastOrders'}" (click)="changeMenu('pastOrders')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-past-orders.svg"> Past Orders</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='savedAddresses'}" (click)="changeMenu('savedAddresses')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-saved-addresses.svg"> Saved Addresses</li>
       <li class="cursor" [ngClass]="{'active-menu':menu=='helpAndSupport'}" (click)="changeMenu('helpAndSupport')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-help-support.svg"> Help and Support</li>-->
     <!--  <li class="cursor" [ngClass]="{'active-menu':menu=='changeLanguage'}" (click)="changeMenu('changeLanguage')"> <img class="profile-info-menu-icon" src="./../../../../assets/profile/profile-menu-change-language.svg"> Change Language</li> -->
       <li class="cursor" [ngClass]="{'active-menu':menu=='logout'}" (click)="changeMenu('logout')" (click)="logout()"> <div class="profile-info-menu-icon-div"><img class="profile-info-menu-icon-2" src="./../../../../assets/profile/profile-menu-logout.svg"></div> Logout</li>
    </ul>
   </div>
       <!--Profile info left menu div end-->
    <!--Profile info right div-->
    <div class="profile-info-right-div">
      <div class="edit-profile-title d-flex space-between">
        <div class="profile-info-text-2">Edit your profile</div>
        <div class="edit-profile-title-btn" *ngIf="(profile_form.dirty || avatar_update)">
           <button class="btn-blue-1 profile-edit-btn" (click)="updateUserProfile()" [disabled]="name_edit">Update</button>
           <button class="btn-grey-1 profile-cancel-btn" (click)="reInit()">Cancel</button>
        </div>
      </div>

      <div class="mob-edit-profile-title-btn" *ngIf="(profile_form.dirty || avatar_update)">
        <button class="btn-blue-1 profile-edit-btn" (click)="updateUserProfile()" [disabled]="name_edit">Update</button>
        <button class="btn-grey-1 profile-cancel-btn" (click)="reinitProfileForm()">Cancel</button>
     </div>

        <div class="profile-edit-div" [formGroup]="profile_form">
            <div class="profile-info-image-div">
               <img src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="imageinput.click()">
               <img *ngIf="form['avatar'].value" [src]="form['avatar'].value" class="profile-info-image" onerror="this.src='https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/bak-3km/default_profile.svg'">
            </div>

            <input style="display: none" type="file" #imageinput (change)="fileSelected($event)">
            <div class="divider-line"></div>

            <div class="profile-name-div">
                  <img *ngIf="!name_edit && !is_verified" src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="name_edit=!name_edit">
                  <div *ngIf="name_edit" class="profile-cancel-text sub-header-1 cursor blue" (click)="cancelNameEdit()">Cancel</div>
                  <div class="flex-1p5 poppins-16-normal">Name</div>
                  <div class="flex-3 poppins-18-normal" *ngIf="!name_edit && (!form['firstname'].dirty) && !form['lastname'].dirty">{{form['firstname'].value}} {{form['lastname'].value}}</div>
                  <div class="profile-fn-input flex-3" *ngIf="!name_edit && (form['firstname'].dirty || form['lastname'].dirty)"><input class="input-box poppins-18-normal" style="width: auto" disabled value="{{form['firstname'].value}} {{form['lastname'].value}}"></div>
                  <div class="flex-3" *ngIf="name_edit">

                   <div class="border-1 profile-name-input-div p-mt-px-10">

                      <div class="heading-4  mb-px-20">Change Profile Name</div>

                      <div class="profile-fn-input mb-px-20"><input class="input-box poppins-18-normal" placeholder="first name" formControlName="firstname"></div> 

                      <div class="profile-fn-input"><input class="input-box poppins-18-normal" placeholder="last name" formControlName="lastname"></div>
                   
                      <button class="btn-blue-1 profile-btn-1" (click)="name_edit=false">Save</button>
                  
                  </div>
                </div>
            </div>

            <div class="divider-line-2"></div>

            <div class="profile-name-div">
                <!--<img *ngIf="!number_edit" src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="number_edit=!number_edit">
                <div *ngIf="number_edit" class="profile-cancel-text sub-header-1 cursor blue" (click)="number_edit=!number_edit">Cancel</div>  -->
                <div class="flex-1p5 poppins-16-normal">Mobile</div>
                <div class="flex-3 poppins-18-normal">+91 <span style="color:#E9E9EB;margin:0px 10px;">|</span> {{form['phone_no'].value}}</div>
            <!--   <div class="profile-fn-input flex-3" *ngIf="!number_edit && (form['change_phone_no'].dirty)"><input class="input-box poppins-18-normal" style="width: auto" disabled [value]="'91-'+form['phone_no'].value"></div> --> 

           <!--     <div class="flex-3 p-mt-px-10" *ngIf="number_edit">

                  <div class="border-1 profile-name-input-div">

                    <div class="heading-4  mb-px-20">Change Mobile Number</div>

                    <div class="profile-fn-input"><input class="input-box poppins-18-normal" placeholder="Mobile Number" formControlName="change_phone_no"></div> 

                    <button class="btn-blue-1 profile-btn-1" (click)="openOtpModal(otpData)">Send Otp</button>

                </div>
              </div>-->
           
              </div>
             <!--OTP Modal-->
              <ng-template #otpData let-modal>
                <div class="poppins-18-normal modal-close-btn-2 cursor" (click)="modal.close()"><i class="fa fa-close"></i></div>                
                <app-verify-otp [phone_no]="form['phone_no'].value" (changeNumber)="modal.close()" (number_verified)="phoneNumberVerified();modal.close();"></app-verify-otp>
              </ng-template>
             <!--End OTP Modal-->

             <!-- Change Password Modal-->
              <ng-template #changePasswordData let-modal>
                <div class="poppins-18-normal modal-close-btn-2 cursor" (click)="modal.close()"><i class="fa fa-close"></i></div>                
                <app-change-password (changeNumber)="modal.close()" (password_changed)="modal.close();"></app-change-password>
              </ng-template>
             <!-- End Change Password Modal/-->

            <div class="divider-line-2"></div>

            <div class="profile-name-div">
               <!-- <img *ngIf="!email_edit" src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="email_edit=!email_edit">
                <div *ngIf="email_edit" class="profile-cancel-text sub-header-1 cursor blue" (click)="cancelEmailEdit()">Cancel</div>   -->

                  <div class="flex-1p5 poppins-16-normal">Email</div>
                  <div class="flex-3 poppins-18-normal">{{form['email'].value}}</div>
                <!--  <div class="profile-fn-input flex-3 p-mt-px-10 p-email-inp" *ngIf="email_edit"><input class="input-box poppins-18-normal p-email-inp" style="width: auto" formControlName="email"></div>-->
            </div>

            <div class="divider-line-2"></div>

            <div class="profile-name-div">
                  <img *ngIf="!form['dob'].touched && !is_verified" src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="editDob()">
                  <div *ngIf="form['dob'].touched" class="profile-cancel-text sub-header-1 cursor blue" (click)="cancelDobEdit()">Cancel</div>  
                  
                  <div class="flex-1p5 poppins-16-normal">Date Of Birth</div>
                  <div class="profile-fn-input flex-3 p-mt-px-10" *ngIf="form['dob'].touched"><input class="input-box poppins-18-normal" style="width: auto" formControlName="dob" type="date"></div>
             <div class="poppins-18-normal flex-3" *ngIf="!form['dob'].touched">
                   {{form['dob'].value | date:'longDate' }}
              </div> 
            </div>

            <div class="divider-line-2"></div>

            <div class="profile-name-div" style="height: 35px;">
                <img *ngIf="!form['gender'].touched && !is_verified" src="./../../../../assets/profile/profile-info-edit.svg" class="profile-info-edit-image cursor" (click)="genderEdit()">
                <div *ngIf="form['gender'].touched" class="profile-cancel-text sub-header-1 cursor blue" (click)="cancelGenderEdit()">Cancel</div>  

                <div class="flex-1p5 poppins-16-normal">Gender</div>
                  <div class="flex-3 poppins-18-normal d-flex align-center p-mt-px-10" *ngIf="!form['gender'].touched"><img *ngIf="cur_gender_image" class="gender-icon" [src]="cur_gender_image">{{form['gender'].value | titlecase}}</div>
                  <div class="flex-3 poppins-18-normal d-flex p-mt-px-10" *ngIf="form['gender'].touched">
                    <div class="profile-radio-input"><input type="radio" value="male" id="male" formControlName="gender"><label for="male"><img class="gender-icon-2" src="./../../../../assets/profile/female-icon.svg">Male</label> </div>
                    <div class="profile-radio-input"><input type="radio" value="female" id="female" formControlName="gender"><label for="female"><img class="gender-icon-2" src="./../../../../assets/profile/male-icon.svg">Female</label></div>
                    <div class="profile-radio-input"><input type="radio" value="other" id="other" formControlName="gender"><label for="other"><img class="gender-icon-2" src="./../../../../assets/profile/other-icon.svg">Other</label></div>
                  </div>
            </div>
      
            <div class="divider-line-2 p-line-2"></div>

            <div class="profile-name-div p-mt-px-10 p-mt-px-20px" style="height: 35px;">
                  <div class="flex-1p5 poppins-16-normal">Account Password</div>
                  <div class="flex-3 poppins-18-normal p-mt-px-10"><button class="profile-change-pwd-btn blue-5" (click)="openChangePasswordModal(changePasswordData)">Change Password</button></div>
            </div>
        </div>
    </div>
        <!--Profile info right div-->
</div>
