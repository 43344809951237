import { Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
//import * as Vimeo from '@vimeo/player';
import Player from '@vimeo/player';

@Component({
  selector: 'app-post-video-player',
  templateUrl: './post-video-player.component.html',
  styleUrls: ['./post-video-player.component.scss']
})
export class PostVideoPlayerComponent implements OnInit, AfterViewInit {

  vimeo_height= "400";
  @Input() url:any;
  
  @Input() id:any;

  sec_url:any;
  constructor(public dom:DomSanitizer) { }

  ngOnInit(): void {
    this.sec_url= this.dom.bypassSecurityTrustResourceUrl(this.url);
    if(window.innerWidth<600){
      this.vimeo_height= "250";
    }
    if(window.innerWidth>600){
      this.vimeo_height= "400";
    }
 }

  ngAfterViewInit(){
    let options={
        url: this.url,
      //  width: 640,
        autopause: true,
        loop: true,
        fullscreen: true,
        responsive: true,
    }
    const iframe = document.getElementById(this.id);
    if(iframe){
    const player = new Player(iframe,options);
    player.on('play', function() {
  });
    
  }
}

}
