<div class="address-list-div">

  <!--  <div class="d-flex space-between align-center mb-px-20">
        <div class="modal-title-1 d-flex align-center"><img class="modal-back-img cursor" src="./../../../../assets/icons/back-button.svg" (click)="dismissModal()"> User Addresses</div>
        <div (click)="dismissModal()">
            <img class="modal-close-img cursor" src="./../../../../assets/icons/close_black_24dp.svg"></div>
    </div> -->

    <div>

        <div class="address-list-card mb-px-20" (click)="selectAddress(i)" [ngClass]="{'address-list-active-card':selected_address && selected_address.address_id==address.address_id}" *ngFor="let address of address_list;index as i">
            <div class="checkout-font-3 poppins-medium">{{address?.firstname}} {{address?.lastname}}</div>
            <div class="checkout-font-3 poppins-light">{{address?.flat_no}},{{address?.landmark}}</div>
            <div class="checkout-font-3 poppins-light">{{address?.area}},{{address?.city}}-{{address?.pincode}}</div>
        </div>

        <!--<div class="mt-px-10">
           <button class="btn-blue-1 checkout-pay-btn" [disabled]="!selected_address" (click)="saveAddress()">Save Address</button>
        </div>-->

        <!--Address add div-->
  <ng-template #addressData let-modal>
    <app-add-address (cancel)="closeModal()" (success)="addressAdd($event)"></app-add-address>
  </ng-template>
     <!--End address add div-->
    </div>
</div>