import { HttpClient, HttpRequest, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, Subject } from 'rxjs';
import { config } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

  constructor(private http:HttpClient) { }

  getAuthorProfile(payload:{id: number, author_type: string, lang: string},page:number){
    return this.http.post(`${config.endpoint}mobile/newspost/authorprofilenew?page=${page}`,payload);
  }

  getSelfAuthorProfile(payload:{id: number, author_type: string, lang: string},page:number,proxy_user_id:number){
    return this.http.get(`${config.endpoint}website/newspost/selfprofile?page=${page}&proxy_user_id=${proxy_user_id}`);
  }

  followAuthor(payload:{entity_id: number, type: string, entity:string}){
    return this.http.post(`${config.endpoint}mobile/follow`,payload);
  }

  unFollowAuthor(payload:{entity_id: number, type: string, entity:string}){
    return this.http.post(`${config.endpoint}mobile/unfollow`,payload);
  }

  authorFollows(payload:{entity_id: number, entity:string}){
    return this.http.post(`${config.endpoint}mobile/follows`,payload);
  }

  authorFollowers(payload:{entity_id: number, entity:string}){
    return this.http.post(`${config.endpoint}mobile/getfollows`,payload);
  }

  authorAnalytics(payload:{authorId: number,type: string,duration:string}){
     return this.http.post(`${config.endpoint}web/user/webPostAnalytics`, payload);
  }
  
}
