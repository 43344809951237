import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { config } from './../../config/config';

declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  paymentsuccessBehaviourSubject= new BehaviorSubject({});
  constructor(public http:HttpClient) {

  }

  payment(req_data:any,pay_data:any,email?:string){

    let desc='Product purchase';
    var options = {
    "key": "rzp_live_xcgamtZiTgvjWA", // Enter the Key ID generated from the Dashboard
    "currency": "INR",
    "name": "3km",
    "description": desc,
    "order_id": pay_data['rzorder_id'], //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": (response:any) => {
        this.handlePayments(response, pay_data['project_id'], pay_data['type']);
    },
    "prefill": {
      "name": `${req_data.firstname} ${req_data.lastname}`,
      "contact": req_data.phone_no,
      "email": (email) ? email: req_data.phone_no+'@bulbandkey.com'
    },
    "notes": {
      "BAK Order ID":  pay_data['order_id']
    },
  };

  var rzp1 = new Razorpay(options);
  rzp1.on('payment.failed', function (response:any){

  });
  rzp1.open();
}

handlePayments(response:any, project_id:any, type:any){
  if(response['razorpay_payment_id']){
    response['type'] = type
    response['project_id'] = project_id
    this.paymentsuccessBehaviourSubject.next({status: "success", response:response});
  }
 }
}
