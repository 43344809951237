<div class="create-new-page-main-div">
   <div class="create-new-page-header">
      <div class="create-new-page-header-left">
        <div class="create-new-page-back-icon-wrapper cursor" *ngIf="phase<3" (click)="back()"><img height="24" width="24" src="./../../../assets/new-logo/arrow-back-icon.svg"></div>
        <h3 class="create-new-page-font-1" style="margin-bottom: 0px;">{{title}}</h3>
      </div>

      <button *ngIf="phase<3" class="create-new-page-btn-1 create-new-page-font-2" [disabled]="(phase && (!page_name || invalid_page_name)) || (phase==2 && !profile_pic)" (click)="next()">
        Next
      </button>
      <img class="new-create-page-icon-1 cursor" (click)="closeModal()" *ngIf="phase>2" src="./../../../assets/icons/close_black_24dp.svg">
   </div>

   <div class="create-new-page-border-1"></div>

 <ng-container *ngIf="phase==1">
   <input class="create-new-page-input-1 create-new-page-font-5" placeholder="Enter page name" [(ngModel)]="page_name" (ngModelChange)="typingPageName()" maxlength="45">
   <p class="create-new-page-font-6 text-danger" *ngIf="invalid_page_name">{{inv_msg}}</p>
   <div class="create-new-page-info-div">
        <h3 class="create-new-page-font-3">Benefits of making your page on 5km</h3>
        <ul class="create-new-page-list">
            <li class="create-new-page-font-4 create-new-page-color-1 create-new-page-list-item">Builds brand awareness and recognition</li>
            <li class="create-new-page-font-4 create-new-page-color-1">Helps understand your target customers' interests</li>
            <li class="create-new-page-font-4 create-new-page-color-1">Provides platforms to tell your brand's story</li>
        </ul>
   </div>
</ng-container>

  <input type="file" style="display: none;" #newpageimage id="newpageimage" (change)="filesSelected($event)">
 <ng-container *ngIf="phase==2">

    <img class="create-new-page-profile-pic" height="180" width="180" [src]="profile_pic">

    <button class="create-new-page-btn-1 create-new-page-btn-2 create-new-page-font-2" (click)="newpageimage.click()">
        <img src="./../../../assets/new-logo/add-gallery-white.svg" style="margin-right: 6px;">{{profile_pic?'Change':'Upload'}} profile picture
    </button>

    <div class="create-new-page-info-div">
        <h3 class="create-new-page-font-3">Recommendation</h3>
        <p class="create-new-page-font-6 create-new-page-color-2">Few points you should keep in mind when you set profile picture</p>
        <ul class="create-new-page-list">
            <li class="create-new-page-font-4 create-new-page-color-1 create-new-page-list-item">Choose an Image to Represent Your Brand/Company</li>
            <li class="create-new-page-font-4 create-new-page-color-1">Avoid Getting a Blurry Image</li>
        </ul>
   </div>
 </ng-container>

 <ng-container *ngIf="phase==3">
    <div class="create-page-success-image-div">
        <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
    </div>

    <p class="create-new-page-font-3 text-center">You successfully created your page</p>
    <p class="create-new-page-font-4 create-new-page-color-1 create-new-page-list-item text-center">Start posting on your page now</p>
 
    <button class="create-new-page-btn-1 create-new-page-btn-2 create-new-page-font-2" (click)="goToPage()">
       View Page
    </button>
</ng-container>
</div>
