import { Component, HostListener, OnInit, ViewChild, Input, ViewEncapsulation, OnDestroy, ChangeDetectorRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { NewsService } from 'src/app/core/services/news/news.service';
import { OwlOptions} from 'ngx-owl-carousel-o';
import { map } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import * as urlSlug from 'url-slug';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss'],
  styles:[`

  .carousel-control-prev{
    margin-bottom: 90px;
    margin-top: 90px;
  }
  
   .carousel-control-next{
    margin-bottom: 90px;
    margin-top: 90px;
  }
  
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
`]
})
export class NewHomeComponent implements OnInit, OnDestroy, AfterViewInit{
  @Input() posts:any;
  skeleton_posts=[1,2,3,4,5,6];
  news_posts_list=[1,2,3];
  current_location='Select location';

  is_content_loading= false;
  window_width:any= '';
  news_category_list:any=[];
  rwc_banner_list:any=[];
  bwc_banner_list:any=[];

  display_a= false;
  isBrowser= false;

  topslides:any=[];

  show_prev=false;
  resize_window=true;

  featured_stories:any[]=[];

  cur_url:any;
  cur_video_type:any;

  subscription1:any;
  subscription2:any;

  is_local_loaded= false;
  final_posts:any=[];

  lang= this.commonService.getLocalStorage('language');
  //lang='hi';
  token= this.commonService.getLocalStorage('token');

  customOptions2:any;
  customOptions:any;

  customOptionsAdv: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    autoplay: true,
    autoplaySpeed:2000,
    autoplayTimeout: 8000,
   // dots: true,
    pullDrag: true,
    center: false,
    lazyLoad: true,
    dots: false,
    slideBy:1,
    navSpeed: 700,
    navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    items: 2.8,
    nav: false
  }

  opt:any=[];
 
  constructor(public router:Router,
              public newsService:NewsService, 
              @Inject(PLATFORM_ID) private platformId: Object,
              public cdr:ChangeDetectorRef ,public modal:NgbModal,  private commonService:CommonService) {
   
          this.commonService.location_update.subscribe((data)=>{
              this.getLocation();
          })
   }

   refresh() {
       if(this.window_width!==window.innerWidth){
        this.window_width= window.innerWidth;
       this.is_refreshed= false;       
       if(window.innerWidth<600){
        this.customOptionsAdv={...this.customOptionsAdv, items: 1.2 , center: true}
        this.customOptions={...this.customOptions, items: 2.5}
      }else{
        this.customOptionsAdv={...this.customOptionsAdv, items: 4.5,center: false}
        this.customOptions={...this.customOptions,items: 6}
      }
      setTimeout(()=>{this.is_refreshed= true;});
    }
   }

   is_refreshed=true;

   hideMenu(){
      let menu= document.getElementById('desktopcommonmenu');
      let content= document.getElementById('contentarea');
      let maincontent= document.getElementById('maincontent');
      if(menu){
         menu.style.display='none';
      }
      if(content){
        content.style.padding="20px 0px";
    //    content.style.backgroundColor="#E9E9E9";
      }
      if(maincontent){
        maincontent.style.backgroundColor="#F4F3F8";
        maincontent.style.columnGap='0px';
      }
   }

   customOptionsTop = {
    loop: true,
    mouseDrag: true,
    lazyLoad: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    slideBy:2,
    navSpeed: 700,
    navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    items: 3,
    nav: false
  }

  special_post:any=[];

  ngOnInit(): void {
    this.hideMenu();
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      lazyLoad: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      slideBy:5,
      navSpeed: 700,
      navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
      items: 6,
      nav: false
    }

    this.window_width= window.innerWidth;
    window.onresize=()=>{
      this.refresh();
   }

    if(window.innerWidth<600){
      this.customOptionsAdv={...this.customOptionsAdv, items: 1.2 , center: true};
      this.customOptions={...this.customOptions, items: 2.5}
    }

    if(!this.lang){
      this.lang='en';
    }
    let payload={
       lang: this.lang,
       token: this.token
    }

    if(isPlatformBrowser(this.platformId)){
       this.isBrowser= true;
       this.is_content_loading= true;
       this.fetchNewsHomeData(payload);
       this.getLocation();
    }
  }

  ngAfterViewInit(){

  }

  goToUrl(b:any,content:any){
    if(b['imageswcta'][0]['post_id']){
          this.router.navigate(['/post-detail/5km-post-detail/'+b['imageswcta'][0]['post_id']]);
    }else if(b['imageswcta'][0]['video']){
          this.cur_url= b['imageswcta'][0]['video'];
          this.cur_video_type= b['imageswcta'][0]['type']=='vimeo'?'vimeo':'normal';
          this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'});
    }
 }

  goToAllPosts(index:any){
       let posts=this.final_posts[index];
       this.router.navigate(['category/posts'],{queryParams:{id:posts['category']['category_id'],name:posts['category']['name'],tags:JSON.stringify(posts['category']['tags'])}});
  }

  fetchDataAfterLogin(payload:any){
   this.subscription1= this.newsService.fetchNewsHomeData(payload).subscribe((data:any)=>{
       if(this.is_local_loaded){
         this.final_posts=[];
         this.is_local_loaded=false;
       }

        for(let i=1;i<data['data']['result']['finalposts'].length;i++){
              this.final_posts[i]=data['data']['result']['finalposts'][i];
        }
        this.is_content_loading=false;
    })
  }

  init_length=0;
  news_first_half:any=[];
  news_second_half:any=[];

  fetchNewsHomeData(payload:any){
   this.subscription2= this.newsService.fetchNewsHomeData(payload).subscribe((data:any)=>{
          this.init_length=data['data']['result']['finalposts'].length;
          if(this.is_local_loaded){
            this.final_posts=[];
            this.is_local_loaded=false;
          }

         /*  for(let i=2;i<data['data']['result']['finalposts'].length;i++){
                 this.final_posts.push(data['data']['result']['finalposts'][i]);
           }
           this.featured_stories.push(data['data']['result']['finalposts'][1]);
           this.billboard= data['data']['result']['finalposts'][0];*/

           for(let c of data['data']['result']['finalposts']){
          if(c['type']=='news_cat' || (c['type']=='banner' && c['bannertype']=='RWC')){
               if(c['type']=='news_cat' && c['category']['name']=='News & Politics'){
                  this.special_post= [c['category']['posts'][0]]
                  this.news_first_half= c['category']['posts'].slice(1,6);
                  this.news_second_half= c['category']['posts'].slice(6);
               }
               this.final_posts.push(c);
           }
           else if(c['type']=='banner' && c['bannertype']=='BWC'){
               this.topslides= c['banners'];
           }
          }
            this.fetchSecondaryNewsHomeData(payload);
            this.is_content_loading=false;
    })
}

 billboard:any;
 subscription3:any;
 subscription4:any;

fetchSecondaryNewsHomeData= async(payload:any)=>{
 this.subscription3=this.newsService.fetchSecondaryNewsHomeData(payload).subscribe((data:any)=>{

         for(let c of data['data']['result']['finalposts']){
          if(c['type']=='news_cat' || c['type']=='quiz' || (c['type']=='banner')){
            this.final_posts.push(c);
          }
         }
         console.log(this.final_posts);
         this.newsService.getAdvData(this.featured_stories);
         this.display_a= true;
  })
}

fetchSecondaryNewsHomeDataAfterLogin= async(payload:any)=>{
 this.subscription4=this.newsService.fetchSecondaryNewsHomeData(payload).subscribe((data:any)=>{
    for(let i=0;i<data['data']['result']['finalposts'].length;i++){
             this.final_posts[this.init_length+i]= data['data']['result']['finalposts'][i];
        }
  })
}

openVideoModal(content:any, i:any, j:any, event:Event){
  event.stopPropagation();
  let obj= this.final_posts[i]['category']['posts'][j];
  this.cur_url= obj['player'] && obj['player']=='vimeo'? obj['vimeo_url']:obj['video'];
  this.cur_video_type= obj['player'] && obj['player']=='vimeo'? 'vimeo':'normal';
  this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'})
}

openSearchModal(data:any){
   this.modal.open(data,{size:'lg'});
}

nextOwl(id:any){
     let owl = document.getElementById(`owl-${id}`);
}

getPassedData(event:any,j:any){
/*   if(event['startPosition']>1){
     this.show_prev=true;
     this.news_category_list[j]['is_prev']= true;
   }else{
    this.news_category_list[j]['is_prev']= false;
  } */
}

openModal(content:any){
  this.modal.open(content,{ scrollable: true,size:'lg'})
}

selected_location(event:any){
    if(event){
    this.current_location= event;
    }
    this.modal.dismissAll();
}
 latitude:any;
 longitude:any;

getLocation(){
  let val=this.commonService.getLocalStorage('location');
  if(val){
      this.latitude= val['lat'];
      this.longitude= val['lng'];
      this.current_location= val['location'];
  }
}

login(){
  this.token= this.commonService.getLocalStorage('token');
  this.lang= this.commonService.getLocalStorage('language');
  this.cdr.detectChanges();
}

viewPostDetail(id:number,outer_index:number,inner_index:number,lang:string){

   let post=this.final_posts[outer_index]['category']['posts'][inner_index];
   let url= this.urlSlugConverter(post['slug_headline']);
   if(!url || url=='undefined'){
   //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
   //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
       url='5-km-post-detail' 
  }
 //  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);

   let back_btn= sessionStorage.getItem('back_btn');
   if(!back_btn){
      sessionStorage.setItem('back_btn','back_btn');
   }
   this.router.navigate(['/post-detail',url,`${id}`]);
}

viewNewsCategoryPostDetail(id:number,i:number,division:string){

  let post=this.news_first_half[i];

  if(division=='second'){
    post=this.news_second_half[i];
  }
  let url= this.urlSlugConverter(post['slug_headline']);
  if(!url || url=='undefined'){
  //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
  //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
      url='5-km-post-detail' 
 }
//  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);

  let back_btn= sessionStorage.getItem('back_btn');
  if(!back_btn){
     sessionStorage.setItem('back_btn','back_btn');
  }
  this.router.navigate(['/post-detail',url,`${id}`]);
}


viewSpecialPostDetail(id:number){

  let post=this.special_post[0];
  let url= this.urlSlugConverter(post['slug_headline']);
  if(!url || url=='undefined'){
  //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
  //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
      url='5-km-post-detail' 
 }
//  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);

  let back_btn= sessionStorage.getItem('back_btn');
  if(!back_btn){
     sessionStorage.setItem('back_btn','back_btn');
  }
  this.router.navigate(['/post-detail',url,`${id}`]);
}

  urlSlugConverter(headline:string){
   let url= urlSlug.convert(headline,
   {camelCase: false});
   return url;
}

trackByFn(index: number, post: any): string {  
      return post.post_id;  
    }  

    closeModal(){
       this.modal.dismissAll();
    }

    showMenu(){
      let menu= document.getElementById('desktopcommonmenu');
      let content= document.getElementById('contentarea');
      if(menu && window.innerWidth>900){
        menu.style.display='block';
      }
      if(content && window.innerWidth>900){
        content.style.padding="10px 0px 10px 10px";
      }else if(content){
        content.style.padding="10px";
      }
      let maincontent= document.getElementById('maincontent');
      if(maincontent && window.innerWidth>900){
        maincontent.style.backgroundColor="white";
        maincontent.style.columnGap='10px';
      }else if(maincontent){
        maincontent.style.backgroundColor="white";
      }
      }
      

goToAppStore(){
  window.open('http://play.google.com/store/apps/details?id=com.bulbandkey.threekmseller&hl=en_IN&gl=US', "_blank")
}

ngOnDestroy(){
  this.showMenu();
  if(this.subscription1){
    this.subscription1.unsubscribe();
    }
    if(this.subscription2){
    this.subscription2.unsubscribe();
    }
    if(this.subscription3){
    this.subscription3.unsubscribe();
    }
    if(this.subscription4){
      this.subscription4.unsubscribe();
    }
    this.customOptionsAdv= {};
    this.customOptions= null;
}

}
