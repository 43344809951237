import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadLinkComponent } from './download-link/download-link.component';
import { LayoutComponent } from './layout/layout.component';
import { FollowersComponent } from './modules/report/followers/followers.component';
import { JanasevakIndividualResultComponent } from './modules/report/janasevak-individual-result/janasevak-individual-result.component';
import { JansevakContestComponent } from './modules/report/jansevak-contest/jansevak-contest.component';
//import { PostsPageComponent } from './pages/news/posts-page/posts-page.component';
import { TermsAndConditionsComponent } from './shared/terms-and-conditions/terms-and-conditions.component';
import { MyCartComponent } from './pages/common-utility/my-cart/my-cart.component';
import { MyCheckoutComponent } from './pages/common-utility/my-checkout/my-checkout.component';
import { AllOrdersComponent } from './pages/common-utility/all-orders/all-orders.component';
import { ComingSoonComponent } from './pages/common-utility/coming-soon/coming-soon.component';
import { OrderDetailComponent } from './pages/common-utility/order-detail/order-detail.component';
import { NewHomeComponent } from './pages/news/new-home/new-home.component';
import { AllPostsComponent } from './pages/news/all-posts/all-posts.component';
import { HelpAndSupportComponent } from './pages/news/help-and-support/help-and-support.component';
import { NewsTagsComponent } from './pages/news/news-tags/news-tags.component';
import { PollDetailComponent } from './pages/news/poll-detail/poll-detail.component';
import { ShowReelComponent } from './pages/news/show-reel/show-reel.component';
import { SinglePostComponent } from './pages/news/single-post/single-post.component';

const routes: Routes = [

 // { path: '', loadChildren: () => import('./../app/pages/pages.module').then(m => m.PagesModule) },

  { path: 'account', loadChildren: () => import('./../app/account/account.module').then(m => m.AccountModule) },
  { path: 'verification', loadChildren: () => import('./../app/verification/verification.module').then(m => m.VerificationModule) },
  {
    path: 'register',
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'register/:id',
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
  },
  { path: '', redirectTo: '/home', pathMatch:'full' },
  { path: 'shop', loadChildren: () => import("./pages/shop/shop.module").then(m => m.ShopModule) },
  { path: 'food', loadChildren: () => import("./pages/food/food.module").then(m => m.FoodModule) },
  { path: 'feed', loadChildren: () => import("./pages/feed/feed.module").then(m => m.FeedModule) },
  { path: 'business', loadChildren: () => import("./pages/businesses/businesses.module").then(m => m.BusinessesModule) },
  { path: 'polls', loadChildren: () => import("./pages/polls/polls.module").then(m => m.PollsModule) },
  { path: 'user', loadChildren: () => import("./pages/common-utility/common-utility.module").then(m => m.CommonUtilityModule) },
  {
    path: 'author',
    loadChildren: () => import('./pages/author/author.module').then(m => m.AuthorModule)
  },

  { path: 'home', component: NewHomeComponent },
  { path: 'category/posts', component: AllPostsComponent },
  { path: 'post-detail/:title/:id', component: SinglePostComponent },
  { path: 'poll/:id', component: PollDetailComponent },
  { path: 'contact', component: HelpAndSupportComponent },
  { path: 'showreel', component: ShowReelComponent },
  { path: 'tag-all-post/:tag', component: NewsTagsComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: 'cart', component: MyCartComponent },
  { path: 'checkout', component: MyCheckoutComponent },
  { path: 'orders', component: AllOrdersComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'order-detail/:id', component: OrderDetailComponent },

  {
    path: 'biz',
    loadChildren: () => import('./modules/creator-details/creator-details.module').then(m => m.CreatorDetailsModule)
  },
  {
    path: 'sell',
    loadChildren: () => import('./modules/product-details/product-details.module').then(m => m.ProductDetailsModule)
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'content-report',
    component: FollowersComponent
  },
  {
    path: 'jansevak/results',
    component: JansevakContestComponent
  },
  {
    path: 'jansevak/categoryresult',
    component: JanasevakIndividualResultComponent
  },
  {
    path: 'download',
    component: DownloadLinkComponent
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
