<div class="container">
   <div class="row">
       <div class="col">
           <h3 class="title">Followers List</h3>
           <table class="table table" style="margin-bottom: 20px;"> 
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Name</th>
                <th scope="col">Followers</th>
              </tr>
            </thead>
            <tbody *ngIf="followers_list.length!==0">
              <tr *ngFor="let user of followers_list;index as i">
                <td>{{i+1}}</td>
                <td><img class="avatar" [src]="user.avatar" onerror="this.src=`https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/bak-3km/default_profile.svg`">{{user.firstname}} {{user.lastname}}</td>
                <td>{{user.followers}}</td> 
              </tr>
            </tbody>
          </table>
          <div class="text-center loading" *ngIf="followers_list.length==0"><h4>Loading...</h4></div>
       </div>
   </div>
</div>