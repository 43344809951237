import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { NewsService } from 'src/app/core/services/news/news.service';

@Component({
  selector: 'app-news-tags',
  templateUrl: './news-tags.component.html',
  styleUrls: ['./news-tags.component.scss'],
})
export class NewsTagsComponent implements OnInit {
  subscription1: any;
  paramSubscription: any;
  tag: string = '';
  token = this.commonService.getLocalStorage('token');
  post = [];
  featured_posts:any=[];
  billboard:any=[];
  topslides_list:any=[];
  is_content_loading= true;
  skeleton_posts=[1,2,3,4,5,6,7,8,9,10];

  constructor(
    public news_service: NewsService,
    public _route: ActivatedRoute,
    public commonService: CommonService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.paramSubscription = this._route.paramMap.subscribe((data) => {
      console.log('params = ', data);
      console.log(data.get('tag'));
      this.tag = data.get('tag') || '';
    });
    let payload = { tag: this.tag, device: this.token };
    console.log('payload data = ', payload);
    this.news_service.getTagAllPost(payload).subscribe((data: any) => {
      console.log(data);
      this.post = data['data']['result']['posts'];
      this.is_content_loading= false;
    });
    if(window.innerWidth>900){
      this.fetchLatestAdv();
    }
  }

  fetchLatestAdv(){
    let payload={
      token: this.token,
      lang: 'en'
    }

      this.subscription1=this.news_service.fetchAdvAndFeaturedPosts(payload).subscribe((data:any)=>{
            if(data['status']=='success'){
              this.billboard=data['data']['result']['finalposts'][2]?data['data']['result']['finalposts'][2]['hoardings']:[];
              this.topslides_list=data['data']['result']['finalposts'][0]['banners'];
              this.featured_posts[0]=data['data']['result']['finalposts'][1]['business'];
            }
      })
  }

  back_to_news_home() {
    if (window.history.state.navigationId > 1) {
      window.history.back();
      this.commonService.detect_menu_change.next(true);
    } else {
      this.router.navigate(['/home']);
      this.commonService.menuChange('news');
    }
  }
}
