
<div class="all-posts-div" infiniteScroll [infiniteScrollDistance]="1" (scrolled)="onScroll()" id="top">
    
    <div class="back-btn-main-div" style="position: sticky;z-index: 10;">
       <div class="back-btn-div cursor"><img class="back-icon cursor" (click)="back_to_news_home()" src="./../../../../assets/icons/back-button.svg"><span class="ap-font-1">Show Reel</span></div>
       <div class="ap-font-2 text-underline cursor" (click)="back_to_news_home()">All Categories</div>
    </div>

    <div class="posts-list-div" style="max-width: 100%;position: relative;" *ngIf="display_posts.length>0">
    <div *ngFor="let post of display_posts;index as a">   
    <div class="post-detail-card" *ngIf="post['type']=='Post'"  style="max-width: 100%;position: relative;">
    <!--Post detail header div-->
            <div class="post-detail-header-div" >
                <div class="pd-name-div">
                   <img class="post-detail-author-image" [src]="post['author']['image']">
                   <div>
                       <div class="pd-font-1 d-flex align-center">{{post['author']['name']}}<img *ngIf="post['author']['is_verified']" class="verified-icon" src="./../../../../assets/icons/verified-icon.svg"></div>
                       <div class="pd-font-2 d-flex align-center">{{post['author_classification']}}<span class="pd-circle-div"></span><span class="pd-font-3">{{post['created_date']}}</span></div> 
                   </div>
                </div>

                <div class="pd-vertical-div cursor">
                  <img class="pd-vertical-icon" src="./../../../../assets/icons/more_vert-24px.svg">
                </div>
            </div>
    <!--End Post detail header div-->

      <!--Image Div-->
 <div class="pd-image-div" style="max-width: 100%;position: relative">
  <ngb-carousel [showNavigationArrows]="false" [id]="'carousel-'+a" [keyboard]="true" [interval]="0" [showNavigationIndicators]="(post['images'].length+post['videos'].length)>1" [pauseOnFocus]="false" [pauseOnHover]="false" style="max-width: 100%;position: relative;">
    <ng-template ngbSlide *ngFor="let image of post['images']">
      <div class="ap-img-wrapper" (swipeleft)="imageSwipeLeft()">
        <img class="ap-img" [src]="image" (swipeleft)="imageSwipeLeft()" alt="" error="this.display='none'">
      </div>
    </ng-template>

    <ng-template ngbSlide *ngFor="let video of post['videos'];index as v">
      <div class="ap-img-wrapper" (click)="openVideoModal(videoContent,a,v)">
     <!--  <div class="video-btn-div">
          <div><img class="video-btn" src="./../../../../assets/icons/play-btn.svg"></div>
         </div>
        <img class="ap-img" [src]="video['thumbnail']" alt="" error="this.display='none'">
      </div> --> 

      <video width="100%" height="auto" controls *ngIf="video?.player!=='vimeo'">
        <source [src]="video.src">
     </video>

    <div *ngIf="video?.player=='vimeo'">
         <app-post-video-player [url]="video.vimeo_url" [id]="a+1"></app-post-video-player>
    </div>
    </div>
    </ng-template>

  </ngb-carousel>
    </div> 
      <!--End Image Div-->
        
      <div class="pd-views-div justify-center align-center text-center">
             <div class="pd-font-5 text-black pd-v-flex-1">+{{post['impressions']}}</div>
             <div class="pd-v-flex-2">
                  
             </div>
             <div class="pd-font-2 pd-v-flex-3">{{post['views']}} Views</div>
      </div>
      
      <div class="divide-margin"></div>

    <div class="pd-title-div">
      <div class="pd-font-4">{{post['headline']}}</div>
  <!--    <div class="pd-story pd-font-6" [innerHtml]="post['story']"></div> -->
      <div class="pd-font-6" [ngClass]="post['read_more']?'pd-story':'pd-story-max'" [innerHtml]="post['story']" [id]="a+'-pd'"></div>
      <div class="pd-font-1 pd-read-more" *ngIf="post['read_more']"><span class="text-blue text-underline cursor" (click)="post['read_more']=!post['read_more']">Read More</span></div>
    </div>
    <div class="pd-interactions-div">
        <div class="pd-like-share-comment-div">
          <div class="interaction-rm like-i">
          <div class="reactions-popup-div" *ngIf="post?.click_reactions">
            <app-like-reactions (liked)="likePost(loginData,post?.post_id, a, $event);post.click_reactions=false;"></app-like-reactions>
          </div>
           <div *ngIf="post?.is_liked"><img class="interaction-icon like-interaction-filled cursor" src="./../../../../assets/icons/like-filled.svg" (click)="unlikePost(post?.post_id, a)"><span class="pd-likes-count pd-font-1 cursor" (click)="openLikesModal(likesContent,post['post_id'])">{{post['likes']>0?post['likes']:''}}</span></div> 
           <div class="like-interaction-empty" *ngIf="!post?.is_liked" (click)="openReactions(a);$event.stopPropagation()"><img class="interaction-icon cursor" src="./../../../../assets/icons/like-icon.svg"></div> 
          
            <!--Likes list div-->
    
      <!-- Scrollable modal -->
    <!-- Button trigger modal -->


   <!--likes list div-->
<ng-template #likesContent let-modal>
  <app-followers-list (close_modal)="closeModal()" [id]="cur_post_id"></app-followers-list>
</ng-template>
   <!--End likes list div-->

      <!--Login modal start-->
    <ng-template #loginData let-modal>
      <div class="login-modal">
        <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
        <app-login (login_successfull)="modal.close();login_successfull();" [is_modal]="true"></app-login>
      </div>
    </ng-template>
 <!--End login modal-->

 <!--Share Component-->
 <ng-template #shareData let-modal>
  <div class="poppins-18-normal modal-close-btn-2 cursor" style="z-index:200" (click)="modal.close()"><i class="fa fa-close"></i></div>                
  <app-share [url]="share_post_url" (share_success)="postShared($event)"></app-share>
</ng-template>
 <!--zEnd Share Component-->
          
   <!--Vimeo Player-->
   <ng-template #videoContent let-modal>
  <div class="d-flex flex-end" style="padding: 1em;justify-content: flex-end;">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-video-player [url]="cur_url" [type]="cur_video_type"></app-video-player>
</div>
  </ng-template>

  <!--Vimeo Player end-->
          </div>
          <div class="interaction-rm comment-i">
            <img (click)="commentOnPost(post?.post_id,a, loginData)" class="interaction-icon cursor" src="./../../../../assets/icons/comment-2.svg">
        <!--   <img *ngIf="is_commented" class="interaction-icon cursor" src="./../../../../assets/icons/comment-fill-2.svg"> --> 
          </div>
          <div class="interaction-rm share-i">
            <div *ngIf="post?.shares>0" class="share-number-div">{{post?.shares}}</div>
            <img class="interaction-icon cursor" (click)="sharePost(post?.post_id,a, shareData)" src="./../../../../assets/icons/share-icon.svg">
          </div>
        </div>

        <div class="pd-tag-div" (click)="moveToTop()">
            <div>
                <img class="interaction-icon cursor" src="./../../../../assets/icons/grey-bookmark.svg">
            </div>
         </div>
    </div>

  <!--Comments list div-->
    <div class="comments-list-div" *ngIf="post?.can_comment">

      <!--Add comment div-->
        <div class="add-comment-div d-flex">
             <div class="comment-image-div">
                <img class="comment-image" [src]="cur_user?.avatar">
             </div>

             <div class="comment-input-div" style="width: 100%;">   
                <input class="comment-input" #cmtinput placeholder="Type something...">   
                <button class="comment-post-btn" [disabled]="!cmtinput.value" (click)="postComment(post?.post_id,a,cmtinput.value);cmtinput.value=''">POST</button>
             </div>
        </div>
             <!--End Add comment div-->

     <!--Comments list div-->
     <div class="comment-details-div-wrapper">
      <div *ngFor="let comment of post?.all_comments;index as c">
    
     <div class="comment-details-div d-flex" *ngIf="c<post?.comments_length">
        <div class="comment-image-div">
          <img class="comment-image" [src]="comment?.avatar">
       </div>

       <div class="comment-input-div">   
        <div class="d-flex">
          <div class="comment-name-text">{{comment?.username}}</div>
        </div>
          <div class="comment-desc-text">{{comment?.comment}}</div>
       </div>
      </div>
  </div>
</div>
     <div class="pd-font-3 load-comments text-underline text-center cursor" *ngIf="post?.all_comments.length>5" (click)="loadAllComments(a)">{{post?.comments_length<=5?'Load more comments...':'Hide comments'}}</div>
  <!--End of comments list div-->
</div>
<!--End of comments list div-->

  </div>
</div>
</div>
  <!--Skeleton-->
  <div class="sk-all-posts-outer-div" style="min-height: 100vh;min-width: 100%;position: relative;display: block;" *ngIf="is_content_loading"> 
      
    <div class="sk-post mb-em-2" style="background-color: white" *ngFor="let i of skeleton_posts">
       
      <div class="d-flex align-center mb-em-1">
        <div class="sk-circle mr-px-10"></div>
        <div class="sk-rect-div">
          <div class="sk-sm-rect mb-px-10" style="max-width: 50%;min-height: 20px;"></div>
          <div class="sk-sm-rect" style="max-width: 40%;"></div>
        </div>
       </div>

       <div class="sk-all-post-image mb-em-1"></div>
       <div class="sk-rect-div">
        <div class="sk-sm-rect mb-px-10" style="min-height: 20px;"></div>
        <div class="sk-sm-rect" style="min-height: 60px;"></div>
      </div>
      </div>


  </div>

<!--Skeleton end-->

</div>

