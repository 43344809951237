import { Component, OnInit, Output,Input, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {

  address_list:any;
  @Input() selected_address:any;

  @Output() close= new EventEmitter();
  @Output() success= new EventEmitter();

  constructor(public commonservice:CommonService, public modal:NgbModal) { }

  ngOnInit(): void {
       this.addressList();
     //  this.getSelectedAddress();
  }


  getSelectedAddress(){
    let address=this.commonservice.getLocalStorage('selected_address');
    if(address){
          this.selected_address= address;
          this.address_list=[this.selected_address,...this.address_list];
          this.address_list=[...new Set(this.address_list)];
    }
  }

  dismissModal(){
    this.close.emit();
 }

 saveAddress(){
    if(this.selected_address){
    this.commonservice.setLocalStorage('selected_address',this.selected_address);
    }
    this.success.emit(this.selected_address);
 }

  selectAddress(i:number){
     this.selected_address= this.address_list[i];
     this.address_list=[this.selected_address,...this.address_list];
     this.address_list=[...new Set(this.address_list)];
     this.success.emit(this.selected_address);
  }

   addressList(){
     this.commonservice.userAddressList().subscribe((data:any)=>{
       this.address_list= data['addresses'];
     }) 
   }

   openModal(data:any){
    this.modal.open(data,{size:'md', backdrop:'static'});
  }  

  addressAdd(address:any){
       this.address_list= address['addresses'];
       for(let addr of this.address_list){
         if(addr['address_id']== address['choosen_address']){
            this.selected_address= addr;
            break;
         }
       }
       this.saveAddress();
       this.closeModal();
  }

   closeModal(){
      this.modal.dismissAll();
   }

   ngOnDestroy(){
       
   }

}
