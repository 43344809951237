<div class="new-post-main-div">

  <ng-container *ngIf="type=='post'">

    <div class="modal-title-div">
        <div class="modal-title-4 d-flex align-center"><img class="post-back-btn cursor" src="./../../../../assets/icons/back-button.svg" (click)="readyForNewPost();dismissModal()"> Add Post</div>
        <div>
           <!-- <img class="modal-close-img cursor" src="./../../../../assets/icons/close_black_24dp.svg">--></div> 
            <button class="btn no-btn post-submit-btn" [disabled]="!latitude || !longitude || !location || (post_images.length<1 && post_videos.length<1 && !description) || (img_count !==(post_videos.length + post_images.length) || (youTubeLink  && !thumbnailSrc))" (click)="uploadPostData()">POST</button>
    </div>

    <div class="modal-content-div">

    <label class="np-font-1 np-font-color-1">Post Location<span class="np-font-2 np-font-color-1">(required)</span></label>
    <div class="np-font-4 np-font-color-3 cursor">{{location}}<span class="np-font-3 np-font-color-2" style="white-space: nowrap;" (click)="type='location'"> {{location?'Change Location':'Add Post Location'}}</span></div>
    <div class="np-border-1"></div>


    <label class="np-font-1 np-font-color-1">Media<span class="np-font-2 np-font-color-1">(required)</span></label>
    <input id="fileinp" #fileinp type="file" style="display: none;" multiple (change)="filesSelected($event)">

    <div *ngIf="!youTubeLink">

        <div  class="np-media-div d-flex align-center justify-center" *ngIf="!progressInfo|| progressInfo.length<1 ">
            <div>
                <img class="new-post-gallery-img-2" style="display: block;margin: 0px auto;" src="./../../../../assets/icons/gallery-blue-2.svg">
                <div class="np-font-3 np-font-color-2 cursor np-add-media" (click)="fileinp.click()">Add Photos/Videos</div>
            </div>
        </div>
    </div>

    <div *ngIf="progressInfo.length>0">
           
        <div class="newpost-image-preview-div">
           <div class="newpost-preview-img d-flex align-center justify-center" [ngStyle]="{'background-image':'url('+img.url+')'}" *ngFor="let img of progressInfo;index as i;">
                <div class="news-post-img-close cursor" (click)="deleteSelectedFile(img,i)"><img class="news-post-red-close-icon" src="./../../../../assets/icons/red-close-icon.svg"></div>
                <div class="np-video-icon-div" *ngIf="img.type=='video'"><img height="20" width="20" src="./../../../../assets/icons/videocam_black.svg"></div>
                <div class="np-loader" *ngIf="img.value<100 && !img.url">
                </div>
                <div class="np-font-3" style="color: white;margin-left: 5px;width: 30px;" *ngIf="img.value<100 && !img.url">{{img.value}}%</div>
           </div>
        </div>

        <div class="np-media-div-2 d-flex align-center justify-center mt-px-10">
            <div>   
                <div class="np-font-3 np-font-color-2 cursor np-add-media" (click)="fileinp.click()"><img class="new-post-gallery-img-2" src="./../../../../assets/icons/gallery-blue-2.svg">Add Photos/Videos</div>
            </div>
        </div>

   </div>
   <p *ngIf="!youTubeLink && progressInfo.length == 0 " class="d-flex justify-center mt-3">OR</p>
   <div class="input-margin-1" *ngIf="progressInfo.length == 0 ">
    <label class="np-font-1 np-font-color-1">Add YouTube Link</label>

    <div style="position: relative;">
    <input type="text" placeholder="Enter your URL here" class="np-custom-input" [(ngModel)]="youTubeLink"  (blur)="onInputBlur()">

    </div>
   </div>
   

    <div class="input-margin-1">
        <label class="np-font-1 np-font-color-1">Description<span class="np-font-2 np-font-color-1">(required)</span></label>
        <div style="position: relative;">
            <textarea class="np-custom-input" placeholder="Enter your description here" [rows]="desc_rows" [(ngModel)]="description" maxlength="2000" (ngModelChange)="typingDescription()"></textarea>
            <div class="np-font-3 np-font-color-1 limit-text" *ngIf="description">{{description.length}}/2000</div>
        </div>
    </div>


<!-- Expanded tile -->
<div class="tile  mt-4 p-4 " >
   
        <div class="row advance-setting ml-3">
           <div class="row">
            <img src="../../../../assets/news-thumbnail/settings.png" alt="3km advance-setting" width="25" height="25">
            <h5  (click)="toggleExpansion()"> &nbsp;Advance setting</h5>
           </div>
            <img class="np-advance-dropIcon " [class.rotate-img]="!expanded" src="./../../../../assets/news-thumbnail/expand_more_black.png" width="25">
        </div>
        
 
   <img id="previewThumbnail" [src]="thumbnailSrc" alt="3km thumbnail" *ngIf="thumbnailSrc !== ''">
    <div *ngIf="expanded" class="mt-3">
        <div class="p-3 np-thumbnail-div advance-setting"  (click)="open(thumbnailOption)" ><p class="np-thumbnail-text"><span *ngIf="thumbnailSrc !== ''">Edit</span> <span *ngIf="thumbnailSrc === ''">Create</span> Thumbnail</p><img class="post-options-icon" src="./../assets/icons/chevron_right.svg"></div>
        <p *ngIf="youTubeLink && thumbnailSrc == ''" style="color:red">Required</p>
        <div class="input-margin-1">
            <label class="np-font-1 np-font-color-1 text-body">Headline/Title<span class="np-font-2 np-font-color-1 text-body">(required)</span></label>
            <div class="np-font-3 np-font-color-1"> Adding Headine will help your post to reach with more people</div>
            <div style="position: relative;">
                <textarea class="np-custom-input bg-transparent" placeholder="Enter your headline here" [rows]="title_rows" [(ngModel)]="title" maxlength="100" (ngModelChange)="typingTitle()"></textarea>
                <div class="np-font-3 np-font-color-1 limit-text" *ngIf="title">{{title.length}}/100</div>
            </div>    
        </div>
        <div class="input-margin-1">
           
            <label class="np-font-1 np-font-color-1 text-body">External Link<span class="np-font-2 np-font-color-1 text-body">(optional)</span></label>
            <div class="np-font-3 np-font-color-1"> Add your link here to take people to your page/website</div>
            <div style="position: relative;">
            <input type="text" placeholder="Enter your link here" class="np-custom-input" [(ngModel)]="ExternalLink">
            </div>
           </div>
    
        <div class="input-margin-1">
            <label class="np-font-1 np-font-color-1 text-body">Tags<span class="np-font-2 np-font-color-1 text-body">(optional)</span></label>
            <div class="np-font-3 np-font-color-1"> Adding Tags will help your post to reach with more people</div>
            <div style="margin-top: 10px;position: relative;">
                <div class="d-flex" style="gap: 15px;width: 100%;flex-flow: row wrap;">
                    <div class="np-tag np-font-4 np-font-color-3" *ngFor="let t of tags"><div>{{t}}</div> <img class="cursor" (click)="deleteTag(t)" src="./../../../../assets/icons/cancel_black.svg" height="20" width="20"></div>
                    <input class="np-custom-input-2 bg-transparent" placeholder="Enter your tag here" [(ngModel)]="tag" (keyup)="typingTag($event)">
                </div>
            </div>
            <div class="np-border-1"></div>
            <div class="np-font-3"> Separate tags with a comma(eg: election,politics)</div>
        </div>
    </div>
  </div>


 

    <!--Select gallery div starts here
    <div *ngIf="type=='selectmedia'">
    <div class="select-gallery-div" *ngIf="preview_url.length==0">
        <input id="fileinp" #fileinp type="file" style="display: none;" multiple (change)="filesSelected($event)">
        <div>
            <div>
                <img class="new-post-gallery-img" src="./../../../../assets/icons/gallery-grey.svg">
            </div>
            <div class="modal-title-2">Upload Images/Videos From Your Computer/Device</div>
      


            <button class="no-btn new-post-upload-btn modal-title-3 font-blue" style="color: #3E7EFF" (click)="fileinp.click()"><img class="new-post-gallery-img-2" src="./../../../../assets/icons/gallery-blue-2.svg">Add From Computer/Device</button>
    
    
           
        </div>
   </div>-->
    <!--Select gallery div ends here-->

    <!--Image preview starts here
       <div *ngIf="preview_url.length>0">
           
            <div class="newpost-image-preview-div">
               <div class="newpost-preview-img" [ngStyle]="{'background-image':'url('+img+')'}" *ngFor="let img of preview_url;index as i;">
                  <div class="news-post-img-close cursor" (click)="deleteSelectedFile(i)"><img class="news-post-red-close-icon" src="./../../../../assets/icons/red-close-icon.svg"></div>
               </div>
            </div>

            <div class="new-post-btn-div desktop-only">
                <button class="btn-blue-1" (click)="moveToForm()" style="padding: 5px 30px;margin: 50px auto 25px;display:block;letter-spacing: 0.8px;" (click)="uploadMedia()">Next</button>
            </div>
       </div>-->
    <!--Image preview ends here
    </div>-->

   <!--Post form starts here
    <div class="post-form-div" *ngIf="type=='form'">

        <div class="post-form-input-div">
            <label class="modal-title-3">HEADLINE</label>
            <textarea class="form-control modal-title-3" rows="2" placeholder="Write post headline here"></textarea>
        </div>

        <div class="post-form-input-div">
            <label class="modal-title-3">DESCRIPTION</label>
            <textarea class="form-control modal-title-3" rows="5" placeholder="Write post description here"></textarea>
        </div>

        <div class="post-form-input-div">
            <label class="modal-title-3">LOCATION</label>
            <input class="form-control" type="text">
        </div>

        <div class="post-form-input-div">
            <label class="modal-title-3">TAGS</label>
            <input class="form-control" type="text">
        </div>

        <div class="new-post-btn-div desktop-only">
            <button class="btn-blue-1 modal-title-3" (click)="moveToForm()" style="padding: 5px 45px;margin: 50px 0px 30px;display:block;letter-spacing: 0.8px;">Post</button>
        </div>

    </div>-->
   <!--Post form ends here-->

</div>
   <!--Modal content div ends here-->

</ng-container>

<ng-container *ngIf="type=='location'">
        <app-post-location (success)="postLocationAdded($event)" (cancel)="type='post'"></app-post-location>
</ng-container>

<ng-container *ngIf="type=='uploading'">
    <div class="np-post-status-div">
        <div class="np-close-icon" (click)="readyForNewPost();dismissModal()">
            <img height="30" width="30" class="cursor" src="./../../../../assets/icons/close_black_24dp.svg">
        </div>
        <div>
            <div class="np-loader-2" *ngIf="msg=='Uploading post'"></div>
            <div class="np-success-image-div" *ngIf="msg=='Post Uploaded successfully'">
                <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
            </div>
            <div class="modal-title-4 text-center" style="margin-top: 10px;">{{msg}}</div>
           <!-- <button class="btn no-btn post-submit-btn" style="display: block;margin: 25px auto;" (click)="readyForNewPost()" *ngIf="msg=='Post Uploaded successfully'">Create Post</button>-->
        </div>
       
    </div>
</ng-container>


<ng-template #thumbnailOption let-modal>
   <app-post-thumbnail  (close)="closeModal()"></app-post-thumbnail>
  </ng-template>



</div>

