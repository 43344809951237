import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewEncapsulation, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorService } from 'src/app/core/services/author/author.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { NewsService } from 'src/app/core/services/news/news.service';
import * as urlSlug from 'url-slug';
import {config} from './../../../core/config/config';

@Component({
  selector: 'app-all-posts',
  templateUrl: './all-posts.component.html',
  styleUrls: ['./all-posts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }

    .carousel-control-prev{
      margin-bottom: 90px;
      margin-top: 90px;
    }
    
     .carousel-control-next{
      margin-bottom: 90px;
      margin-top: 90px;
    }

    .cust-modal{
       width: 100vw;
       height: 100vh;
    }

    @media (max-width: 900px) {
 
      .carousel-indicators li {
        position: relative;
        top: 42px;
        width : 8px!important;
        background: #D5D5D5 0% 0% no-repeat padding-box;
        height: 8px !important;
        border-radius: 50% !important;
      }
      
      .carousel-indicators .active {
        width : 10px!important;
        background-color: #3E7EFF;
        background: #3E7EFF 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 41px;
        height: 10px !important;
        border-radius: 50% !important
      }
    }
  
    @media (min-width: 900px) {
       .carousel-indicators li {
        width : 10px!important;
        height: 10px !important;
        border-radius: 50% !important
      }
      
       .carousel-indicators li {
        position: relative;
        top: 51px;
        width : 8px!important;
        background: #D5D5D5 0% 0% no-repeat padding-box;
        height: 8px !important;
        border-radius: 50% !important;
      }
      
        .carousel-indicators .active {
        width : 10px!important;
        background-color: #3E7EFF;
        background: #3E7EFF 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 50px;
        height: 10px !important;
        border-radius: 50% !important
      }
    }
  `]
})
export class AllPostsComponent implements OnInit, AfterViewInit, OnDestroy {

  all_posts=[];
  all_posts_ids=[];

  is_liked=false;
  is_commented=true;
  click_reactions=false;

  comments_list=[];

  is_content_loading=false;
  skeleton_posts=[1,2,3,4,5,6,7,8,9,10];

  youtubewidth:any;

  showNavigationArrows = false;
  showNavigationIndicators = true;
  images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);

  lang= this.commonService.getLocalStorage('language');
  //lang='hi';

  category_id=this._route.snapshot.queryParams['id']?this._route.snapshot.queryParams['id']:'';
  tags=this._route.snapshot.queryParams['tags']?JSON.parse(this._route.snapshot.queryParams['tags']):[];
  category_name=this._route.snapshot.queryParams['name']?this._route.snapshot.queryParams['name']:'';
  page_no=1;
  curpage_post_ids=[];
  display_posts:any=[];
  d_posts=[];
  is_local_loaded= false;

  featured_posts:any=[];
  billboard:any=[];
  topslides_list:any=[];

  cur_url:any;
  cur_video_type:any;
  token=  this.commonService.getLocalStorage('token');
  cur_index:any;
  cur_post_id:any;
  cur_user= this.commonService.getLocalStorage('user');

  cmttext:any='';
  left_display:any='';
  right_display:any='';

  isBrowser= false;

  subscription1:any;
  subscription2:any;
  subscription3:any;
  subscription4:any;
  subscription5:any;

  reactions:any={
    like: './../../../../assets/lottie-icons/Like.svg',
    love: './../../../../assets/lottie-icons/Love.svg',
    care: './../../../../assets/lottie-icons/Care.svg',
    angry: './../../../../assets/lottie-icons/Angry.svg',
    sad: './../../../../assets/lottie-icons/Sad.svg',
    laugh: './../../../../assets/lottie-icons/Laugh.svg'
 }

  @HostListener('document:click', ['$event']) onDocumentClick(event:any) {4
      if(this.cur_index){
      this.display_posts[this.cur_index]['click_reactions']= false;
      }
      event.stopPropagation();
  }

  constructor(confi: NgbCarouselConfig,public router:Router,public modal:NgbModal,public _route:ActivatedRoute, public news_service:NewsService,
              public cdr:ChangeDetectorRef, public commonService:CommonService, public authorservice:AuthorService,
              public meta:Meta, public ngbModal:NgbModal,@Inject(PLATFORM_ID) private platformId: Object) {
    // customize default values of carousels used by this component tree
    this.commonService.user_data.subscribe((data)=>{
      this.token=  this.commonService.getLocalStorage('token');
    })
  }

  ngOnInit(): void {
      if(!this.lang){
        this.lang='en';
      }
      if(isPlatformBrowser(this.platformId)){
        this.isBrowser= true;
        this.getLocalPosts();
        if(window.innerWidth>900){
          this.fetchLatestAdv();
        }
        this.cdr.detectChanges(); 
      }
  }

  getLocalPosts(){
     let posts= this.commonService.getLocalStorage('all_posts');
     if(posts){
       this.is_local_loaded= true;
       this.display_posts= posts;
       setTimeout(()=>{
        this.fetchCategoryAllPosts();
       },1500);
     }else{
       this.is_content_loading= true;
       this.fetchCategoryAllPosts();
     }
  }

  moveToTop(){
    let top=document.getElementById('top');
    top?.scrollIntoView({behavior:'smooth'});
  }
  //view all of a category
  fetchCategoryAllPosts(){
     let payload={
          category: this.category_id,
          tags: this.tags,
          lang: this.lang,
          token: this.token
     }
     this.subscription1= this.news_service.categoryViewAll(payload).subscribe((data:any)=>{
         this.all_posts= data['data']['result']['posts'];
         this.fetchViewAllPosts();
         this.moveToTop();
     })
  }

  openLikesModal(content:any,a:any,i:number){
    this.cur_post_id= a;
    this.modal.open(content,{ scrollable: true,size:'lg'})
   // this.goToPostDetail(i);
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  goToAuthorProfile(author:any,t:any){
    let type=t[0];
    this.router.navigate([`/author/profile/${author['id']}-${type}`])
}
  fetchViewAllPosts(){
    this.curpage_post_ids=[];

    if(this.is_local_loaded){
      this.display_posts=[];
      this.is_local_loaded= false;
    }

    for(let i=((this.page_no-1)*10);i<this.all_posts.length && i<this.page_no*10;i++){
      this.curpage_post_ids.push(this.all_posts[i]['post_id']);
    }
      let payload={
          category:parseInt(this.category_id),
          tags: this.tags,
          post_ids: this.curpage_post_ids,
          page: this.page_no,
          token: this.token,
          lang: this.lang
      }
      this.subscription2= this.news_service.fetchCategoryViewAllPosts(payload).subscribe((data:any)=>{
            for(let post of data['data']['result']['posts']){
               let post_info=post;
               post_info['read_more']= true;
               post_info['can_comment']= false;
               post_info['comments_length']= 5;
               post_info['click_reactions']= false;
               post_info['all_comments']=[];

               if(post_info['latest_comment'] && post_info['latest_comment']['user']){
                let c=post_info['latest_comment']['user'];
                c['comment']=post_info['latest_comment']['comment'];
                c['username']= post_info['latest_comment']['user']['name'];
                post_info['all_comments'].push(c);
              }
             /* if(post_info['comments']>0){
                post_info['right_display']= `${post_info['comments']} ${post_info.comments>1?'Comments':'Comment'}`;
              } */

              if(post_info['views']>50){
                post_info['right_display']= `${post_info['views']} Views`;
              }
              else if(post_info['comments']>0){
                post_info['right_display']= `${post_info['comments']} ${post_info.comments>1?'Comments':'Comment'}`;
              }

               this.display_posts.push(post_info);
            }  
            this.is_content_loading=false;
      })
      setTimeout(()=>{
          this.dom();
      },1000);
      if(isPlatformBrowser(this.platformId)){
        setTimeout(()=>{
          let id=document.getElementById('videocontainer');
                  if(id){
                     this.youtubewidth=id.clientWidth;
                }},2000);
              }
  }

  goToPostDetail(i:number){

    let url= this.urlSlugConverter(this.display_posts[i]['slug_headline']);
    if(!url || url=='undefined'){
    //   url= post['headline']?post['headline'].replaceAll(' ','-'):`${this.lang}-lang`;
    //   url=post['headline']?(post['headline'].replaceAll(/\s+/g,'-')).replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''):`${this.lang}-lang`;
        url='5-km-post-detail' 
   }
  //  this.router.navigate(['/post-detail',url,`${id}-${this.lang}`]);
 
   // this.router.navigate(['/post-detail',url,`${this.display_posts[i]['post_id']}`]);

    window.open(`/post-detail/${url}/${this.display_posts[i]['post_id']}`,'_blank');
  }

  back_to_news_home(){
      this.router.navigate(['/home'])
  }

  toggle_like_button(type:string){
      if(type=='like'){
        this.is_liked=true;
        this.click_reactions=true;
      }else{
      this.is_liked=false;
       this.click_reactions=false;
      }
  }

  openModal(content:any){
    this.modal.open(content,{ scrollable: true,size:'lg'})
  }

  openVideoModal(content:any, i:any, v:any){
    let obj= this.display_posts[i];
    this.cur_url= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? obj['videos'][v]['vimeo_url']:obj['videos'][v]['src'];
    this.cur_video_type= obj['videos'][v]['player'] && obj['videos'][v]['player']=='vimeo'? 'vimeo':'normal';
    this.modal.open(content,{ centered: true, windowClass: 'dark-modal', size:'lg'})
  }

  dom(){
      for(let i=0;i<this.display_posts.length;i++){
          let elem=document.getElementById(`${i}-pd`);
          if(elem && elem?.clientHeight<100){
              this.display_posts[i]['read_more']=false;
              elem.classList.remove('pd-story');
              elem.classList.add('pd-story-max');
          }
      }
   //   this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  likePost(data:any,id:any,i:any,event:Event){
      //  this.display_posts[i]['click_reactions']= false;
        if(!this.token){
           this.ngbModal.open(data,{size:'md'});
        }
        else{
          this.display_posts[i]['is_liked']=true;
          if(event && this.display_posts[i]['list_emotions'].indexOf(event)==-1){
            this.display_posts[i]['list_emotions'].push(event);
          }

          let payload={
             module:'news_post',
             entity_id: id,
             emotion: event
          }

          this.subscription3= this.news_service.like(payload).subscribe((data:any)=>{
              if(data['status']=='success'){
                 this.display_posts[i]['is_liked']= true;
                 this.display_posts[i]['likes']++;
              }else{
                this.display_posts[i]['list_emotions'].pop();
                this.display_posts[i]['is_liked']= false;
                this.display_posts[i]['likes']--;
              }
          });
        }
  }

  unlikePost(id:any,i:any){
      this.display_posts[i]['is_liked']= false;
      this.display_posts[i]['list_emotions'].pop();
      let payload={
         module:'news_post',
         entity_id: id
        }

      this.news_service.unlike(payload).subscribe((data:any)=>{
          if(data['status']=='success'){
             this.display_posts[i]['is_liked']= false;
             this.display_posts[i]['likes']--;
          }else{
            this.display_posts[i]['is_liked']= true;
            this.display_posts[i]['likes']++;
          }
      });
    //  this.goToPostDetail(i);
}

  onScroll(){
      this.is_content_loading= true;
      this.page_no++;
      this.fetchViewAllPosts();      
  }

  openReactions(i:any){
      
         if(this.cur_index){
          this.display_posts[this.cur_index]['click_reactions']= false;
         }
         this.display_posts[i]['click_reactions']= true;
         this.cur_index=i;
       //  this.goToPostDetail(i);
  }

  login_successfull(){
    this.token=  this.commonService.getLocalStorage('token');
    this.cur_user= this.commonService.getLocalStorage('user');
  }

  commentOnPost(id:number,i:number,data:any){

 //   this.goToPostDetail(i);
    
    if(!this.token){
      this.ngbModal.open(data,{size:'md'});
   }
    else{
      this.display_posts[i]['can_comment']=true;
       this.loadComments(id,i);
    }
  }

  loadComments(id:number,i:number){
      let payload={
        entity_id: id,
        module: 'news_post'
      }
     this.subscription4=this.news_service.getComments(payload).subscribe((data:any)=>{
        this.display_posts[i]['all_comments']= data['data']['result']['comments'];
        this.display_posts[i]['all_comments']= this.display_posts[i]['all_comments'].slice().reverse();
      })
  }

  postComment(id:number,i:number,comment:string){
    if(comment.trim()==''){
      return;
    }
    let payload={
      entity_id: id,
      module: 'news_post',
      comment: comment
    }
     let new_comment={
       avatar: this.cur_user.avatar,
       comment: comment
     }
     this.display_posts[i]['all_comments']=[new_comment,...this.display_posts[i]['all_comments']]
     this.display_posts[i]['comments_length']=5;

     this.subscription5= this.news_service.postComment(payload).subscribe((data:any)=>{
        if(data['status']=='success'){
           this.display_posts[i]['all_comments']= data['data']['result']['comments'].slice().reverse();
        }
     })
  }

  url= config.domain;
  share_post_url='';
  post:any;

  urlSlugConverter(headline:string){
    let url= urlSlug.convert(`${headline}`,
    {camelCase: false});
    return url;
  }

  cur_post_index:any;

  sharePost(id:number,i:number,data:any){
    this.cur_post_index= i;
    this.post= this.display_posts[i];  
    this.meta.addTag({ property:'og:site_name', content: '5km'});
    this.meta.addTag({ property:'og:type', content: 'website'});
  //  this.title.setTitle(this.post.headline);
    if(this.post.story.replace( /(<([^>]+)>)/ig, '')){
      this.meta.addTag({ property:'og:description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
      this.meta.addTag({ property:'description', content: this.post.story.replace( /(<([^>]+)>)/ig, '').substring(0,80)});
    }
    if(this.post.thumbnail){
      this.meta.addTag({ property:'og:image', content: this.post.thumbnail});
    }
    else if(this.post.images[0]){
      let image = this.post.images[0].replace('https://bulbandkey-webapp.s3.ap-south-1.amazonaws.com/','https://cdn.bulbandkey.com/fit-in/400x400/')
      this.meta.addTag({ property:'og:image', content: image});
    } else if(this.post.videos[0]){
      this.meta.addTag({ property:'og:video', content: this.post.videos[0]['src']});
      this.meta.addTag({ property:'og:video:secure_url', content: this.post.videos[0]['src']});
      this.meta.updateTag({ property:'og:type', content: 'video.other'});
      this.meta.updateTag({ property:'og:video:type', content: 'video/mp4'});
      if(this.post.videos[0].thumbnail){
        this.meta.addTag({ property:'og:image', content: this.post.videos[0].thumbnail});
      }
    }
    if(this.post.headline.replace( /(<([^>]+)>)/ig, '')){
      this.meta.addTag({ property:'og:title', content: this.post.headline.replace( /(<([^>]+)>)/ig, '')});
    }
    this.meta.addTag({ property:'og:url', content: `https://5km.city/post-detail?id=${this.post.post_id}`});
   
      let slugurl= this.urlSlugConverter(this.post['slug_headline']);
      if(!slugurl){
        slugurl='5-km-post-detail';
      }
      let user_id= this.commonService.getLocalStorage('user_id');
      let proxy_user_ids= this.commonService.getLocalStorage('proxy_user_ids');
      if(!proxy_user_ids){
         proxy_user_ids=[];
      }

       if((user_id && user_id!==this.post['author']['id']) && !proxy_user_ids.includes(this.post['author']['id'])){
        this.share_post_url= `${this.url}post-detail/${slugurl?slugurl:'5-km-post'}/${id}-${user_id}`;
      }else{
        this.share_post_url= `${this.url}post-detail/${slugurl?slugurl:'5-km-post'}/${id}`;
      }
      this.ngbModal.open(data,{size:'md',centered:true});
  }

  postShared(event:any){
    this.display_posts[this.cur_post_index]['shares']++;
  }

  fetchLatestAdv(){
    let payload={
      token: this.token,
      lang: this.lang
    }

      this.subscription1=this.news_service.fetchAdvAndFeaturedPosts(payload).subscribe((data:any)=>{
            if(data['status']=='success'){
              this.billboard=data['data']['result']['finalposts'][2]?data['data']['result']['finalposts'][2]['hoardings']:[];
              this.topslides_list=data['data']['result']['finalposts'][0]['banners'];
              this.featured_posts[0]=data['data']['result']['finalposts'][1]['business'];
            }
      })
  }

  loadAllComments(a:any){
    let num=this.display_posts[a]['comments_length'];
    if(num>5){
      this.display_posts[a]['comments_length']= 5;
    }else{
      this.display_posts[a]['comments_length']= this.display_posts[a]['all_comments'].length;
    }
  }

  slideChange(){
    console.log('Slide change');
    if(this.last_played_id){
      let video= document.getElementById(this.last_played_id) as HTMLVideoElement;
      if(video){
        video.pause();
      }
   }
 }

  followAuthor(author:any,data:any,type:any,i:any){
    let user= this.commonService.getLocalStorage('token');
    if(user){
          this.display_posts[i]['author']['is_followed']= true;
       let payload={
            entity: type,
            type: type,
            entity_id: author['id']
       }
       this.authorservice.followAuthor(payload).subscribe((data:any)=>{
         if(data['status']=='success'){
             this.display_posts[i]['author']['is_followed']= true;
         }
       })
    }else{
      this.modal.open(data,{size:'md'});
    }
  }

  current_video_id=0;
  current_video_v=0;

 /* stopOtherVideos(id:any,v:any){
     this.current_video_id= id;
     this.current_video_v= v;
     var videos= document.querySelectorAll('video');

     var post=document.getElementById(this.current_video_id+"ap-"+this.current_video_v) as HTMLVideoElement | null;
     if(post){
          post.pause();
     }

     var post=document.getElementById(id+"ap-"+v) as HTMLVideoElement | null;
     if(post){
          post.play();
     }
  }*/

  last_played_id:any;

playingVideo(id:any){
   console.log('Playing video'+ id);
  if(this.last_played_id && this.last_played_id!==id){
      let video= document.getElementById(this.last_played_id) as HTMLVideoElement;
      console.log(video);
      if(video){
        video.pause();
        this.last_played_id= id;
      }
   }else{
      this.last_played_id= id;
   }
}


  ngOnDestroy(){
    if(this.subscription1){
    this.subscription1.unsubscribe();
    }
    if(this.subscription2){
    this.subscription2.unsubscribe();
    }
    if(this.subscription3){
    this.subscription3.unsubscribe();
    }
    if(this.subscription4){
    this.subscription4.unsubscribe();
    }
    if(this.subscription5){
    this.subscription5.unsubscribe();
    }
    this.isBrowser= false;
    this.commonService.setLocalStorage('all_posts', this.display_posts);
  }
}
