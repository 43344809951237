import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { config } from '../../config/config';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireDatabase} from '@angular/fire/compat/database';


@Injectable({
  providedIn: 'root'
})
export class FoodService {

  order_update= new Subject();

//  constructor(public http:HttpClient, public firestore:AngularFirestore, public database:AngularFireDatabase) { }
constructor(public http:HttpClient) { }

    getTrendingCategories(payload:any){
      return this.http.post(`${config.endpoint}mobile/restaurant/cuisines/trending/list`,payload);
    }

    restaurantList(payload:{lat:any,lng:any,page:number}){
      return this.http.post(`${config.endpoint}mobile/menu/home`,payload);
    }

    biriyaniRestaurantsList(payload:{lat:any,lng:any,page:number}){
      return this.http.post(`${config.endpoint}mobile/menu/biryani_restaurants`,payload);
    }

    restaurantMenu(id:number){
      return this.http.get(`${config.endpoint}mobile/restaurent/menu?id=${id}`);
    }

    restaurantShippingRates(payload:any){
      return this.http.post(`${config.endpoint}mobile/menu/shiprates`,payload);
    }

    restaurantCheckout(payload:any){
      return this.http.post(`${config.endpoint}mobile/menu/checkout`,payload);
    }

    getFirebaseOrderDetail(date:any, value:any){
    //  return this.database.list(`/orders/${date}`,ref=>ref.orderByChild('order_id').equalTo(value)).valueChanges();
    }

    updateOrder(order:any){
       this.order_update.next(order);
    }

  }
