import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common/common.service';
import { fromEvent, Subscription } from 'rxjs';
@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss'],
})
export class NewPostComponent implements OnInit {
  private storageSubscription: Subscription | undefined;
  images: any[] = [];
  url: any;
  type = 'post';
  // type='uploading';
  desc_rows = 1;
  title_rows = 1;
  description: any = '';
  title: any = '';
  youTubeLink: any = "";
  ExternalLink: any = "";
  location: any = '';
  latitude: any;
  longitude: any;
  post_images: any = [];
  post_videos: any = [];
  filesuploading=false;

  preview_url: any[] = [];

  tags: string[] = [];
  tag: any;
  msg: any = '';
  expanded = true;
  modalReference: NgbModalRef | undefined;
  thumbnailSrc = '';
  thumbnailLink = '';

  @Output() close = new EventEmitter();

  constructor(private commonService: CommonService, private modal: NgbModal) {}

  ngOnInit(): void {
    localStorage.removeItem('thumbnailSrc');
    this.getLocation();
    const storage$ = fromEvent(window, 'storage');
    this.storageSubscription = storage$.subscribe((event: Event) => {
      if (event instanceof StorageEvent) {
        console.log(
          `The value of ${event.key} has been updated to ${event.newValue}`
        );
      }
    });
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  open(content: any) {
    this.modalReference = this.modal.open(content, { size: 'lg' });
  }

  async closeModal() {
    this.thumbnailSrc = localStorage.getItem('thumbnailSrc') || '';
    console.log(this.thumbnailSrc);

    this.modalReference?.close();
  }

  getLocation() {
    let val = this.commonService.getLocalStorage('post_location');
    if (val) {
      this.latitude = val['lat'];
      this.longitude = val['lng'];
      this.location = val['location'];
    }
  }

  ArrayBufferToBinary(buffer: any) {
    var uint8 = new Uint8Array(buffer);
    return uint8.reduce((binary, uint8) => binary + uint8.toString(2), '');
  }

  /*
  upload(file:any,index:any,url:any, resourceURL:any){
    let f:File= file;
    this.progressInfo[index] = { value: 0,url:url,fileName: file.name,type:'',resourceURL:resourceURL};
    if(file.type.indexOf('image')!==-1){
      this.progressInfo[index].type='image';

      var reader= new FileReader();
      var self= this;
      reader.readAsDataURL(f);
      reader.onload= ()=>{
    this.commonService.uploadFileToSignedUrl(url,reader.result?.toString()).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfo[index].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
           this.progressInfo[index].url = resourceURL;
           this.post_images.push(resourceURL);
        }
      },
      error: (err: any) => {
        this.progressInfo[index].value = 0;
      }

    })}
  }else if(file.type.indexOf('video')!==-1){
    this.progressInfo[index].type='video';

    var reader= new FileReader();
    var self= this;
    reader.readAsDataURL(f);
    reader.onload= ()=>{
    this.commonService.uploadFileToSignedUrl(url,reader?.result?.toString()).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfo[index].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
           this.progressInfo[index].url = resourceURL;
           this.post_videos.push(resourceURL);
        }
      },
      error: (err: any) => {
        this.progressInfo[index].value = 0;
      }
    })
   }
  }
  } 


  async filesSelected(event: any){
     this.images= [...this.images,...event.target.files];

       for(let i=0;i<event.target.files.length; i++){
        this.commonService.getSignedUrl({fileextention:event.target.files[i]['type'].split('/')[1],modulename:'news'}).subscribe((data:any)=>{
          this.upload(event.target.files[i],i,data['data']['result']['uploadURL'],data['data']['result']['resourceUrl']);
      })
       }
  }
*/

  uploadThumbnail(file: File): any {
    this.commonService.uploadImage(file, 'thumbnail').subscribe({
      next: (event: any) => {
        // if (event.type === HttpEventType.UploadProgress) {
        //   this.progressInfo[index].value = Math.round(
        //     (100 * event.loaded) / event.total
        //   );
        // } else
        if (event instanceof HttpResponse) {
          this.thumbnailLink = event.body.photo.id;
          console.log(this.thumbnailLink);
          localStorage.removeItem('thumbnailSrc');
          this.createPost();
          //this.post_images.push(event.body.photo.id);
        }
      },
      error: (err: any) => {
        console.log(err);
        // this.progressInfo[index].value = 0;
      },
    });
    return true;
  }

  upload(file: any, index: any) {
    this.progressInfo[index] = {
      value: 0,
      url: '',
      fileName: file.name,
      type: '',
    };
    if (file.type.indexOf('image') !== -1) {
      this.progressInfo[index].type = 'image';
      this.commonService.uploadImage(file).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfo[index].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            this.progressInfo[index].url = event.body.photo.id;
            this.post_images.push(event.body.photo.id);
          }
        },
        error: (err: any) => {
          this.progressInfo[index].value = 0;
        },
      });
    } else if (file.type.indexOf('video') !== -1) {
      this.progressInfo[index].type = 'video';
      this.commonService.uploadVideo(file).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfo[index].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            this.progressInfo[index].url = event.body.video.id;
            this.post_videos.push({ src: event.body.video.id, thumbnail: '' });
          }
        },
        error: (err: any) => {
          this.progressInfo[index].value = 0;
        },
      });
    }
  }

  progressInfo: any = [];
  img_count = 0;

  async filesSelected(event: any) {
    this.filesuploading= true;
    this.images = [...this.images, ...event.target.files];
    for (let i = 0; i < event.target.files.length; i++) {
      this.upload(event.target.files[i], this.img_count + i);
    }
    this.img_count = this.img_count + event.target.files.length;
    /* let reader= new FileReader();
     reader.onload=(e)=>{

        this.url= reader.readAsDataURL(this.images[0]);
     }*/
  }

  typingDescription() {
    if (this.description.length > 50 * this.desc_rows) {
      this.desc_rows = Math.ceil(this.description.length / 50);
    }
    if (this.description.length < 50 * this.desc_rows) {
      this.desc_rows = Math.ceil(this.description.length / 50);
    }
    if (this.description.length < 1) {
      this.desc_rows = 1;
    }
  }

  typingTitle() {
    if (this.title.length > 50 * this.title_rows) {
      this.title_rows = Math.ceil(this.title.length / 50);
    }
    if (this.title.length < 50 * this.title_rows) {
      this.title_rows = Math.ceil(this.title.length / 50);
    }
    if (this.title.length < 1) {
      this.title_rows = 1;
    }
  }

  typingTag(event: any) {
    if (
      (event.keyCode == 13 || event.keyCode == 188) &&
      this.tag &&
      this.tag !== '' &&
      this.tag !== ','
    ) {
      this.tag = this.tag.trim();
      if (this.tag.indexOf(' ') !== -1) {
        this.tag = this.tag.split(' ').join('');
      }
      if (this.tag.indexOf(',') !== -1) {
        this.tag = this.tag.split(',')[0];
      }
      this.tags.push(this.tag);
      this.tag = '';
    }
  }

  dismissModal() {
    this.close.emit();
  }

  deleteTag(tag: any) {
    this.tags = this.tags.filter((a) => a !== tag);
  }

  deleteSelectedFile(img: any, i: number) {
    this.progressInfo.splice(i, 1);
     console.log(this.progressInfo);
    if (img['type'] == 'image') {
      this.deleteImage(img['url']);
    } else if (img['type'] == 'video') {
      this.deleteVideo(img['url']);
     }
     this.img_count--;
    }
 /* deleteSelectedFile(img: any, i: number) {
    this.progressInfo.splice(i, 1);
    if (img['type'] == 'image') {
      this.deleteImage(img['url']);
    } else if (img['type'] == 'video') {
      this.deleteVideo(img['url']);
    }
  }*/

  deleteImage(url: any) {
    this.post_images = this.post_images.filter((a: any) => a !== url);
  }

  deleteVideo(url: any) {
    this.post_videos = this.post_videos.filter((a: any) => a['src'] !== url);
  }

  moveToForm() {
    this.type = 'selectmedia';
  }

  uploadMedia() {}

  postLocationAdded(location: any) {
    this.latitude = location['lat'];
    this.longitude = location['lng'];
    this.location = location['location'];
    this.type = 'post';
  }

  readyForNewPost() {
    this.type = 'post';
    this.desc_rows = 1;
    this.title_rows = 1;

    this.description = '';
    this.title = '';
    // this.location='';
    // this.latitude='';
    // this.longitude='';
    this.post_images = [];
    this.post_videos = [];
    this.msg = '';

    this.progressInfo = [];
    this.img_count = 0;

    this.tags = [];
    this.tag = '';
  }

  readyForNewPost2() {
    this.desc_rows = 1;
    this.title_rows = 1;

    this.description = '';
    this.title = '';
    // this.location='';
    // this.latitude='';
    // this.longitude='';
    this.post_images = [];
    this.post_videos = [];

    this.progressInfo = [];
    this.img_count = 0;

    this.tags = [];
    this.tag = '';
    this.type = 'uploading';
  }

  async uploadPostData() {
    console.log(this.thumbnailSrc);
    if (this.thumbnailSrc != '') {
      const response = await fetch(this.thumbnailSrc);
      const blob = await response.blob();
      this.uploadThumbnail(new File([blob], '3kmThumbnail.png'));
    } else {
      this.createPost();
    }
  }

  onInputBlur() { 
    console.log("sasfa")
    console.log(!this.youTubeLink.includes("youtube.com"),!this.youTubeLink.includes("youtu.be") )
    if (this.youTubeLink.includes("youtube.com") ) {
      //this.youTubeLink = ""
    } else if( this.youTubeLink.includes("youtu.be")) {
     // this.youTubeLink = ""
    } else {
      this.youTubeLink = ""
    }
  }


  async createPost() {
    if (this.youTubeLink) {
      this.post_videos = []
      let youTubeId = ""

      if (this.youTubeLink.includes("youtube.com") || this.youTubeLink.includes("youtu.be")) {
        if (this.youTubeLink.includes("watch?v")) {
          youTubeId = this.youTubeLink.split("watch?v=")[1];
        } else if (this.youTubeLink.includes("shorts/")) {
          youTubeId = this.youTubeLink.split("?")[0].split("shorts/")[1];
        } else if (this.youTubeLink.includes(".be/")) {
          youTubeId = this.youTubeLink.split(".be/")[1];
        }
      }
      this.post_videos.push({ src: this.youTubeLink, thumbnail: '',youtube_id:youTubeId });
    }
    if (this.post_videos.length > 0 && this.post_images.length == 0) {
      this.post_videos[0]['thumbnail'] = this.thumbnailLink;
      this.thumbnailLink = '';
    }
    this.type = 'uploading';
    this.msg = 'Uploading post';
    let page = this.commonService.getLocalStorage('current_page');
    let payload: any = {
      headline: this.title,
    //  story: this.description + (this.ExternalLink ? `<br><a href = "${this.ExternalLink}" >${this.ExternalLink}</a>`: ""),
      story: `${this.description}${(this.ExternalLink ? `<br><a href = "${this.ExternalLink}" >${this.ExternalLink}</a>`: "")}`,
      images: this.post_images,
      videos: this.post_videos,
      tags: this.tags,
      areas: [],
      latitude: this.latitude,
      longitude: this.longitude,
      location: this.location,
      business: [],
      products: [],
      ...(this.thumbnailLink != '' ? { thumbnail: this.thumbnailLink } : {}),
    };
    console.log(page);
    if (page && page['page_id']) {
      payload['page_id'] = page['page_id'];
    }
    console.log(payload);
    this.commonService.createPost(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.msg = 'Post Uploaded successfully';
        this.readyForNewPost2();
      }
    });
  }
}
