import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config/config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  adv_story_data = new Subject();

  constructor(private http: HttpClient) {}

  getAdvData(data: any) {
    this.adv_story_data.next(data);
  }

  fetchNewsHomeData(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/home`,payload);
  }

  fetchSecondaryNewsHomeData(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/homesecond`,payload);
  }

  fetchAdvAndFeaturedPosts(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/adv_featured_post`,payload);
  }

  categoryViewAll(payload:any){
    return this.http.post(`${config.endpoint}mobile/newspost/geolistelastic`,payload);
  }

  getServerlessPostDetail(id:number,token:string,lang:string,no_view: boolean,related:boolean,ip:any,visitor:any,sharer:number){
    let payload={
       post_id: id,
       token: token?token:visitor,
       lang: lang,
       no_view: no_view,
       related: related,
       user_ip: ip,
       sharer: sharer
    };

    return this.http.post(
      'https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-149101b4-b02f-4e0e-ac3e-a069f9084ea5/news_post/detail',
      payload
    );
  }

  getPostDetail(
    id: number,
    token: string,
    lang: string,
    no_view: boolean,
    related: boolean,
    ip:any,
    visitor:any,
    sharer:any
  ) {
    if (no_view) {
      return this.http.get(
        `${config.endpoint}mobile/newspost/detail?id=${id}&token=${token}&lang=${lang}&noview=${no_view}&related=${related}`
      );
    } else {
      return this.http.get(
        `${config.endpoint}mobile/newspost/detail?id=${id}&token=${token}&lang=${lang}&related=${related}`
      );
    }
  }

  getComments(payload: { entity_id: number; module: string }) {
    return this.http.post(`${config.endpoint}mobile/comment/all`, payload);
  }

  postComment(payload: { entity_id: number; module: string; comment: string }) {
    return this.http.post(`${config.endpoint}mobile/comment/submit`, payload);
  }

  submitComment(payload: {
    entity_id: number;
    module: string;
    comment: string;
  }) {
    return this.http.post(`${config.endpoint}mobile/comment/submit`, payload);
  }

  fetchCategoryViewAllPosts(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/newspost/listpages`,
      payload
    );
  }

  showReelPosts(payload: any) {
    return this.http.post(
      `${config.endpoint}mobile/newspost/showreel`,
      payload
    );
  }

  like(payload: any) {
    return this.http.post(`${config.endpoint}mobile/like`, payload);
  }

  unlike(payload: any) {
    return this.http.post(`${config.endpoint}mobile/unlike`, payload);
  }

  likesList(payload: { entity_id?: number; module: string }) {
    return this.http.post(`${config.endpoint}mobile/likes`, payload);
  }

  followersList(payload: any) {
    return this.http.post(`${config.endpoint}mobile/getfollows`, payload);
  }

  getTagAllPost(payload: any) {
    console.log('getallPost service = ', payload);
    return this.http.post(
      `${config.endpoint}mobile/newspost/tagsPost`,
      payload
    );
  }
}
