<div class="modal-title-div">
    <div class="modal-title-4 d-flex align-center"><img class="post-back-btn cursor" src="./../../../../assets/icons/back-button.svg" (click)="closeModal()"> Add Location</div>
    <div>
       <!-- <img class="modal-close-img cursor" src="./../../../../assets/icons/close_black_24dp.svg">--></div> 
        <button class="btn no-btn post-submit-btn" style="letter-spacing: 0.8px;" (click)="addAddress()">ADD</button>
</div>

<div class="np-map-div">
    <div style="position: relative;margin-bottom:10px;">
        <input class="map-c-input" style="position: relative;" (keydown.enter)="$event.preventDefault()" placeholder="Search for a location" [(ngModel)]="searchterm" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"  #postsearch>
        <div style="position: absolute;top:8px;right:10px;" (click)="searchterm='';postsearch.click()"><img src="./../../../../assets/icons/close_black_24dp.svg" height="20" width="20"></div>
     </div>

<agm-map [latitude]="latitude" [longitude]="longitude">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
    (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>

     <div class="address-input-div">
         <div class="map-font-2 font-grey">Address</div>
         <div class="map-font-2">{{place}} </div>
     </div>
</div>