<ng-template #imageSelector>
    <div class="ev-img-div">
        <!-- <img class="ev-edit-icon" src="./../../../assets/profile/profile-info-edit.svg" alt=""
            (click)="fileInput.click()"> -->
        <div class="allImg">
            <div class="selectedImage" *ngFor="let url of imageUrls; let i = index">
                <img style=" justify-content: center;" id="previewImg" [src]="url" alt="5km-post-Image" width="158">
                <img (click)="deleteImg(i)" class="deleteImg" src="./../../../assets/icons/red-close-icon.svg" alt=""
                    width="18">
            </div>
        </div>
        <input type="file" id="fileInput" #fileInput (change)="handleFileInput($event)" hidden accept="image/*"
            multiple />

        <button (click)="fileInput.click()" id="addMoreImg" class="btn btn-secondary mt-2"><img
                src="../../../assets/news-thumbnail/add_photo_alternate_black.png" width="20" alt=""> Add More
            Photos</button>
    </div>
</ng-template>



<div class="job-post-main-div">
    <div class="d-flex space-between align-center" *ngIf="step<4" style="margin-bottom: 30px;">
        <div class="d-flex align-center justify-center"><img style="margin-right: 10px;" class="job-post-icon-1 cursor"
                (click)="goBack()" src="./../../../assets/icons/back-button.svg">
            <h2 class="job-post-text-1" style="margin-bottom: 0px;">{{step<3?'Ad Details':'Review Ad'}}</h2>
        </div>
        <p class="job-post-text-2 job-post-color-1 desktop-only">step {{step}}/3</p>
    </div>
    <div class="job-post-close-icon" *ngIf="step==4" (click)="closeModal()">
        <img class="cursor job-post-close-icon-2" src="./../../../../assets/icons/close_black_24dp.svg">
    </div>
    <ng-container *ngIf="step==1">
        <p style="margin-bottom: 8px;">I am looking for a<strong class="text-c-warning">*</strong></p>
        <div class="type-of-work-div">
            <div><input style="margin-right: 8px;" type="radio" name="lookingFor" value="Bride" id="Bride"
                    [(ngModel)]="lookingFor"
                    (change)="imageUrls[0] = 'https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/ad3f6c70-d76f-11ed-a780-b53db26335a7.png'">
                <label for="Bride"> Bride</label>
            </div>
            <div><input style="margin-right: 8px;" type="radio" name="lookingFor" value="Groom" id="Groom"
                    [(ngModel)]="lookingFor"
                    (change)="imageUrls[0] = 'https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/b9d418a0-d76f-11ed-a780-b53db26335a7.png'">
                <label for="Groom"> Groom</label>
            </div>
        </div>
        <h2 class="job-post-text-3 job-post-margin-2">Enter your details</h2>
        <p>Adding your details here will help people understand your profile better</p>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Location (City)</p>
            <input id="cphno" placeholder="Enter text (Eg: Pune, Maharashtra)" type="text" [(ngModel)]="location"
                appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Gender<strong class="text-c-warning">*</strong></p>
            <input id="gender" placeholder="Enter text (Eg: Male/Female)" type="text" [(ngModel)]="gender"
                appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Age<strong class="text-c-warning">*</strong></p>
            <input id="age" placeholder="Enter text (Eg: 27 years)" type="number" [(ngModel)]="age"
                appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Height<strong class="text-c-warning">*</strong></p>
            <input id="height" placeholder="Enter text (Eg: 5’9 feet or 178 cm)" type="text" [(ngModel)]="height"
                appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Educational Qualification</p>
            <input id="Educational" placeholder="Enter text (Eg: Bsc Nursing)" type="text" [(ngModel)]="educational"
                appRemoveLeadingSpace>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Profession</p>
            <input id="profession" placeholder="Enter text (Eg: Nurse)" type="text" [(ngModel)]="profession"
                appRemoveLeadingSpace>
        </div>
        <h2 class="job-post-text-3 job-post-margin-2">Description</h2>
        <p>Add other details or your partner preferences to get faster response </p>
        <div class="job-post-input-3">
            <!-- <p style="margin-bottom: 8px;">Item Description</p> -->
            <textarea class="job-post-text-area" rows="6"
                placeholder="Enter text (Include important details like -family details, partner preferred age, food, religion/caste, salary, interests, or hobbies )"
                [(ngModel)]="description" (ngModelChange)="typingDescription()" appRemoveLeadingSpace></textarea>
            <p class="text-c-warning" *ngIf="inv_description">Invalid input</p>
        </div>
        <div class="job-post-input-1">
            <p style="margin-bottom: 8px;">Phone Number</p>
            <input id="contactno" placeholder="Enter text (Eg: +91-9325360000)" type="number" [(ngModel)]="contactno"
                (ngModelChange)="typingContactNo()" appRemoveLeadingSpace>
        </div>
        <p class="text-c-warning" *ngIf="inv_contact_no">Invalid number</p>
        <h2 class="job-post-text-3 job-post-margin-2">Add Photos</h2>
        <p>Add your photos to get faster response </p>
        <ng-container *ngTemplateOutlet="imageSelector"></ng-container>

        <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="step=2"
            [disabled]="!age || !height || !gender || inv_contact_no">Next</button>

    </ng-container>
    <ng-container *ngIf="step==2">
        <ng-container *ngTemplateOutlet="imageSelector"></ng-container>
        <h2 class="job-post-text-3 job-post-margin-2 mt-3">Looking for a {{lookingFor}}</h2>
        <div class="job-post-review-input-1" *ngIf="location">
            <div>
                <p class="job-post-text-5 job-post-color-1">Location (City)</p>
                <p class="job-post-text-6">{{location}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="gender">
            <div>
                <p class="job-post-text-5 job-post-color-1">Gender</p>
                <p class="job-post-text-6">{{gender}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="age">
            <div>
                <p class="job-post-text-5 job-post-color-1">Age</p>
                <p class="job-post-text-6">{{age}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="height">
            <div>
                <p class="job-post-text-5 job-post-color-1">Height</p>
                <p class="job-post-text-6">{{height}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="educational">
            <div>
                <p class="job-post-text-5 job-post-color-1">Educational Qualification</p>
                <p class="job-post-text-6">{{educational}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="profession">
            <div>
                <p class="job-post-text-5 job-post-color-1">Profession</p>
                <p class="job-post-text-6">{{profession}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="description">
            <div>
                <p class="job-post-text-5 job-post-color-1">Description</p>
                <p class="job-post-text-6">{{description}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <div class="job-post-review-input-1" *ngIf="contactno">
            <div>
                <p class="job-post-text-5 job-post-color-1">Phone Number</p>
                <p class="job-post-text-6">{{contactno}}</p>
            </div>

            <img src="./../../../assets/profile/profile-info-edit.svg" class="job-post-edit-image-2" (click)="step = 1">
        </div>
        <button (click)="createmarriagePost()" class="btn no-btn job-post-btn-1 job-post-text-4"
            style="width:30%">Create POST</button>
    </ng-container>
    <ng-container *ngIf="step==3">
        <div class="job-post-status-div">
            <div>
                <div class="job-post-success-image-div">
                    <img height="40" width="40" src="./../../../../assets/icons/success-tick.svg">
                </div>
                <h3 class="job-post-text-7 text-center" style="margin-bottom: 12px;">AD created successfully!
                </h3>
                <p class="job-post-text-8 job-post-color-1 text-center">Share it with people to create awareness</p>
            </div>

            <!--    <button class="btn no-btn job-post-btn-1 job-post-text-4" (click)="shareJobPost()">Share</button>-->

        </div>
    </ng-container>

</div>