import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-marriage-post',
  templateUrl: './marriage-post.component.html',
  styleUrls: ['./marriage-post.component.scss'],
})
export class MarriagePostComponent implements OnInit ,OnDestroy{
  imageChangedEvent: any;
 
 
  constructor(  public commonService: CommonService,
    ) {}

  @Output() close = new EventEmitter<any>();

  step = 1;
  lookingFor: any = "Bride";
  
  location: any;
  gender: any;
  age: any;
  height: any;
  educational: any;
  profession: any;
  inv_description = false;
  description: any;
  contactno: any;
 // post_pic: any="https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/ad3f6c70-d76f-11ed-a780-b53db26335a7.png";
 // filesToUpload: File[] = [];
  imageUrls: string[] = ["https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/ad3f6c70-d76f-11ed-a780-b53db26335a7.png"];
  finaldesc:string=""
  latitude: any = 18.530823;
  longitude: any = 73.847466;
  postlocation: any = 'Pune, Maharashtra, India';
  post_pic: string[] = [];
  inv_contact_no: boolean = false;
  subscribeObject1: any;
  subscribeObject2: any;
 
  ngOnInit(): void {
    this.getLocation();
  }

  ngOnDestroy(): void {
    this.subscribeObject1.unsubscribe()
    this.subscribeObject2.unsubscribe()
  }

  getLocation() {
    let val = this.commonService.getLocalStorage('post_location');
    if (val) {
      this.latitude = val['lat'];
      this.longitude = val['lng'];
      this.postlocation = val['location'];
    }
  }

  closeModal() {
    this.close.emit();
  }

  goBack() {
    if(this.step == 3) {
      this.closeModal();
    } else if (this.step > 1) {
      this.step--;
    } else {
      this.closeModal();
    }
  }

  typingContactNo() {
    let str: string = `${this.contactno}`;
    if (this.contactno && str.length > 0 && str.length !== 10) {
      this.inv_contact_no = true;
    } else {
      this.inv_contact_no = false;
    }
  }

  typingDescription() {
    let str: string = this.description;
    if (str.trim() == '') {
      this.inv_description = true;
    } else {
      this.inv_description = false;
    }
  }

  handleFileInput(event: Event) {
    let files = (event.target as HTMLInputElement).files!;
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrls.push(event.target.result);
      };
      reader.readAsDataURL(files[i]);
      //this.filesToUpload.push(files[i]);
      this.upload(files[i]);
    }
  }

  deleteImg(i:number) {
    this.imageUrls.splice(i, 1)
   // this.filesToUpload.splice(i,1)
  }

  preparDesc() {
    if (this.location) {
      this.finaldesc = `<p><strong>Description: </strong></p><p>${this.description.trim()}</p>`
    }
    if (this.location) {
      this.finaldesc = `${this.finaldesc} <p><strong>Location: </strong>${this.location} </p>`
    }
    if (this.gender) {
      this.finaldesc = `${this.finaldesc} <p><strong>Gender: </strong>${this.gender} </p>`
      
    }
    if (this.age) { 
      this.finaldesc = `${this.finaldesc} <p><strong>Age: </strong>${this.age} </p>`

    }
    if (this.height) { 
      this.finaldesc = `${this.finaldesc} <p><strong>Height: </strong>${this.height} </p>`

    }
    if (this.educational) { 
      this.finaldesc = `${this.finaldesc} <p><strong>Educational Qualification: </strong>${this.educational} </p>`

    }
    if (this.profession) { 
      
      this.finaldesc = `${this.finaldesc} <p><strong>Profession: </strong>${this.profession} </p>`

    }
    if (this.contactno) {
      this.finaldesc = `${this.finaldesc} <p><strong>Phone Number: </strong><a href="tel:${this.contactno}"> ${this.contactno}</a></p>`
      
    }
  }

  // async filesSelected(event: any) {
  //   for (let i = 0; i < event.target.files.length; i++) {
  //     this.upload(event.target.files[i]);
  //   }
  // }

  upload(file: any) {
    this.subscribeObject1=  this.commonService.uploadImage(file).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event instanceof HttpResponse) {
          this.post_pic.push(event.body.photo.id)
        }
      },
      error: (err: any) => {},
    });
  }

  

  createmarriagePost() {
    this.lookingFor == "Bride" ? this.post_pic.push("https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/ad3f6c70-d76f-11ed-a780-b53db26335a7.png") : this.post_pic.push("https://bakdocdn.sgp1.cdn.digitaloceanspaces.com/general/b9d418a0-d76f-11ed-a780-b53db26335a7.png")

    
    this.preparDesc();
    let page= this.commonService.getLocalStorage('current_page');
    let payload: any = {
      headline: `Looking for a ${this.lookingFor}`,
      story: this.finaldesc,
      images: this.post_pic,
      videos: [],
      tags: ["marriage","matrimony","wedding"],
      areas: [],
      latitude: this.latitude,
      longitude: this.longitude,
      location: this.postlocation,
      business: [],
      products: [],
    };
    console.log(payload);
    if(page && page['page_id']){
      payload['page_id']= page['page_id'];
    }
    this.subscribeObject2 = this.commonService.createPost(payload).subscribe((data: any) => {
      if (data['status'] == 'success') {
        this.step = 3;
        //  this.msg='Post Uploaded successfully';
      }
    });
  }

}
