import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { NewsService } from 'src/app/core/services/news/news.service';

@Component({
  selector: 'app-followers-list',
  templateUrl: './followers-list.component.html',
  styleUrls: ['./followers-list.component.scss']
})
export class FollowersListComponent implements OnInit {

  @Input() id:any;
  followers_list:any=[];

  anonymous_count=0;
  count= 0;

  @Output() close_modal= new EventEmitter<any>(); 

  constructor(public newsService:NewsService) { }

  ngOnInit(): void {
    this.getLikesList();
  }

  closeModal(){
    this.close_modal.emit(true);
  }

  getLikesList(){
      this.newsService.likesList({entity_id: parseInt(this.id), module: 'news_post'}).subscribe((data:any)=>{
        this.followers_list= data['data']['result']['users'];
        this.anonymous_count= data['data']['result']['anonymous_count'];
        this.count= data['data']['result']['count'];
      })
  }

}
